











import { onSSR } from "@konfetti-core/core";
import { ref } from "@vue/composition-api";
import { KftProductShowcase } from "~/components";
import { useGiftcardEvents } from "~/composables";

export default {
  name: "KftGiftcardEvents",

  components: {
    KftProductShowcase,
  },

  props: {
    gtmItemListId: {
      type: String,
      default: null,
    },
  },

  setup() {
    const searched = ref(false);

    const { fetchKonfettiGiftcardEvents, konfettiGiftcardEvents } =
      useGiftcardEvents();

    onSSR(async () => {
      if (!process.client || searched.value) {
        return;
      }

      searched.value = true;
      await fetchKonfettiGiftcardEvents();
    });

    return {
      konfettiGiftcardEvents,
    };
  },
};
