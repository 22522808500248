<template>
  <div class="col-12 d-flex kft-location-info">
    <i class="bi bi-geo-alt-fill font-size-4 me-2" />
    <div>
      <span class="location-heading h6"> {{ title }}</span>
      <div>{{ subtitle }}</div>
    </div>
  </div>
</template>
<script>
import { KftHeading } from "@konfetti-ui/vue";

export default {
  name: "KftLocationInfo",
  components: {
    KftHeading,
  },
  transition: "fade",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.kft-location-info {
  i {
    margin-top: 5px;
  }
}
.location-heading {
  @include font(
    --heading-title-font,
    var(--font-weight--semibold),
    var(--h6-font-size),
    var(--h6-line-height),
    var(--font-family--heading)
  );
}
</style>
