import { render, staticRenderFns } from "./GalleryVideoPlayer.vue?vue&type=template&id=4538af91&scoped=true&"
import script from "./GalleryVideoPlayer.vue?vue&type=script&lang=js&"
export * from "./GalleryVideoPlayer.vue?vue&type=script&lang=js&"
import style0 from "./GalleryVideoPlayer.vue?vue&type=style&index=0&id=4538af91&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4538af91",
  null
  
)

export default component.exports