import Vue from "vue";
import VueCompositionAPI, { reactive, computed } from "@vue/composition-api";
import { useGtm } from "./useGtm";

// We need to register it again because of Vue instance instantiation issues
Vue.use(VueCompositionAPI);

const state = reactive({
  isCartSidebarOpen: false,
  isDateRequestSidebarOpen: false,
  isDateWaitlistSidebarOpen: false,
  isNavigationSidebarOpen: false,
  isFederatedSearchOpen: false,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useUiState = (multiselect) => {
  const { sendViewCart, sendDateRequestSidebarView, sendWaitlistSidebarView } =
    useGtm();
  const isCartSidebarOpen = computed(() => state.isCartSidebarOpen);
  const toggleCartSidebar = (send_to_gtm: boolean) => {
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
    if (send_to_gtm) {
      sendViewCart();
    }
  };

  const isDateRequestSidebarOpen = computed(
    () => state.isDateRequestSidebarOpen,
  );

  const isDateWaitlistSidebarOpen = computed(
    () => state.isDateWaitlistSidebarOpen,
  );

  const toggleDateRequestSidebar = () => {
    if (multiselect) {
      multiselect.value?.toggle();
    }

    state.isDateRequestSidebarOpen = !state.isDateRequestSidebarOpen;

    if (state.isDateRequestSidebarOpen) {
      sendDateRequestSidebarView();
    }
  };
  const toggleDateWaitlistSidebar = () => {
    if (multiselect) {
      multiselect.value?.toggle();
    }

    state.isDateWaitlistSidebarOpen = !state.isDateWaitlistSidebarOpen;

    if (state.isDateWaitlistSidebarOpen) {
      sendWaitlistSidebarView();
    }
  };

  const isNavigationSidebarOpen = computed(() => state.isNavigationSidebarOpen);
  const toggleNavigationSidebar = () => {
    state.isNavigationSidebarOpen = !state.isNavigationSidebarOpen;
  };

  const isFederatedSearchOpen = computed(() => state.isFederatedSearchOpen);
  const toggleFederatedSearchOpen = () => {
    state.isFederatedSearchOpen = !state.isFederatedSearchOpen;
  };

  return {
    isCartSidebarOpen,
    isDateRequestSidebarOpen,
    isDateWaitlistSidebarOpen,
    isNavigationSidebarOpen,
    isFederatedSearchOpen,
    toggleCartSidebar,
    toggleDateRequestSidebar,
    toggleNavigationSidebar,
    toggleFederatedSearchOpen,
    toggleDateWaitlistSidebar,
  };
};

export default useUiState;
