export const useDebounced = (timeout = 600) => {
  const debouncePromise = (fn, time) => {
    let timerId;

    return function debounced(...args) {
      if (timerId) {
        clearTimeout(timerId);
      }

      return new Promise((resolve) => {
        timerId = setTimeout(() => resolve(fn(...args)), time);
      });
    };
  };

  const debounced = debouncePromise((items) => Promise.resolve(items), timeout);

  return {
    debounced,

    debouncePromise,
  };
};
