import { configureContext } from '@konfetti-core/core';
import { useContext as useBaseContext } from '@nuxtjs/composition-api';

const contextPlugin = (ctx, inject) => {
  const sharedMap = new Map();

  const UseKftContext = () => {
    const {
      $kft,
      ...context
    } = useBaseContext();

    return { $kft, ...context, ...$kft };
  };

  configureContext({ UseKftContext });
  inject('sharedRefsMap', sharedMap);
};

export default contextPlugin;
