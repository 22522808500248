<template>
  <div id="contact-form">
    <div
      :id="modalId"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="teamEventModal"
      aria-hidden="true"
      data-test-id="contact-form"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title kft-heading__title h5">
              {{ $t("product.teamEventsForm.title") }}
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="$emit('close')"
            ></button>
          </div>

          <div class="modal-body">
            <div ref="hubSportForm"></div>
            <noscript>{{ $t("product.teamEventsForm.noscript") }}</noscript>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import useScript from "~/utilities/useScript";

export default {
  name: "ContactForm",

  props: {
    modalId: {
      type: String,
      required: true,
    },
    scriptInnerHtml: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    const hubSportForm = ref(null);

    const init = () => {
      hubSportForm.value.innerHTML = "";
      useScript(
        "//js-eu1.hsforms.net/forms/shell.js",
        hubSportForm.value,
        () => {
          const scriptElement = document.createElement("script");
          scriptElement.innerHTML = props.scriptInnerHtml
            ? props.scriptInnerHtml
            : 'hbspt.forms.create({region:"eu1",portalId:"25031492",formId:"9e7effaf-fca4-4ac4-881c-06aadd2b6d6c"});';
          if (hubSportForm.value) {
            hubSportForm.value.appendChild(scriptElement);
          }
        },
      );
    };

    return {
      hubSportForm,
      init,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/_modal.scss";
</style>
