const middleware = {}

middleware['redirect-to-new-app'] = require('../middleware/redirect-to-new-app.js')
middleware['redirect-to-new-app'] = middleware['redirect-to-new-app'].default || middleware['redirect-to-new-app']

middleware['server/events/legacy-event-redirects'] = require('../middleware/server/events/legacy-event-redirects.js')
middleware['server/events/legacy-event-redirects'] = middleware['server/events/legacy-event-redirects'].default || middleware['server/events/legacy-event-redirects']

middleware['server/events/old-event-redirects'] = require('../middleware/server/events/old-event-redirects.js')
middleware['server/events/old-event-redirects'] = middleware['server/events/old-event-redirects'].default || middleware['server/events/old-event-redirects']

middleware['server/general/check-route-existence'] = require('../middleware/server/general/check-route-existence.js')
middleware['server/general/check-route-existence'] = middleware['server/general/check-route-existence'].default || middleware['server/general/check-route-existence']

middleware['server/general/legacy-general-redirects'] = require('../middleware/server/general/legacy-general-redirects.js')
middleware['server/general/legacy-general-redirects'] = middleware['server/general/legacy-general-redirects'].default || middleware['server/general/legacy-general-redirects']

middleware['server/seo/seo'] = require('../middleware/server/seo/seo.js')
middleware['server/seo/seo'] = middleware['server/seo/seo'].default || middleware['server/seo/seo']

export default middleware
