import { parseURL } from "./ufo-copy";

export function getFileExtension(url = "") {
  return url.split(/[?#]/).shift().split("/").pop().split(".").pop();
}

export function parseSize(input = "") {
  if (typeof input === "number") {
    return input;
  }
  if (typeof input === "string") {
    if (input.replace("px", "").match(/^\d+$/g)) {
      return parseInt(input, 10);
    }
  }
}

export function srcIsKonfettiCDN(konfettiCDN, src) {
  const { host } = parseURL(src);

  return host === parseURL(konfettiCDN).host;
}
