<template>
  <div class="kft-cart-product-list__container">
    <!--BEGIN::Alerts-->
    <transition mode="out-in" name="sf-collapse-top">
      <div class="notifications">
        <KftAlert
          v-if="lastRemoved"
          :title="$t('cart.removeItem.title')"
          class="mb-5 kft-alert--info"
        >
          <div class="text-center w-100">
            <KftLink class="text-bold" @click:link="fnUndoRemoveItem">
              <div>{{ $t("cart.removeItem.message") }}</div>
              <div class="text-decoration-underline">
                {{ $t("cart.removeItem.cancel") }}
              </div>
            </KftLink>
          </div>
        </KftAlert>
      </div>
    </transition>
    <!--END::Alerts-->

    <!--BEGIN::Products / Cart -->
    <transition mode="out-in" name="sf-fade">
      <div v-if="totalItems" key="my-cart" class="my-cart">
        <div class="collected-product-list">
          <!--BEGIN::Product-->
          <transition-group name="sf-fade" tag="div">
            <template v-for="(product, index) in items">
              <KftCollectedProduct
                :key="`cart-sidebar-collected-product-${index}`"
                :available-qty="cartGetters.getAvailableStock(product)"
                :gift="cartGetters.isGiftcard(product)"
                :image="cartGetters.getItemImage(product)"
                :is-private="cartGetters.isPrivate(product)"
                :qty="cartGetters.getItemQty(product)"
                class="collected-product"
                data-cy="collected-product-cart-sidebar"
                @cart-action="handleCartAction(product, $event)"
                @input="updateItemQuantity(product, $event)"
                @click:remove="handleRemove(product)"
              >
                <template #title>
                  <div class="kft-collected-product__title">
                    <KftLink
                      :link="
                        localePath(
                          '/e/' + cartGetters.getItemPermalink(product),
                        )
                      "
                    >
                      <KftHeading
                        :level="6"
                        :title="cartGetters.getItemName(product)"
                        class="kft-heading--left heading-title mb-4"
                      />
                    </KftLink>
                    <div
                      v-if="!cartGetters.isGiftcard(product)"
                      class="kft-collected-product__date-and-time"
                      v-html="fnGetDateLabelHtml(product)"
                    ></div>
                  </div>
                </template>
                <template #configuration>
                  <div></div>
                </template>

                <template #price>
                  <KftPrice class="sf-product-card__price">
                    <template #special>
                      <ins class="kft-price__special"
                        >{{ $n(cartGetters.getItemPrice(product), "currency") }}
                      </ins>
                    </template>
                  </KftPrice>
                </template>
              </KftCollectedProduct>

              <div
                v-if="
                  fnGetChildrenHtmlArr(product).length > 0 &&
                  !cartGetters.isGiftcard(product)
                "
                :key="`cart-sidebar-collected-multi-date-details-${index}`"
                class="multi-date-details-card"
              >
                <KftHeading
                  :level="5"
                  :title="
                    $t('cart.multiDateCard.title', {
                      count: fnGetChildrenHtmlArr(product).length + 1,
                    })
                  "
                />

                <!-- We need to show main and children dates in here -->
                <KftBulletPoints
                  class="mt-4 kft-bullet-points--fw-medium font-family-heading"
                  :items-html-arr="[
                    ...fnGetChildrenHtmlArr({ children: { data: [product] } }),
                    ...fnGetChildrenHtmlArr(product),
                  ]"
                />
              </div>
            </template>
          </transition-group>
          <!--END::Product-->
        </div>
      </div>
      <div v-else key="empty-cart" class="empty-cart">
        <div class="empty-cart__banner">
          <KftHeading
            :description="$t('cart.emptyCart.description')"
            :level="2"
            :title="$t('cart.emptyCart.title')"
            class="empty-cart__heading text-center mt-4"
          />
        </div>
      </div>
    </transition>
    <!--END::Products / Cart -->
  </div>
</template>

<script>
import { KftHeading, KftLink, KftPrice } from "@konfetti-ui/vue";
import { cartGetters, useCart } from "@konfetti/composables";
import { computed, onBeforeMount, ref } from "@vue/composition-api";
import KftAlert from "../../molecules/KftAlert/KftAlert.vue";
import { useEventDateHelpers, useGtm } from "~/composables";
import KftBulletPoints from "@/components/atoms/KftBulletPoints/KftBulletPoints";

export default {
  name: "KftCartProductList",
  components: {
    KftBulletPoints,
    KftAlert,
    KftLink,
    KftPrice,
    KftHeading,
    KftCollectedProduct: () =>
      // eslint-disable-next-line node/no-unsupported-features/es-syntax
      import(
        "~/components/organisms/KftCollectedProduct/KftCollectedProduct.vue"
      ),
  },
  props: {},

  emits: {},
  setup() {
    /** =====
     * Variables
     * ====== */
    const { sendAddToCart, sendRemoveFromCart } = useGtm();
    const visible = ref(false);
    const { fnGetDateLabelHtml } = useEventDateHelpers();

    const {
      cart,
      removeItem,
      updateItemQuantity,
      loadCart,
      toggleGiftbox,
      loading: cartLoading,
      fnUndoRemoveItem,
      lastRemoved,
    } = useCart("general-instance");

    const items = computed(() => cartGetters.getItems(cart.value));
    const totals = computed(() => cartGetters.getTotals(cart.value));
    const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
    const { fnGetChildrenHtmlArr } = useEventDateHelpers();

    /** =====
     * Methods
     * ===== */
    const closeRemoveMessage = () => (visible.value = false);
    const handleRemove = (product) => {
      sendRemoveFromCart(product);
      removeItem(product);

      if (!cartGetters.hasGiftcard(cart.value) && cart.value.isGiftboxEnabled) {
        toggleGiftbox();
      }

      closeRemoveMessage();
    };

    const handleCartAction = (product, action) => {
      action === "plus"
        ? sendAddToCart(null, false, product, 1)
        : sendRemoveFromCart(product, 1);
    };

    /** =====
     * Hooks
     * ===== */
    onBeforeMount(() => {
      loadCart();
    });

    return {
      // Attributes
      cart,
      cartLoading,
      fnGetChildrenHtmlArr,

      // Computed
      visible: computed(() => visible.value),
      totals,
      totalItems,
      items,
      lastRemoved,

      // Methods
      loadCart,
      removeItem,
      updateItemQuantity,
      cartGetters,
      closeRemoveMessage,
      handleRemove,
      fnUndoRemoveItem,
      fnGetDateLabelHtml,
      handleCartAction,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .kft-bullet-points {
  --kft-bullet-points-font-size: 12px;
}

.heading-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.notifications {
  @include font(
    --giftcard-tag-font,
    var(--overline-font-weight),
    var(--overline-font-size),
    var(--overline-line-height),
    var(--font-family--primary)
  );
}

.multi-date-details-card {
  padding: var(--spacer-3);
  border-radius: 8px;
  box-shadow: $box-shadow-sm;
  min-width: 390px;
}
</style>
