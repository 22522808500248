import { ValidationError } from "@konfetti/composables/src";
import { Ref } from "@nuxtjs/composition-api";

export enum ErrorStoreErrorType {
  OrderValidationError = "orderValidationError",
  OrderError = "orderError",
  SofortOrderError = "sofortOrderError",
  SEPAOrderError = "sepaOrderError",
  PaypalOrderError = "paypalOrderError",
  CartValidationError = "cartValidationError",
  Stripe3DAuthError = "stripe3DAuthError",
}
export interface StripeValidationError {
  type: "validation_error";
  code: string;
  message: string;
}

export type KftError = ValidationError | StripeValidationError | Error;

export interface ErrorStoreError<T = KftError> {
  type: ErrorStoreErrorType;
  error: T;
}

export type ErrorHandler<T = KftError> = (error: ErrorStoreError<T>) => void;

export type ErrorHandlers = {
  [errorType in ErrorStoreErrorType]?: ErrorHandler;
};

export interface ErrorStore {
  error: Ref<ErrorStoreError | null>;
  setError: (error: ErrorStoreError) => void;
  clearError: () => void;
  registerHandler: (
    type: ErrorStoreErrorType,
    handler: (error: ErrorStoreError) => void,
  ) => void;
}
