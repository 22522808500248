<template>
  <div class="banner-with-search">
    <div class="banner-with-search__container">
      <!--begin::Search box & title-->
      <div class="banner-with-search__search-card">
        <!--begin::Title-->
        <h1
          v-if="text"
          :style="{ color: `${color} !important`, ...textStyle }"
          class="kft-heading__title h3 mb-3"
          v-html="text"
        ></h1>
        <component
          :is="descriptionTag || 'h2'"
          :style="{ color: `${color} !important`, ...textStyle }"
          class="kft-heading__description"
        >
          {{ description }}
        </component>
        <!--end::Title-->
        <!--begin::Search-->
        <div class="mt-2 w-100 banner-with-search__search-input">
          <form
            v-show="!isMounted"
            action=""
            class="aa-Form"
            novalidate=""
            role="search"
          >
            <div class="aa-InputWrapperPrefix">
              <label id="autocomplete-0-label" class="aa-Label">
                <button
                  :style="{
                    backgroundColor: $device.isDesktopOrTablet
                      ? 'var(--c-primary)'
                      : 'white',
                  }"
                  class="aa-SubmitButton"
                  title="Submit"
                  type="submit"
                >
                  <em
                    :style="{
                      color: $device.isDesktopOrTablet
                        ? 'white'
                        : 'var(--c-primary)',
                    }"
                    class="bi bi-search aa-SubmitIcon"
                  />
                </button>
              </label>
            </div>
            <div class="aa-InputWrapper">
              <input
                v-model="placeholderSearchQuery"
                :placeholder="$t('search.algoliaAutocomplete.placeholder')"
                class="aa-Input"
                maxlength="56"
                type="search"
                @click.prevent.stop="
                  $router.push(
                    localePath(`/search/?query=${placeholderSearchQuery}`),
                  )
                "
              />
            </div>
          </form>
          <client-only>
            <AlgoliaFederatedSearch v-show="isMounted" />
          </client-only>
        </div>
        <!--end::Search-->
      </div>
      <!--end::Search box & title-->
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/composition-api";
import AlgoliaFederatedSearch from "../../molecules/AlgoliaFederatedSearch/AlgoliaFederatedSearch.vue";

export default {
  name: "KftBannerWithSearch",

  components: {
    AlgoliaFederatedSearch,
  },

  props: {
    text: {
      type: String,
      default: "",
    },

    description: {
      type: String,
      default: "",
    },

    color: {
      type: String,
      default: "#FFFFFF",
    },

    descriptionTag: {
      type: String,
      default: "",
    },

    isComponentMounted: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    /** =====
     * VARIABLES & COMPOSABLES
     * ===== */
    const placeholderSearchQuery = ref(null);

    /** =====
     * HOOKS
     * ===== */
    const isMounted = ref(false);
    onMounted(() => {
      isMounted.value = true;
    });

    return {
      /* Variables */
      placeholderSearchQuery,
      isMounted,
    };
  },

  computed: {
    textStyle() {
      return {
        color: this.color + " !important",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~/assets/components/organisms/KftBannerWithSearch.scss";

.kft-heading__title {
  @include for-desktop {
    font-size: 32px;
  }
}
/* create wrapper */
.brush-wrap {
  position: relative;
  display: inline-block;
  padding: 1.125rem;
  color: $primary !important;
  margin-left: -1rem;

  @include for-mobile {
    padding: 0.5rem;
  }
}

/* clipping/animating object (pseudo element) */
.brush-wrap:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: $teal;
  z-index: -1;
  clip-path: url(#clip); /* applying clip animation */
}
</style>
