/* eslint-disable  */

export default {
  footer: {
    poweredBy: "Buchungslösung von",
    aboutUs: "Inspiration",
    help: "Hilfe",
    usefulLinks: "Top Kurse und Workshops in Deiner Nähe",
    workWithUs: "Arbeite mit uns",
    social: "Folge uns auf",
    socialEmbedded: "Folge uns auf",
    paymentMethods: "Zahlungsarten",
    paymentMethodsEmbedded: "Zahlungsarten",

    tabs: {
      cities: "Top Städte",
      categories: "Top Kategorien",
      cityCategories: "Top Kategorien pro Stadt",
    },
    contact: {
      title: "Kontakt",
    },
    events: {
      one: {
        title: "Kurse für Kinder",
        link: "https://gokonfetti.com/de-de/t/fuer-kinder/",
      },
      two: {
        title: "Sommerfest planen: Der ultimative Guide",
        link: "https://gokonfetti.com/de-de/magazine/sommerfest-planen/",
      },
      three: {
        title: "JGA planen: Checkliste für die Junggesellenabschied Planung",
        link: "https://gokonfetti.com/de-de/magazine/junggesellenabschied-planen/",
      },
      four: {
        title: "JGA für Schwangere: Die besten Ideen",
        link: "https://gokonfetti.com/de-de/magazine/junggesellinnenabschied-fuer-schwangere/",
      },
      five: {
        title: "JGA Kosten: Budgetplanung für den JGA",
        link: "https://gokonfetti.com/de-de/magazine/junggesellenabschied-kosten/",
      },
      six: {
        title: "Sommerfest Ideen entdecken",
        link: "https://gokonfetti.com/de-de/t/sommerfest/",
      },
      seven: {
        title: "Malen mit Acrylfarben: Acryl malen für Anfänger",
        link: "https://gokonfetti.com/de-de/magazine/acryl-malen-fuer-anfaenger/",
      },
      eight: {
        title: "Töpfern zu Hause - Ohne Brennofen Töpfern",
        link: "https://gokonfetti.com/de-de/magazine/toepfern-zu-hause/",
      },
      nine: {
        title: "Acryl Pouring: Alles über die Fließtechnik",
        link: "https://gokonfetti.com/de-de/magazine/acryl-pouring/",
      },
      ten: {
        title: "Tufting: Was ist Tufting?",
        link: "https://gokonfetti.com/de-de/magazine/was-ist-tufting/",
      },
    },

    links: {
      faq: "Häufige Fragen",
      backOfficeName: "Login Partnerbereich",
      backOfficeLink: "https://backoffice.gokonfetti.com/#/sign-in",
      impressum: "Impressum",
      termsAndConditions: "AGB",
      dataProtection: "Datenschutz",
      dataProtectionLink: "https://www.iubenda.com/privacy-policy/79545710",
      contactUs: "Kontaktieren",
      termsSupplier: "Kooperations-/Presseanfragen",
      termsSupplierLink: "https://gokonfetti.com/de-de/impressum/",
      suppliers: "Werde Partner",
      partner: "Werde Partner",
      pressName: "Presse",
      pressLink:
        "https://konfetti.notion.site/Pressebereich-konfetti-8ba576a989014ac9a6976509ab633332",
      teamevents: "Teamevent",
      carrers: "Karriere",
      cookie: "Cookie-Richtlinie",
      sitemap: "Sitemap",
      magazine: "Magazin",
      cookieLink:
        "https://www.iubenda.com/privacy-policy/79545710/cookie-policy",
    },
    // COMMENT HERE - let's add links like { giftcard: 'Geschenkgutschein', Teamevents: 'Teamevent buchen', Courses: 'Alle Kurse', }

    // Social Media
    socialMedia: {
      links: {
        facebook: "https://www.facebook.com/gokonfetti",
        linkedin: "https://www.linkedin.com/company/72680824",
        pinterest: "https://www.pinterest.de/gokonfetti/",
        instagram: "https://www.instagram.com/gokonfetti/",
      },
    },
  },
};
