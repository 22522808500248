/* eslint-disable  */
export default {
  partnerBookingSolution: {
    header: {
      title: "Buchungslösung",
      description:
        "Für Deine Webseite, Instagram und Co. Verkaufe Tickets, Teamevents und Gutscheine direkt auf Deiner Seite.",
      titleTextColor: "white",
      descriptionTextColor: "white",
      buttonLabel: "Jetzt anfragen",
      buttonColor: "var(--kft-primary)",
      buttonHoverColor: "var(--kft-primary)",
      buttonBgColor: "white",
      buttonHoverBgColor: "#dde7e8",
      bgColor: "var(--kft-primary)",
    },

    softwareInfo: {
      sectionTitle: "konfetti Buchungslösung",
      actionText: "Demo vereinbaren",
      cards: [
        {
          title: "Zahlungsmöglichkeiten",
          subtitle:
            "Deine Kunden profitieren von einer Vielzahl an digitalen Zahlungsmöglichkeiten.\n",
          imageUrl: "/partner/booking-solution/payment-options.png",
          imageAlt: "Img description",
        },
        {
          title: "Zentrale Verwaltung",
          subtitle:
            "Manage all Deine Kurse und Buchungen zentral in einem System und reduziere Deinen manuellen Aufwand.\n",
          imageUrl: "/partner/booking-solution/central-administration.png",
          imageAlt: "Img description",
        },
        {
          title: "Keine Vertragslaufzeit",
          subtitle:
            "Teste die Buchungslösung ganz unverbindlich, ohne Dich an Mindestlaufzeiten zu binden.\n",
          imageUrl: "/partner/booking-solution/contract-period.png",
          imageAlt: "Img description",
        },
      ],
    },

    processSteps: {
      title:
        "Gestalte den Verkauf Deiner Tickets für Deine Kunden noch einfacher",
      items: {
        1: {
          image: "/team-events/michael-l-geschaftsfuhrer-hyrise-academy.webp",
          title: "Direkter Verkauf von Tickets, Gutscheinen & Teamevents",
          description:
            "Nutze unseren Conversion-optimierten Ticketshop für Deine Webseite, Instagram & Co. Erreiche mehr Kunden und verkaufe ganz einfach rund um die Uhr Tickets, Gutscheine und Teamevents.",
          link: "#",
          linkText: "",
          circleContent: '<i class="bi bi-currency-exchange"></i>',
        },
        2: {
          image: "/team-events/ceo-pospulse.webp",
          title: "Automatische Abrechnung & Auszahlung",
          description:
            "Die konfetti Buchungslösung nimmt Dir den administrativen Aufwand hinter jedem Verkauf ab. Die Abrechnung sowie die Auszahlung Deiner Verkäufe passieren automatisch. Du kannst Dich voll und ganz auf Deine kreative Arbeit konzentrieren.",
          link: "#",
          linkText: "",
          circleContent: '<i class="bi bi-credit-card-fill"></i>',
        },
        3: {
          image: "/team-events/Ostrom_Founders_2-800x533.webp",
          title: "Unkomplizierte & schnelle Implementierung",
          description:
            "Wir unterstützen Dich bei der Integration und Optimierung der Buchungslösung auf Deiner Seite und teilen unsere Erfahrung mit Dir - für Deinen Erfolg.",
          link: "#",
          linkText: "",
          circleContent: '<i class="bi bi-check-circle-fill"></i>',
        },
      },
    },

    stepsToJoin: {
      title: "So einfach geht's:",
      items: {
        1: {
          image: "/partner/booking-solution/join-bs-1.webp",
          title: "Buche eine kostenlose Demo",
          description:
            "Such Dir direkt einen passenden Termin aus und werde all Deine Fragen los. Wir beraten Dich gern, welche Version der Buchungslösung am besten zu Dir und Deinem Angebot passt.",
        },
        2: {
          image: "/partner/booking-solution/join-bs-2.webp",
          title: "Integriere sie auf Deiner Seite",
          description:
            "Direkt nach der Demo erhältst Du Deine persönliche Buchungslösung von uns. Die Integration dauert nur wenige Minuten und ist ganz einfach. Sehr gerne machen wir das mit Dir gemeinsam in einem Video Call.",
        },
        3: {
          image: "/partner/booking-solution/join-bs-3.webp",
          title: "Tickets verkaufen",
          description:
            "Und schon kann es losgehen. Unsere optimierte Buchungslösung macht es Deinen Kunden noch einfacher mit nur wenigen Klicks Tickets und Gutscheine für Deine Events zu kaufen.",
        },
      },
    },

    heroes: {
      first: {
        title: "konfetti Erfolgsstudie",
        subtitle:
          "\"Durch konfetti führen wir mittlerweile mehrmals pro Woche voll besetzte Cocktailkurse durch. In den letzten Monaten haben wir zwei neue Teammitglieder eingestellt, damit wir dem Andrang gerecht werden können.\" - Geschäftsführer von 'die Krake'. Willst Du wissen, wie sie das geschafft haben?",
        image: "/partner/booking-solution/hero-first-bs.webp",
        mobileImage: "/partner/booking-solution/hero-first-bs-mobile.webp",
        buttonText: "Infomaterial anfordern",
      },
    },

    faq: [
      {
        title: "Häufig gestellte Fragen",
        questions: [
          {
            question:
              "Welche Möglichkeiten gibt es, die Buchungslösung auf meiner Seite abzubilden?",
            answer:
              "Wenn Du lediglich einen Kurs pro Seite abbilden möchtest, kannst Du ein Widget integrieren. Dieses Buchungsfenster wird einfach in Deine bestehende Seite eingearbeitet. Bei mehreren Kursen oder der Integration auf Instagram gibt es einen Buchungslink, der all Deine Kurse sowie die Möglichkeit einen Gutschein zu erwerben bündelt. Diesen Link kannst Du auch als eigenen Online Shop verwenden.",
          },
          {
            question:
              "Erhalte ich Unterstützung bei der Integration der Buchungslösung?",
            answer:
              "Sehr gern unterstützen wir Dich dabei, die Buchungslösung optimal auf Deiner Website zu platzieren. Dafür können wir uns in einem kurzen Video Call treffen. Auch nach der Implementierung werden wir Dir beratend zur Seite stehen, um das Maximum aus der Buchungslösung rauszuholen.",
          },
          {
            question:
              "Ist konfetti die richtige Buchungslösung für mein Unternehmen?",
            answer:
              "Du bietest Kurse, Workshops oder Teambuilding Events an? Dann ist konfetti die perfekte Softwarelösung für Dich. Wir haben konfetti in enger Abstimmung mit unseren Partnern entwickelt. Ziel unserer All-in-One Lösung ist es, Dir den Arbeitsalltag zu erleichtern indem wir alltägliche Prozesse automatisieren und Tickets online für Dich verkaufen. ",
          },
          {
            question: "Kann ich die Buchungslösung testen?",
            answer:
              "Du kannst die Buchungslösung jederzeit kostenfrei und unverbindlich testen. Sie lässt sich schnell und unkompliziert integrieren und auch von der eigenen Seite entfernen, falls Du wider Erwarten nicht zufrieden sein solltest. :)",
          },
          {
            question:
              "Kann ich mit Jemandem von konfetti über die Buchungslösung sprechen?",
            answer:
              "Sende eine Mail an partner@gokonfetti.com oder buch Dir oben ein erstes unverbindliches Beratungsgespräch.",
          },
        ],
      },
    ],
  },
};
