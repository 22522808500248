import { strapiImageGetters } from "./strapiImageGetters";

const getId = (articleCategory): string => {
  return articleCategory?.id || null;
};

const getSlug = (articleCategory): string => {
  return articleCategory?.attributes?.slug || null;
};

const getHeaderImage = (articleCategory) => {
  return articleCategory?.attributes?.header_image?.data || null;
};

const getHeaderImageUrl = (articleCategory, format = null): string => {
  return (
    strapiImageGetters.getImageUrl(getHeaderImage(articleCategory), format) ||
    null
  );
};

const getTitle = (articleCategory): string => {
  return articleCategory?.attributes?.title || null;
};

const getArticles = (articleCategory): string => {
  return articleCategory?.attributes?.articles?.data || null;
};

const getDescription = (articleCategory): string => {
  return articleCategory?.attributes?.description || null;
};

const getSeoTitle = (articleCategory): string => {
  return articleCategory?.attributes?.seo?.metaTitle || null;
};

const getSeoImageUrl = (articleCategory, format = null): string => {
  return (
    strapiImageGetters.getImageUrl(
      articleCategory?.attributes?.seo?.metaImage?.data,
      format,
    ) || null
  );
};

const getSeoMetaDescription = (articleCategory): string => {
  return articleCategory?.attributes?.seo?.metaDescription || null;
};

const getCreatedAt = (articleCategory): string => {
  return articleCategory?.attributes?.createdAt || null;
};

const getSummaryList = (articleCategory): any => {
  return articleCategory?.attributes?.summary_list || null;
};

export const articleCategoryGetters = {
  getId,
  getSlug,
  getHeaderImage,
  getHeaderImageUrl,
  getTitle,
  getArticles,
  getDescription,
  getSeoTitle,
  getSeoMetaDescription,
  getCreatedAt,
  getSummaryList,
  getSeoImageUrl,
};
