import { AlgoliaEventItem, EventDescription } from "../types";
import { computed, onMounted, ref } from "@vue/composition-api";
import { getISO2Locale } from "../../../theme/helpers/algoliaHelpers";
import { useProduct } from "../useProduct";

const LAST_VIEWED_EVENTS_LOCAL_KEY = "last-viewed-event-ids";
const LAST_VIEWED_EVENS_TIME_LOCAL_STORAGE_KEY = "last-viewed-events-time";

const getLastViewedStorageKey = (loc: string) =>
  `${LAST_VIEWED_EVENTS_LOCAL_KEY}-${getISO2Locale(loc)}`;

const safeJsonParse = (str, defaultReturn = []) => {
  let arr = defaultReturn || [];
  try {
    arr = JSON.parse(str);
  } catch (_err) {
    console.warn("JSON.parse error", _err);
  }
  return arr;
};

export const useLastViewedEvents = (
  locale: any,
  loadLastSeen: boolean,
): any => {
  const lastViewedEvents = ref<EventDescription[]>([]);
  const parsedLastViewedEvents = ref([]);
  const now = Date.now();

  const { loadEventsByIds } = useProduct("homepage");

  const parseEventDescriptionToAlgoliaEventItem = (
    event,
  ): Partial<AlgoliaEventItem> => {
    return {
      categories: event.categories?.data,
      title: event.title,
      // eslint-disable-next-line camelcase
      default_duration: event.default_duration,
      // eslint-disable-next-line camelcase
      default_price: event.default_price,
      // eslint-disable-next-line camelcase
      hashed_id: event.id,
      images: event.photos,
      permalink: event.permalink,
      rating: event.reviews?.data?.rating,
    } as Partial<AlgoliaEventItem>;
  };

  const getLastViewedEventsFromLocalStorage = async () => {
    if (!window?.localStorage) {
      return;
    }

    const storageLastViewedEventsTime = window.localStorage.getItem(
      LAST_VIEWED_EVENS_TIME_LOCAL_STORAGE_KEY,
    );
    const storageLastViewedEventsIds = safeJsonParse(
      window.localStorage.getItem(getLastViewedStorageKey(locale)),
    );

    const thirtyMin = 1000 * 60 * 30;
    const isPastThirtyMin =
      thirtyMin < now - Number(storageLastViewedEventsTime);

    if (!storageLastViewedEventsIds) {
      return;
    }
    parsedLastViewedEvents.value = [];

    const ress = await loadEventsByIds(storageLastViewedEventsIds);
    (ress.data || [])
      .map((ev) => {
        ev.__order = storageLastViewedEventsIds.indexOf(ev.id) + 1;
        return ev;
      })
      .filter((ev) => ev.__order) // setting up for ordering
      .sort((ae, be) => ae.__order - be.__order) // actual ordering
      .map((ev) =>
        parsedLastViewedEvents.value.push(
          parseEventDescriptionToAlgoliaEventItem(ev),
        ),
      );

    // Checks if the last viewed events are past 30 minutes
    // Only diplay last viewed if the first stored event id is older than 30 minutes.
    if (isPastThirtyMin) {
      lastViewedEvents.value = parsedLastViewedEvents.value;
    }
  };

  const setLastViewedEvent = (eventId: string) => {
    if (!process.client || !window?.localStorage || !eventId) {
      return;
    }

    const lastViewedIds = [eventId];

    const storedEventsId = window.localStorage.getItem(
      getLastViewedStorageKey(locale),
    );

    if (storedEventsId) {
      let newStoredEventsId = safeJsonParse(storedEventsId);

      if (newStoredEventsId.some((el: string) => el === eventId)) {
        newStoredEventsId.splice(
          newStoredEventsId.findIndex((v: string) => v === eventId),
          1,
        );
      }
      newStoredEventsId.unshift(eventId);
      newStoredEventsId = newStoredEventsId.slice(0, 10);

      window.localStorage.setItem(
        getLastViewedStorageKey(locale),
        JSON.stringify(newStoredEventsId),
      );

      return newStoredEventsId;
    } else {
      window.localStorage.setItem(
        getLastViewedStorageKey(locale),
        JSON.stringify(lastViewedIds),
      );
      window.localStorage.setItem(
        LAST_VIEWED_EVENS_TIME_LOCAL_STORAGE_KEY,
        now.toString(),
      );

      return lastViewedIds;
    }
  };

  onMounted(() => {
    if (loadLastSeen) {
      getLastViewedEventsFromLocalStorage();
    }
  });

  return {
    lastViewedEvents: computed(() => lastViewedEvents.value),
    setLastViewedEvent,
  };
};
