<template>
  <div class="preview-container">
    <KftButton
      class="back-button"
      aria-label="Close preview modal"
      data-bs-dismiss="modal"
      type="button"
      @click="hidePreviewGallery"
    >
      <div class="arrow-container">
        <i class="bi bi-chevron-left"></i>
      </div>
    </KftButton>
    <div class="preview-gallery">
      <div
        v-for="(picture, index) in images"
        :key="'kft-gallery-image-' + index"
        :class="getImageClass(picture.dimensions)"
      >
        <SfImage
          :height="'auto'"
          :preload="true"
          :src="
            $device.isDesktopOrTablet
              ? picture.cropped || picture.original
              : picture.thumb
          "
          alt="picture.alt"
          aspect-ratio="4/3"
          class=""
          loading="eager"
          sizes="xs:100vw sm:100vw md:100vw lg:910px xl:910px xxl:910px"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { SfImage, KftButton } from "@konfetti-ui/vue";

export default {
  name: "GalleryPreview",
  components: {
    SfImage,
    KftButton,
  },
  transition: "fade",
  props: {
    images: {
      type: Array,
      required: true,
    },
    hidePreviewGallery: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const getImageClass = (dimensions) => {
      if (!dimensions) return "image-horizontal";
      return dimensions?.width > dimensions?.height
        ? "image-horizontal"
        : "image-vertical";
    };

    return {
      getImageClass,
    };
  },
};
</script>

<style lang="scss" scoped>
.preview-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--spacer-4);
  padding: var(--spacer-6) 0;
  @include for-tablet {
    padding: var(--spacer-7) 0;
  }
  @include for-desktop {
    padding: var(--spacer-11) 0;
  }
}
.image-horizontal {
  width: 316px;
  height: 236px;
  @include for-tablet {
    width: 367px;
    height: 280px;
  }
  @include for-desktop {
    width: 583px;
    height: 436px;
  }
}
.image-vertical {
  width: 170px;
  height: 236px;
  @include for-tablet {
    height: 280px;
  }
  @include for-desktop {
    width: 361;
    height: 600;
  }
}
.arrow-container {
  cursor: pointer;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  z-index: 2;
  width: 35px;
  height: 35px;
  position: relative;
  @include for-tablet {
    width: 45px;
    height: 45px;
  }

  i {
    display: block;
    line-height: 35px;
    text-align: center;
    @include for-tablet {
      line-height: 45px;
    }
  }
}
.preview-container {
  background-color: #f4f4f4;
  min-height: 100%;
  padding: var(--spacer-6);
  @include for-tablet {
    padding: var(--spacer-7);
  }
  @include for-desktop {
    padding: var(--spacer-11);
  }
}
</style>
