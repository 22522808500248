import { Logger, sharedRef } from "@konfetti-core/core";
import {
  ErrorStoreError,
  ErrorStoreErrorType,
  ErrorHandler,
  ErrorHandlers,
} from "./types";
import createErrorStore from "./createErrorStore";

export * from "./types";

const defaultErrorHandler = (error: ErrorStoreError) => {
  Logger.error("useErrorStore - Generic Error", error);
};

/*
The `useErrorStore()` function is used to retrieve the `ErrorStore`
object and returns an object with methods to handle errors.
`handleError(error: ErrorStoreError)` is the main method used to set an
error and register a handler for that error type.
*/
export const useErrorStore = () => {
  const errorStore = createErrorStore();
  const globalHandlers = sharedRef<ErrorHandlers>(
    {},
    "useErrorStore-globalHandlers",
  );
  const customHandlers: ErrorHandlers = {};

  const registerGlobalErrorHandler = (
    type: ErrorStoreErrorType,
    handler: ErrorHandler,
  ) => {
    globalHandlers.value[type] = handler;
  };

  const registerCustomErrorHandler = (
    type: ErrorStoreErrorType,
    handler: ErrorHandler,
  ) => {
    customHandlers[type] = handler;
  };

  const registerHandlerOnStore = (type: ErrorStoreErrorType) => {
    const handlerToBeRegisteredOnStore =
      customHandlers[type] || globalHandlers.value[type] || defaultErrorHandler;

    errorStore.registerHandler(type, handlerToBeRegisteredOnStore);
  };

  const handleError = (error: ErrorStoreError) => {
    registerHandlerOnStore(error.type);
    errorStore.setError(error);
  };

  return {
    error: errorStore.error,
    clearError: errorStore.clearError,
    registerCustomErrorHandler,
    registerGlobalErrorHandler,
    handleError,
  };
};
