<template>
  <div class="referral-info-instructions">
    <div class="referral-info-instructions__heading">
      <i class="bi bi-send-fill"></i>
      <KftHeading :title="title" :level="2" h-class="h6" />
    </div>

    <div>
      <div class="referral-info-instructions__code-container">
        <div class="referral-info-instructions__input-wrapper">
          <i
            class="bi bi-subtract"
            @click.prevent="handleClickCopyCodeToClipboard"
          ></i>

          <KftTooltip
            ref="clipboardTooltipRef"
            :styles="{ 'font-size': '16px' }"
            :tooltip-text="$t('general.copyToClipboard')"
            :on-click-text="$t('general.copiedToClipboard')"
            length="medium"
          >
            <input
              ref="codeInputRef"
              class="overline"
              type="text"
              name="code"
              readonly
              :value="userReferralCode"
            />
          </KftTooltip>
        </div>

        <div
          v-if="$device.isMobile"
          class="referral-info-instructions__share-button"
        >
          <i class="bi bi-share-fill"></i>
        </div>

        <div
          v-else
          class="referral-info-instructions__share-button"
          @click.prevent="handleClickCopyCodeToClipboard"
        >
          <i class="bi bi-share-fill"></i>
        </div>
      </div>

      <button
        v-if="$device.isMobile"
        class="mt-4 btn btn-shadow w-100 btn-primary"
        @click.prevent="handleShareCode"
      >
        Share
      </button>

      <button
        v-else
        class="mt-4 btn btn-shadow w-100 btn-primary"
        @click.prevent="handleClickCopyCodeToClipboard"
      >
        Share
      </button>
    </div>
  </div>
</template>

<script>
import { KftHeading, KftTooltip } from "@konfetti-ui/vue";
import { ref } from "@nuxtjs/composition-api";
import { computed, onBeforeMount } from "@vue/composition-api";
import { userGetters, useUserComplete } from "@konfetti/composables";

export default {
  name: "ReferralInfoUserCode",
  components: {
    KftHeading,
    KftTooltip,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },

  setup(props, { root: { $fullLocalizedUrl, $i18n } }) {
    const { getUser, user } = useUserComplete("general-instance");

    const userReferralCode = computed(() =>
      user.value ? userGetters.getReferralCouponCode(user.value.data) : "",
    );

    const codeInputRef = ref(null);
    const clipboardTooltipRef = ref(null);

    const handleClickCopyCodeToClipboard = () => {
      codeInputRef.value.select();
      codeInputRef.value.setSelectionRange(0, 99999);
      document.execCommand("copy");
      clipboardTooltipRef.value?.toggle();
    };

    const handleShareCode = () => {
      if (window.navigator.share) {
        window.navigator.share({
          url: $fullLocalizedUrl("/"),
          title: $i18n.t("general.referral-share-title", {
            referralCode: userReferralCode,
          }),
        });
      }
    };

    onBeforeMount(async () => {
      await getUser(true);
    });

    return {
      codeInputRef,
      clipboardTooltipRef,

      userReferralCode: computed(() => userReferralCode.value?.data?.code),

      handleClickCopyCodeToClipboard,
      handleShareCode,
    };
  },
};
</script>

<style lang="scss" scoped>
.referral-info-instructions {
  &__heading {
    color: $primary;
    display: flex;
    align-items: center;
    column-gap: var(--spacer-2);
    margin: 0 0 var(--spacer-base);

    i {
      font-size: 20px;
    }
  }

  &__share-button {
    border-radius: 50%;
    width: 59px;
    height: 52px;
    border: 1px solid #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;

    &:hover {
      color: rgba(91, 91, 91, 0.43);
    }

    &:active {
      color: $black;
    }
  }

  &__code-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: var(--spacer-2);
  }

  &__input-wrapper {
    border: 1px solid #f4f4f4;
    border-radius: 12px;
    width: 100%;
    padding: 0 var(--spacer-3);
    color: rgba(91, 91, 91, 0.43);

    input {
      background-color: transparent;
      border: none;
      padding: var(--spacer-3) 0;
      color: rgba(91, 91, 91, 0.43);
    }

    i {
      cursor: pointer;
      font-size: 16px;
      margin-right: var(--spacer-3);

      &:hover {
        color: $black;
      }

      &:active {
        color: rgba(91, 91, 91, 0.43);
      }
    }
  }
}
</style>
