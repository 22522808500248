import { Logger, sharedRef } from "@konfetti-core/core";
import { computed } from "@vue/composition-api";
import { useApiHandler } from "../useApiHandler";

export const useStrapiSingleType = <T = any>(id: string): any => {
  const singleType = sharedRef<{ data: T; meta: any }>(
    null,
    `useStrapiSingleType-article-${id}`,
  );
  const loading = sharedRef(false, `useStrapiSingleType-loading-${id}`);
  const error = sharedRef(null, `useStrapiSingleType-error-${id}`);

  /** Wrapper for Strapi API requests */
  const { makeStrapiRequest } = useApiHandler();

  // Method to call an API endpoint and update `categories`, `loading` and `error` properties
  const loadSingleType = async (params: string) => {
    Logger.debug(`useStrapiSingleType/${id}/loadSingleType`, params);

    try {
      loading.value = true;
      singleType.value = await makeStrapiRequest("getStrapiSingleType", params);

      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useStrapiSingleType/${id}/loadSingleType`, err);
    } finally {
      loading.value = false;
    }
  };

  return {
    // Variables
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    singleType: computed(() => singleType.value),

    // Methods
    loadSingleType,
  };
};
