/* eslint-disable  */

export default {
  multiselect: {
    placeholder: 'Wählen Sie eine Option',
    selectLabel: 'ENTER zum Auswählen',
    selectedLabel: 'Ausgewählt',
    deselectLabel: 'ENTER zum Entfernen',
    noResult: 'Keine Ergebnisse gefunden',
    noOptions: 'Ups, keine Optionen vorhanden'
  },
};
