import { replaceSubdomainToProd } from "../helpers/mediaHelpers";

const getId = (supplier): number => {
  return supplier?.id || null;
};

const getCreatedAt = (supplier): boolean => {
  return supplier?.created_at || "";
};

const getDescription = (supplier): string => {
  return supplier?.description || "";
};

const getShortDescription = (supplier): string => {
  return supplier?.description_summary || "";
};

const getAddress = (supplier): string => {
  return supplier?.address || "";
};

const getLogo = (supplier): string => {
  return (
    replaceSubdomainToProd(supplier?.logo?.data?.url)?.replace(
      "150x150",
      "150x0",
    ) || ""
  );
};

const getLogoMimeType = (supplier): string => {
  return supplier?.logo?.data?.mime_type || "";
};

const getHeaderLogo = (supplier): string => {
  const logo = supplier?.headerLogo?.data?.url;

  if (logo && logo !== "") {
    return logo;
  }

  return getLogo(supplier);
};

const getName = (supplier): string => {
  return supplier?.name || "";
};

const getPageTitle = (supplier): string => {
  return supplier?.page_title ?? getName(supplier);
};

const getUserName = (supplier): string => {
  if (!supplier?.user?.data?.first_name && !supplier?.user?.data?.last_name) {
    return "";
  }

  return `${supplier?.user.data.first_name} ${supplier?.user.data.last_name}`;
};

const getPermalink = (supplier): string => {
  return supplier?.permalink || "";
};

const getKonfettiProfilePath = (supplier): string => {
  const permalink = getPermalink(supplier);
  return permalink ? `/p/${permalink}/` : "";
};

const getWebsite = (supplier): string => {
  return supplier?.website || "";
};

const getSubdomain = (supplier): string => {
  return supplier?.subdomain || "";
};

const getHasGiftcardEmbedded = (supplier): boolean => {
  return Boolean(supplier?.giftcard_embedded);
};

const getReviewsAverage = (supplier): string | null => {
  const avg = supplier?.avg_review;

  if (avg && avg !== "0.0") {
    return Number(avg).toFixed(1);
  }

  return null;
};

const getReviewsTotal = (supplier): number => {
  return supplier?.total_reviews || 0;
};

const getReviewsList = (supplier): any[] => {
  return supplier?.reviewsNew?.data || [];
};

const getSeoBusinessType = (supplier): any[] => {
  return supplier?.business_type || null;
};

const isNameLikePageTitle = (supplier): boolean => {
  return supplier?.name === supplier?.page_title;
};

export const supplierGetters = {
  getId,
  getCreatedAt,
  getAddress,
  getDescription,
  getShortDescription,
  getLogo,
  getLogoMimeType,
  getName,
  getPageTitle,
  getUserName,
  getWebsite,
  getSubdomain,
  getHasGiftcardEmbedded,
  getPermalink,
  getKonfettiProfilePath,
  getReviewsAverage,
  getReviewsTotal,
  getReviewsList,
  getSeoBusinessType,
  getHeaderLogo,
  isNameLikePageTitle,
};
