var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kft-horizontal-scroll-wrapper",class:{ 'mt-8': !_vm.noMargin }},[_c('div',{staticClass:"kft-horizontal-scroll-wrapper__container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.itemsLength > 1 &&
        _vm.scrollableContainerRef &&
        (_vm.scrollableContainerRef.scrollLeft === 0 ||
          _vm.scrollableContainerRef.scrollLeft +
            _vm.scrollableContainerRef.offsetWidth <
            _vm.scrollableContainerRef.scrollWidth - 50)
      ),expression:"\n        itemsLength > 1 &&\n        scrollableContainerRef &&\n        (scrollableContainerRef.scrollLeft === 0 ||\n          scrollableContainerRef.scrollLeft +\n            scrollableContainerRef.offsetWidth <\n            scrollableContainerRef.scrollWidth - 50)\n      "}],staticClass:"kft-horizontal-scroll-wrapper__right-arrow",on:{"click":function($event){return _vm.scrollableContainerRef.scrollTo({
          left:
            _vm.scrollableContainerRef.scrollLeft +
            _vm.scrollableContainerRef.offsetWidth,
          behavior: 'smooth',
        })}}},[_vm._m(0)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.shadowContainerScrollLeft > 50 && _vm.itemsLength > 1),expression:"shadowContainerScrollLeft > 50 && itemsLength > 1"}],staticClass:"kft-horizontal-scroll-wrapper__left-arrow",on:{"click":function($event){return _vm.scrollableContainerRef.scrollTo({
          left:
            _vm.scrollableContainerRef.scrollLeft -
            _vm.scrollableContainerRef.offsetWidth,
          behavior: 'smooth',
        })}}},[_vm._m(1)]),_vm._v(" "),_c('div',{directives:[{name:"dragscroll",rawName:"v-dragscroll.x",modifiers:{"x":true}}],ref:"scrollableContainerRef",staticClass:"d-flex gap-4 kft-horizontal-scroll-wrapper__cards-container",class:{ 'py-4': !_vm.noMargin, 'py-2': _vm.noMargin },staticStyle:{"flex-wrap":"nowrap !important"},on:{"dragscrollstart":_vm.onDragStart,"&scroll":function($event){return _vm.fnHandleShadowScroll.apply(null, arguments)},"!click":function($event){return _vm.onDragClick.apply(null, arguments)}}},[_vm._t("default")],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kft-horizontal-scroll-wrapper__arrow-container"},[_c('i',{staticClass:"bi bi-chevron-right"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kft-horizontal-scroll-wrapper__arrow-container"},[_c('i',{staticClass:"bi bi-chevron-left"})])}]

export { render, staticRenderFns }