import { integrationPlugin } from "@konfetti-core/core";
import { JwtService } from "@konfetti/api";

// @ts-ignore
export default integrationPlugin(({ integration }) => {
  const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };

  const isAuthenticated = () => {
    const accessToken = JwtService.getToken();
    const refreshToken = JwtService.getRefreshToken();
    const expiresAt = JwtService.getExpiresAt();
    const user = getUser();
    let authenticated = false;

    if (refreshToken && accessToken && user !== null) {
      authenticated = true;
    }

    if (!authenticated || parseInt(expiresAt) < Math.floor(Date.now() / 1000)) {
      authenticated = false;
    }

    return authenticated;
  };

  integration.configure("auth", {
    getUser,
    isAuthenticated,
    doNotInjectApis: true,
    // other options
  });
});
