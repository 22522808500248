/* eslint-disable  */

// @francesca - img are fine, link to drive folder here: t.ly/gCLF

export default {
  giftcard: {
    header: {
      title: "Gutschein verschenken für Erlebnisse",
      subtitle:
        "Das wahrscheinlich kreativste Geschenk: Der Erlebnisgutschein ist für über 4.500 Kurse einlösbar.",
      buttonLabel: "In den Warenkorb",
      titleColor: "#FFFFFF",
      bgColor: "#61B0BC",
    },

    validity: "Der Beschenkte wählt Kurs und Termin ",
    forAnyKonfetti: " Einlösbar für über 4.500 konfetti Kurse ",
    experience: "Ab Kauf 3 Jahre gültig",

    ///COMMENT HERE - I don't understand where these are/stand for

    mostBought: "",
    pickFavorite: "",

    options: "Gutschein kaufen",
    value: "Wert wählen",

    bookAction: "In den Warenkorb",

    mainContent: {
      one: {
        title: "Vorfreude und gemeinsame Zeit verschenken",
        description:
          "Der wohl kreativste Geschenkgutschein: Verschenke mit konfetti besondere Vorfreude und eine schöne Zeit. Von Action-Painting über italienisch Kochen, bis hin zu Töpfern an der Drehscheibe oder Verkosten exquisiter Cocktails - bei konfetti findet jeder das passende Event.",
      },
      two: {
        title: "Erlebnisgutschein, mit dem Du von echten Experten lernst",
        description:
          "Jedes Erlebnis wird von erfahrenen Experten geleitet. Mit dem konfetti Gutschein triffst Du auf Sommeliers, Köche, Handwerker, Künstler und viele weitere Menschen, die Freude daran haben, ihre Leidenschaft zu teilen.",
      },
      three: {
        title: "Das kreativste Last Minute Geschenk: Der Gutschein für Kurse",
        description:
          "Ein Last-Minute-Geschenk, bei dem man merkt, dass Du Dir wirklich Gedanken gemacht hast. Wähle den Gutschein, der am besten zum Beschenkten passt. Den Gutschein erhältst Du direkt zum Ausdrucken per E-Mail oder als Geschenkkarte per Post.",
      },
    },

    content: {
      title: "Besondere Erlebnisse verschenken",
      icons: {
        one: {
          title: "Lege den Gutscheinwert fest",
          description: "Von 15 bis 500 Euro - Du bestimmst den Gutscheinwert.",
        },
        two: {
          title: "Persönliches Last Minute Geschenk",
          description:
            "Nach Deinem Kauf senden wir Dir den Gutschein zum Drucken via E-Mail zu.",
        },
        three: {
          title: "Optionale Gutschein-Geschenkkarte",
          description:
            "Entscheidest Du Dich für die Geschenkkarte, schicken wir diese am nächsten Werktag an Deine Adresse.",
        },
        four: {
          title: "Gutschein verschenken für Kurse",
          description:
            "Einlösbar für mehr als 4.500 einzigartige Erlebnisse - in ganz Deutschland, Österreich und online.",
        },
      },
    },

    ///COMMENT HERE - I don't understand where are this ones

    heroes: {
      first: {
        title: "Freie Wahl beim Gutscheinwert",
        subtitle: "Von 15€ bis 500€ - wähle Deinen Gutscheinwert aus.",
        image: "/giftcard/first.png",
        mobileImage: "/giftcard/first.png",
        buttonText: "Search",
      },
      second: {
        title: "Gift options",
        subtitle:
          "Mit konfetti findest Du spannende Workshops für Dein nächstes Teamevent. Mit konfetti findest Du spannende Workshops.",
        image: "/giftcard/second.png",
        mobileImage: "/giftcard/second.png",
        buttonText: "Search",
      },
      third: {
        title: "Gift options",
        subtitle:
          "Mit konfetti findest Du spannende Workshops für Dein nächstes Teamevent. Mit konfetti findest Du spannende Workshops.",
        image: "/giftcard/third.png",
        mobileImage: "/giftcard/third.png",
        buttonText: "Search",
      },
    },

    breadcrumb: {
      value: "Gutschein",
    },
  },
};
