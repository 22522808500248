/* eslint-disable  */

export default {
  city: {
    categories: 'Pick from our favorite categories',
    categoryEventsInCity: '{category} in {city}',
    seeMore: '{category} in {city} entdecken',

    header: {
      title: 'Kurse und Workshops in {city}',
      description:
        'Bei konfetti neue Erlebnisse und Events in {city} entdecken. Für Dich selbst und als Geschenk für Deine Liebsten.',
      button: 'Zu allen Kursen',
      image: '/city/header.png',
      imageMobile: '/city/header.png',
      titleTextColor: '#ffffff',
      descriptionTextColor: '#ffffff',
      bgColor: '#a29ddf',
    },

    heroes: {
      giftcard: {
        title: 'Auf der Suche nach einem Geschenk in {city}?',
        subtitle: 'Verschenke jetzt unseren Gutschein. Einlösbar für mehr als 4.500 Workshops und Erlebnisse. Das perfekte Geschenk für (fast) jeden Anlass.',
        buttonText: 'Geschenkgutschein kaufen',
        image: '/homepage/heroFirst.webp',
        mobileImage: '/homepage/heroFirst.webp',
      }
    },

    showcase: {
      title: 'What our partners say about us'
    },
  }
};
