import { MenuComponent, DrawerComponent } from "./components";

/**
 * @description Initialize KeenThemes custom components
 */
const initializeComponents = () => {
  setTimeout(() => {
    MenuComponent.bootstrap();
    DrawerComponent.bootstrap();
  }, 0);
};

/**
 * @description Reinitialize KeenThemes custom components
 */
const reinitializeComponents = () => {
  setTimeout(() => {
    MenuComponent.reinitialization();
    DrawerComponent.reinitialization();
  }, 0);
};

initializeComponents();

export { initializeComponents, reinitializeComponents };
