import { Plugin } from "@nuxt/types";
import { getSupplierSubdomain } from "@konfetti/composables/src";
import { withTrailingSlash } from "../helpers/url";

declare module "vue/types/vue" {
  // this.$fullLocalizedUrl inside Vue components
  interface Vue {
    $fullLocalizedUrl(path: string, includeSubdomain: boolean): string;
  }
}

declare module "@nuxt/types" {
  // nuxtContext.app.$fullLocaleUrl inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $fullLocalizedUrl(path: string, includeSubdomain: boolean): string;
  }

  // nuxtContext.$fullLocaleUrl
  interface Context {
    $fullLocalizedUrl(path: string, includeSubdomain: boolean): string;
  }
}

const fullLocalizedUrl: Plugin = (ctx, inject) => {
  const { app, nuxtState, ssrContext } = ctx;
  let base = app.$config.baseUrl;
  if (base.substr(-1) === "/") {
    base = base.slice(0, -1);
  }

  inject("fullLocalizedUrl", (path, includeSubdomain = false) => {
    const url = new URL(base + app.localePath(path));

    const subdomain = getSupplierSubdomain(
      ssrContext || nuxtState,
      app.$config,
    );
    if (includeSubdomain && subdomain) {
      url.host = `${subdomain}.${url.host}`;
    }

    return withTrailingSlash(url.toString());
  });
};

export default fullLocalizedUrl;
