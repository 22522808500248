import Url from "url-parse";
import consola from "consola";

const util = require("util");
const fs = require("graceful-fs");
const readFile = util.promisify(fs.readFile);

export default async (req, res, next) => {
  const path = req.url.split("?")[0] || req.url;
  return readFile(require.resolve("./legacy-route-map.json")).then((file) => {
    const parsedRedirects = JSON.parse(file);

    if (path && parsedRedirects[path] !== undefined) {
      consola.log("Redirecting route...");

      const newUrl = new Url(parsedRedirects[path].page);
      res.writeHead(parsedRedirects[path].code, {
        location: newUrl.href,
      });

      res.end();
    } else {
      next();
    }
  });
};
