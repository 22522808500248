export const i18nConfig = {
  strategy: "prefix",
  baseUrl: process.env.BASE_URL || "https://gokonfetti.com",
  currency: "EUR",
  country: "US",
  countries: [
    {
      name: "US",
      label: "United States"
    },
    {
      name: "AT",
      label: "Austria"
    },
    {
      name: "DE",
      label: "Germany"
    },
    {
      name: "NL",
      label: "Netherlands"
    }
  ],
  currencies: [
    {
      name: "EUR",
      label: "Euro"
    },
    {
      name: "USD",
      label: "Dollar"
    }
  ],
  locales: [
    // iso value automatically goes to hreflang meta
    {
      code: "en-us",
      label: "English",
      file: "en_US/index.ts",
      iso: "en",
      flag: "united-states.svg"
    },
    {
      code: "de-de",
      label: "Deutsch",
      file: "de_DE/index.ts",
      flag: "germany.svg",
      iso: "de"
    }
  ],
  defaultLocale: "de-de",
  noPrefixDefaultLocale: false,
  lazy: true,
  langDir: "lang/",
  vueI18n: {
    fallbackLocale: "de-de",
    numberFormats: {
      "en-us": {
        currency: {
          style: "currency",
          currency: "EUR",
          currencyDisplay: "symbol"
        }
      },
      "de-de": {
        currency: {
          style: "currency",
          currency: "EUR",
          currencyDisplay: "symbol"
        }
      }
    }
  },
  detectBrowserLanguage: {
    cookieKey: "kft-locale"
  }
};
