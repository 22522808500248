import { computed, ref } from "@vue/composition-api";
import { useProduct } from "@konfetti/composables";
import { parseEventDescriptionToAlgoliaEventItem } from "~/helpers";

export const useGiftcardEvents = (filters: any) => {
  const giftcardSupplierId = "z38lrx";
  const currentFilteredCategories = ref<string[]>([]);

  const filteredKonfettiGiftcardEvents = ref([]);
  const konfettiGiftcardEvents = ref([]);
  const hasFetchedGiftcardEvents = computed(
    () =>
      konfettiGiftcardEvents.value.length &&
      konfettiGiftcardEvents.value.length !== 0,
  );

  const { loadSupplierEvents, supplierEvents } = useProduct(
    "use-giftcard-events",
  );
  /* Function to check if supplierEvents already exists on localStorage and set if it doesn't */
  const fetchKonfettiGiftcardEvents = async () => {
    await loadSupplierEvents(giftcardSupplierId, false, "created_at");
    konfettiGiftcardEvents.value = supplierEvents.value?.data?.map((event) =>
      parseEventDescriptionToAlgoliaEventItem(event),
    );
  };

  const filterGiftcardEventsByCategory = () => {
    filteredKonfettiGiftcardEvents.value = konfettiGiftcardEvents.value.filter(
      (el: any) =>
        el.categories.filter((item) => {
          const includes = filters.value.categories.includes(item.name);

          if (includes) {
            currentFilteredCategories.value.push(item.name);
          }

          return includes;
        }).length > 0,
    );
  };

  /* Function to filter supplierEvents based on selected categories */
  const fetchAndFilterGiftcardEventsByCategory = async () => {
    const newCategoriesWereAdded = filters.value.categories.some(
      (x) => !currentFilteredCategories.value.includes(x),
    );
    const categoriesWereRemoved = currentFilteredCategories.value.some(
      (x) => !filters.value.categories.includes(x),
    );

    const hasAnyCategoryFilters =
      filters.value.categories && filters.value.categories.length > 0;

    if (!newCategoriesWereAdded && !categoriesWereRemoved) {
      return;
    }

    if (hasAnyCategoryFilters && !hasFetchedGiftcardEvents.value) {
      await fetchKonfettiGiftcardEvents();
    } else if (!hasAnyCategoryFilters) {
      filteredKonfettiGiftcardEvents.value = [];
    }

    currentFilteredCategories.value = [];
    filterGiftcardEventsByCategory();
  };

  return {
    konfettiGiftcardEvents: computed(() => konfettiGiftcardEvents.value),
    filteredKonfettiGiftcardEvents: computed(
      () => filteredKonfettiGiftcardEvents.value,
    ),
    fetchKonfettiGiftcardEvents,
    fetchAndFilterGiftcardEventsByCategory,
  };
};
