import { Context } from '@konfetti-core/core';

export async function sendOrder (
  context: Context,
  params: string
): Promise<any> {
  const url = new URL('v1/checkout/orders', context.config.api.url);
  // Use axios to send a GET request
  const { data } = await context.client.post(url.href, params);
  // Return data from the API
  return data;
}

export async function captureOrder (
  context: Context,
  orderId: string
): Promise<any> {
  const url = new URL(
    `v1/checkout/payment/paypal/${orderId}/capture`,
    context.config.api.url
  );
  // Use axios to send a GET request
  const { data } = await context.client.post(url.href);
  // Return data from the API
  return data;
}

export async function sendOrderValidation (
  context: Context,
  params: string
): Promise<any> {
  const url = new URL('v1/checkout/orders/validate', context.config.api.url);
  // Use axios to send a GET request
  const { data } = await context.client.post(url.href, params);
  // Return data from the API
  return data;
}

export async function sendOrderSurvey (
  context: Context,
  params: any
): Promise<any> {
  const {
    orderId,
    answers
  } = params;

  const url = new URL(
    `v1/checkout/orders/${orderId}/survey`,
    context.config.api.url
  );
  // Use axios to send a GET request
  const { data } = await context.client.patch(url.href, answers);

  return data;
}

export async function getOrderById (
  context: Context,
  orderId: string
): Promise<any> {
  const url = new URL(
    `v1/checkout/orders/${orderId}?include=coupon`,
    context.config.api.url
  );
  // Use axios to send a GET request
  const { data } = await context.client.get(url);
  // Return data from the API
  return data;
}

export async function validateCoupon (
  context: Context,
  params: any
): Promise<any> {
  const url = new URL(
    `v1/store/coupon/${params.code}/validate`,
    context.config.api.url
  );
  // Use axios to send a GET request
  const { data } = await context.client.post(url, params.params);
  // Return data from the API
  return data;
}

export async function getUserOrders (
  context: Context,
  page: number
): Promise<any> {
  const url = new URL(
    'v1/checkout/orders/?orderBy=created_at&sortedBy=desc&page=' + page,
    context.config.api.url
  );

  // Use axios to send a GET request
  const { data } = await context.client.get(url);
  // Return data from the API
  return data;
}

export async function getOrderItemDownloads (
  context: Context,
  params: any
): Promise<any> {
  const {
    orderId,
    orderItemId
  } = params;
  const url = new URL(
    `v1/checkout/orders/${orderId}/items/${orderItemId}/files`,
    context.config.api.url
  );

  // Use axios to send a GET request
  const { data } = await context.client.get(url);
  // Return data from the API
  return data;
}
