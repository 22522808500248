













































import { computed, ref } from "@nuxtjs/composition-api";
import { KftHeading, KftModal } from "@konfetti-ui/vue";
import { CartValidationError } from "@konfetti/composables/src";

export default {
  name: "ValidationModal",
  components: {
    KftModal,
    KftHeading,
  },
  setup(props, { root: { $router, localePath } }) {
    const alertModal = ref(null);
    const errors = ref<CartValidationError>([]);

    const goToEvent = (permalink: string): void => {
      alertModal.value.hide();

      $router.push({
        path: localePath(`/e/${permalink}`),
      });
    };

    const openModal = (errArr: CartValidationError[]) => {
      errors.value = errArr;

      if (errors.value.length > 0) {
        alertModal.value?.show();
      }
    };

    return {
      alertModal,
      errors,

      hide: computed(() => alertModal.value?.hide),
      show: openModal,
      goToEvent,
    };
  },
};
