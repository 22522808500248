import {
  joinURL,
  parseSize,
  parseURL,
  withBase,
  srcIsKonfettiCDN,
} from "../utilities/helpers";

const defineMixin = (opts) => opts;

export const screens = {
  xs: 320,
  sm: 640,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1536,
  "2xl": 1536,
};

// @vue/component
export const imageMixin = defineMixin({
  head() {
    const links = [];

    if (this.preload === true) {
      const srcKey = typeof this.nSources[1] !== "undefined" ? 1 : 0;

      if (typeof this.nSources[srcKey].src === "undefined") {
        return;
      }

      const preload = {
        rel: "preload",
        as: "image",
        imagesrcset: this.nSources[srcKey].srcset,
      };

      const prefetch = {
        rel: "prefetch",
        as: "image",
        imagesrcset: this.nSources[srcKey].srcset,
      };

      const prerender = {
        rel: "prerender",
        as: "image",
        imagesrcset: this.nSources[srcKey].srcset,
      };

      if (typeof this.nSources[srcKey].sizes !== "undefined") {
        preload.imagesizes = this.nSources[srcKey].sizes;
        prefetch.imagesizes = this.nSources[srcKey].sizes;
        prerender.imagesizes = this.nSources[srcKey].sizes;
      }

      links.push(preload);
      links.push(prefetch);
      links.push(prerender);
    }

    return {
      link: links,
    };
  },

  props: {
    src: { type: String, required: false, default: null },
    format: { type: String, default: void 0 },
    quality: { type: [Number, String], default: void 0 },
    background: { type: String, default: void 0 },
    fit: { type: String, default: void 0 },
    modifiers: { type: Object, default: void 0 },
    preset: { type: String, default: void 0 },
    provider: { type: String, default: void 0 },
    sizes: { type: [Object, String], default: void 0 },
    preload: { type: Boolean, default: void 0 },
    width: { type: [String, Number], default: void 0 },
    height: { type: [String, Number], default: void 0 },
    alt: { type: String, default: void 0 },
    referrerpolicy: { type: String, default: void 0 },
    usemap: { type: String, default: void 0 },
    longdesc: { type: String, default: void 0 },
    ismap: { type: Boolean, default: void 0 },
    crossorigin: {
      type: [Boolean, String],
      default: void 0,
      validator: (val) =>
        ["anonymous", "use-credentials", "", true, false].includes(val),
    },
    loading: { type: String, default: void 0 },
    decoding: {
      type: String,
      default: void 0,
      validator: (val) => ["async", "auto", "sync"].includes(val),
    },
  },
  computed: {
    nImgAttrs() {
      return {
        width: parseSize(this.width),
        height: parseSize(this.height),
        alt: this.alt,
        referrerpolicy: this.referrerpolicy,
        usemap: this.usemap,
        longdesc: this.longdesc,
        ismap: this.ismap,
        crossorigin:
          this.crossorigin === true ? "anonymous" : this.crossorigin || void 0,
        loading: this.loading,
        decoding: this.decoding,
      };
    },
    nModifiers() {
      return {
        ...this.modifiers,
        width: parseSize(this.width),
        height: parseSize(this.height),
        format: this.format,
        quality: this.quality,
        background: this.background,
        fit: this.fit,
      };
    },
    nOptions() {
      return {
        provider: this.provider,
        preset: this.preset,
      };
    },
  },
  methods: {
    // eslint-disable-next-line func-names
    getImage(src, { modifiers = {} } = {}) {
      if (!src) {
        return {
          url: null,
        };
      }
      const { pathname } = parseURL(src);

      const baseURL = this.$config?.mediaUrl;

      // If the given url doesn't use Konfetti Media,
      // then we don't modify it
      if (!srcIsKonfettiCDN(baseURL, this.src)) {
        return {
          url: src,
        };
      }

      const {
        // fit,
        // smart,
        width = "0",
        height = "0",
        filters = {},
        format,
        quality,
      } = modifiers;

      const doResize = width !== "0" || height !== "0";

      if (format) {
        filters.format = String(format);
      }

      if (quality) {
        filters.quality = String(quality);
      }

      // const _filters = Object.entries(filters || {}).map(e => `${e[0]}(${e[1]})`).join(':');

      // const options = joinURL(
      //   fit ? `fit-${fit}` : '',
      //   doResize ? `${width}x${height}` : '',
      //   smart ? 'smart' : '',
      //   _filters ? ('filters:' + _filters) : ''
      // );

      const splittedPathname = pathname.split("/");

      const dotSplittedPathname =
        splittedPathname[splittedPathname.length - 1].split(".");
      dotSplittedPathname[1] = format;
      splittedPathname[splittedPathname.length - 1] =
        dotSplittedPathname.join(".");

      const originalSize = splittedPathname[2];
      splittedPathname[2] = doResize ? `${width}x${height}` : originalSize;

      const url = withBase(joinURL(splittedPathname.join("/")), baseURL);

      return {
        url,
      };
    },
    getSizes(input, opts) {
      const width = parseSize(opts.modifiers?.width);
      const height = parseSize(opts.modifiers?.height);
      const hwRatio = width && height ? height / width : 0;
      const variants = [];
      const sizes = {};
      if (typeof opts.sizes === "string") {
        for (const entry of opts.sizes.split(/[\s,]+/).filter((e) => e)) {
          const s = entry.split(":");
          if (s.length !== 2) {
            continue;
          }
          sizes[s[0].trim()] = s[1].trim();
        }
      } else {
        Object.assign(sizes, opts.sizes);
      }
      for (const key in sizes) {
        const screenMaxWidth = (screens && screens[key]) || parseInt(key);
        let size = String(sizes[key]);
        const isFluid = size.endsWith("vw");
        if (!isFluid && /^\d+$/.test(size)) {
          size = size + "px";
        }
        if (!isFluid && !size.endsWith("px")) {
          continue;
        }
        let _cWidth = parseInt(size);
        if (!screenMaxWidth || !_cWidth) {
          continue;
        }
        if (isFluid) {
          _cWidth = Math.round((_cWidth / 100) * screenMaxWidth);
        }
        const _cHeight = hwRatio ? Math.round(_cWidth * hwRatio) : height;
        const modifiers = {
          ...opts.modifiers,
          width: _cWidth,
          height: _cHeight,
        };

        const src = this.getImage(input, {
          ...opts,
          modifiers: modifiers,
        }).url;

        if (src) {
          variants.push({
            width: _cWidth,
            size,
            screenMaxWidth,
            media: `(max-width: ${screenMaxWidth}px)`,
            src,
          });
        }
      }
      variants.sort((v1, v2) => v1.screenMaxWidth - v2.screenMaxWidth);
      const defaultVar = variants[variants.length - 1];
      if (defaultVar) {
        defaultVar.media = "";
      }
      return {
        sizes: variants
          .map((v) => `${v.media ? v.media + " " : ""}${v.size}`)
          .join(", "),
        srcset: variants.map((v) => `${v.src} ${v.width}w`).join(", "),
        src: defaultVar?.src,
      };
    },
  },
});
