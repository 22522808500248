import Router from "vue-router";

const ROOT_DIR = "root-domain";
const SUBDOMAIN_DIR = "sub-domain";

/*
 * This file will check if the application has subdomains
 * If so, the context will be changed so the supplier scope is added
 *  */
export function createRouter(
  ssrContext,
  createDefaultRouter,
  routerOptions,
  config,
) {
  function isUnderDirectory(route, directory) {
    let includes = false;
    if (route.name) {
      includes = route.name.includes(directory);
    }

    return includes || route.path.includes(directory);
  }

  function filterRoutesArray(routes, isRoot) {
    return routes
      .filter((route) => {
        /**
         * remove routes from other directories
         * some routes (with diff behavior) are under root-domain and others under sub-domain
         * */
        return !isUnderDirectory(route, isRoot ? SUBDOMAIN_DIR : ROOT_DIR);
      })
      .map((route) => {
        const directory = isRoot ? ROOT_DIR : SUBDOMAIN_DIR;
        // remove directory from path and name
        if (isUnderDirectory(route, directory)) {
          const path = route.path
            .replace(`${directory}`, "")
            .replace("//", "/");

          const name = route.name.replace(`${directory}-`, "");
          return {
            ...route,
            path: path || "/",
            name: name || "home",
          };
        }

        return route;
      });
  }

  const options = routerOptions || createDefaultRouter(ssrContext).options;

  let domainLevel = null;
  let subdomain = null;
  if (process.server && ssrContext && ssrContext.nuxt && ssrContext.req) {
    const req = ssrContext.req;
    const host = req.headers.host;
    const hostIsIpAddress = host.match(
      /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}(\:\d{1,5})?$/,
    );

    domainLevel = (host.match(/\./g) || []).length + 1;

    if (domainLevel > 1) {
      subdomain = hostIsIpAddress ? null : host.split(".")[0];

      if (
        subdomain !== "www" &&
        subdomain !== "front-hml" &&
        subdomain !== "gokonfetti"
      ) {
        ssrContext.nuxt.subdomain = subdomain;
        config.subdomain = subdomain;
      }
    }
  }

  if (process.client && window.__NUXT__ && window.__NUXT__.subdomain) {
    subdomain = window.__NUXT__.subdomain;
    if (
      subdomain !== "www" &&
      subdomain !== "front-hml" &&
      subdomain !== "gokonfetti"
    ) {
      config.subdomain = subdomain;
    }
  }

  let newRoutes = options.routes;
  newRoutes = filterRoutesArray(
    newRoutes,
    subdomain === null ||
      subdomain === "www" ||
      subdomain === "front-hml" ||
      subdomain === "gokonfetti",
  );

  return new Router({
    ...options,
    routes: newRoutes,
  });
}
