/* eslint-disable  */

export default {
  faq: {
    title: "Häufig gestellte Fragen",
    sections: [
      {
        title: "Buchung & Bezahlung",
        questions: [
          {
            question: "Welche Zahlungsmöglichkeiten stehen zur Verfügung?",
            answer:
              'Bei uns kann man mit verschiedenen Zahlungsmöglichkeiten bezahlen: Paypal, Kreditkarte oder per Klarna Sofort. Private Veranstaltungen und Teamevents kannst Du auch per Rechnung bezahlen.',
          },
          {
            question: "Wie funktioniert die Buchung?",
            answer:
              "Wähle den Kurs, den Du besuchen möchtest entscheide Dich für einen Kurstermin und die Anzahl an Tickets, die Du kaufen möchtest. Füge den Kurs zum Warenkob hinzu und gehe anschließend zur Kasse. Gebe dort Deine Daten an, schließe die Bestellung über den Bezahlvorgang ab und schon hast Du Deinen Kurs gebucht.",
          },
          {
            question:
              "Wo kann ich meine Buchungsdetails sehen, sobald die Buchung abgeschlossen wurde?",
            answer:
              "Gleich nach der Buchung bekommst Du eine Bestätigungsmail mit Deinem Ticket. Ansonsten kannst Du Deine Buchunsdetails auch immer in Deinem konfetti Account finden. Solltest Du eine Rechnung benötigen, melde Dich bitte bei uns: hallo@gokonfetti.com",
          },
          {
            question: "Wo kann ich den Gutscheincode anwenden?",
            answer:
              "Im Buchungsvorgang an der Kasse, hast Du die Möglichkeit, einen Gutscheincode einzugeben. Nach Anwendung des Codes wird Dir der Restbetrag angezeigt, der noch zu zahlen ist. Deckt der Gutschein den Preis Deines Kurses ab, musst Du nur noch den Bestellvorgang mit dem Kauf abschließen. Überschüssiges Guthaben Deines Geschenkgutscheins wird Deinem Kundenkonto gutgeschrieben und kann für spätere Käufe verwendet werden.",
          },
          {
            question:
              "Ist mein Wunschgutschein von Wunschgutschein.de auch bei konfetti einlösbar?",
            answer:
              "konfetti ist Einlösepartner von Wunschgutschein. Deinen Wunschgutschein kannst Du also auch in unserem Shop einlösen.",
          },
          {
            question:
              "Wie kann ich meinen Wunschgutschein bei konfetti einlösen?",
            answer:
              "Gib auf der Webseite von Wunschgutschein (https://einloesen.wunschgutschein.de/) Deinen Wunschgutscheincode ein. Anschließend wählst Du konfetti aus den Partner-Shops aus und erhältst Deinen konfetti Gutscheincode. Diesen kannst Du dann bei uns auf der Seite einlösen.  ",
          },
          {
            question: "Kann ich auch vor Ort zahlen?",
            answer:
              "Das ist leider nicht möglich. Bei einer Buchung ist direkt eine Zahlung notwendig.",
          },
          {
            question:
              "Ich finde leider die Mail mit meinem Ticket nicht mehr - was tun?",
            answer:
              "Kein Problem – Du stehst auf der Gästeliste. Bring in diesem Fall bitte Deinen Ausweis mit. Die Kursleitung vor Ort hakt Deinen Namen dann ab.",
          },
          {
            question: "Muss ich ein Konto für die Buchung anlegen?",
            answer:
              "Ja, im Registrierungsprozess legst Du automatisch ein Konto an.",
          },
          {
            question: "Muss ich mein Ticket zum Kurs mitbringen?",
            answer:
              "Ja, Du musst es aber nicht ausdrucken. Es reicht völlig, wenn Du es in elektronischer Form vorzeigen kannst. So vermeiden wir Missverständnisse und alle wissen auf Anhieb, dass Du auf der Gästeliste stehst.",
          },
        ],
      },
      {
        title: "Ausfall & Stornierung",
        questions: [
          {
            question: "Ist meine Buchung stornierbar?",
            answer:
              "Buchungen sind grundsätzlich nicht stornierbar. Wenn du am Kurstermin krank oder verhindert sein solltest, dann kann aber ohne Probleme eine andere Person für Dich teilnehmen. Bei den konfetti Workshops handelt es sich um Veranstaltungen (ähnlich wie Konzerte oder ein Kinotickets), daher ist die Möglichkeit einer Umbuchung nicht verpflichtend.",
          },
          {
            question: "Haben die Kurse eine Mindestteilnehmerzahl?",
            answer:
              "In manchen Fällen ist eine Mindestanzahl an Teilnehmern für unsere Kursleiter notwendig, damit sich die Veranstaltung für sie lohnt. Sollte es für einen Workshop nur eine Anmeldung geben, kann es sein, dass der Workshop leider abgesagt werden muss.",
          },
          {
            question: "Was passiert, wenn ein Kurs abgesagt wird?",
            answer:
              "Wenn ein Workshop vom Anbieter abgesagt werden muss (bspw. aufgrund von Krankheit oder nicht erreichter  Mindestteilnehmeranzahl) gehst Du auf keinen Fall mit leeren Händen aus. In diesem Fall kontaktieren wir Dich rechtzeitig und kümmern uns um eine Lösung.",
          },
          {
            question:
              "Kann ich einen gebuchten Kurs auf eine andere Person übertragen?",
            answer:
              "Ja. Wenn Du verhindert sein solltest und nicht am Kurs teilnehmen kannst, kannst Du das gebuchte Ticket an eine andere Person übertragen. Leite der Person hierfür einfach Dein Ticket weiter, welches zum Kurs mitgebracht werden sollte und die Person kann das elektronische Ticket vor Ort vorzeigen. Gut wäre es, wenn die Person der Kursleitung mitteilt, für wen sie auf der Gästeliste “eingesprungen” ist.",
          },
          {
            question: "Kann mich jemand zum Kurs begleiten?",
            answer:
              "Ob Dich jemand zum Kurs begleiten kann, kommt darauf an, was in der Kursbeschreibung steht. Spreche das am besten vorab mit den Veranstaltern ab.  In der Regel ist es notwendig, ein zusätzliches Ticket für Deine Begleitperson zu buchen.",
          },
        ],
      },
      {
        title: "Allgemeines zu den Kursen",
        questions: [
          {
            question: "Wie kann ich meinen Veranstalter kontaktieren?",
            answer:
              "Durch die Buchung erhältst Du die Möglichkeit, Deinen Veranstalter zu kontaktieren. Du kannst Veranstaltern dann eine E-Mail schreiben oder sie auch telefonisch erreichen, falls Du noch etwas absprechen möchtest.",
          },
          {
            question: "Was muss ich zu Euren Kursen mitbringen?",
            answer:
              "In vielen Workshops musst Du nichts mitbringen. Der Veranstalter besorgt dann alle Materialien für Dich. Jedoch ist das von Kurs zu Kurs unterschiedlich. Die Anforderungen zu den einzelnen Kursen findest Du in der Kursbeschreibung und auf Deinem Ticket.",
          },
          {
            question: "Wer ist der Anbieter von meinem Kurs?",
            answer:
              "Der Name des Anbieters bzw. Veranstalters wird im spezifischen Event sowie im Ticket nach dem Kauf ausgewiesen. konfetti tritt als Vermittler auf.",
          },
          {
            question: "Darf ich mein Werk mit nach Hause nehmen?",
            answer:
              "Alles, was Du im Kurs produzierst, kannst Du selbstverständlich auch mit nach Hause nehmen. Es sei denn, Du hast es während des Kurses bereits konsumiert 😉",
          },
          {
            question: "Wie viele Leute sind maximal in einem Kurs?",
            answer:
              "Die Teilnehmerzahl variiert sehr stark zwischen den unterschiedlichen Kursen und Anbietern. Manchmal sind es sehr kleine Gruppen von 6-8 Leuten, oft aber auch mal etwas größere Gruppen von 15-20 Leuten. So oder so ist die Gruppengröße immer perfekt auf den Kursrahmen abgestimmt. Es wird sichergestellt, dass Kursleiter individuell auf jeden Teilnehmer eingehen können.",
          },
          {
            question: "Wie viele Tickets muss ich für Online Kurse kaufen?",
            answer:
              "Bei den meisten unserer Online Kurse benötigst Du ein Ticket pro Endgerät. Sollte bei dem Online Kurs zusätzlich z.B. eine Kochbox oder Materialbox nach Hause geschickt werden, kann es sein, dass Du mehrere Tickets benötigst. Entnehme dies bitte der Kursbeschreibung oder melde Dich kurz bei uns im Chat bei Zweifeln.",
          },
        ],
      },
    ],
  },
};
