/* eslint-disable camelcase */
import { useStripe } from "@konfetti/composables";
import { Logger, sharedRef, UseKftContext } from "@konfetti-core/core";
import { computed } from "@vue/composition-api";
import {
  ErrorStoreErrorType,
  useErrorStore,
  useOrderValidation,
} from "~/composables";
import { addSubdomain, getSupplierSubdomain } from "~/helpers";

/**
 * @name usePaypal
 * @desc This module is responsible for dealing with stripe Paypal checkout
 *
 * @param id used to define the shared Refs
 * @param hasAccessToWindowObject used to block usage of window objects in iFrames
 * @param customUserToken used if we do not have access to window object and we're forcing the user token to the requests
 * */

const usePaypal = (
  id: string,
  hasAccessToWindowObject: boolean,
  customUserToken = null,
): any => {
  const context = UseKftContext();
  const loading = sharedRef(null, `usePaypal/loading-${id}`);
  const error = sharedRef(null, `usePaypal/error-${id}`);
  const stripeInstance = sharedRef(null, `usePaypal/stripeInstance-${id}`);
  const {
    sendOrder,
    order,
    error: orderError,
  } = useStripe("general-instance", hasAccessToWindowObject);

  const { cartValidationErrors, orderValidationErrors, fnValidateOrder } =
    useOrderValidation("general-instance", hasAccessToWindowObject);

  const setStripeInstance = (instance): void => {
    stripeInstance.value = instance;
  };

  const { handleError } = useErrorStore();

  /**
   * @name fnSubmitOrder
   * @description sends a validation request to the API in order to make sure we have a valid order
   *
   * @param params The mounted params
   * */

  const fnSubmitOrder = async (params) => {
    loading.value = true;
    Logger.debug("checkout-payment-pending:stripe");

    console.log("params", params, customUserToken);

    await sendOrder(
      null,
      params.items,
      params.shipping,
      params.invoice,
      params.coupon,
      null,
      customUserToken,
      params.external,
      "PAYPAL",
    );

    if (orderError.value !== null) {
      Logger.error("checkout-payment-attempt:stripe", {
        error: orderError.value,
      });
      handleError({
        type: ErrorStoreErrorType.PaypalOrderError,
        error: orderError.value,
      });
      error.value = orderError.value;
    }
  };

  /**
   * @name fnProcessPayment
   * @description
   * Function process the first stage of a payment,
   * if 3DS is required, then it will call the functions to handle that
   *
   * @param params Mounted object with necessary variables
   * */
  const fnProcessPayment = async (params) => {
    loading.value = true;

    await fnSubmitOrder(params);

    if (orderError.value !== null && orderError.value.length > 0) {
      error.value = orderError.value;
      loading.value = false;
      return;
    }

    const id = order.value?.data?.id || null;
    const paypalIntentSecret = order.value?.meta?.custom?.client_secret || null;
    if (!paypalIntentSecret) {
      loading.value = false;
      return;
    }

    const alias = getSupplierSubdomain(
      context?.ssrContext?.nuxt || context.nuxtState,
      context.$config,
    );

    stripeInstance.value
      .confirmPayPalPayment(paypalIntentSecret, {
        payment_method: {
          // paypal: {
          //   // @todo set it for another country later
          //   country: "DE",
          // },
        },
        // Return URL where the customer should be redirected after the authorization.
        return_url: addSubdomain(
          context.$config.baseUrl.replace(/\/+$/, "") +
            context.localePath(`/checkout/?orderId=${id}`),
          alias,
        ),
      })
      .then((result) => {
        console.log(result);
      })
      .finally(() => {
        loading.value = false;
      });
  };

  /**
   * @name fnSubmitPayment
   * @description Initializes a payment with Paypal, the backend will retrieve an intent token so we can proceed with the purchase
   *
   * @param params Mounted object with necessary variables
   * */
  const fnSubmitPayment = async (params) => {
    loading.value = true;

    /* Validating order and items */
    await fnValidateOrder(params, customUserToken);

    /* At this point, events were already issued in validateOrder(), we are just stopping the process */
    if (
      cartValidationErrors.value !== null &&
      cartValidationErrors.value.length > 0
    ) {
      error.value = cartValidationErrors.value;
      loading.value = false;
      return;
    }

    /* At this point, events were already issued in validateOrder(), we are just stopping the process */
    if (
      orderValidationErrors.value !== null &&
      orderValidationErrors.value.length > 0
    ) {
      error.value = orderValidationErrors.value;
      loading.value = false;
      return;
    }

    /* Process the payment */
    await fnProcessPayment(params);

    loading.value = false;
  };

  return {
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    order: computed(() => order.value),

    fnSubmitPayment,
    setStripeInstance,
  };
};

export { usePaypal };
