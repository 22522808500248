





















































































import { KftLink, SfImage } from "@konfetti-ui/vue";
import Vue from "vue";
import KftHeaderNavigation from "./_internal/KftHeaderNavigation.vue";
import KftHeaderNavigationItem from "./_internal/KftHeaderNavigation.vue";
import useUiState from "~/composables/useUiState";

Vue.component("KftHeaderNavigation", KftHeaderNavigation);
Vue.component("KftHeaderNavigationItem", KftHeaderNavigationItem);

export default {
  name: "KftHeader",
  components: {
    KftLink,
    SfImage,
  },
  props: {
    useIcons: {
      type: Boolean,
      default: false,
    },
    isBookingSolution: {
      type: Boolean,
      default: false,
    },

    /**
     * Header logo
     */
    logo: {
      type: [String, Object],
      default: "",
    },

    /**
     * Header title
     */
    title: {
      type: String,
      default: "",
    },

    /**
     * Header activeIcon (accepts account, wishlist and cart)
     */
    activeIcon: {
      type: String,
      default: "",
      validator(value) {
        return ["", "account", "wishlist", "cart"].includes(value);
      },
    },

    /**
     * Header search placeholder
     */
    searchPlaceholder: {
      type: String,
      default: "Search for items",
    },

    /**
     * Header search phrase
     */
    searchValue: {
      type: String,
      default: "",
    },

    /**
     * Header wishlist items quantity
     */
    wishlistItemsQty: {
      type: [String, Number],
      default: "0",
    },

    /**
     * Header cart items quantity
     */
    cartItemsQty: {
      type: [String, Number],
      default: 0,
    },

    /**
     * Header sticky to top
     */
    isSticky: {
      type: Boolean,
      default: false,
    },

    /**
     * Is nav slot visible on mobile view
     */
    isNavVisible: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { toggleNavigationSidebar } = useUiState();

    return {
      toggleNavigationSidebar,
    };
  },

  data() {
    return {
      hidden: false,
      sticky: false,
      scrollDirection: null,
      lastScrollPosition: 0,
      animationStart: null,
      animationLong: null,
      animationDuration: 300,
    };
  },

  computed: {
    cartHasProducts() {
      return parseInt(this.cartItemsQty, 10) > 0;
    },
    wishlistHasProducts() {
      return parseInt(this.wishlistItemsQty, 10) > 0;
    },
  },
};
