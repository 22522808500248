import { Logger, sharedRef } from "@konfetti-core/core";
import { computed, watch } from "@vue/composition-api";
import { useApiHandler } from "../useApiHandler";
import { Error400 } from "../types";
import { useCart } from "../useCart";

export enum CartKeys {
  GENERAL = "general-instance",
  EMBEDDED = "embedded-instance",
}

export const useCartValidation = (id: string): any => {
  // const loading = sharedRef(false, `useCart-loading-${id}`);

  /* Cart */
  const { cart, loadCart } = useCart(id);

  const cartItemsValidationError = sharedRef<Error400>(
    null,
    `useCart-cartValidationError-${id}`,
  );

  /** Wrapper for API requests */
  const { makeRequest } = useApiHandler();

  const fnCreateCartValidationItemsObject = async () => {
    const response = [];

    // We need to load cart to make sure we are validating the latest
    // instance of the cart
    await loadCart();
    if (cart.value.items.length === 0) {
      return response;
    }

    for (let i = 0; i < cart.value.items.length; i++) {
      const item = cart.value.items[i];
      response.push({
        type: item.type,
        ...(item.type !== "GIFT_CARD" && { product: item.id }),
        quantity: item.quantity,
        private: item.private,
        value: item?.price?.amount,
        ...(item.type === "GIFT_CARD" && { event: item.eventDescriptionId }),
      });
    }

    return response;
  };

  const fnResetCartItemsValidationError = () => {
    cartItemsValidationError.value = null;
  };

  const fnValidateCartItems = async () => {
    fnResetCartItemsValidationError();
    const items = await fnCreateCartValidationItemsObject();
    Logger.debug(`useCart/${id}/validateCartItems`, items);

    let valid = true;

    if (items.length === 0) {
      return valid;
    }

    try {
      const resp = await makeRequest("validateCartItems", { items: items });
      Logger.debug(`useCart/${id}/validateCartItems`, resp);
    } catch (err) {
      cartItemsValidationError.value = err?.response?.data || err;
      Logger.error(`useCart/${id}/validateCartItems`, err);

      valid = false;
    }

    return valid;
  };

  watch(cart, () => {
    // Need to keep errors only if there are corresponding items in cart
    // if (
    //   cartItemsValidationError?.value?.errors &&
    //   cartItemsValidationError.value.errors.length > 0
    // ) {
    //   cartItemsValidationError.value.errors =
    //     cartItemsValidationError.value.errors.filter(
    //       (error) =>
    //         (error?.date?.id || error?.event?.id) !==
    //         cartGetters.getItemId(obj),
    //     );
    // }
  });

  return {
    // Variables
    cartItemsValidationError: computed(() => cartItemsValidationError.value),

    // Methods
    fnValidateCartItems,
    fnResetCartItemsValidationError,
  };
};
