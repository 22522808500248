<template>
  <!-- @todo put directive back on  -->
  <!--  v-click-outside='hide' -->
  <div class="kft-drawer">
    <!--begin::View component-->
    <div
      ref="drawerEl"
      :data-kt-drawer-direction="direction"
      class="bg-white kft-drawer__container"
      data-kt-drawer="true"
      data-kt-drawer-activate="true"
      data-kt-drawer-close="#drawer_close"
      data-kt-drawer-name="drawer"
      data-kt-drawer-overlay="false"
    >
      <div class="card w-100 rounded-0">
        <!--begin::Card header-->

        <div class="card-header pe-5 ps-4">
          <slot name="header">
            <!--begin::Title-->
            <div class="card-title">
              <!--begin::User-->
              <div class="d-flex justify-content-center flex-column me-3">
                <KftHeading
                  :level="3"
                  :title="title"
                  class="kft-heading--left kft-heading--no-underline kft-drawer__heading"
                />
              </div>
              <!--end::User-->
            </div>
            <!--end::Title-->
            <!--begin::Card toolbar-->
            <div class="card-toolbar">
              <!--begin::Close-->
              <div id="drawer_close" class="btn btn-sm btn-icon">
                <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                <span class="svg-icon svg-icon-2">
                  <svg
                    fill="none"
                    height="16"
                    viewBox="0 0 18 18"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 1L17 17" stroke="#171717" stroke-width="2" />
                    <path d="M17 1L1 17" stroke="#171717" stroke-width="2" />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </div>
              <!--end::Close-->
            </div>
            <!--end::Card toolbar-->
          </slot>
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div class="card-body scroll-y">
          <slot />
        </div>
        <!--end::Card body-->
        <div class="card-footer py-4">
          <slot name="footer" />
        </div>
      </div>
    </div>
    <!--end::View component-->
  </div>
</template>

<script>
import { DrawerComponent } from "@konfetti/theme/plugins/metronic/components/_DrawerComponent.ts";
import { clickOutside } from "@konfetti-ui/vue/src/utilities/directives/";
import { KftHeading } from "@konfetti-ui/vue";
import { onMounted, ref } from "@vue/composition-api";

export default {
  name: "KftDrawer",

  directives: {
    clickOutside,
  },

  components: {
    KftHeading,
  },

  props: {
    /**
     * The sidebar's title
     */
    title: {
      type: String,
      default: "",
    },

    direction: {
      type: String,
      default: "start",
    },
  },

  emits: {
    hide: null,
  },

  setup(props, context) {
    const drawerEl = ref(null);
    const drawerObj = ref(null);

    onMounted(() => {
      drawerObj.value = new DrawerComponent(drawerEl.value, { overlay: false });
      drawerObj.value.on("kt.drawer.after.hidden", () => {
        context.emit("hide");
      });
    });

    const show = () => {
      return drawerObj.value.show();
    };

    const hide = (e) => {
      if (!drawerObj.value.isShown()) {
        return false;
      }

      return drawerObj.value.hide();
    };

    return {
      drawerEl,
      show,
      hide,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@konfetti/theme/assets/metronic/core/components/mixins/_scroll.scss";
@import "@konfetti/theme/assets/metronic/core/components/_scroll.scss";

.kft-drawer {
  &__container {
    width: 100%;

    @include for-tablet {
      width: 400px;
    }

    @include for-desktop {
      width: 32rem;
    }
  }

  &__heading h3 {
    font-size: 16px;

    @include for-desktop {
      font-size: 20px;
    }
  }
}

.card-body {
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.3) 20%,
      transparent 20%,
      transparent 40%,
      rgba(255, 255, 255, 0.3) 40%,
      rgba(255, 255, 255, 0.3) 60%,
      transparent 60%,
      transparent 80%,
      rgba(255, 255, 255, 0.3) 80%
    );
    border-radius: 3px;
  }
}
</style>
