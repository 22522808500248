/* eslint-disable  */

export default {
  supplierProfile: {
    heading: "{name} buchen",
    supplierDescriptionTitle: "Erfahre mehr über {supplierName}",
    eventsFromTheSameSupplier: "Kurse und Erlebnisse buchen von {supplierName}",
    address: {
      title: "{name} Adresse - hier findest Du {name}:",
    },
    relatedLinks: {
      city: "{name} bietet Kurse in folgenden Städten an:",
      category: "{name} bietet Kurse und Erlebnisse in folgenden Kategorien an:",
    },
    articles: {
      sectionTitle: "Magazinartikel von {partnerName}"
    }
  },
};
