<template>
  <div :class="[staticClass, className]" class="kft-sidebar">
    <SfOverlay :visible="visibleOverlay" />
    <transition :name="transitionName">
      <aside
        v-if="visible"
        ref="asideContent"
        v-click-outside="checkPersistence"
        v-focus-trap
        class="kft-sidebar__aside"
        data-test-id="cart-sidebar__aside"
      >
        <!--@slot Use this slot to place content inside the modal bar.-->
        <slot name="bar">
          <SfBar
            :back="true"
            :title="title"
            class="smartphone-only"
            @click:back="close"
          />
        </slot>
        <!--@slot Use this slot to replace close icon.-->
        <slot v-bind="{ close, button }" name="circle-icon">
          <i class="bi bi-x desktop-only" v-if="button" @click="close"></i>
        </slot>
        <div v-if="title || hasTop" class="kft-sidebar__top">
          <!--@slot Use this slot to replace KftHeading component.-->
          <slot v-bind="{ title, subtitle, headingLevel }" name="title">
            <KftHeading
              v-if="title"
              :description="subtitle"
              :level="headingLevel"
              :title="title"
              class="kft-heading--left kft-heading--no-underline kft-sidebar__title desktop-only"
            />
          </slot>
          <!--@slot Use this slot to add sticky top content.-->
          <slot name="content-top" />
        </div>
        <div class="kft-sidebar__content" ref="sidebarContent">
          <!--@slot Use this slot to add KftSidebar content.-->
          <slot />
        </div>
        <!--@slot Use this slot to place content to sticky bottom.-->
        <div v-if="hasBottom" class="kft-sidebar__bottom">
          <slot name="content-bottom" />
        </div>
      </aside>
    </transition>
  </div>
</template>

<script>
import { isClient } from "@konfetti-ui/vue/src/utilities/helpers";
import {
  clickOutside,
  focusTrap,
} from "@konfetti-ui/vue/src/utilities/directives/";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { SfBar, KftHeading, SfOverlay } from "@konfetti-ui/vue";

export default {
  name: "KftSidebar",

  directives: {
    focusTrap,
    clickOutside,
  },

  components: {
    SfBar,
    SfOverlay,
    KftHeading,
  },

  props: {
    /**
     * The sidebar's title
     */
    title: {
      type: String,
      default: "",
    },

    /**
     * The sidebar's subtitle
     */
    subtitle: {
      type: String,
      default: "",
    },

    /**
     * The heading's level
     */
    headingLevel: {
      type: Number,
      default: 3,
    },

    /**
     * The close button
     */
    button: {
      type: Boolean,
      default: true,
    },

    /**
     * The sidebar's visibility
     */
    visible: {
      type: Boolean,
      default: false,
    },

    /**
     * The overlay's visibility
     */
    overlay: {
      type: Boolean,
      default: true,
    },

    /**
     * If true clicking outside will not dismiss the sidebar
     */
    persistent: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      position: "left",
      staticClass: null,
      className: null,
    };
  },

  computed: {
    visibleOverlay() {
      return this.visible && this.overlay;
    },
    transitionName() {
      return "sf-slide-" + this.position;
    },
    hasTop() {
      return this.$slots.hasOwnProperty("content-top");
    },
    hasBottom() {
      return this.$slots.hasOwnProperty("content-bottom");
    },
  },

  watch: {
    visible: {
      handler(value) {
        if (!isClient) return;
        if (value) {
          this.$nextTick(() => {
            disableBodyScroll(this.$refs.asideContent);
          });

          document.addEventListener("keydown", this.keydownHandler);
        } else {
          clearAllBodyScrollLocks();
          document.removeEventListener("keydown", this.keydownHandler);
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.classHandler();
  },

  updated() {
    this.classHandler();
  },

  methods: {
    close() {
      this.$emit("close");
    },
    checkPersistence() {
      if (!this.persistent) this.close();
    },
    keydownHandler(e) {
      if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
        this.close();
      }
    },
    classHandler() {
      let update = false;
      if (this.staticClass !== this.$vnode.data.staticClass) {
        this.staticClass = this.$vnode.data.staticClass;
        update = true;
      }
      if (this.className !== this.$vnode.data.class) {
        this.className = this.$vnode.data.class;
        update = true;
      }
      if (update) {
        this.position =
          [this.staticClass, this.className].toString().search("--right") > -1
            ? "right"
            : "left";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@konfetti-ui/shared/styles/components/organisms/KftSidebar.scss";
</style>
