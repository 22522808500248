import { Logger, sharedRef } from "@konfetti-core/core";
import { computed } from "@vue/composition-api";
import { paginationGetters } from "../getters";
import { useApiHandler } from "../useApiHandler";

export const useCategory = (id: string): any => {
  const categories = sharedRef(null, `useCategory-categories-${id}`);
  const loading = sharedRef(false, `useCategory-loading-${id}`);
  const error = sharedRef(null, `useCustom-error-${id}`);

  /** Wrapper for API requests */
  const { makeRequest } = useApiHandler();

  const clearCategories = () => {
    categories.value = null;
  };

  // Method to call an API endpoint and update `categories`, `loading` and `error` properties
  const loadCategories = async (params: string) => {
    Logger.debug(`useCategory/${id}/loadCategories`, params);

    try {
      loading.value = true;
      categories.value = await makeRequest("getCategory", params);

      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useCategory/${id}/loadCategories`, err);
    } finally {
      loading.value = false;
    }
  };

  return {
    // Variables
    categories: computed(() =>
      paginationGetters.validatePagination(categories.value),
    ),
    loading: computed(() => loading.value),
    error: computed(() => error.value),

    // Methods
    loadCategories,
    clearCategories,
  };
};
