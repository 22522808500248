import insightsClient from "search-insights";
import { UseKftContext } from "@konfetti-core/core";
import { computed, ref } from "@vue/composition-api";
import { hashString, uuid4 } from "~/helpers/cryptoHelpers";

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const useSearchInsights = () => {
  const ALGOLIA_USER_TOKEN_KEY = "konfetti-algolia-tracking-user-token";
  const token = ref(null);

  /**
   * Variables
   * */
  const context = UseKftContext();

  /**
   * Methods
   * */
  const fnGetUserToken = () => {
    let algoliaToken = null;
    if (!process.server) {
      return;
    }

    insightsClient("getUserToken", null, (err, userToken) => {
      if (err) {
        console.error(err);
        return;
      }

      algoliaToken = userToken;
    });

    return algoliaToken;
  };

  const fnSetUserToken = () => {
    if (process.server) {
      return;
    }

    token.value = window?.localStorage?.getItem(ALGOLIA_USER_TOKEN_KEY);

    if (!token.value || token.value === "undefined") {
      token.value = fnGetUserToken() || uuid4();
    }

    const storageUser = window?.localStorage?.getItem("user");
    if (storageUser) {
      const user = JSON.parse(storageUser);
      token.value = user?.data?.email
        ? hashString(user.data.email)
        : token.value;
    }

    if (window?.localStorage) {
      window.localStorage.setItem(ALGOLIA_USER_TOKEN_KEY, token.value);
    }

    insightsClient("setUserToken", token.value);
  };

  const fnInit = () => {
    if (!process.server) {
      insightsClient("init", {
        appId: context.$config.algolia.appId,
        apiKey: context.$config.algolia.apiClientKey,
      });

      insightsClient(
        "onUserTokenChange",
        (userToken) => {
          if (!window?.dataLayer) {
            return;
          }

          window.dataLayer.push({
            algoliaUserToken: userToken,
          });
        },
        { immediate: true },
      );
    }
    fnSetUserToken();
  };

  return {
    client: insightsClient,

    token: computed(() => token.value),

    /* Methods */
    fnInit,
    fnSetUserToken,
  };
};
