































































































































































import { computed, onMounted, ref } from "@vue/composition-api";
import { KftHeading } from "@konfetti-ui/vue";
import { useCart, useCheckout, CartKeys } from "@konfetti/composables";
import KftMultiselect from "../../organisms/KftMultiselect/KftMultiselect.vue";
import { useFormValidation } from "~/composables/useFormValidation";
import { useEmbeddedCheckout } from "~/composables/useEmbeddedCheckout";
import { loadingWrapper } from "~/helpers";

export default {
  name: "KftAddressForm",

  components: {
    KftHeading,
    Multiselect: KftMultiselect,
  },

  props: {
    shippingDetails: {
      first_name: "",
      // eslint-disable-next-line camelcase
      last_name: "",
      street: "",
      // eslint-disable-next-line camelcase
      street_number: "",
      zipcode: "",
      locality: "",
      country: "",
      // eslint-disable-next-line camelcase
      additional_information: "",
    },
    controlVariables: {},
  },

  setup(props, { emit, root: { $i18n } }) {
    const form = ref({
      first_name: "",
      // eslint-disable-next-line camelcase
      last_name: "",
      street: "",
      // eslint-disable-next-line camelcase
      street_number: "",
      zipcode: "",
      locality: "",
      country: "",
      // eslint-disable-next-line camelcase
      additional_information: "",
    });
    const rules = ref({
      first_name: "required",
      last_name: "required",
      // eslint-disable-next-line camelcase
      street: "required",
      locality: "required",
      zipcode: "required",
      country: "required",
      // eslint-disable-next-line camelcase
      street_number: "required",
    });

    const { fnLoadShippingCosts, shippingCosts } = useCheckout();
    const { isEmbedded } = useEmbeddedCheckout();

    const { cart, loadCart, fnSetShippingCost } = useCart(
      isEmbedded.value ? CartKeys.EMBEDDED : CartKeys.GENERAL,
    );

    const countriesValueArr = computed(
      () =>
        shippingCosts.value?.data?.map((x) => x.country.data.codeIsoAlpha2) ||
        [],
    );

    const computedCountryNamesMap = computed(() => {
      const map = {};
      shippingCosts.value?.data.forEach((x) => {
        map[x.country.data.codeIsoAlpha2] = x.country.data.name;
      });

      return map;
    });

    const selectedCountryData = computed(() =>
      shippingCosts.value?.data?.find(
        (x) => x.country.data.codeIsoAlpha2 === form.value.country,
      ),
    );

    const controlVariables = ref(props.controlVariables);
    const { errors, hasError, validate, invalidKeys } = useFormValidation(
      form,
      rules,
      controlVariables,
    );

    /**
     * @name fnValidateForm
     * @description used when an address is inputted, if the form is not valid, it blocks the order
     * @param key the key to be validated, null for all
     * @param showErrors If the view should display the validation errors
     * */
    const fnValidateForm = async (key, showErrors = true) => {
      await validate(key);

      if (showErrors && hasError()) {
        emit("invalid");
      }

      emit("valid", form.value);
    };

    const fnOnChangeCountry = () => {
      fnSetShippingCost(selectedCountryData.value);
    };

    onMounted(() => {
      loadingWrapper(async () => {
        await fnLoadShippingCosts();
        if (selectedCountryData.value) {
          fnOnChangeCountry();
        }
      });
      form.value = props.shippingDetails;
    });

    return {
      form,
      errors,
      hasError,
      validate,
      invalidKeys,
      countriesValueArr,
      shippingCosts,
      computedCountryNamesMap,

      fnValidateForm,
      fnOnChangeCountry,
    };
  },
};
