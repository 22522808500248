const loadScript = (url: string, target) =>
  new Promise((resolve) => {
    const tag = document.createElement("script");
    tag.async = false;
    tag.src = url;
    target.appendChild(tag);
    tag.addEventListener("load", resolve, {
      once: true,
    });
  });

const useScript = (url: string, target, cb: () => void = () => {}) => {
  if (!target) {
    const { body } = document;
    target = body;
  }

  Promise.all([loadScript(url, target)]).then(cb);
};

export default useScript;
