






export default {
  name: "WhiteButton",
};
