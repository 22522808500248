/* eslint-disable camelcase */
// @todo change to useAuth

import { Logger, sharedRef } from "@konfetti-core/core";
import { computed, ref } from "@vue/composition-api";
import { useApiHandler } from "../useApiHandler";
import { supplierGetters } from "../getters/supplierGetters";
import { useSupplier } from "../useSupplier";

/**
 * @description
 * This module is a reduced (or core) version of the User components
 * It doesn't handle localStorage and it shouldn't
 * our iFrames will use it and they don't have direct access to it
 * */

const RESET_PASSWORD_FORM_EMPTY = {
  password: null,
  passwordConfirmation: null,
  email: null,
  token: null,
};

export const useUserReduced = (
  id: string,
  hasAccessToWindowObject: boolean,
): any => {
  const user = sharedRef(null, `${id}--user`);
  const tokens = ref(null);
  const userExistsByEmail = ref(null);
  const loading = ref(false);
  const error = ref(null);
  const resetPasswordForm = ref(RESET_PASSWORD_FORM_EMPTY);
  const { supplier } = useSupplier("supplier-layout", false);

  /** Wrapper for API requests */
  const { makeRequest, setJwtToken } = useApiHandler(hasAccessToWindowObject);

  const setUser = (value) => {
    user.value = value;
  };

  const setUserExistsByEmail = (value) => {
    userExistsByEmail.value = value;
  };

  const setTokens = (
    accessToken: string,
    refreshToken: string,
    expiresAt: number,
  ) => {
    tokens.value = {
      access_token: accessToken,
      refresh_token: refreshToken,
      expires_at: expiresAt,
    };
  };

  const verifyRefreshToken = async (
    accessToken: string,
    refreshToken: string,
  ) => {
    Logger.debug(`useUser/${id}/verifyRefreshToken`);

    try {
      loading.value = true;
      error.value = null;

      setJwtToken(accessToken);
      const result = await makeRequest("verifyRefreshToken", {
        refreshToken,
      });

      /* The tokens are still valid, nothing to do... */
      if (result?.refresh?.length === 0) {
        return;
      }

      const refreshTokens = result?.refresh?.response_content;
      if (refreshTokens) {
        tokens.value = {
          access_token: refreshTokens.access_token,
          refresh_token: refreshTokens.refresh_token,
          expires_at:
            parseInt(refreshTokens.expires_in) + Math.floor(Date.now() / 1000),
        };
      }

      const user = result?.user;
      if (user) {
        user.value = user;
      }

      if (!tokens.value) {
        error.value = true;
      }
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useUser/${id}/verifyRefreshToken`, err);

      user.value = null;
      tokens.value = null;
    } finally {
      loading.value = false;
    }
  };

  const getUser = async (token?: string | undefined) => {
    Logger.debug(`useUser/${id}/user`);

    try {
      loading.value = true;
      error.value = null;

      if (!token) {
        throw new Error("No token provided for getUser");
      }

      setJwtToken(token);
      user.value = await makeRequest("getUser");
    } catch (err) {
      error.value = err?.response?.data || err;
      if (err.message === "No token provided for getUser") {
        Logger.debug(`useUser/${id}/getUser`, err);
      } else {
        Logger.error(`useUser/${id}/getUser`, err);
      }
      user.value = null;
    } finally {
      loading.value = false;
    }
  };

  const login = async (user) => {
    Logger.debug(`useUser/${id}/login`, user);

    try {
      loading.value = true;
      error.value = null;

      const result = await makeRequest("login", {
        email: user.username,
        password: user.password,
      });

      tokens.value = {
        access_token: result.access_token,
        refresh_token: result.refresh_token,
        expires_at: parseInt(result.expires_in) + Math.floor(Date.now() / 1000),
      };

      return await getUser(tokens.value.access_token);
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useUser/${id}/login`, err);
    } finally {
      loading.value = false;
    }
  };

  const requestPasswordReset = async (params) => {
    Logger.debug(`useUser/${id}/requestPasswordReset`, params);

    try {
      loading.value = true;
      error.value = null;

      const { email, resetUrl } = params;

      await makeRequest("sendPasswordResetEmail", {
        email,
        reseturl: resetUrl,
      });

      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useUser/${id}/register`, err);
    } finally {
      loading.value = false;
    }
  };

  const resetPassword = async (params) => {
    Logger.debug(`useUser/${id}/resetPassword`, params);

    try {
      loading.value = true;
      error.value = null;

      const { password, passwordConfirmation, token, email } = params;

      await makeRequest("resetPassword", {
        password,
        email,
        // eslint-disable-next-line camelcase
        password_confirmation: passwordConfirmation,
        token,
      });

      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useUser/${id}/register`, err);
    } finally {
      loading.value = false;
    }
  };

  const register = async (params, newsletter) => {
    Logger.debug(`useUser/${id}/ `, params);

    try {
      loading.value = true;
      error.value = null;

      const { email, password, firstName, lastName, companyName } = params;
      const supplierId = supplierGetters.getId(supplier.value?.data);

      const response = await makeRequest("registerUser", {
        email,
        password,
        // eslint-disable-next-line camelcase
        first_name: firstName,
        // eslint-disable-next-line camelcase
        last_name: lastName,
        company_name: companyName,
        newsletter,
        ...(supplierId && { origin_supplier_id: supplierId }),
      });

      let expiresIn = 8600;
      if (response.data?.token?.expires_in) {
        expiresIn = parseInt(response.data.token.expires_in);
      }

      tokens.value = {
        access_token: response.data?.token?.access_token || null,
        refresh_token: response.data?.token?.refresh_token || null,
        expires_at: Number(Math.floor(Date.now() / 1000)) + expiresIn || null,
      };

      await getUser(tokens.value.access_token);

      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useUser/${id}/register`, err);
    } finally {
      loading.value = false;
    }
  };

  const checkUserExistsByEmail = async (email) => {
    Logger.debug(`useUser/${id}/existsByEmail`, email);

    try {
      loading.value = true;

      const params = {
        email,
      };

      const result = await makeRequest("existsByEmail", params);

      if (result?.error && result?.status === 404) {
        userExistsByEmail.value = false;
      } else {
        userExistsByEmail.value = true;
      }
    } catch (err) {
      Logger.warn(`useUser/${id}/existsByEmail`, err);
      userExistsByEmail.value = null;
    } finally {
      loading.value = false;
    }
  };

  const resetExists = () => {
    userExistsByEmail.value = null;
  };

  const updateCustomer = async (params) => {
    Logger.debug(`useUser/${id}/updateCustomer`, params);

    try {
      loading.value = true;

      await makeRequest("updateCustomer", params);
      error.value = null;
    } catch (err) {
      Logger.error(`useUser/${id}/updateCustomer`, err);

      error.value = err?.response?.data || err || null;
    } finally {
      loading.value = false;
    }
  };

  const updatePassword = async (params) => {
    Logger.debug(`useUser/${id}/updatePassword`, params);

    try {
      loading.value = true;

      await makeRequest("updatePassword", params);
      error.value = null;
    } catch (err) {
      Logger.error(`useUser/${id}/updatePassword`, err);

      error.value = err?.response?.data || err || null;
    } finally {
      loading.value = false;
    }
  };

  return {
    /* Attributes */
    userExistsByEmail: computed(() => userExistsByEmail.value),
    user: computed(() => user.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    isAuthenticated: computed(() => user.value !== null),
    resetPasswordForm: computed(() => resetPasswordForm.value),
    tokens: computed(() => tokens.value),

    /* Methods */
    getUser,
    login,
    register,
    verifyRefreshToken,
    checkUserExistsByEmail,
    resetExists,
    resetPassword,
    requestPasswordReset,
    setUser,
    setUserExistsByEmail,
    setTokens,
    updateCustomer,
    updatePassword,
  };
};
