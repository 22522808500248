<template>
  <div
    v-show="showcaseItems.length > 0"
    class="kft-simple-card-showcase__container"
  >
    <div class="my-0 mx-auto">
      <LazyHydrate when-visible>
        <div
          class="kft-simple-card-showcase__simple-cards-container d-flex flex-wrap flex-md-nowrap justify-content-between"
        >
          <template v-for="(item, i) in showcaseItems">
            <component
              :is="Boolean(item.link) && shouldWrapWithLink ? 'KftLink' : 'div'"
              :key="`kft-simple-card-showcase-card-${i}`"
              :link="item.link"
              class="kft-simple-card-showcase__simple-card-container"
              :class="{ 'kft-link--black': item.link }"
            >
              <KftSimpleCard
                :class="['kft-simple-card-showcase__simple-card', cardClass]"
                :description="item.description || ''"
                :image="item.imageUrl"
                :image-height="$device.isMobile ? '50px' : '250px'"
                :image-width="$device.isMobile ? '50px' : '300px'"
                :style="{ '--index': i }"
                :title="item.title || 'simple-category-showcase'"
                :link="item.link"
                :link-text="item.linkText"
                :circle-content="item.circleContent"
              />
            </component>
          </template>
        </div>
      </LazyHydrate>
    </div>
  </div>
</template>

<script>
import LazyHydrate from "vue-lazy-hydration";
import { KftLink } from "@konfetti-ui/vue";
import KftSimpleCard from "../../molecules/KftSimpleCard/KftSimpleCard.vue";

export default {
  name: "KftSimpleCardShowcase",
  components: {
    LazyHydrate,
    KftSimpleCard,
    KftLink,
  },
  props: {
    showcaseItems: {
      type: Array,
      default: () => [],
    },
    showNumber: {
      type: Boolean,
      default: false,
    },
    shouldWrapWithLink: {
      type: Boolean,
      default: false,
    },
    cardClass: {
      type: String,
      default: "",
    },
  },
  emits: {},
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "~/assets/components/organisms/KftSimpleCardShowcase.scss";
</style>
