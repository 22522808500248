import { Context } from '@konfetti-core/core';

export async function getLocalities (context: Context, params: any): Promise<any> {
  const url = new URL('v1/store/localities' + params, context.config.api.url);
  const { data } = await context.client.get(url.href);

  return data;
}

export async function getLocalitiesWithEvents (context: Context, params: any): Promise<any> {
  const url = new URL('v1/store/localities?hasEvents=true' + params, context.config.api.url);
  const { data } = await context.client.get(url.href);

  return data;
}
