import { integrationPlugin } from "@konfetti-core/core";

const moduleOptions = {
  i18n: {
    strategy: "prefix",
    useNuxtI18nConfig: true,
  },
};

// @ts-ignore
export default integrationPlugin(({ integration }) => {
  integration.configure("konfetti", {
    ...moduleOptions,
    // other options
  });
});
