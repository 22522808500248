export const createImagePreloadLinks = (
  srcSet,
  { media = null, sizes = null },
) => {
  const links: Partial<HTMLLinkElement>[] = [];

  if (srcSet) {
    const preload = {
      rel: "preload",
      as: "image",
      imagesrcset: srcSet,
      ...(media && {
        media,
      }),
    } as Partial<HTMLLinkElement>;

    const prefetch = {
      rel: "prefetch",
      as: "image",
      imagesrcset: srcSet,
      ...(media && {
        media,
      }),
    } as Partial<HTMLLinkElement>;

    const prerender: Partial<HTMLLinkElement> = {
      rel: "prerender",
      as: "image",
      imagesrcset: srcSet,
      ...(media && {
        media,
      }),
    } as Partial<HTMLLinkElement>;

    if (sizes) {
      preload.imagesizes = sizes;
      prefetch.imagesizes = sizes;
      prerender.imagesizes = sizes;
    }

    links.push(preload);
    links.push(prefetch);
    links.push(prerender);
  }

  return links;
};
