/* eslint-disable  */

export default {
  review: {
    seeAll: "Alle Bewertungen lesen",
    heading: {
      title: "Wir freuen uns über Deine Bewertung.",
      description: "Dein Feedback hilft dem Kursleiter sehr. Auf gokonfetti.com wird nur Dein Vorname veröffentlicht.",
    },
    rating: {
      title: "Gibst Du dem Kurs 5 Sterne?",
    },
    review: {
      title: "Schreibe ein paar Sätze über den Kurs.",
      placeholder:
        "z.B.: Für wen würdest Du den Kurs empfehlen? Was hat Dir am besten gefallen?",
    },
    legalCopy:
      'Mit dem Klicken auf "Absenden" erklärst Du Dich mit unserem {termsAndConditions} einverstanden',
    notification: {
      success: {
        title: "Vielen Dank für Deine Bewertung",
        description: "Als kleines Dankeschön für Dein Feedback erhältst du 15 € Rabatt mit dem Code KONBACK10. Mindestbestellwert 50 €.",
      },
      error: {
        title: "Es ist ein Fehler aufgetreten",
        description:
          "Wenn Du diesen Kurs bereits bewertet hast, kannst Du sie nicht erneut bewerten.",
      },
    },
  },
};
