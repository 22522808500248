interface cacheControlOptions {
  values?: any;
  disableCache?: boolean;
  forceCache?: boolean;
}

const cacheControl =
  (options: cacheControlOptions) =>
  ({ res, route }) => {
    const { values, disableCache, forceCache } = options;
    if (!process.server) {
      return;
    }

    let cacheControlValue = null;

    if (values) {
      cacheControlValue = Object.entries(values)
        .map(([key, value]) => `${key}=${value}`)
        .join(",");
    }

    // We cannot store or use cache for urls using query parameters
    // since they are not canonical and thus cause indexing problems
    if (
      (typeof process.env.APP_ENV !== "undefined" &&
        process.env.APP_ENV !== "production" &&
        process.env.APP_ENV !== "prod") ||
      (!forceCache && (disableCache || Object.keys(route.query).length > 0))
    ) {
      cacheControlValue = "no-cache, no-store, must-revalidate";
    }

    res.setHeader("Cache-Control", cacheControlValue);
  };

export default cacheControl;
