/* eslint-disable  */

export default {
  checkout: {
    defaultCouponError: "Gutscheincode ungültig",
    back: "Zurück",
    registration: "Kontakt",
    login: "Login",
    yourAccount: "Dein Konto",
    createAccount: "Kontakt",
    checkout: "Zahlungsdetails",
    done: " Fertig",
    confirmOrder: "Bestellung absenden",
    review: "Überprüfe",
    payment: "Zahlung",
    userRegistered: "Log Dich in Dein konfetti Konto ein",
    userNotRegistered: "Wie erreichen wir Dich?",
    userUnknown: "E-Mail eingeben und starten",
    emptyCartMessage: "Dein Warenkorb ist leer.",
    surveyTitle: "Bitte teile uns mit, wo Du von konfetti gehört hast?",

    newsletter:
      "Ja, ich möchte per E-Mail-Newsletter über Trends und Aktionen informiert werden. Abmeldung jederzeit möglich. (optional)",

    legalCopy:
      "Mit Deiner Bestellung erklärst Du Dich mit unseren {privacyPolicy}, {termsAndConditions} und {cancellationPolicy} einverstanden. Als Kunde von gokonfetti.com erhältst Du Produktempfehlungen per Mail, von denen Du Dich jederzeit über einen Link in der Mail abmelden kannst.",
    termsAndConditions: "AGB",
    privacyPolicy: "Datenschutzbestimmungen",
    cancellationPolicy: "Widerrufsbestimmungen",

    personalDetails: {
      loginButton: "Anmelden",
      logoutButton: "Abmelden",
      fillDetails: "oder fülle folgende Details aus:",
      loginMessage: "Schon registriert?",
      logoutMessage: "Das bist nicht Du?",
      accountPerks:
        "Genieße folgende Vorteile mit Deinem kostenlosen konfetti Konto!",
      facebookLoginButton: "Weiter mit Facebook",
      appleLoginButton: "Weiter mit Apple",
      googleLoginButton: "Weiter mit Google",

      form: {
        firstName: "Vorname",
        lastName: "Nachname",
        companyName: "Firmenname (für Firmenkunden)",
        email: "E-Mail",
        password: "Passwort",
      },
    },

    addedToCart: "Erfolgreich zum Warenkorb hinzugefügt",

    paymentDetails: {
      paymentErrorMessage:
        "Bei Deiner Zahlung ist etwas schief gelaufen. Bitte kontaktiere uns unter <u>+030257706800</u> oder sende eine E-Mail an <u>hallo@gokonfetti.com</u>",
      creditCard: "Kartenzahlung",
      paypal: "PayPal",
      balance: "Mit Guthaben bezahlen",
      sofort: "SOFORT Überweisung",
      sepa: "SEPA Lastschrift",
      form: {
        title: "Rechnungsadresse",
        productList: "Produktliste",
        invoice: "Ich benötige eine Rechnung",
        paymentMethods: "Zahlungsart",
        payWithGiftcard: "Mit Gutschein bezahlen",
        payWithPaypal: "Mit PayPal bezahlen",
        payWithSEPA: "Zahlungspflichtig bestellen",
        payWithSofort: "Mit SOFORT Überweisung bezahlen",
        pay: "Bezahlen",
        payWithBalanceAndCoupon: "Mit Gustschein und Guthaben bezahlen",
        payWithBalance: "Mit Guthaben bezahlen",
        payWithCard: "Zahlungspflichtig bestellen",

        errors: {
          name: "Der Name des Karteninhabers wird benötigt.",
        },

        card: {
          expirationDate: "Gültigkeit:",
          whereIsMyCvc: "Wo finde ich meine Kartenprüfnummer?",
          keep: "Kartendetails für spätere Käufe speichern.",
          number: "Nummer",
          holder: "Karteninhaber",
          cvc: "Kartenprüfnummer",
        },
      },
    },

    shippingDetails: {
      title: "Versand",
      form: {
        firstName: "Vorname",
        apartment: "Apartment",
        lastName: "Nachname",
        street: "Straße",
        streetNumber: "Hausnummer",
        locality: "Stadt",
        state: "Bundesland",
        zipcode: "Postleitzahl",
        country: "Land",
        phoneNumber: "Telefon",
        additional: "Zusätzliche Informationen",
      },
    },

    invoiceDetails: {
      title: "Rechnungsadresse",
      form: {
        firstName: "Vorname",
        apartment: "Apartment",
        lastName: "Nachname",
        companyName: "Firmenname",
        street: "Straße",
        streetNumber: "Hausnummer",
        locality: "Stadt",
        state: "Bundesland",
        zipcode: "Postleitzahl",
        country: "Land",
        phoneNumber: "Telefon",
        vat: "USt-ID",
        sameAddressAsShipping: "Rechnungsadresse gleicht Versandadresse",
      },
    },

    steps: {
      details: "Setup Details",
      review: "Bestellung überprüfen",
      continue: "Nächster Schritt",
    },

    stepConfirmOrder: {
      title: "Deine Bestellung",
      subtotal: "Zwischensumme",
      total: "Gesamtsumme (inkl. MwSt.)",
      iAgreeWith: "Ich stimme zu ",
      termsAndConditions: "AGB",
    },

    orderReview: {
      title: "Bestellung überprüfen",
      personalDetails: "Persönliche Details",
      shippingDetails: "Lieferadresse",
      billingAddress: "Rechnungsadresse",
      sameAsShippingAddress: "Entspricht meiner Lieferadresse",
      paymentMethod: "Zahlungsart",
      promoCode: "Code eingeben",
    },

    orderSummary: {
      subtotal: "Zwischensumme",
      giftboxPrice: "Geschenkverpackung",
      shippingPrice: "Versand",
      total: "Total",
      items: "Artikel",
      grandTotal: "Gesamtsumme (inkl. MwSt.)",
      balance: "Kundenguthaben",
      promoCode: "Code eingeben",
      discount: "Rabatt",
      shipping: "Versand",
      shippingInfo: "Der Versand dauert 3-5 Werktage.",
      voucher: "Gutschein eingeben",
      voucherSuccess: "Gutschein angewendet",
      yourOrder: "Deine Bestellung",
    },

    termsModalTitle: "AGB",
    acceptTermsSpanText:
      "Mit Deiner Bestellung erklärst Du Dich mit unseren Datenschutzbestimmungen, AGB und Widerrufsbestimmungen einverstanden. Als Kunde von gokonfetti.com erhältst Du Produktempfehlungen per Mail, von denen Du dich jederzeit über einen Link in der Mail abmelden kannst.",
    acceptTermsSpanTerms: "AGB",

    success: {
      title: "Fantastisch - die Bestellung war erfolgreich.",
      orderNo: "Bestellnummer:",
      copy: "Wir haben Dir Deine Bestellbestätigung per E-Mail zugesendet.",
      orders: "Zu Deiner Bestellung gehen",
      addToCalendar: "Zum Kalendar hinzufügen",
      homeButton: "Zur Startseite",
      referralInfo: {
        title:
          "Noch mehr konfetti: Empfehle uns weiter und Ihr erhaltet beide 20 €.",
      },
    },

    pending: {
      title:
        "Vielen Dank für Dein Vertrauen, Deine Bestellung wird gerade bearbeitet",
    },

    errors: {
      notLoggedIn: "Bitte loggen Sie sich erneut ein.",
      onePlusEventsWithoutEnoughTicketsError:
        "Our system has removed several items from your cart, for they are currently sold out. Please try to book for another date.",
      paymentFailed:
        "Die Zahlung war nicht erfolgreich. Bitte versuchen Sie es erneut",

      // Most of these come from:
      // https://bitbucket.org/gokonfetti/backend/src/develop/source/app/Containers/CheckoutSection/Payment/README.md
      unauthenticated:
        "Sie sind nicht eingeloggt. Bitte melden Sie sich erneut an",
      gatewayUnauthorized:
        "Die Zahlungstransaktion wurde nicht autorisiert. Bitte versuchen Sie es erneut",
      cardDeclined: "Die Karte wurde abgelehnt. Bitte versuchen Sie es erneut",
      invalidGateway:
        "Es gab ein Problem mit Ihrer Zahlung. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut",
      invalidPaymentMethod:
        "Es gab ein Problem mit Ihrer Zahlung. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut",
      invalidPaymentSignature:
        "Es gab ein Problem mit Ihrer Zahlung. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut",
      invalidValueCaptured:
        "Der Gesamtpreis scheint nicht korrekt zu sein. Bitte überprüfen Sie Ihren Warenkorb und versuchen Sie es erneut",
      invalidValueInformed:
        "Der Gesamtpreis scheint nicht korrekt zu sein. Bitte überprüfen Sie Ihren Warenkorb und versuchen Sie es erneut",
      invalidValueRefundedCaptured:
        "Der erstattete Wert weicht von der Transaktionssumme ab, bitte aktualisieren Sie und versuchen Sie es erneut",
      actionRequired:
        "Es ist ein zusätzlicher Sicherheitsschritt erforderlich. Bitte befolgen Sie die Anweisungen, die angezeigt werden",
      confirmationRequired:
        "Es ist ein zusätzlicher Sicherheitsschritt erforderlich. Bitte bestätigen Sie die Transaktion",
    },

    allItemsRemoved:
      "Alle Artikel wurden aus Deinem Warenkorb entfernt. Du wirst weitergeleitet...",

    stripePayment: {
      stripe3DAuth: {
        alerts: {
          succeeded: {
            title: "Super!",
            message: "Bestätigung war erfolgreich!",
          },
          requires_action: {
            title: "Error",
            message:
              "Die Angaben stimmen nicht überein, bitte versuche es noch einmal.",
          },
          requires_payment_method: {
            title: "Error",
            message: "Zahlungsmethodenfehler, bitte versuche es noch einmal.",
          },
        },
      },
    },
  },
};
