import { EventDate, EventDescription } from "../types";
import { eventGetters } from "../getters";
import { Ref } from "@nuxtjs/composition-api";

const parseGiftcardToCartItem = (
  event: EventDescription,
  selectedTicketQuantity: number = null,
  selectedTime: Ref<EventDate> = null,
): any => {
  return {
    type: "GIFT_CARD",
    id: eventGetters.getId(event) || null,
    eventDescriptionId: eventGetters.getId(event),
    title: eventGetters.getTitle(event),
    children: selectedTime?.value?.children || [],
    price: selectedTime?.value?.product?.data?.price || event?.default_price,
    thumbnail: eventGetters.getFirstThumb(event),
    quantity: selectedTicketQuantity || null,
    permalink: eventGetters.getPermalink(event),
    availableTicketsQuantity:
      selectedTime?.value?.availableTicketsQuantity || null,
    startTime: selectedTime?.value?.start || null,
    endTime: selectedTime?.value?.end || null,
  };
};

export default parseGiftcardToCartItem;
