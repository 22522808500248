<template>
  <div
    class="kft-video-container"
    :class="{ 'kft-video-container--vertical': orientation === 'vertical' }"
  >
    <div class="position-relative h-100">
      <video
        ref="videoPlayer"
        class="kft-gallery__video-player"
        :src="source"
        :poster="poster"
        autoplay="true"
        loop="true"
        muted="true"
        playsinline
        controlslist="nofullscreen nodownload noremoteplayback noplaybackrate"
        @click.prevent="handleVideoClick"
      />
      <div
        v-if="displayControls"
        class="position-absolute kft-video-play-button"
        :class="{
          'kft-video-play-button--playing': isPlaying,
          'kft-video-play-button--paused': !isPlaying,
        }"
        @click="togglePlayPause"
      />
    </div>
    <KftModal
      ref="lightboxModal"
      size="xl"
      card-container
      name="lightboxModal"
      @hidden="onHideLightboxModal"
    >
      <template #modal-header>
        <div class="modal-header chip-select-modal">
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
            @click="lightboxModal.hide()"
          ></button>
        </div>
      </template>
      <video
        ref="videoPlayerMod"
        class="kft-gallery__video-player lightbox-video"
        :src="source"
        :poster="poster"
        autoplay="true"
        loop="true"
        muted="true"
        controls
        playsinline
        controlslist="nofullscreen nodownload noremoteplayback noplaybackrate"
      />
    </KftModal>
  </div>
</template>
<script>
import { ref } from "@nuxtjs/composition-api";

export default {
  name: "GalleryVideoPlayer",
  components: {
    KftModal: () =>
      import("@konfetti-ui/vue/src/components/organisms/KftModal/KftModal.vue"),
  },
  props: {
    source: {
      type: String,
      default: "",
    },
    poster: {
      type: String,
      default: "",
    },
    orientation: {
      type: String,
      default: "horizontal",
    },
    allowModal: {
      type: Boolean,
      default: true,
    },
    displayControls: {
      type: Boolean,
      default: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    allowFullscreen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isPlaying = ref(true);
    const showControls = ref(false);
    const videoPlayer = ref(null);
    const videoPlayerMod = ref(null);
    const lightboxModal = ref(null);

    const togglePlayPause = () => {
      if (videoPlayer.value.paused) {
        videoPlayer.value.play();
        isPlaying.value = true;
      } else {
        videoPlayer.value.pause();
        isPlaying.value = false;
      }
    };

    const videoEnded = () => {
      isPlaying.value = false;
    };

    const showLightboxModal = () => {
      lightboxModal.value && lightboxModal.value.show();
    };

    const onHideLightboxModal = () => {
      videoPlayerMod.value.pause();
    };

    const enterVideoFullscreen = () => {
      const video = videoPlayer.value;
      if (video.webkitSupportsFullscreen) {
        video.webkitEnterFullscreen();
        return;
      }
      video.requestFullscreen();
    };

    const handleVideoClick = () => {
      if (props.isMobile && props.allowFullscreen) {
        enterVideoFullscreen();
        return;
      }

      props.allowModal ? showLightboxModal() : togglePlayPause();
    };

    return {
      isPlaying,
      showControls,
      togglePlayPause,
      videoEnded,
      videoPlayer,
      videoPlayerMod,
      lightboxModal,
      showLightboxModal,
      onHideLightboxModal,
      handleVideoClick,
    };
  },
};
</script>
<style scoped>
.kft-gallery__video-player {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.35rem;
}

.kft-video-container {
  height: 100%;

  .modal-header {
    padding: 1rem;
  }
  .modal-dialog {
    margin-top: var(--navbar-height);
  }
  .modal-body {
    overflow: hidden;
    max-height: calc(100svh - var(--navbar-height) - var(--spacer-8) * 2);
    margin-bottom: var(--spacer-8);
  }
}
.kft-video-container--vertical {
  grid-row: 1 / 3;
}
.kft-video-play-button {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  box-sizing: border-box;
  height: 1em;
  width: 0;
  transition: border 0.3s linear;
}
.kft-video-play-button--paused {
  border-width: 0.5em 0 0.5em 1em;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
}
.kft-video-play-button--playing {
  border: 0 solid transparent;
  border-left: 1em double #fff;
}
.lightbox-video {
  max-height: calc(100svh - var(--navbar-height) - var(--spacer-8) * 4);
  object-fit: contain;
  border-radius: 0;
}
</style>
