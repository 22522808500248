const getId = (city): string => {
  return city?.id || null;
};

const getName = (city): string => {
  return city?.name || null;
};

const getSlug = (city): string => {
  return city?.slug || null;
};

const getTitle = (city): string => {
  return city?.title || null;
};

const getSeoTitle = (city): string => {
  return city?.seo_title || null;
};

const getDescription = (city): string => {
  return city?.description || null;
};

const getSeoMetaDescription = (city): string => {
  return city?.seo_meta_description || null;
};

const getSubtitle = (city): string => {
  return city?.subtitle || null;
};

const getGiftboxTitle = (city): string => {
  return city?.title_gift_box || null;
};

const getGiftboxSubtitle = (city): string => {
  return city?.subtitle_gift_box || null;
};

const getImage = (city): string => {
  return city?.image?.data?.url || null;
};

const getImageDesktop = (city): string => {
  return city?.image?.data?.desktop?.url || getImage(city);
};

const getImageMobile = (city): string => {
  return city?.image?.data?.mobile?.url || getImageDesktop(city);
};

const getShowcaseTitle = (city): string => {
  return city?.showcase_title || "";
};

const getShowcase = (city): string => {
  return city?.showcase || null;
};

const getLocalityCategories = (city): string[] => {
  return city?.localityCategories?.data || [];
};

const getHasPage = (city): string[] => {
  return city?.has_page || false;
};

export const cityGetters = {
  getId,
  getSlug,
  getTitle,
  getName,
  getDescription,
  getSeoMetaDescription,
  getSeoTitle,
  getSubtitle,
  getGiftboxTitle,
  getGiftboxSubtitle,
  getImage,
  getImageDesktop,
  getImageMobile,
  getShowcaseTitle,
  getShowcase,
  getLocalityCategories,
  getHasPage,
};
