<template>
  <div class="kft-article-quote p-13 text-center">
    <KftHeading
      :title="`&quot;${quoteString}&quot;`"
      :level="4"
      class="kft-heading--center"
      :use-h-tag="false"
    />
    <KftHeading
      v-if="quoteAuthorString"
      :title="`— ${quoteAuthorString}`"
      :level="5"
      class="kft-heading--center mt-5"
      :use-h-tag="false"
    />
    <KftBrushStrokeButton class="mt-5 d-inline-block" :link="buttonLink">{{
      buttonLabel
    }}</KftBrushStrokeButton>
  </div>
</template>

<script>
import { KftHeading } from "@konfetti-ui/vue";
import KftBrushStrokeButton from "../KftBrushStrokeButton/KftBrushStrokeButton";

export default {
  name: "KftArticleQuote",
  components: { KftBrushStrokeButton, KftHeading },
  props: {
    quoteString: {
      type: String,
      default: "",
    },
    quoteAuthorString: {
      type: String,
      default: null,
    },
    buttonLabel: {
      type: String,
      default: "",
    },
    buttonLink: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.kft-article-quote {
  background-color: #f4f4f4;
}

::v-deep .kft-heading {
  .kft-heading__title {
    margin: 0;
  }
}
</style>
