<template>
  <div class="kft-category-showcase__container">
    <!-- Begin::Title -->
    <KftHeading
      :level="2"
      :title="title"
      class="kft-heading--left mb-5 mb-md-5"
    />
    <!-- End::Title -->
    <!-- Begin::Loading List-->
    <div
      v-if="(loading || !_isMounted) && categories.length === 0"
      class="kft-category-showcase__container"
    >
      <div
        class="kft-category-showcase__cards-container row row-cols-xl-5 row-cols-lg-5 row-cols-md-4 row-cols-sm-3 row-cols-2 g-4"
      >
        <content-loader
          v-for="index in $device.isDesktopOrTablet ? 10 : 4"
          :key="`search-events__loading__category-${index}`"
          :height="175"
          :speed="2"
          :width="165"
          class="kft-category-card loading"
          primary-color="#ebebeb"
          secondary-color="#ffffff"
        >
          <rect height="120" rx="5" ry="5" width="160" x="0" y="0" />
          <rect height="15" rx="5" ry="5" width="160" x="0" y="130" />
        </content-loader>
      </div>
    </div>
    <!-- End::Loading List-->

    <!-- Begin::Category List -->
    <div v-else>
      <LazyHydrate when-visible>
        <div
          class="kft-category-showcase__cards-container row row-cols-xl-5 row-cols-lg-5 row-cols-md-4 row-cols-sm-3 row-cols-2 g-4"
        >
          <KftCategoryCard
            v-for="(category, i) in categories.slice(0, 10)"
            :key="`kft-category-showcase__categories-${i}-${category.slug}`"
            :image="categoryGetters.getThumbnail(category)"
            :link="localePath(`/c/${category.slug}`)"
            :style="{ '--index': i }"
            :title="category.name"
            data-cy="category-card"
          />
        </div>
      </LazyHydrate>
      <!-- End::Category List -->
    </div>
  </div>
</template>

<script>
import LazyHydrate from "vue-lazy-hydration";
import { KftHeading } from "@konfetti-ui/vue";
import { categoryGetters } from "@konfetti/composables";
import { ContentLoader } from "vue-content-loader";
import KftCategoryCard from "../../molecules/KftCategoryCard/KftCategoryCard.vue";

export default {
  name: "KftCategoryShowcase",
  components: {
    LazyHydrate,
    KftCategoryCard,
    KftHeading,
    ContentLoader,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    categories: {
      type: Array,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: {},
  setup() {
    return {
      categoryGetters,
    };
  },
};
</script>

<style lang="scss">
@import "~/assets/components/organisms/KftCategoryShowcase.scss";
</style>
