












export default {
  name: "KftSwitch",
  props: {
    label: "",
    value: null,
  },
  emits: ["input"],
  setup(props, context) {
    const fnHandleInput = (event): void => {
      context.emit("input", event.target.checked);
    };

    return {
      fnHandleInput,
    };
  },
};
