<template>
  <div class="kft-bullet-points">
    <ul class="bar">
      <li v-for="(item, i) in itemsHtmlArr" :key="i" v-html="item"></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "KftBulletPoints",
  props: {
    itemsHtmlArr: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.bar {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #7f8297;

  @include font(
    --kft-bullet-points-font,
    var(--overline-font-weight),
    var(--overline-font-size),
    var(--overline-line-height),
    var(--font-family--heading)
  );

  li:only-child::after {
    display: none;
  }

  li:not(:first-child) {
    padding: var(--spacer-1) 0 0;
  }

  & > li {
    position: relative;
  }
  & > li:before {
    content: "\25CF";
    margin-right: 10px;
    font-size: 20px;
    color: $secondary;
  }
  & > li:after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-left: 2px solid $secondary;
    margin-left: 5px;
    height: 100%;
  }
  & > li:first-of-type:after {
    top: 50%;
  }
  & > li:last-of-type:after {
    top: -50%;
  }
}
</style>
