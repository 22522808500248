












































import { KftSidebar } from "@konfetti-ui/vue";
import { eventGetters } from "@konfetti/composables";
import { computed, watch } from "@nuxtjs/composition-api";
import { ref } from "@vue/composition-api";
import DateRequestForm from "../DateRequestForm/DateRequestForm.vue";
import DateRequestComplete from "../DateRequestComplete/DateRequestComplete.vue";
import { useUiState, useEvent } from "~/composables";
import { formatPriceAmount } from "~/helpers";

export default {
  name: "DateRequestSidebar",

  components: {
    KftSidebar,
    DateRequestForm,
    DateRequestComplete,
  },

  props: {
    isSupplier: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props, context) {
    const { event } = useEvent("event-page");
    /** =====
     * Variables
     * === **/
    const { isDateRequestSidebarOpen, toggleDateRequestSidebar } = useUiState();

    const computedEventData = computed(() => event.value?.data);

    const completedRequest = ref(false);

    const innerToggleDateRequestSidebar = () => {
      completedRequest.value = false;
      toggleDateRequestSidebar();
    };

    // watch route and close sidebar
    // if it changes
    watch(
      () => context.root.$route,
      (value, oldValue) => {
        const isDifferentPath = value.fullPath !== oldValue.fullPath;
        if (isDifferentPath && isDateRequestSidebarOpen.value) {
          toggleDateRequestSidebar();
        }
      },
    );

    return {
      // Attributes
      computedEventData,
      completedRequest,

      // Methods
      eventGetters,
      isDateRequestSidebarOpen,
      toggleDateRequestSidebar: innerToggleDateRequestSidebar,
      formatPriceAmount,
    };
  },
};
