<template>
  <div class="kft-link">
    <template v-if="isExternal(link) || forceAnchor || openNewTab">
      <!-- @slot Custom markup for outside, after the anchor -->
      <a
        :href="link"
        :target="openNewTab ? '_blank' : '_self'"
        :itemprop="itemprop"
        :itemscope="itemscope"
        :itemtype="itemtype"
        :itemid="itemid"
        v-bind="$attrs"
      >
        <slot />
      </a>
      <slot name="after-anchor"></slot>
    </template>
    <template v-else>
      <nuxt-link
        v-if="link !== ''"
        :to="link"
        v-on:click.native="$emit('click:link')"
      >
        <slot />
      </nuxt-link>
      <a v-else href="" @click.prevent.stop="$emit('click:link')">
        <slot />
      </a>
    </template>
  </div>
</template>
<script>
import { focus } from "../../../utilities/directives";

export default {
  name: "KftLink",
  directives: { focus },
  props: {
    /**
     * Page route
     */
    link: {
      type: [String, Object],
      default: "",
    },

    /**
     * Link Target
     */
    openNewTab: {
      type: Boolean,
      default: false,
    },

    /**
     * Force anchor tag
     */
    forceAnchor: {
      type: Boolean,
      default: false,
    },

    /**
     * SEO attrs
     */
    itemprop: {
      type: String,
      default: undefined,
    },
    itemscope: {
      type: Boolean,
      default: undefined,
    },

    itemtype: {
      type: String,
      default: undefined,
    },
    itemid: {
      type: String,
      default: undefined,
    },
  },

  setup(props, context) {
    const isExternal = (link) => {
      if (typeof link !== "string") {
        return true;
      }

      if (
        link.indexOf("http://") !== -1 ||
        link.indexOf("https://") !== -1 ||
        link.indexOf("mailto:") !== -1 ||
        link.indexOf("tel:") !== -1
      ) {
        return true;
      }

      return false;
    };

    return {
      isExternal,
    };
  },
};
</script>
<style lang="scss">
@import "~@konfetti-ui/shared/styles/components/atoms/KftLink.scss";
</style>
