import { UseKftContext } from "@konfetti-core/core";

const loadingWrapper = async (
  fn: () => void | Promise<void>,
): Promise<void> => {
  if (typeof window === "undefined") {
    return fn();
  }

  const instanceName = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(0, 5);

  const { app } = UseKftContext();
  await app.wait.start(instanceName);
  await fn();
  await app.wait.end(instanceName);
};

export { loadingWrapper };
