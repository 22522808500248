


















import { computed } from "@vue/composition-api";
export default {
  props: {
    logo: {
      type: String,
      default: "/logos/full-logo-color-black.svg",
    },
  },

  setup(props) {
    return {
      logoImage: computed(
        () => props.logo || "/logos/full-logo-color-black.svg",
      ),
    };
  },
};
