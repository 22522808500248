import { Context } from "@konfetti-core/core";

export async function getKey(context: Context, key: string): Promise<any> {
  try {
    await context.cache.init();

    return await context.cache.get(key);
  } catch (e) {
    console.error(e);

    return null;
  }
}

export async function setKey(context: Context, params: any): Promise<any> {
  const { key, value } = params;

  try {
    await context.cache.init();

    return await context.cache.set(key, value);
  } catch (e) {
    console.error(e);

    return null;
  }
}
