/* eslint-disable  */
import Legacy from "./legacy-en";
import Home from "./home";
import Product from "./product";
import General from "./general";
import Cart from "./cart";
import Search from "./search";
import Checkout from "./checkout";
import Footer from "./footer";
import TeamEvents from "./teamevents";
import Login from "./login";
import Giftcard from "./giftcard";
import Giftcards from "./giftcards";
import City from "./city";
import Faq from "./faq";
import FaqGiftcard from "./faq.giftcard";
import FaqGiftcards from "./faq.giftcards";
import FaqSuppliers from "./faq.suppliers";
import FaqTeamEvents from "./faq.teamevents";
import Category from "./category";
import CityCategory from "./city-category";
import PasswordReset from "./password-reset";
import Embedded from "./embedded";
import Validation from "./validation";
import Survey from "./survey";
import Magazine from "./magazine";
import Multiselect from "./multiselect";
import Review from "./review";
import Account from "./account";
import EventListPage from "./event-list-page";
import SupplierProfile from "./supplier-profile";
import Partners from "./partner";
import PartnerPricing from "./partner-pricing";
import PartnerBookingSolution from "./partner-booking-solution";
import Seo from "./seo";
import Sitemap from "./sitemap";

export default {
  ...Account,
  ...Review,
  ...Multiselect,
  ...Survey,
  ...Legacy,
  ...Validation,
  ...Embedded,
  ...Product,
  ...Home,
  ...General,
  ...Cart,
  ...Category,
  ...CityCategory,
  ...City,
  ...Search,
  ...Checkout,
  ...Footer,
  ...Login,
  ...Giftcard,
  ...TeamEvents,
  ...Login,
  ...Giftcards,
  ...Faq,
  ...FaqGiftcard,
  ...FaqGiftcards,
  ...FaqSuppliers,
  ...FaqTeamEvents,
  ...PasswordReset,
  ...Magazine,
  ...EventListPage,
  ...SupplierProfile,
  ...Partners,
  ...PartnerPricing,
  ...PartnerBookingSolution,
  ...Seo,
  ...Sitemap,
};
