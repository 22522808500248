


























































































































































































































































import { computed, onBeforeMount, ref, watch } from "@vue/composition-api";
import {
  KftButton,
  KftFilterOption,
  SearchResultsCount,
  SearchResultsSortOrderMenu,
} from "@konfetti-ui/vue";
import DatePicker from "vue2-datepicker";
import _ from "lodash";
import { dateToFormat, formatMoney } from "~/helpers";
import KftChip from "~/components/atoms/KftChip/KftChip.vue";
import KftRangeSlider from "~/components/atoms/KftRangeSlider/KftRangeSlider.vue";
import "vue2-datepicker/locale/de";
import "vue2-datepicker/locale/en";
import { fnGetISO2Locale } from "~/helpers/localeHelpers";
import {
  EMPTY_FILTERS_INSTANCE,
  useAlgoliaCore,
  useDebounced,
  useSearchFilters,
} from "~/composables";

import { fnGetCitiesIndexName } from "~/helpers/algoliaHelpers";
import KftHorizontalScrollWrapper from "~/components/organisms/KftHorizontalScrollWrapper/KftHorizontalScrollWrapper.vue";
import KftSearchFiltersMainModal from "~/components/organisms/KftSearchFiltersMainModal/KftSearchFiltersMainModal.vue";

export default {
  name: "SearchFilters",
  components: {
    KftHorizontalScrollWrapper,
    KftSearchFiltersMainModal,
    KftChip,
    DatePicker,
    KftFilterOption,
    KftRangeSlider,
    KftButton,
    SearchResultsCount,
    SearchResultsSortOrderMenu,
  },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    hitsLength: {
      type: Number,
      default: null,
    },
    categories: {
      type: [Array, Object],
      default: () => [],
    },
    languages: {
      type: [Array, Object],
      default: () => [],
    },
    hiddenFilters: {
      type: Array,
      default: () => [],
    },
    emptyFilters: {
      type: Object,
      default: () => ({}),
    },
  },

  setup: (props, { emit, root: { $i18n } }) => {
    /** @name filterSidebar
     ** @desc The Drawer component, used to access its functions */
    const filterSidebar = ref(null);
    const isChipFilterOverflowing = ref(true);
    const {
      filters,
      fnUpdateURLQueryFilters,
      availableLocalities,
      availableLanguages,
      orderByOptions,
    } = useSearchFilters("general-instance");

    /* DOM Refs */
    const localityChipRef = ref(null);
    const priceRangeSliderRef = ref(null);
    const dateChipRef = ref(null);
    const categoriesChipRef = ref(null);

    /* Search variables */
    const localitySearch = ref("");
    const categorySearch = ref("");

    /* Vars */
    const orderBy = ref(orderByOptions.value[0]);
    const showOrderByDropdown = ref(false);
    const temporaryFilters = ref(_.cloneDeep(EMPTY_FILTERS_INSTANCE));

    /** @desc Imports from algolia core, used in searches */
    const {
      token: algoliaCoreUserToken,
      fnInit: fnInitAlgoliaCoreClient,
      fnPerformSearch: fnPerformAlgoliaCoreSearch,
    } = useAlgoliaCore("kft-search-filters");

    const { debounced } = useDebounced();

    const categoryNames = computed(() =>
      Object.keys(props.categories)
        .filter((x) => props.categories[x] > 0)
        .sort((a, b) => props.categories[b] - props.categories[a]),
    );

    const suggestionChips = computed(() =>
      [
        ...categoryNames.value
          .map((v) => ({
            type: "category",
            name: v,
            amount: props.categories[v],
          }))
          .filter((x) => x.amount > 0),
      ].sort((a, b) => b.amount - a.amount),
    );

    /** METHODS
     * -------------------------- */

    /** @name fnSetPrice
     *  */
    const fnSetPrice = (value) => {
      filters.value.price.min = value[0] * 100;
      filters.value.price.max = value[1] * 100;
    };

    const toggleOrderByDropdown = () => {
      showOrderByDropdown.value = !showOrderByDropdown.value;
    };

    /**
     * @name fnSelectOrderBy
     * @desc Select order by and emit event
     *
     * @param option The selected order by option
     *  */
    const fnSelectOrderBy = (option) => {
      orderBy.value = option;

      emit("order-by-change", orderBy.value);
    };

    /**
     * @name fnFormChanged
     * @description Emits the changed event and pushes filters to the parent
     * */
    const fnFormChanged = (newFilters) => {
      temporaryFilters.value = _.cloneDeep(newFilters);
      emit("change", newFilters);
    };

    /**
     *  @name fnSetFilters
     *  @description Sets the filters variable, that will be used in the template
     *  On every change, the new value will be emitted to the parent
     *
     *  @param {Object} value The new value of the filters
     *  */
    const fnSetFilters = (value) => {
      const newFilters = _.cloneDeep(filters.value);
      newFilters.radius = value?.radius;
      newFilters.online = value?.online;
      newFilters.hasAvailableDates = value?.hasAvailableDates;
      newFilters.dates = value?.dates;
      newFilters.tags = value?.tags;
      newFilters.categories = value?.categories;
      newFilters.categoriesBySlug = value?.categoriesBySlug;
      newFilters.languages = value?.languages;
      newFilters.price = value?.price;
      newFilters.locality = value?.locality;
      priceRangeSliderRef.value?.fnSetValues([
        parseInt(newFilters.price.min || "0") / 100,
        parseInt(newFilters.price.max || "120000") / 100,
      ]);

      filters.value = newFilters;
      temporaryFilters.value = _.cloneDeep(newFilters);

      fnUpdateURLQueryFilters();
    };

    /**
     * @name fnClearDates
     * @description Clears the dates filter
     * */
    const fnClearDates = () => {
      filters.value.dates = [..._.cloneDeep(EMPTY_FILTERS_INSTANCE.dates)];
      temporaryFilters.value.dates = [...filters.value.dates];
    };
    const handleDateChange = () => {
      filters.value.dates = temporaryFilters.value.dates;
      dateChipRef.value.fnHideModal();
    };

    /** @name fnClearAllFilters
     ** @desc Sets all filters to default/initial state **/
    const fnClearAllFilters = () => {
      /* Get only the value from the object, not the reference */
      fnSetFilters(
        _.cloneDeep({ ...EMPTY_FILTERS_INSTANCE, ...props.emptyFilters }),
      );
    };

    /**
     * @name fnComparePriceOption
     * @description Compare price options
     *
     * @param original
     * @param compared
     * */
    const fnComparePriceOption = (original, compared) => {
      return original.min === compared.min && original.max === compared.max;
    };

    /**
     * @name disabledBeforeToday
     * @desc For the calendar component, if the date should be disabled on the filters or not
     *
     * @param {Date} date The date to check
     * */
    const disabledBeforeToday = (date) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    };

    /**
     * @name fnToggleFacet
     * @description Pushes a category|language|other to the filter
     *
     * @param key The name of the filter to be manipulated
     * @param value The value to be included within the selected filters
     * */
    const fnToggleFacet = (key, value) => {
      const index = filters.value?.[key].indexOf(value);
      if (index === -1) {
        filters.value?.[key].push(value);

        return;
      }

      filters.value?.[key].splice(index, 1);
    };

    /**
     * @name fnToggleFacet
     * @description Pushes a category|language|other to the temporary filter
     *
     * @param key The name of the filter to be manipulated
     * @param value The value to be included within the selected filters
     * */
    const toggleTemporaryFacet = (key, value) => {
      const index = temporaryFilters.value?.[key].indexOf(value);
      if (index === -1) {
        temporaryFilters.value?.[key].push(value);

        return;
      }

      temporaryFilters.value?.[key].splice(index, 1);
    };

    /**
     * @name fnSetFilterValue
     * @description Sets a filter value or object
     *
     * @param {string} key The key to be changed
     * @param {any} value The value to be set
     * */
    const fnSetFilterValue = (key: string, value: any) => {
      filters.value[key] = value;
    };

    /** @name fnSearchLocality
     ** @desc Searches for the inputted locality text in algolia and parses the results **/
    const fnSearchLocality = () => {
      debounced(() => {
        const params = {
          userToken: algoliaCoreUserToken.value,
          hitsPerPage: 6,
          aroundLatLngViaIP: true,
          aroundRadius: "all",
        };

        return fnPerformAlgoliaCoreSearch({
          customParams: params,
          query: localitySearch.value,
        });
      }).then(async (fn) => {
        const results = await fn();
        availableLocalities.value = results.hits;
      });
    };

    /**
     * @name fnSelectLocality
     * @description Mark a locality as selected
     *
     * @param option The value to be selected
     * */
    const fnSelectLocality = (option) => {
      if (option === null || filters.value?.locality?.slug === option?.slug) {
        filters.value.locality = _.cloneDeep(EMPTY_FILTERS_INSTANCE.locality);
      } else {
        filters.value.locality = option;
      }

      localityChipRef.value.fnHideModal();
    };

    const handleLocalityInputConfirm = () => {
      const query = localitySearch.value;
      const locality = availableLocalities.value.find(
        (locality) => locality?.name?.toLowerCase() === query?.toLowerCase(),
      );
      fnSelectLocality(locality);
    };

    const fnSetAvailableLocalities = (value) => {
      availableLocalities.value = value;
    };

    const formattedPrice = computed(() => {
      const range = [
        formatMoney(filters.value.price.min / 100, $i18n.locale, "EUR"),
        formatMoney(filters.value.price.max / 100, $i18n.locale, "EUR"),
      ];

      return range
        .filter((item) => {
          return item !== undefined && item !== null && item !== "";
        })
        .join("  -  ");
    });

    const formattedDateRange = computed(() => {
      const dates = [null, null];
      if (filters.value.dates?.[0]) {
        const aux = new Date(filters.value.dates[0]).toString();

        dates[0] = dateToFormat(aux, "DD.MM", $i18n.locale);
      }

      if (filters.value.dates?.[1]) {
        const aux = new Date(filters.value.dates[1]).toString();

        dates[1] = dateToFormat(aux, "DD.MM", $i18n.locale);
      }

      return dates.filter(Boolean).join("  -  ");
    });

    const fnHandlePressedFilterChip = (value, arr) => {
      const id = arr.indexOf(value);

      if (id >= 0) {
        arr.splice(id, 1);
      } else {
        arr.push(value);
      }
    };

    const handleConfirmCategoryModal = () => {
      fnSetFilters(temporaryFilters.value);
      categoriesChipRef.value?.fnHideModal();
    };

    /** HOOKS
     * ------------------------- */
    watch(
      () => filters.value,
      (newFilters, oldFilters) => {
        if (!oldFilters || _.isEqual(filters.value, oldFilters.value)) {
          return;
        }
        debounced(() => {
          return fnFormChanged(newFilters);
        }).then(async (fn) => {
          await fn();
        });
      },
      { deep: true },
    );

    onBeforeMount(async () => {
      await fnInitAlgoliaCoreClient(fnGetCitiesIndexName());
    });

    return {
      isChipFilterOverflowing,
      filterSidebar,
      localityChipRef,
      priceRangeSliderRef,
      dateChipRef,
      filters,
      temporaryFilters,
      orderBy,
      localitySearch,
      categorySearch,
      availableLocalities,
      orderByOptions,
      showOrderByDropdown,
      availableLanguages,
      formattedDateRange,
      formattedPrice,
      categoryNames,
      suggestionChips,
      categoriesChipRef,

      fnClearDates,
      handleDateChange,
      toggleOrderByDropdown,
      fnGetISO2Locale,
      fnComparePriceOption,
      disabledBeforeToday,
      fnToggleFacet,
      fnSetFilterValue,
      fnSetFilters,
      fnSetAvailableLocalities,
      fnClearAllFilters,
      fnSelectOrderBy,
      fnSearchLocality,
      fnSelectLocality,
      handleLocalityInputConfirm,
      fnSetPrice,
      fnFormChanged,
      fnHandlePressedFilterChip,
      toggleTemporaryFacet,
      handleConfirmCategoryModal,
      cloneDeep: _.cloneDeep,
    };
  },
};
