import { Logger, sharedRef } from "@konfetti-core/core";
import { computed } from "@vue/composition-api";
import { useApiHandler } from "../useApiHandler";

export const useArticleCategory = (id: string): any => {
  const articleCategory = sharedRef(
    null,
    `useArticleCategory-articleCategory-${id}`,
  );
  const loading = sharedRef(false, `useArticleCategory-loading-${id}`);
  const error = sharedRef(null, `useArticleCategory-error-${id}`);

  /** Wrapper for Strapi API requests */
  const { makeStrapiRequest } = useApiHandler();

  // Method to call an API endpoint and update `categories`, `loading` and `error` properties
  const loadArticleCategoryBySlug = async (params: string) => {
    Logger.debug(
      `useArticleCategoryCategories/${id}/loadArticleCategoryCategories`,
      params,
    );

    try {
      loading.value = true;
      articleCategory.value = await makeStrapiRequest(
        "getArticleCategoryBySlug",
        params,
      );

      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useArticleCategory/${id}/loadArticleCategory`, err);
    } finally {
      loading.value = false;
    }
  };

  return {
    // Variables
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    articleCategory: computed(() => articleCategory.value),

    // Methods
    loadArticleCategoryBySlug,
  };
};
