<template>
  <div class="kft-quantity-selector">
    <KftButton
      :disabled="disabled || qty <= 1"
      class="kft-button--pure kft-quantity-selector__button"
      data-testid="+"
      @click="handleDecreaseQuantity(qty - 1, max, $listeners)"
    >
      &minus;
    </KftButton>
    <input
      disabled
      :value="qty"
      class="kft-quantity-selector__input"
      data-testid="kft-quantity-selector input"
      type="text"
      @input="handleChangeQuantity($event, max, $listeners)"
    />

    <KftButton
      :disabled="disabled || qty >= max"
      class="kft-button--pure kft-quantity-selector__button"
      data-testid="-"
      @click="handleIncreaseQuantity(qty + 1, max, $listeners)"
    >
      +
    </KftButton>
  </div>
</template>
<script>
import KftButton from "../../atoms/KftButton/KftButton.vue";

export default {
  name: "KftQuantitySelector",
  components: {
    KftButton,
  },
  props: {
    /** Quantity */
    qty: {
      type: [Number, String],
      default: 1,
    },

    /* Max input allowed */
    max: {
      type: Number,
      default: 99,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleChangeQuantity(qty, max, listeners) {
      let quantity = 1;

      if (!isNaN(qty) && qty > 1) {
        quantity = qty;
      }

      if (max <= quantity) {
        quantity = max;
      }

      return listeners.input && listeners.input(quantity);
    },

    handleIncreaseQuantity(qty, max, listeners) {
      this.$emit("cart-action", "plus");

      this.handleChangeQuantity(qty, max, listeners);
    },

    handleDecreaseQuantity(qty, max, listeners) {
      this.$emit("cart-action", "minus");

      this.handleChangeQuantity(qty, max, listeners);
    },
  },
};
</script>
<style lang="scss">
@import "~@konfetti-ui/shared/styles/components/atoms/KftQuantitySelector.scss";
</style>
