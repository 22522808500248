/* eslint-disable camelcase */

import { Logger, sharedRef } from "@konfetti-core/core";
import { computed } from "@vue/composition-api";
import { JwtService } from "@konfetti/api";
import { useApiHandler } from "../useApiHandler";
import { fnCreateOrderObject } from "../helpers";
import { paginationGetters } from "../getters";

export const useOrder = (id: string, hasAccessToWindowObject: boolean): any => {
  const order = sharedRef(null, `useOrder-order-${id}`);
  const orders = sharedRef(null, `useOrder-orders-${id}`);

  const coupon = sharedRef(null, `useOrder-coupon-${id}`);
  const couponLoading = sharedRef(false, `useOrder-couponLoading-${id}`);
  const couponError = sharedRef(null, `useOrder-couponError-${id}`);
  const couponPaymentLoading = sharedRef(false, `useOrder-couponLoading-${id}`);
  const couponPaymentError = sharedRef(
    null,
    `useOrder-balancePaymentError-${id}`,
  );
  const balancePaymentLoading = sharedRef(
    false,
    `useOrder-couponLoading-${id}`,
  );
  const balancePaymentError = sharedRef(
    null,
    `useOrder-balancePaymentError-${id}`,
  );

  const loading = sharedRef(false, `useOrder-loading-${id}`);
  const error = sharedRef(null, `useOrder-error-${id}`);

  /** Wrapper for API requests */
  const { setJwtToken, makeRequest } = useApiHandler(hasAccessToWindowObject);

  const sendOrderViaCoupon = async (
    coupon: string,
    items: Array<any>,
    shippingAddress: any = null,
    invoiceAddress: any = null,
    external = false,
  ) => {
    Logger.debug(`useOrder/${id}/sendOrderViaCoupon`, coupon);

    couponPaymentLoading.value = true;

    try {
      const params = fnCreateOrderObject(
        "KONFETTI",
        null,
        items,
        shippingAddress,
        invoiceAddress,
        coupon,
        external,
      );

      order.value = await makeRequest("sendOrder", params);
      couponPaymentError.value = null;
    } catch (err) {
      couponPaymentError.value = err?.response?.data || err;
      Logger.error(`useOrder/${id}/sendOrderViaCoupon`, err);
    } finally {
      couponPaymentLoading.value = false;
    }
  };

  const fnSendOrderViaBalance = async (
    items: Array<any>,
    shippingAddress: any = null,
    external = false,
  ) => {
    Logger.debug(`useOrder/${id}/sendOrderViaBalance`);

    balancePaymentLoading.value = true;

    try {
      const params = fnCreateOrderObject(
        "KONFETTI",
        null,
        items,
        shippingAddress,
        null,
        null,
        external,
      );

      order.value = await makeRequest("sendOrder", params);
      balancePaymentError.value = null;
    } catch (err) {
      balancePaymentError.value = err?.response?.data || err;
      Logger.error(`useOrder/${id}/sendOrderViaBalance`, err);
    } finally {
      balancePaymentLoading.value = false;
    }
  };

  const sendOrderViaBalanceAndCoupon = async (
    coupon: string,
    items: Array<any>,
    shippingAddress: any = null,
    invoiceAddress: any = null,
    external = false,
  ) => {
    Logger.debug(`useOrder/${id}/sendOrderViaBalanceAndCoupon`, coupon);

    balancePaymentLoading.value = true;

    try {
      const params = fnCreateOrderObject(
        "KONFETTI",
        null,
        items,
        shippingAddress,
        invoiceAddress,
        coupon,
        external,
      );

      order.value = await makeRequest("sendOrder", params);
      balancePaymentError.value = null;
    } catch (err) {
      balancePaymentError.value = err?.response?.data || err;
      Logger.error(`useOrder/${id}/sendOrderViaBalanceAndCoupon`, err);
    } finally {
      balancePaymentLoading.value = false;
    }
  };

  const validateCoupon = async (
    promoCode: string,
    value: string,
    external = false,
    supplierId: string = null,
  ) => {
    Logger.debug(`useOrder/${id}/validateCoupon`, promoCode);
    const token =
      process.client && hasAccessToWindowObject
        ? JwtService.getToken()
        : undefined;

    couponLoading.value = true;
    couponError.value = null;

    try {
      const params = {
        value,
        ...(external && { supplier_id: supplierId }),
      };

      /* @todo later, put this in a unique call */
      setJwtToken(token);
      coupon.value = await makeRequest("validateCoupon", {
        code: promoCode,
        params: params,
      });
    } catch (err) {
      couponError.value = err?.response?.data || err;
      Logger.error(`useOrder/${id}/validateCoupon`, err);
    } finally {
      couponLoading.value = false;
    }
  };

  const getOrderById = async (orderId: string) => {
    Logger.debug(`useOrder/${id}/getOrderById`, orderId);

    loading.value = true;
    error.value = null;

    const token =
      process.client && hasAccessToWindowObject
        ? JwtService.getToken()
        : undefined;

    setJwtToken(token);
    try {
      order.value = await makeRequest("getOrderById", orderId);
      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useOrder/${id}/getOrderById`, err);
    } finally {
      loading.value = false;
    }
  };

  const sendOrderSurvey = async (orderId: string, answers: any) => {
    Logger.debug(`useOrder/${id}/sendOrderSurvey`, orderId);

    loading.value = true;
    error.value = null;

    const token =
      process.client && hasAccessToWindowObject
        ? JwtService.getToken()
        : undefined;
    setJwtToken(token);

    try {
      order.value = await makeRequest("sendOrderSurvey", {
        orderId: orderId,
        answers: answers,
      });
      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useOrder/${id}/sendOrderSurvey`, err);
    } finally {
      loading.value = false;
    }
  };

  const getUserOrders = async (page: number) => {
    Logger.debug(`useOrder/${id}/getUserOrders`);

    loading.value = true;
    error.value = null;

    const token =
      process.client && hasAccessToWindowObject
        ? JwtService.getToken()
        : undefined;

    setJwtToken(token);

    try {
      orders.value = await makeRequest("getUserOrders", page);
      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useOrder/${id}/getUserOrders`, err);
    } finally {
      loading.value = false;
    }
  };

  const getOrderItemDownloads = async (orderId, orderItemId) => {
    Logger.debug(`useOrder/${id}/getOrderItemDownloads`);

    loading.value = true;
    error.value = null;

    const token =
      process.client && hasAccessToWindowObject
        ? JwtService.getToken()
        : undefined;

    setJwtToken(token);

    try {
      const params = {
        orderId: orderId,
        orderItemId: orderItemId,
      };

      const downloads = await makeRequest("getOrderItemDownloads", params);
      for (const download of downloads) {
        window.open(download);
      }

      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useOrder/${id}/getOrderItemDownloads`, err);
    } finally {
      loading.value = false;
    }
  };

  return {
    /* General */
    orders: computed(() => paginationGetters.validatePagination(orders.value)),
    order: computed(() => order.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    getOrderById,

    /* Balance */
    balancePaymentError: computed(() => balancePaymentError.value),
    balancePaymentLoading: computed(() => balancePaymentLoading.value),
    fnSendOrderViaBalance,
    sendOrderViaBalanceAndCoupon,

    /* Coupon */
    coupon: computed(() => coupon.value),
    couponLoading: computed(() => couponLoading.value),
    couponError: computed(() => couponError.value),
    couponPaymentLoading: computed(() => couponLoading.value),
    couponPaymentError: computed(() => couponError.value),
    sendOrderViaCoupon,
    validateCoupon,

    fnCreateOrderObject,

    /* Survey */
    sendOrderSurvey,

    /* Order Item List */
    getUserOrders,

    /* Order Item Downloads */
    getOrderItemDownloads,
  };
};
