// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import { latinize } from "./latinize";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function cleanUrl(url) {
  url.set(
    "pathname",
    latinize(decodeURIComponent(url.pathname))
      .replace("[^a-z0-9s/-]", "")
      .toLowerCase(),
  );

  if (url.pathname.match("^/.*(?<!/)$")) {
    url.set("pathname", url.pathname + "/");
  }

  return url;
}
