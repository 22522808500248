<template>
  <div
    :class="{ 'd-flex justify-content-center': center }"
    class="kft-tag-showcase py-4"
  >
    <!-- Begin::Featured Chips -->
    <LazyHydrate when-visible>
      <div class="kft-tag-showcase__container">
        <KftHeading
          v-if="title"
          :class="{ 'text-center': center }"
          :level="2"
          :title="title"
          class="kft-heading--left mb-5"
        />
        <div
          v-show="tags.length > 0"
          class="kft-tag-showcase__chips-container mb-5"
        >
          <KftChip
            v-for="(tag, i) in tags"
            :key="`kft-tag-showcase__chips-${i}`"
            :label="tag.name"
            class="tag-showcase__chips"
            @pressed="$router.push(localePath(`/search?tag=${tag.slug}`))"
          />
        </div>
      </div>
    </LazyHydrate>
    <!-- End::Featured Chips -->
  </div>
</template>

<script>
import LazyHydrate from "vue-lazy-hydration";
import { KftHeading } from "@konfetti-ui/vue";
import KftChip from "../../atoms/KftChip/KftChip.vue";

export default {
  name: "KftTagShowcase",
  components: {
    LazyHydrate,
    KftChip,
    KftHeading,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    tags: {
      type: Array,
      default: [],
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    click: null,
  },
  setup() {
    return {};
  },
};
</script>
