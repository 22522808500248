import { i18nRedirectsUtil, KFT_COUNTRY_COOKIE, KFT_CURRENCY_COOKIE, KFT_LOCALE_COOKIE } from '@konfetti-core/core';
import { i18nConfig } from '@konfetti/config';

const i18nCookiesPlugin = ({
  $cookies,
  i18n,
  app,
  redirect
}) => {
  const isServer = typeof window === 'undefined';
  const navigator = isServer ? undefined : window.navigator;
  const acceptedLanguage = app.context.req?.headers?.['accept-language'] || navigator?.languages || '';
  const isRouteValid = !!app.context.route.name;
  const cookieNames = {
    currency: i18nConfig.cookies?.currencyCookieName || KFT_CURRENCY_COOKIE,
    country: i18nConfig.cookies?.countryCookieName || KFT_COUNTRY_COOKIE,
    locale: i18nConfig.cookies?.localeCookieName || KFT_LOCALE_COOKIE
  };
  const cookieLocale = $cookies.get(cookieNames.locale);
  const cookieCurrency = $cookies.get(cookieNames.currency);

  const getCurrencyByLocale = (locale) =>
    i18n.numberFormats?.[locale]?.currency?.currency
    || i18nConfig.currency
    || (i18nConfig.currencies.length && i18nConfig.currencies[0].name);

  const utils = i18nRedirectsUtil({
    path: app.context.route.path,
    defaultLocale: i18nConfig.defaultLocale,
    availableLocales: i18nConfig.locales.map((item) => item.code),
    acceptedLanguages: isServer ? acceptedLanguage.split(',').map((item) => item.split(';')[0]) : acceptedLanguage,
    cookieLocale
  });

  const targetLocale = utils.getTargetLocale();
  const redirectPath = utils.getRedirectPath();

  if (!isRouteValid) {
    app.i18n.setLocale(targetLocale);
  }

  if (isServer) {
    app.i18n.cookieValues = {
      [cookieNames.locale]: targetLocale,
      [cookieNames.currency]: getCurrencyByLocale(targetLocale)
    };

    if (redirectPath) {
      redirect(redirectPath);
    }

    return;
  }
  const cookieOptions = {
    path: '/',
    sameSite: 'lax',
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) // Year from now
  };
  const settings = {
    locale: targetLocale,
    currency: getCurrencyByLocale(targetLocale),
    country: i18nConfig.country || (i18nConfig.countries.length && i18nConfig.countries[0].name)
  };

  const missingFields = Object
    .entries(settings)
    .reduce((carry, [name, value]) => {
      return [
        ...carry,
        ...(!value ? [name] : [])
      ];
    }, []);

  if (missingFields.length) {
    throw new Error(`Following fields are missing in the i18n configuration: ${missingFields.join(', ')}`);
  }

  if (cookieLocale !== settings.locale) {
    $cookies.set(cookieNames.locale, settings.locale, cookieOptions);
  }

  if (cookieCurrency !== settings.currency) {
    $cookies.set(cookieNames.currency, settings.currency, cookieOptions);
  }

  !$cookies.get(cookieNames.country) && $cookies.set(cookieNames.country, settings.country, cookieOptions);

  i18n.onBeforeLanguageSwitch = (oldLocale, newLocale, isInitialSetup, context) => {
    $cookies.set(cookieNames.locale, newLocale, cookieOptions);
    $cookies.set(cookieNames.currency, getCurrencyByLocale(newLocale), cookieOptions);
    window.location.href = context.route.fullPath;
  };
};

export default i18nCookiesPlugin;
