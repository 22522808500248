import { ref } from "@nuxtjs/composition-api";
import {
  ErrorStoreError,
  ErrorStoreErrorType,
  ErrorHandlers,
  ErrorStore,
} from "./index";
/*
The `error()`, `clearError()`, and `registerHandler(type: ErrorStoreErrorType, handler:
  ErrorHandler)` methods are used to retrieve and manipulate the `ErrorStore` object.
*/
const createErrorStore = (): ErrorStore => {
  const error = ref<ErrorStoreError | null>(null);
  const handlers: ErrorHandlers = {};

  const setError = (newError: ErrorStoreError): void => {
    error.value = newError;

    const handler = handlers[newError.type];

    if (handler) {
      handler(newError);
    }
  };

  const clearError = (): void => {
    error.value = null;
  };

  const registerHandler = (
    type: ErrorStoreErrorType,
    handler: (error: ErrorStoreError) => void,
  ): void => {
    handlers[type] = handler;
  };

  return {
    error,
    setError,
    clearError,
    registerHandler,
  };
};

export default createErrorStore;
