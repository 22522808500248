declare global {
  interface Window {
    __NUXT__: any;
  }
}

export const getSupplierSubdomain = (nuxt, config): string | null => {
  if (typeof window !== "undefined" && window?.__NUXT__?.subdomain) {
    return window.__NUXT__.subdomain;
  }

  return nuxt?.subdomain || config?.subdomain || null;
};
