import { Logger, sharedRef } from "@konfetti-core/core";
import { useApiHandler } from "../useApiHandler";
import { JwtService } from "@konfetti/api";
import { fnCreateOrderObject } from "../helpers";

export const usePaypal = (id: string): any => {
  const loading = sharedRef(false, `useCustom-loading-${id}`);
  const error = sharedRef(null, `useCustom-error-${id}`);
  const { setJwtToken, makeRequest } = useApiHandler(true);
  const order = sharedRef(null, `useOrder-order-${id}`);

  const sendOrder = async (
    items: Array<any>,
    shippingAddress: any = null,
    invoiceAddress: any = null,
    coupon: string = null,
    external = false,
    useBalance = true,
  ) => {
    Logger.debug(`usePaypal/${id}/sendOrder`);

    try {
      loading.value = true;
      const token = process.client ? JwtService.getToken() : undefined;

      const params = fnCreateOrderObject(
        "PAYPAL",
        {},
        items,
        shippingAddress,
        invoiceAddress,
        coupon,
        external,
        null,
        useBalance,
      );

      setJwtToken(token);
      order.value = await makeRequest("sendOrder", params);
      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`usePaypal/${id}/sendOrder`, err);
    } finally {
      loading.value = false;
    }
  };

  const captureOrder = async (id: string) => {
    Logger.debug(`usePaypal/${id}/captureOrder`);

    try {
      loading.value = true;
      const token = process.client ? JwtService.getToken() : undefined;

      setJwtToken(token);
      order.value = await makeRequest("captureOrder", id);
      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`usePaypal/${id}/captureOrder`, err);
    } finally {
      loading.value = false;
    }
  };

  return {
    order,
    loading,
    error,

    sendOrder,
    captureOrder,
  };
};
