import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1dc353e1 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _eac161de = () => interopDefault(import('../pages/account/general-info.vue' /* webpackChunkName: "pages/account/general-info" */))
const _294ef703 = () => interopDefault(import('../pages/account/orders.vue' /* webpackChunkName: "pages/account/orders" */))
const _febeeca0 = () => interopDefault(import('../pages/account/referral-info.vue' /* webpackChunkName: "pages/account/referral-info" */))
const _5f93cb7c = () => interopDefault(import('../pages/account/user-info.vue' /* webpackChunkName: "pages/account/user-info" */))
const _992eaa7c = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _7018fb2a = () => interopDefault(import('../pages/checkout/index.js' /* webpackChunkName: "pages/checkout/index" */))
const _6182159a = () => interopDefault(import('../pages/checkout/order-summary.vue' /* webpackChunkName: "pages/checkout/order-summary" */))
const _7ecffc39 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _47f280dc = () => interopDefault(import('../pages/checkout/personal-details.vue' /* webpackChunkName: "pages/checkout/personal-details" */))
const _680bc19f = () => interopDefault(import('../pages/event-list-page.vue' /* webpackChunkName: "pages/event-list-page" */))
const _0110336c = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _22fc23aa = () => interopDefault(import('../pages/impressum.vue' /* webpackChunkName: "pages/impressum" */))
const _90b5a240 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _7a293210 = () => interopDefault(import('../pages/magazine.vue' /* webpackChunkName: "pages/magazine" */))
const _5588f877 = () => interopDefault(import('../pages/magazine/article.vue' /* webpackChunkName: "pages/magazine/article" */))
const _e1c34c66 = () => interopDefault(import('../pages/magazine/category.vue' /* webpackChunkName: "pages/magazine/category" */))
const _06f92729 = () => interopDefault(import('../pages/magazine/magazine-wrapper.vue' /* webpackChunkName: "pages/magazine/magazine-wrapper" */))
const _9aa0c490 = () => interopDefault(import('../pages/order-success.vue' /* webpackChunkName: "pages/order-success" */))
const _6642ba7f = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _3eafaa99 = () => interopDefault(import('../pages/password-reset.vue' /* webpackChunkName: "pages/password-reset" */))
const _6a22a462 = () => interopDefault(import('../pages/ping-default.vue' /* webpackChunkName: "pages/ping-default" */))
const _f111d3f0 = () => interopDefault(import('../pages/ping-optimized.vue' /* webpackChunkName: "pages/ping-optimized" */))
const _b9330a24 = () => interopDefault(import('../pages/ping.vue' /* webpackChunkName: "pages/ping" */))
const _6cd9dfdb = () => interopDefault(import('../pages/root-domain/home.vue' /* webpackChunkName: "" */))
const _487eeb24 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _5bb6f269 = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _6c152236 = () => interopDefault(import('../pages/stripe-3d-auth-complete.vue' /* webpackChunkName: "pages/stripe-3d-auth-complete" */))
const _46dda0f1 = () => interopDefault(import('../pages/sub-domain/home.vue' /* webpackChunkName: "" */))
const _521a12d4 = () => interopDefault(import('../pages/teamevent/index.vue' /* webpackChunkName: "pages/teamevent/index" */))
const _4be491f0 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _094877ce = () => interopDefault(import('../pages/terms-supplier.vue' /* webpackChunkName: "pages/terms-supplier" */))
const _4868b178 = () => interopDefault(import('../pages/auth/social-login.vue' /* webpackChunkName: "pages/auth/social-login" */))
const _603a4c25 = () => interopDefault(import('../pages/city-category/city-category.vue' /* webpackChunkName: "pages/city-category/city-category" */))
const _08837850 = () => interopDefault(import('../pages/embedded/booking.vue' /* webpackChunkName: "pages/embedded/booking" */))
const _6fc5270b = () => interopDefault(import('../pages/embedded/button.vue' /* webpackChunkName: "pages/embedded/button" */))
const _e8fa69da = () => interopDefault(import('../pages/login/forgot-password.vue' /* webpackChunkName: "pages/login/forgot-password" */))
const _71e1c950 = () => interopDefault(import('../pages/partner/booking-solution.vue' /* webpackChunkName: "pages/partner/booking-solution" */))
const _2c3de633 = () => interopDefault(import('../pages/partner/pricing.vue' /* webpackChunkName: "pages/partner/pricing" */))
const _4d788cbc = () => interopDefault(import('../pages/root-domain/giftcard.vue' /* webpackChunkName: "pages/root-domain/giftcard" */))
const _226d7263 = () => interopDefault(import('../pages/special-giftcard/special-giftcard.vue' /* webpackChunkName: "pages/special-giftcard/special-giftcard" */))
const _0ee9d2d2 = () => interopDefault(import('../pages/sub-domain/giftcard.vue' /* webpackChunkName: "pages/sub-domain/giftcard" */))
const _6670f1c2 = () => interopDefault(import('../pages/t/general-event-list-page.vue' /* webpackChunkName: "pages/t/general-event-list-page" */))
const _78d0e030 = () => interopDefault(import('../pages/c/_categorySlug.vue' /* webpackChunkName: "pages/c/_categorySlug" */))
const _07c27922 = () => interopDefault(import('../pages/city/_city.vue' /* webpackChunkName: "pages/city/_city" */))
const _646d1081 = () => interopDefault(import('../pages/e/_event.vue' /* webpackChunkName: "pages/e/_event" */))
const _60f95601 = () => interopDefault(import('../pages/e/_event/index.ts' /* webpackChunkName: "pages/e/_event/index" */))
const _2db809d2 = () => interopDefault(import('../pages/e/_event/BasicDetails.vue' /* webpackChunkName: "pages/e/_event/BasicDetails" */))
const _0b5a7174 = () => interopDefault(import('../pages/e/_event/EventBreadcrumbs.vue' /* webpackChunkName: "pages/e/_event/EventBreadcrumbs" */))
const _9cf67d7a = () => interopDefault(import('../pages/e/_event/EventIconList.vue' /* webpackChunkName: "pages/e/_event/EventIconList" */))
const _a53ec10e = () => interopDefault(import('../pages/e/_event/EventIconList/EventIconListTable.vue' /* webpackChunkName: "pages/e/_event/EventIconList/EventIconListTable" */))
const _6c66cbb8 = () => interopDefault(import('../pages/e/_event/Gallery.vue' /* webpackChunkName: "pages/e/_event/Gallery" */))
const _9eb89190 = () => interopDefault(import('../pages/e/_event/LocationAndMap.vue' /* webpackChunkName: "pages/e/_event/LocationAndMap" */))
const _25945c43 = () => interopDefault(import('../pages/e/_event/PrivateEventInfo.vue' /* webpackChunkName: "pages/e/_event/PrivateEventInfo" */))
const _204e4cc3 = () => interopDefault(import('../pages/e/_event/ReviewShowcase.vue' /* webpackChunkName: "pages/e/_event/ReviewShowcase" */))
const _1fe0c5c2 = () => interopDefault(import('../pages/e/_event/composables/useEventBreadcrumbs.ts' /* webpackChunkName: "pages/e/_event/composables/useEventBreadcrumbs" */))
const _2a460ab2 = () => interopDefault(import('../pages/e/_event/composables/useKftBuyBox.ts' /* webpackChunkName: "pages/e/_event/composables/useKftBuyBox" */))
const _77ad5d32 = () => interopDefault(import('../pages/p/_permalink.vue' /* webpackChunkName: "pages/p/_permalink" */))
const _f3f318e2 = () => interopDefault(import('../pages/preview/_previewPermalink.vue' /* webpackChunkName: "pages/preview/_previewPermalink" */))
const _7091e666 = () => interopDefault(import('../pages/review/_event.vue' /* webpackChunkName: "pages/review/_event" */))
const _32e6c04c = () => interopDefault(import('../pages/teamevent/_city.vue' /* webpackChunkName: "pages/teamevent/_city" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de-de/account/",
    component: _1dc353e1,
    pathToRegexpOptions: {"strict":true},
    name: "account___de-de",
    children: [{
      path: "general-info/",
      component: _eac161de,
      pathToRegexpOptions: {"strict":true},
      name: "account-general-info___de-de"
    }, {
      path: "orders/",
      component: _294ef703,
      pathToRegexpOptions: {"strict":true},
      name: "account-orders___de-de"
    }, {
      path: "referral-info/",
      component: _febeeca0,
      pathToRegexpOptions: {"strict":true},
      name: "account-referral-info___de-de"
    }, {
      path: "user-info/",
      component: _5f93cb7c,
      pathToRegexpOptions: {"strict":true},
      name: "account-user-info___de-de"
    }]
  }, {
    path: "/de-de/checkout/",
    component: _992eaa7c,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _7018fb2a,
      pathToRegexpOptions: {"strict":true},
      name: "checkout___de-de"
    }, {
      path: "order-summary/",
      component: _6182159a,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-order-summary___de-de"
    }, {
      path: "payment/",
      component: _7ecffc39,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-payment___de-de"
    }, {
      path: "personal-details/",
      component: _47f280dc,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-personal-details___de-de"
    }]
  }, {
    path: "/de-de/event-list-page/",
    component: _680bc19f,
    pathToRegexpOptions: {"strict":true},
    name: "event-list-page___de-de"
  }, {
    path: "/de-de/faq/",
    component: _0110336c,
    pathToRegexpOptions: {"strict":true},
    name: "faq___de-de"
  }, {
    path: "/de-de/impressum/",
    component: _22fc23aa,
    pathToRegexpOptions: {"strict":true},
    name: "impressum___de-de"
  }, {
    path: "/de-de/login/",
    component: _90b5a240,
    pathToRegexpOptions: {"strict":true},
    name: "login___de-de"
  }, {
    path: "/de-de/magazine/",
    component: _7a293210,
    pathToRegexpOptions: {"strict":true},
    name: "magazine___de-de",
    children: [{
      path: "article/",
      component: _5588f877,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-article___de-de"
    }, {
      path: "category/",
      component: _e1c34c66,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-category___de-de"
    }, {
      path: "magazine-wrapper/",
      component: _06f92729,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-magazine-wrapper___de-de"
    }]
  }, {
    path: "/de-de/order-success/",
    component: _9aa0c490,
    pathToRegexpOptions: {"strict":true},
    name: "order-success___de-de"
  }, {
    path: "/de-de/partner/",
    component: _6642ba7f,
    pathToRegexpOptions: {"strict":true},
    name: "partner___de-de"
  }, {
    path: "/de-de/password-reset/",
    component: _3eafaa99,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset___de-de"
  }, {
    path: "/de-de/ping-default/",
    component: _6a22a462,
    pathToRegexpOptions: {"strict":true},
    name: "ping-default___de-de"
  }, {
    path: "/de-de/ping-optimized/",
    component: _f111d3f0,
    pathToRegexpOptions: {"strict":true},
    name: "ping-optimized___de-de"
  }, {
    path: "/de-de/ping/",
    component: _b9330a24,
    pathToRegexpOptions: {"strict":true},
    name: "ping___de-de"
  }, {
    path: "/de-de/root-domain/",
    component: _6cd9dfdb,
    props: true,
    name: "home___de-de"
  }, {
    path: "/de-de/search/",
    component: _487eeb24,
    pathToRegexpOptions: {"strict":true},
    name: "search___de-de"
  }, {
    path: "/de-de/sitemap/",
    component: _5bb6f269,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___de-de"
  }, {
    path: "/de-de/stripe-3d-auth-complete/",
    component: _6c152236,
    pathToRegexpOptions: {"strict":true},
    name: "stripe-3d-auth-complete___de-de"
  }, {
    path: "/de-de/sub-domain/",
    component: _46dda0f1,
    props: true,
    name: "supplier-home___de-de"
  }, {
    path: "/de-de/teamevent/",
    component: _521a12d4,
    pathToRegexpOptions: {"strict":true},
    name: "teamevent___de-de"
  }, {
    path: "/de-de/terms-and-conditions/",
    component: _4be491f0,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___de-de"
  }, {
    path: "/de-de/terms-supplier/",
    component: _094877ce,
    pathToRegexpOptions: {"strict":true},
    name: "terms-supplier___de-de"
  }, {
    path: "/en-us/account/",
    component: _1dc353e1,
    pathToRegexpOptions: {"strict":true},
    name: "account___en-us",
    children: [{
      path: "general-info/",
      component: _eac161de,
      pathToRegexpOptions: {"strict":true},
      name: "account-general-info___en-us"
    }, {
      path: "orders/",
      component: _294ef703,
      pathToRegexpOptions: {"strict":true},
      name: "account-orders___en-us"
    }, {
      path: "referral-info/",
      component: _febeeca0,
      pathToRegexpOptions: {"strict":true},
      name: "account-referral-info___en-us"
    }, {
      path: "user-info/",
      component: _5f93cb7c,
      pathToRegexpOptions: {"strict":true},
      name: "account-user-info___en-us"
    }]
  }, {
    path: "/en-us/checkout/",
    component: _992eaa7c,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _7018fb2a,
      pathToRegexpOptions: {"strict":true},
      name: "checkout___en-us"
    }, {
      path: "order-summary/",
      component: _6182159a,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-order-summary___en-us"
    }, {
      path: "payment/",
      component: _7ecffc39,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-payment___en-us"
    }, {
      path: "personal-details/",
      component: _47f280dc,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-personal-details___en-us"
    }]
  }, {
    path: "/en-us/event-list-page/",
    component: _680bc19f,
    pathToRegexpOptions: {"strict":true},
    name: "event-list-page___en-us"
  }, {
    path: "/en-us/faq/",
    component: _0110336c,
    pathToRegexpOptions: {"strict":true},
    name: "faq___en-us"
  }, {
    path: "/en-us/impressum/",
    component: _22fc23aa,
    pathToRegexpOptions: {"strict":true},
    name: "impressum___en-us"
  }, {
    path: "/en-us/login/",
    component: _90b5a240,
    pathToRegexpOptions: {"strict":true},
    name: "login___en-us"
  }, {
    path: "/en-us/magazine/",
    component: _7a293210,
    pathToRegexpOptions: {"strict":true},
    name: "magazine___en-us",
    children: [{
      path: "article/",
      component: _5588f877,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-article___en-us"
    }, {
      path: "category/",
      component: _e1c34c66,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-category___en-us"
    }, {
      path: "magazine-wrapper/",
      component: _06f92729,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-magazine-wrapper___en-us"
    }]
  }, {
    path: "/en-us/order-success/",
    component: _9aa0c490,
    pathToRegexpOptions: {"strict":true},
    name: "order-success___en-us"
  }, {
    path: "/en-us/partner/",
    component: _6642ba7f,
    pathToRegexpOptions: {"strict":true},
    name: "partner___en-us"
  }, {
    path: "/en-us/password-reset/",
    component: _3eafaa99,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset___en-us"
  }, {
    path: "/en-us/ping-default/",
    component: _6a22a462,
    pathToRegexpOptions: {"strict":true},
    name: "ping-default___en-us"
  }, {
    path: "/en-us/ping-optimized/",
    component: _f111d3f0,
    pathToRegexpOptions: {"strict":true},
    name: "ping-optimized___en-us"
  }, {
    path: "/en-us/ping/",
    component: _b9330a24,
    pathToRegexpOptions: {"strict":true},
    name: "ping___en-us"
  }, {
    path: "/en-us/root-domain/",
    component: _6cd9dfdb,
    props: true,
    name: "home___en-us"
  }, {
    path: "/en-us/search/",
    component: _487eeb24,
    pathToRegexpOptions: {"strict":true},
    name: "search___en-us"
  }, {
    path: "/en-us/sitemap/",
    component: _5bb6f269,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___en-us"
  }, {
    path: "/en-us/stripe-3d-auth-complete/",
    component: _6c152236,
    pathToRegexpOptions: {"strict":true},
    name: "stripe-3d-auth-complete___en-us"
  }, {
    path: "/en-us/sub-domain/",
    component: _46dda0f1,
    props: true,
    name: "supplier-home___en-us"
  }, {
    path: "/en-us/teamevent/",
    component: _521a12d4,
    pathToRegexpOptions: {"strict":true},
    name: "teamevent___en-us"
  }, {
    path: "/en-us/terms-and-conditions/",
    component: _4be491f0,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___en-us"
  }, {
    path: "/en-us/terms-supplier/",
    component: _094877ce,
    pathToRegexpOptions: {"strict":true},
    name: "terms-supplier___en-us"
  }, {
    path: "/de-de/auth/social-login/",
    component: _4868b178,
    pathToRegexpOptions: {"strict":true},
    name: "auth-social-login___de-de"
  }, {
    path: "/de-de/city-category/city-category/",
    component: _603a4c25,
    pathToRegexpOptions: {"strict":true},
    name: "city-category-city-category___de-de"
  }, {
    path: "/de-de/embedded/booking/",
    component: _08837850,
    pathToRegexpOptions: {"strict":true},
    name: "embedded-booking___de-de"
  }, {
    path: "/de-de/embedded/button/",
    component: _6fc5270b,
    pathToRegexpOptions: {"strict":true},
    name: "embedded-button___de-de"
  }, {
    path: "/de-de/login/forgot-password/",
    component: _e8fa69da,
    pathToRegexpOptions: {"strict":true},
    name: "login-forgot-password___de-de"
  }, {
    path: "/de-de/partner/booking-solution/",
    component: _71e1c950,
    pathToRegexpOptions: {"strict":true},
    name: "partner-booking-solution___de-de"
  }, {
    path: "/de-de/partner/pricing/",
    component: _2c3de633,
    pathToRegexpOptions: {"strict":true},
    name: "partner-pricing___de-de"
  }, {
    path: "/de-de/root-domain/giftcard/",
    component: _4d788cbc,
    pathToRegexpOptions: {"strict":true},
    name: "root-domain-giftcard___de-de"
  }, {
    path: "/de-de/root-domain/giftcard/",
    component: _4d788cbc,
    meta: {"contentGroup":"giftcard"},
    props: true,
    name: "gifcard-page___de-de"
  }, {
    path: "/de-de/root-domain/home/",
    component: _6cd9dfdb,
    pathToRegexpOptions: {"strict":true},
    name: "root-domain-home___de-de"
  }, {
    path: "/de-de/special-giftcard/special-giftcard/",
    component: _226d7263,
    pathToRegexpOptions: {"strict":true},
    name: "event___de-de"
  }, {
    path: "/de-de/sub-domain/giftcard/",
    component: _0ee9d2d2,
    pathToRegexpOptions: {"strict":true},
    name: "sub-domain-giftcard___de-de"
  }, {
    path: "/de-de/sub-domain/giftcard/",
    component: _0ee9d2d2,
    props: true,
    name: "supplier-giftcard-page___de-de"
  }, {
    path: "/de-de/sub-domain/home/",
    component: _46dda0f1,
    pathToRegexpOptions: {"strict":true},
    name: "sub-domain-home___de-de"
  }, {
    path: "/de-de/t/general-event-list-page/",
    component: _6670f1c2,
    pathToRegexpOptions: {"strict":true},
    name: "t-general-event-list-page___de-de"
  }, {
    path: "/en-us/auth/social-login/",
    component: _4868b178,
    pathToRegexpOptions: {"strict":true},
    name: "auth-social-login___en-us"
  }, {
    path: "/en-us/city-category/city-category/",
    component: _603a4c25,
    pathToRegexpOptions: {"strict":true},
    name: "city-category-city-category___en-us"
  }, {
    path: "/en-us/embedded/booking/",
    component: _08837850,
    pathToRegexpOptions: {"strict":true},
    name: "embedded-booking___en-us"
  }, {
    path: "/en-us/embedded/button/",
    component: _6fc5270b,
    pathToRegexpOptions: {"strict":true},
    name: "embedded-button___en-us"
  }, {
    path: "/en-us/login/forgot-password/",
    component: _e8fa69da,
    pathToRegexpOptions: {"strict":true},
    name: "login-forgot-password___en-us"
  }, {
    path: "/en-us/partner/booking-solution/",
    component: _71e1c950,
    pathToRegexpOptions: {"strict":true},
    name: "partner-booking-solution___en-us"
  }, {
    path: "/en-us/partner/pricing/",
    component: _2c3de633,
    pathToRegexpOptions: {"strict":true},
    name: "partner-pricing___en-us"
  }, {
    path: "/en-us/root-domain/giftcard/",
    component: _4d788cbc,
    pathToRegexpOptions: {"strict":true},
    name: "root-domain-giftcard___en-us"
  }, {
    path: "/en-us/root-domain/giftcard/",
    component: _4d788cbc,
    meta: {"contentGroup":"giftcard"},
    props: true,
    name: "gifcard-page___en-us"
  }, {
    path: "/en-us/root-domain/home/",
    component: _6cd9dfdb,
    pathToRegexpOptions: {"strict":true},
    name: "root-domain-home___en-us"
  }, {
    path: "/en-us/special-giftcard/special-giftcard/",
    component: _226d7263,
    pathToRegexpOptions: {"strict":true},
    name: "event___en-us"
  }, {
    path: "/en-us/sub-domain/giftcard/",
    component: _0ee9d2d2,
    pathToRegexpOptions: {"strict":true},
    name: "sub-domain-giftcard___en-us"
  }, {
    path: "/en-us/sub-domain/giftcard/",
    component: _0ee9d2d2,
    props: true,
    name: "supplier-giftcard-page___en-us"
  }, {
    path: "/en-us/sub-domain/home/",
    component: _46dda0f1,
    pathToRegexpOptions: {"strict":true},
    name: "sub-domain-home___en-us"
  }, {
    path: "/en-us/t/general-event-list-page/",
    component: _6670f1c2,
    pathToRegexpOptions: {"strict":true},
    name: "t-general-event-list-page___en-us"
  }, {
    path: "/de-de/auth/facebook/callback/",
    component: _4868b178,
    meta: {"socialLoginType":"facebook"},
    name: "social-login-facebook___de-de"
  }, {
    path: "/de-de/auth/google/callback/",
    component: _4868b178,
    meta: {"socialLoginType":"google"},
    name: "social-login-google___de-de"
  }, {
    path: "/en-us/auth/facebook/callback/",
    component: _4868b178,
    meta: {"socialLoginType":"facebook"},
    name: "social-login-facebook___en-us"
  }, {
    path: "/en-us/auth/google/callback/",
    component: _4868b178,
    meta: {"socialLoginType":"google"},
    name: "social-login-google___en-us"
  }, {
    path: "/de-de/c/:categorySlug?/",
    component: _78d0e030,
    pathToRegexpOptions: {"strict":true},
    name: "c-categorySlug___de-de"
  }, {
    path: "/de-de/city/:city?/",
    component: _07c27922,
    pathToRegexpOptions: {"strict":true},
    name: "city-city___de-de"
  }, {
    path: "/de-de/e/:event?/",
    component: _646d1081,
    pathToRegexpOptions: {"strict":true},
    name: "event___de-de",
    children: [{
      path: "",
      component: _60f95601,
      pathToRegexpOptions: {"strict":true},
      name: "e-event___de-de"
    }, {
      path: "BasicDetails/",
      component: _2db809d2,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-BasicDetails___de-de"
    }, {
      path: "EventBreadcrumbs/",
      component: _0b5a7174,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-EventBreadcrumbs___de-de"
    }, {
      path: "EventIconList/",
      component: _9cf67d7a,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-EventIconList___de-de",
      children: [{
        path: "EventIconListTable/",
        component: _a53ec10e,
        pathToRegexpOptions: {"strict":true},
        name: "e-event-EventIconList-EventIconListTable___de-de"
      }]
    }, {
      path: "Gallery/",
      component: _6c66cbb8,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-Gallery___de-de"
    }, {
      path: "LocationAndMap/",
      component: _9eb89190,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-LocationAndMap___de-de"
    }, {
      path: "PrivateEventInfo/",
      component: _25945c43,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-PrivateEventInfo___de-de"
    }, {
      path: "ReviewShowcase/",
      component: _204e4cc3,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-ReviewShowcase___de-de"
    }, {
      path: "composables/useEventBreadcrumbs/",
      component: _1fe0c5c2,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-composables-useEventBreadcrumbs___de-de"
    }, {
      path: "composables/useKftBuyBox/",
      component: _2a460ab2,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-composables-useKftBuyBox___de-de"
    }]
  }, {
    path: "/de-de/e/:event/",
    component: _646d1081,
    meta: {"contentGroup":"event"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "event-page___de-de"
  }, {
    path: "/de-de/giftcard/:event/",
    component: _226d7263,
    meta: {"contentGroup":"giftcard"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "special-giftcards___de-de"
  }, {
    path: "/de-de/giftcard/:event/",
    component: _226d7263,
    meta: {"contentGroup":"giftcard"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "special-giftcards___de-de"
  }, {
    path: "/de-de/magazine/:slug/",
    component: _06f92729,
    meta: {"contentGroup":"magazine"},
    props: true,
    name: "magazine-pages___de-de"
  }, {
    path: "/de-de/p/:permalink?/",
    component: _77ad5d32,
    pathToRegexpOptions: {"strict":true},
    name: "p-permalink___de-de"
  }, {
    path: "/de-de/p/:permalink/",
    component: _77ad5d32,
    meta: {"contentGroup":"partner"},
    props: true,
    name: "supplier-profile___de-de"
  }, {
    path: "/de-de/p/:permalink/",
    component: _77ad5d32,
    meta: {"contentGroup":"partner"},
    props: true,
    name: "supplier-profile___de-de"
  }, {
    path: "/de-de/preview/:previewPermalink?/",
    component: _f3f318e2,
    pathToRegexpOptions: {"strict":true},
    name: "preview-previewPermalink___de-de"
  }, {
    path: "/de-de/review/:event?/",
    component: _7091e666,
    pathToRegexpOptions: {"strict":true},
    name: "review-event___de-de"
  }, {
    path: "/de-de/t/:slug/",
    component: _6670f1c2,
    meta: {"contentGroup":"tag"},
    props: true,
    name: "general-event-list-page___de-de"
  }, {
    path: "/de-de/t/:slug/",
    component: _6670f1c2,
    meta: {"contentGroup":"tag"},
    props: true,
    name: "general-event-list-page___de-de"
  }, {
    path: "/de-de/teamevent/:city/",
    component: _32e6c04c,
    pathToRegexpOptions: {"strict":true},
    name: "teamevent-city___de-de"
  }, {
    path: "/de-de/teamevent/:city/",
    component: _32e6c04c,
    meta: {"contentGroup":"team event"},
    props: true,
    name: "team-event-page___de-de"
  }, {
    path: "/de-de/teamevent/:city/",
    component: _32e6c04c,
    meta: {"contentGroup":"team event"},
    props: true,
    name: "team-event-page___de-de"
  }, {
    path: "/en-us/c/:categorySlug?/",
    component: _78d0e030,
    pathToRegexpOptions: {"strict":true},
    name: "c-categorySlug___en-us"
  }, {
    path: "/en-us/city/:city?/",
    component: _07c27922,
    pathToRegexpOptions: {"strict":true},
    name: "city-city___en-us"
  }, {
    path: "/en-us/e/:event?/",
    component: _646d1081,
    pathToRegexpOptions: {"strict":true},
    name: "event___en-us",
    children: [{
      path: "",
      component: _60f95601,
      pathToRegexpOptions: {"strict":true},
      name: "e-event___en-us"
    }, {
      path: "BasicDetails/",
      component: _2db809d2,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-BasicDetails___en-us"
    }, {
      path: "EventBreadcrumbs/",
      component: _0b5a7174,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-EventBreadcrumbs___en-us"
    }, {
      path: "EventIconList/",
      component: _9cf67d7a,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-EventIconList___en-us",
      children: [{
        path: "EventIconListTable/",
        component: _a53ec10e,
        pathToRegexpOptions: {"strict":true},
        name: "e-event-EventIconList-EventIconListTable___en-us"
      }]
    }, {
      path: "Gallery/",
      component: _6c66cbb8,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-Gallery___en-us"
    }, {
      path: "LocationAndMap/",
      component: _9eb89190,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-LocationAndMap___en-us"
    }, {
      path: "PrivateEventInfo/",
      component: _25945c43,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-PrivateEventInfo___en-us"
    }, {
      path: "ReviewShowcase/",
      component: _204e4cc3,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-ReviewShowcase___en-us"
    }, {
      path: "composables/useEventBreadcrumbs/",
      component: _1fe0c5c2,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-composables-useEventBreadcrumbs___en-us"
    }, {
      path: "composables/useKftBuyBox/",
      component: _2a460ab2,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-composables-useKftBuyBox___en-us"
    }]
  }, {
    path: "/en-us/e/:event/",
    component: _646d1081,
    meta: {"contentGroup":"event"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "event-page___en-us"
  }, {
    path: "/en-us/giftcard/:event/",
    component: _226d7263,
    meta: {"contentGroup":"giftcard"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "special-giftcards___en-us"
  }, {
    path: "/en-us/giftcard/:event/",
    component: _226d7263,
    meta: {"contentGroup":"giftcard"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "special-giftcards___en-us"
  }, {
    path: "/en-us/magazine/:slug/",
    component: _06f92729,
    meta: {"contentGroup":"magazine"},
    props: true,
    name: "magazine-pages___en-us"
  }, {
    path: "/en-us/p/:permalink?/",
    component: _77ad5d32,
    pathToRegexpOptions: {"strict":true},
    name: "p-permalink___en-us"
  }, {
    path: "/en-us/p/:permalink/",
    component: _77ad5d32,
    meta: {"contentGroup":"partner"},
    props: true,
    name: "supplier-profile___en-us"
  }, {
    path: "/en-us/p/:permalink/",
    component: _77ad5d32,
    meta: {"contentGroup":"partner"},
    props: true,
    name: "supplier-profile___en-us"
  }, {
    path: "/en-us/preview/:previewPermalink?/",
    component: _f3f318e2,
    pathToRegexpOptions: {"strict":true},
    name: "preview-previewPermalink___en-us"
  }, {
    path: "/en-us/review/:event?/",
    component: _7091e666,
    pathToRegexpOptions: {"strict":true},
    name: "review-event___en-us"
  }, {
    path: "/en-us/t/:slug/",
    component: _6670f1c2,
    meta: {"contentGroup":"tag"},
    props: true,
    name: "general-event-list-page___en-us"
  }, {
    path: "/en-us/t/:slug/",
    component: _6670f1c2,
    meta: {"contentGroup":"tag"},
    props: true,
    name: "general-event-list-page___en-us"
  }, {
    path: "/en-us/teamevent/:city/",
    component: _32e6c04c,
    pathToRegexpOptions: {"strict":true},
    name: "teamevent-city___en-us"
  }, {
    path: "/en-us/teamevent/:city/",
    component: _32e6c04c,
    meta: {"contentGroup":"team event"},
    props: true,
    name: "team-event-page___en-us"
  }, {
    path: "/en-us/teamevent/:city/",
    component: _32e6c04c,
    meta: {"contentGroup":"team event"},
    props: true,
    name: "team-event-page___en-us"
  }, {
    path: "/de-de/:citySlug/",
    component: _07c27922,
    meta: {"contentGroup":"city"},
    props: true,
    name: "city-page___de-de"
  }, {
    path: "/de-de/:citySlug/",
    component: _07c27922,
    meta: {"contentGroup":"city"},
    props: true,
    name: "city-page___de-de"
  }, {
    path: "/en-us/:citySlug/",
    component: _07c27922,
    meta: {"contentGroup":"city"},
    props: true,
    name: "city-page___en-us"
  }, {
    path: "/en-us/:citySlug/",
    component: _07c27922,
    meta: {"contentGroup":"city"},
    props: true,
    name: "city-page___en-us"
  }, {
    path: "/de-de/:citySlug/:categorySlug/",
    component: _603a4c25,
    meta: {"contentGroup":"city category"},
    props: true,
    name: "city-categories-page___de-de"
  }, {
    path: "/de-de/:citySlug/:categorySlug/",
    component: _603a4c25,
    meta: {"contentGroup":"city category"},
    props: true,
    name: "city-categories-page___de-de"
  }, {
    path: "/en-us/:citySlug/:categorySlug/",
    component: _603a4c25,
    meta: {"contentGroup":"city category"},
    props: true,
    name: "city-categories-page___en-us"
  }, {
    path: "/en-us/:citySlug/:categorySlug/",
    component: _603a4c25,
    meta: {"contentGroup":"city category"},
    props: true,
    name: "city-categories-page___en-us"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
