import Url from "url-parse";
import { cleanUrl } from "../../../utilities/cleanUrl";

const whitelist = ["/en-us/ping/"];

export default (req, res, next) => {
  const host = req.headers.host;
  const env = process.env.APP_ENV || process.env.NODE_ENV;
  const cleanHost = host.replace("www.", "").replace("https://", "");
  const isProduction =
    env === "production" || env === "prod" || env === "homolog";

  // This cleans and redirects urls with special characters or uppercase
  const originalUrlObj = new Url(req.url);
  const cleanUrlObj = cleanUrl(new Url(req.url));

  if (whitelist.includes(originalUrlObj.pathname)) {
    return next();
  }

  if (
    isProduction &&
    Boolean(originalUrlObj.href.match("^/(?!api/).*$")) &&
    Boolean(originalUrlObj.href.match("^/(?!_).*$")) &&
    (cleanUrlObj.pathname !== originalUrlObj.pathname || host !== cleanHost)
  ) {
    res.writeHead(301, {
      location: "https://" + cleanHost + cleanUrlObj.href,
    });
    res.end();
  } else {
    next();
  }
};
