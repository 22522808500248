<template>
  <div class="kft-ticket-card">
    <div class="w-100">
      <KftHeading
        :title="title ? title : formatDateWithYearForDateInNextYear(date.start)"
        :use-h-tag="false"
        :level="5"
        class="mb-2 kft-heading--left"
      />

      <KftBulletPoints
        v-if="hasChildrenEvents"
        class="mb-2"
        :items-html-arr="childrenHtmlArr"
      />
      <div
        v-else
        class="kft-ticket-card__single-date font-family-heading overline"
      >
        <template v-if="date && date.dateType === 'PRIVATE'">
          {{ $t("product.ticketCardsSection.perGroup") }}
        </template>
        <template v-if="date && date.dateType === 'PUBLIC'">
          {{ `${formatTime(date.start)}-${formatTime(date.end)}` }}
        </template>
      </div>
    </div>

    <div class="w-100 align-self-end">
      <div
        v-if="date && date.product && date.dateType"
        class="overline font-family-heading fw-normal"
      >
        {{ ticketPrice }}
        {{ date.dateType === "PUBLIC" ? "" : `/ ${$t("general.perGroup")}` }}
      </div>
      <div v-else>
        <p class="kft-ticket-card__description font-family-heading overline">
          {{ description }}
        </p>
      </div>

      <KftBrushStrokeButton
        v-if="date"
        class="kft-brush-stroke-button--75w"
        @click.prevent="$emit('click:ticket-card-cta', date)"
      >
        {{ $t("product.ticketCardsSection.addToCart") }}
      </KftBrushStrokeButton>
      <KftBrushStrokeButton
        v-else
        @click.prevent="$emit('click:ticket-card-cta')"
      >
        {{ ctaLabel }}
      </KftBrushStrokeButton>
    </div>
  </div>
</template>
<script>
import { KftHeading } from "@konfetti-ui/vue";
import { eventGetters } from "@konfetti/composables";
import KftBrushStrokeButton from "@/components/molecules/KftBrushStrokeButton/KftBrushStrokeButton";
import { KftBulletPoints } from "@/components";
import { formatDate, formatTime } from "~/helpers/dateHelpers";
import { useEventDateHelpers } from "@/composables";
import { fnConvertLocaleIntoBCP47, formatMoney } from "@/helpers";

export default {
  name: "KftTicketCard",
  components: { KftBrushStrokeButton, KftBulletPoints, KftHeading },
  props: {
    date: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    ctaLabel: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { fnGetChildrenHtmlArr } = useEventDateHelpers();
    const childrenHtmlArr = props.date
      ? [
          ...fnGetChildrenHtmlArr({ children: { data: [props.date] } }),
          ...fnGetChildrenHtmlArr(props.date),
        ]
      : null;

    const formatDateWithYearForDateInNextYear = (date) => {
      const currentYear = new Date().getFullYear();
      const eventDateYear = new Date(date).getFullYear();
      const dateFormatString =
        currentYear === eventDateYear ? "dd. DD. MMM" : "dd. DD. MMM YYYY";
      return formatDate(date, null, dateFormatString);
    };

    return {
      eventGetters,
      childrenHtmlArr,
      formatDateWithYearForDateInNextYear,
    };
  },
  computed: {
    hasChildrenEvents() {
      return this.date?.children?.data?.length;
    },
    ticketPrice() {
      const basePriceAmount = eventGetters.getPriceAmount(
        this.date?.product?.data,
      );
      const formattedPrice = eventGetters.getPrice(this.date?.product?.data);
      const minTickets = this.date?.privateMinTickets;

      const formattedPrivateMinimumPrice = formatMoney(
        (basePriceAmount * minTickets) / 100,
        fnConvertLocaleIntoBCP47(this.$i18n.locale),
        "EUR",
        2,
      );

      return this.date?.dateType === "PRIVATE"
        ? formattedPrivateMinimumPrice
        : formattedPrice;
    },
  },
  methods: {
    formatDate,
    formatTime,
  },
};
</script>

<style lang="scss" scoped>
.kft-ticket-card {
  background: $white;
  display: flex;
  flex-wrap: wrap;
  padding: var(--spacer-4) var(--spacer-5);
  box-shadow: $--konfetti-sm-box-shadow;
  border-radius: 10px;
  position: relative;
  z-index: 1;

  ::v-deep .kft-bullet-points {
    font-weight: var(--font-weight--medium);
  }

  &__single-date,
  &__description {
    color: $description;
  }

  &__price {
    color: #343a3f;
    font-size: 14px;
  }
}
</style>
