import { Ref } from "@nuxtjs/composition-api";
import { UseKftContext } from "../context";
import { kftRef } from "../ssr";

function sharedRef<T>(value: T, key: string): Ref;
function sharedRef(key: string, _?): Ref;

function sharedRef<T>(value: T, key: string): Ref {
  const { $sharedRefsMap } = UseKftContext() as any;
  const givenKey = key || value;

  if ($sharedRefsMap.has(givenKey)) {
    return $sharedRefsMap.get(givenKey);
  }

  const newRef = kftRef(key ? value : null, givenKey as string);
  $sharedRefsMap.set(givenKey, newRef);

  return newRef;
}

export { sharedRef };
