/* eslint-disable  */

export default {
  faqGiftcard: {
    sections: [
      {
        title: "Häufige Fragen zum Gutschein",
        questions: [
          {
            question: "Was ist der konfetti Geschenkgutschein?",
            answer:
              "Der konfetti Geschenkgutschein ist für 4.500 einzigartige Kurse und Erlebnisse in ganz Deutschland und Österreich einlösbar. Der Gutschein kann für jeden Kurs, den wir anbieten, verwendet werden.",
          },
          {
            question: "Was macht den konfetti Gutschein besonders?",
            answer:
              "Mit dem konfetti Erlebnisgutschein verschenkst Du Vorfreude und eine schöne Zeit, die lange in Erinnerung bleiben wird. Das beweist, dass Du Dir wirklich Gedanken gemacht hast. Unter 4.500 verschiedenen Erlebnissen findet jeder das Geschenk, das Freude bereitet.",
          },
          {
            question: "Zu welchen Anlässen kann ich den Gutschein verschenken?",
            answer:
              "Der Gutschein kann zu jedem Anlass verschenkt werden. Ob als einzigartiges Geburtstagsgeschenk, Weihnachtsgeschenk, Hochzeitsgeschenk, Muttertagsgeschenk oder Mitarbeitergeschenk, der konfetti Gutschein zaubert jedem ein Lächeln ins Gesicht.",
          },
          {
            question: "Wie funktioniert die Bestellung des Gutscheins?",
            answer:
              "Wähle den Gutschein aus und bestimme den Gutscheinwert. Im Warenkorb kannst Du entscheiden, ob Du zusätzlich die Geschenkkarte bestellen möchtest. Nachdem Du den Kauf abgeschlossen hast, senden wir Dir den Gutschein zum Ausdrucken per E-Mail zu. Entscheidest Du Dich für die Geschenkkarte, senden wir Dir diese per Post.",
          },
          {
            question: "Wie löse ich den Gutschein ein?",
            answer:
              "Wähle auf gokonfetti.com ein Erlebnis, für welches Du den Gutschein einlösen möchtest. Suche einen Termin aus und füge diesen zum Warenkorb hinzu. Im Bezahlvorgang kann der Gutschein bei der Auswahl der Zahlungsmethode eingegeben werden. Entweder Du brauchst den gesamten Gutscheinwert auf oder behältst das restliche Guthaben für Dein nächstes Erlebnis. Wählst Du ein Event, für welches der Wert Deines Gutscheins nicht ausreicht, wird Dir der Restbetrag angezeigt, der noch zu bezahlen ist.",
          },

          {
            question: "Wie lange ist der Gutschein gültig?",
            answer: "Der Gutschein ist ab dem Kaufdatum für drei Jahre gültig.",
          },
          {
            question: "Kann ich mit dem Gutschein mehrere Kurse buchen?",
            answer:
              "Wenn Dein Gutschein den Preis der Kurse abdeckt, kannst Du so viele Kurse buchen, wie Du möchtest. Reicht das Guthaben nicht aus, wird Dir im Warenkorb angezeigt, wie hoch der Restbetrag ist, der noch beglichen werden muss.",
          },

          {
            question:
              "Was kann ich tun, wenn mein Wunsch-Kurs nicht mehr angeboten wird?",
            answer:
              'Es kann vorkommen, dass ein Kurs abgesagt werden muss, weil der Kursleiter wegen Krankheit ausfällt oder die Teilnehmer-Mindestanzahl nicht erreicht wurde. Natürlich hast Du dann die Möglichkeit, Dich für ein anderes Event anzumelden. Schreibe uns einfach eine E-Mail an hallo@gokonfetti.com und wir finden eine gemeinsame Lösung.',
          },

          {
            question: "Gibt es eine Geschenkkarte?",
            answer:
              "Du kannst zu Deinem Gutschein unsere hochwertige Geschenkkarte dazu buchen. Die Karte ist ein echter Hingucker und bereitet garantiert Freude! ",
          },
          {
            question: "Wie lang dauert die Lieferzeit für die Geschenkkarte?",
            answer:
              "Die Geschenkkarte übergeben wir noch am gleichen oder direkt am nächsten Werktag der Post. Wie lang die Lieferzeit beträgt, ist dann von der Post abhängig.",
          },
          {
            question: "Sind Gutscheine unpersönlich?",
            answer:
              "Mit dem konfetti Geschenkgutschein beweist Du, dass Du Dir wirklich Gedanken gemacht hast. Neben einem besonderen Erlebnis verschenkst Du gemeinsame Zeit und Vorfreude. Das macht den Geschenkgutschein zu einem ganz persönlichen Geschenk. ",
          },
        ],
      },
    ],
  },
};
