<template>
  <div class="kft-horizontal-card-list">
    <KftHeading
      v-if="title"
      :level="2"
      class="kft-heading--left mb-6"
      :title="title"
    />

    <KftHorizontalScrollWrapper :items-length="cardList.length">
      <component
        :is="card.link ? 'KftButton' : 'div'"
        v-for="(card, i) in cardList"
        :key="i"
        class="kft-horizontal-card-list__card kft-link--black"
        :link="card.link"
      >
        <KftUserProfileHeader
          class="kft-user-profile-header--transparent-background"
          :subtitle="card.subtitle"
          :title="card.title"
          :hide-image="card.imageUrl === null"
        >
          <template #image>
            <SfImage
              :alt="card.title"
              :src="card.imageUrl"
              class="kft-horizontal-card-list__card__image"
              fit="cover"
            />
          </template>
        </KftUserProfileHeader>
      </component>
    </KftHorizontalScrollWrapper>

    <div v-if="footerLinkText" class="overline mt-8 kft-link--black">
      <a :href="footerLink">{{ footerLinkText }} ></a>
    </div>
  </div>
</template>

<script>
import {
  KftHeading,
  KftButton,
  SfImage,
  KftUserProfileHeader,
} from "@konfetti-ui/vue";
import { KftHorizontalScrollWrapper } from "~/components";

export default {
  name: "KftHorizontalCardList",

  components: {
    KftHeading,
    KftButton,
    SfImage,
    KftUserProfileHeader,
    KftHorizontalScrollWrapper,
  },

  props: {
    cardList: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    footerLinkText: {
      type: String,
      required: false,
    },
    footerLink: {
      type: String,
      required: false,
    },
  },

  emits: ["click:card"],
};
</script>

<style lang="scss" scoped>
.kft-horizontal-card-list {
  &__card {
    box-shadow: $--konfetti-md-box-shadow;
    min-width: 290px;
    background-color: #fff !important;
    border-radius: $border-radius-md;
    text-align: left;
    padding: var(--spacer-2);
  }
}
</style>
