<template>
  <div
    id="algolia-federated-search"
    :class="{ reduced: reduced, fullwidth: fullWidth }"
  ></div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
import { onMounted, ref } from "@vue/composition-api";
import { useDebounced, useUiState } from "~/composables";
import {
  fnGetCitiesIndexName,
  fnGetFederatedSearchIndexName,
  fnGetCategoriesIndexName,
} from "~/helpers";

export default {
  name: "AlgoliaFederatedSearch",
  props: {
    reduced: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root: { $i18n, $config, $router, localePath, $device } }) {
    /**
     * HOOKS
     * */
    const searchClient = algoliasearch(
      $config.algolia.appId,
      $config.algolia.apiClientKey,
    );

    const { toggleFederatedSearchOpen } = useUiState();

    const isSearchResultsOpen = ref(false);
    const toggleIsSearchResultsOpen = () => {
      isSearchResultsOpen.value = !isSearchResultsOpen.value;
      toggleFederatedSearchOpen();
    };

    const { debounced } = useDebounced(750);

    const mountUrlParamsForLocality = (item) => {
      let geoloc = "";
      if (item?._geoloc) {
        geoloc = `geoloc=${item._geoloc.lat},${item._geoloc.lng}`;
      }

      const city = "city=" + encodeURIComponent(item.slug);

      return [geoloc, city].join("&");
    };

    onMounted(() => {
      const el = document.getElementById("algolia-federated-search");
      const searchQuery = new URL(window?.location)?.searchParams?.get(
        "searchquery",
      );

      if (el === null || el.childNodes.length > 0) {
        return;
      }

      autocomplete({
        container: "#algolia-federated-search",
        defaultActiveItemId: 0,
        openOnFocus: true,
        translations: {
          detachedCancelButtonText: $i18n.t("general.cancel"),
        },
        initialState: {
          query:
            new URL(window?.location)?.searchParams?.get("searchquery") ?? "",
        },

        onStateChange({ state }) {
          if (state.isOpen !== isSearchResultsOpen.value) {
            toggleIsSearchResultsOpen();
          }
        },

        render({ elements, render, html }, root) {
          const { eventCategories, events, localities } = elements;
          render(
            html`<div class="aa-PanelLayout aa-Panel--scrollable">
              <div class="aa-PanelSections">
                <div class="aa-PanelSection--left">
                  <div>${localities}</div>
                  <div class="aa-CategorySection">${eventCategories}</div>
                </div>
                <div class="aa-PanelSection--right">${events}</div>
              </div>
            </div> `,
            root,
          );
          if (props.fullWidth) {
            const resultsPanel = document.getElementsByClassName("aa-Panel");
            if (resultsPanel && resultsPanel[0]) {
              resultsPanel[0]?.classList?.add("aa-Panel-fullwidth");
            }
          }
        },

        getSources({ setIsOpen }) {
          return debounced([
            {
              sourceId: "localities",
              getItemInputValue: ({ item }) => item.query,
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: fnGetCitiesIndexName(),
                      query,
                      params: {
                        hitsPerPage: $device.isMobile ? 3 : 6,
                        attributesToSnippet: ["name:24"],
                        snippetEllipsisText: "…",
                        optionalWords: query,
                      },
                    },
                  ],
                });
              },
              getItemUrl({ item }) {
                // Adding empty space is a workaround to get navigator
                // hook working for Enter key
                return item.url + " ";
              },
              templates: {
                header({ html }) {
                  return html`<div>
                    <span class="aa-SourceHeaderTitle"
                      >${$i18n.t("general.cities")}</span
                    >
                    <div className="aa-SourceHeaderLine"></div>
                  </div>`;
                },
                item({ item, components, html }) {
                  return html` <div class="aa-ItemContent">
                    ${components.Highlight({
                      hit: item,
                      attribute: "name",
                    })}
                  </div>`;
                },
                noResults() {
                  return $i18n.t("multiselect.noResult");
                },
              },
              onSelect({ item }) {
                setIsOpen(false);
                /* considering the items we have now are only query suggestions */
                return $router.push(
                  localePath(`/search/?${mountUrlParamsForLocality(item)}`),
                );
              },
            },
            {
              sourceId: "events",
              getItemInputValue: ({ item }) => item.query,
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: fnGetFederatedSearchIndexName($i18n.locale),
                      query,
                      params: {
                        hitsPerPage: 4,
                        attributesToSnippet: ["name:24"],
                        snippetEllipsisText: "…",
                        aroundLatLngViaIP: true,
                        aroundRadius: "all",
                        aroundPrecision: [
                          { from: 0, value: 15000 },
                          { from: 30000, value: 30000 },
                          { from: 150000, value: 100000 },
                        ],
                      },
                    },
                  ],
                });
              },
              getItemUrl({ item }) {
                // Adding empty space is a workaround to get navigator
                // hook working for Enter key
                return item.url + " ";
              },
              templates: {
                header({ html }) {
                  return html`<div>
                    <span class="aa-SourceHeaderTitle"
                      >${$i18n.t("general.events")}</span
                    >
                    <div className="aa-SourceHeaderLine"></div>
                  </div>`;
                },
                item({ item, html }) {
                  return html` <div class="aa-ItemContent">
                    <img
                      class="aa-ItemIcon"
                      src="${item.images[0].thumb}"
                      alt="${item.label}"
                    />
                    <span class="aa-ItemContentTitle"> ${item.title} </span>
                  </div>`;
                },
                noResults() {
                  return $i18n.t("general.select.noResultsFound");
                },
              },
              onSelect({ item }) {
                setIsOpen(false);
                return item?.supplier === "konfetti Gutscheine"
                  ? $router.push(localePath(`/giftcard/${item.permalink}`))
                  : $router.push(localePath(`/e/${item.permalink}`));
              },
            },
            {
              sourceId: "eventCategories",
              getItemInputValue: ({ item }) => item.query,
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: fnGetCategoriesIndexName($i18n.locale),
                      query,
                      params: {
                        hitsPerPage: $device.isMobile ? 3 : 6,
                        attributesToSnippet: ["name:24"],
                        snippetEllipsisText: "…",
                        optionalWords: query,
                      },
                    },
                  ],
                });
              },
              getItemUrl({ item }) {
                // Adding empty space is a workaround to get navigator
                // hook working for Enter key
                return item.url + " ";
              },
              templates: {
                header({ html }) {
                  return html`<div>
                    <span class="aa-SourceHeaderTitle"
                      >${$i18n.t("general.categories")}</span
                    >
                    <div className="aa-SourceHeaderLine"></div>
                  </div>`;
                },
                item({ item, components, html }) {
                  return html` <div class="aa-ItemContent">
                    ${components.Highlight({
                      hit: item,
                      attribute: "name",
                    })}
                  </div>`;
                },
                noResults() {
                  return $i18n.t("multiselect.noResult");
                },
              },
              onSelect({ item }) {
                setIsOpen(false);
                return $router.push(localePath(`/c/${item.slug}`));
              },
            },
          ]);
        },
        placeholder: $i18n.t("search.algoliaFederatedSearch.placeholder"),
        onSubmit({ state }) {
          return $router.push(
            localePath("/search/?searchquery=" + state?.query),
          );
        },
        navigator: {
          navigate({ state }) {
            return $router.push(
              localePath("/search/?searchquery=" + state?.query),
            );
          },
        },
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~/assets/components/atoms/KftAlgoliaAutocomplete.scss";
</style>
