/* eslint-disable camelcase */

import { UseKftContext } from "@konfetti-core/core";

/** @name fnGetISO2Locale
 ** @description Transforms a internal locale (de-de) into the ISO2 format (de)
 ** @param locale user's locale, internal format
 **/
const fnGetISO2Locale = (locale: string): string => {
  const ISO2LocaleParts = locale.split("-");

  return ISO2LocaleParts[0] || "de";
};

const fnConvertLocaleIntoBCP47 = (locale: string): string => {
  const ISO2LocaleParts = locale.split("-");

  return ISO2LocaleParts.length > 1
    ? `${ISO2LocaleParts[0]}-${ISO2LocaleParts[1].toUpperCase()}`
    : "de-DE";
};

/** @name fnGetAvailableLanguages
 ** @desc Returns the list of available languages in Konfetti **/
const fnGetAvailableLanguages = (): any => {
  const context = UseKftContext();

  return {
    en_US: context.i18n.t("general.languages.english"),
    de_DE: context.i18n.t("general.languages.german"),
    // fr_FR: context.i18n.t('general.languages.french'),
    // es_ES: context.i18n.t('general.languages.spanish'),
    // ru_RU: context.i18n.t('general.languages.russian'),
    // it_IT: context.i18n.t('general.languages.italian')
  };
};

export { fnGetAvailableLanguages, fnGetISO2Locale, fnConvertLocaleIntoBCP47 };
