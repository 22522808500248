import { sharedRef, UseKftContext, onSSR } from "@konfetti-core/core";

export const useAsyncDataState = (id) => {
  const asyncDataState = sharedRef(null, `${id}-async-data-state`);

  const { ssrContext } = UseKftContext();

  const mountAsyncDataState = () => {
    const state = {};
    if (!ssrContext) {
      return;
    }

    asyncDataState.value =
      ssrContext.asyncData[Object.keys(ssrContext.asyncData)[0]] || null;
  };

  onSSR(() => {
    mountAsyncDataState();
  });

  return {
    asyncDataState,
    mountAsyncDataState,
  };
};
