/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Logger, sharedRef } from "@konfetti-core/core";
import { computed } from "@vue/composition-api";
import { useStripe } from "@konfetti/composables";
import { ErrorStoreErrorType, useErrorStore } from "../useErrorStore";

/**
 * @name useStripeCreditCard
 * @desc This module is responsible for dealing with stripe CREDIT CARD checkout
 *
 * @param id used to define the shared Refs
 * @param hasAccessToWindowObject used to block usage of window objects in iFrames
 * @param customUserToken used if we do not have access to window object and we're forcing the user token to the requests
 * */
export const useStripeCreditCard = (
  id: string,
  hasAccessToWindowObject: boolean,
  customUserToken = null,
): any => {
  /* @konfetti/composables integration */
  const {
    order,
    sendOrder,
    error: orderError,
  } = useStripe(
    `theme/useOrder/useStripeCreditCard-${id}`,
    hasAccessToWindowObject,
  );

  /* card and elements are refs from the StripeElements component */
  const card = sharedRef(null, `useStripeCreditCard/card-${id}`);
  const elements = sharedRef(null, `useStripeCreditCard/elements-${id}`);

  /* Loading and error indicators */
  const loading = sharedRef(false, `useStripeCreditCard/loading-${id}`);
  const error = sharedRef(null, `useStripeCreditCard/error-${id}`);

  /* Used when an action is required - mainly 3d secure auth */
  const code = sharedRef(null, `useStripeCreditCard/code-${id}`);
  const { handleError } = useErrorStore();

  const fnSubmitOrder = async (
    params,
    token,
    paymentIntentId,
    useBalance = true,
  ) => {
    loading.value = true;
    Logger.debug("checkout-payment-pending:stripe");

    await sendOrder(
      token,
      params.items,
      params.shipping,
      params.invoice,
      params.coupon,
      paymentIntentId,
      customUserToken,
      params.external,
      "CREDIT_CARD",
      useBalance,
    );

    /* 406 code means the user needs to go through 3D Authentication
     https://stripe.com/docs/payments/3d-secure */
    if (order.value.code === 406) {
      Logger.error("checkout-payment-attempt:stripe", { order: order.value });
      code.value = 406;

      return;
    }

    if (orderError.value !== null) {
      Logger.error("checkout-payment-attempt:stripe", {
        error: orderError.value,
      });
      error.value = orderError.value;
      handleError({ type: ErrorStoreErrorType.OrderError, error: error.value });

      return;
    }

    Logger.debug("checkout-payment-successful:stripe", { order: order.value });
    code.value = 200;
    error.value = null;
    loading.value = false;
  };

  const fnOnStripe3DSAuthenticationSuccess = async (
    params,
    token,
    paymentIntent,
  ) => {
    loading.value = true;
    await fnSubmitOrder(params, token, paymentIntent.id, false);
    loading.value = false;
  };

  const fnOnStripePaymentError = (error) => {
    loading.value = true;
    Logger.error("checkout-payment-attempt:stripe", { error });
    loading.value = false;
  };

  return {
    card,
    elements,
    order,

    loading: computed(() => loading.value),
    error: computed(() => error.value),
    code: computed(() => code.value),

    fnSubmitOrder,
    fnOnStripe3DSAuthenticationSuccess,
    fnOnStripePaymentError,
  };
};
