export const getFromStrapiApi = (context, reqPath) => {
  const { $kft, $config, app } = context;

  const url = new URL(reqPath, $config.strapiApiUrl);

  return $kft.$konfetti.client.get(url.href, {
    headers: {
      Authorization: `Bearer ${$config.strapiApiKey}`,
      "Accept-Language": app.i18n.locale,
    },
  });
};

export const getFromKftApi = (context, reqPath) => {
  const { $kft, $config, app } = context;
  const localeArr = app.i18n.locale.split("-");
  const localeBackend = localeArr[0] + "_" + localeArr[1].toUpperCase();

  const url = new URL(reqPath, $config.apiUrl);

  return $kft.$konfetti.client.get(url.href, {
    headers: {
      "Accept-Language": localeBackend,
    },
  });
};
