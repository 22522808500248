<template functional>
  <div
    v-bind='data.attrs'
    v-on='listeners'
    :class="[data.class, data.staticClass, 'kft-chevron']"
    :style='[data.style, data.staticStyle]'
  >
    <!-- @slot Custom chevron markup -->
    <slot>
      <span class='kft-chevron__bar kft-chevron__bar--left'></span>
      <span class='kft-chevron__bar kft-chevron__bar--right'></span>
    </slot>
  </div>
</template>
<script>
import { focus } from "../../../utilities/directives";

export default {
  name: "KftChevron",
  directives: {
    focus
  }
};
</script>
<style lang='scss'>
@import "~@konfetti-ui/shared/styles/components/atoms/KftChevron.scss";
</style>
