/* eslint-disable  */

export default {
  product: {
    shareButton: "Teilen",
    seeAllImages: "Alle Fotos anzeigen",
    eventsFromTheSameSupplier: "Veranstaltungen vom selben Anbieter",
    similarEvents: "Ähnliche Kurse in Deiner Nähe",
    similarOnlineEvents: "Ähnliche Online Kurse und Workshops",

    giftcardEventsFromTheSameSupplier: "Andere Gutscheine entdecken",
    giftcardSimilarEvents: "Beliebte Kurse für Deinen Gutschein",
    giftcardSimilarOnlineEvents: "Beliebte Online Kurse für Deinen Gutschein",

    gallery: {
      shareModal: {
        shareEventTitle: "Dieses Event teilen",
        shareGiftcardTitle: "Dieses Geschenkgutschein teilen",
        copyLinkButton: "Link kopieren",
        copiedLinkButton: "In Zwischenablage kopiert",
        shareEventMessage:
          "Hier ist ein Event, das ich bei konfetti gefunden habe",
        shareGiftcardMessage:
          "Hier ist ein Geschenkgutschein, den ich bei konfetti gefunden habe",
        shareEventSubject: "Schau Dir folgendes Event an",
        shareGiftcardSubject: "Schau Dir diesen Geschenkgutschein an",
      },
    },

    dateRequest: {
      ticketCardTitle: "Für mehr Termine",
      ticketCardActionLabel: "Termin anfragen und Warteliste",
      ticketCardDescription:
        "Frage einen Termin an oder trage Dich in die Warteliste ein und werde bei neuen Terminen benachrichtigt.",
      sidebarTitle: "Termin anfragen",

      eventSuggestions: {
        title: "Weitere Kurse, die Dich interessieren könnten",
        requestSent: "Deine Anfrage wurde abgesendet",
        seeMoreButton: "Weitere Kurse entdecken",
        seeMoreNotification: "Hier sind weitere Vorschläge für Dich",
      },
    },

    waitlist: {
      sidebarTitle: "Warteliste",
    },

    status: {
      soldOut: "Ausverkauft",
      open: "Verfügbar",
      expired: "Geschlossen",
      fewTicketsLeft: "Wenige Tickets",
    },

    reviews: {
      reviewAmountLabel: "Neu | {n} Bewertung | {n} Bewertungen",
      ratingLabel: "Neu | {n} | {n} ",
      newEventLabel: "Neu",
      supplierVerifiedLabel: "Geprüfter Partner",
      readMoreLabel: "mehr lesen",
    },
    show: {
      noAvailableDatesBox: {
        title: "Für mehr Termine",
        altTitle: "Für neue Termine benachrichtigt werden",
        ctaTextOnlyProposeDate: "Termin anfragen",
        ctaTextOnlyWaitlist: "Auf die Warteliste",
        tooltipText:
          "Termine anfragen oder auf Warteliste für neue Termine eintragen",
      },

      icons: {
        duration: "Dauer",
        durationDefaultContent: "auf Anfrage | {n} Stunde | {n} Stunden",
        whatsIncluded: "Welche Leistungen inklusive sind",
        teamEventsAndGiftCards: "Teamevents und private Gruppen",
        locationSummary: "Location",
        languages: {
          title: "Kurssprachen",
          list: {
            de_DE: "Deutsch",
            en_US: "Englisch",
            pt_BR: "Portugiesisch",
            es_ES: "Spanisch",
            ja_JP: "Japanisch",
            fr_FR: "Französisch",
          },
        },
      },

      giftcardIcons: {
        duration: "Termine",
        durationContent: "Flexible Terminwahl rund ums Jahr",
        whatsIncluded: "Das optimale Geschenk für",
        whatsIncludedContent: "Gustschein für einen Kochkurs Deiner Wahl",
        teamEventsAndGiftCards: "Wofür der Gutschein einlösbar ist",
        teamEventsAndGiftCardsContent: "Auch für private Events gültig",
        locationSummary: "Locations",
        locationContent:
          "Über 145 Standorte und Kochschulen in ganz Deutschland, Österreich und online",
        languages: {
          title: "Kurssprachen",
          content: "Deutsch, Englisch",
        },
      },

      privateEventInfo: {
        title: "Wie funktioniert ein privates Event?",
      },

      basicDetails: {
        description: "Kursbeschreibung",
        giftcardDescription: "Die wichtigsten Infos zum Gutschein",
        detailsToRemember: "Wichtige Details",
        whatToBring: "Was Du mitbringen solltest",
        giftcardWhatToBring: "Das beinhaltet der Gutschein",
        whatIsIncluded: "Welche Leistungen inklusive sind",
        giftcardWhatIsIncluded: "So erhältst Du den Gutschein",
      },

      map: {
        openingTimes: "Öffnungszeiten",
        address: "Adresse: Hier findet der Kurs statt.",
      },

      checkout: {
        availability: "Verfügbarkeit",
        teamEventMessage: "Für Teamevents und private Gruppen.",
        noAvailableDates: "Klicken und Warteliste beitreten für neue Termine",
        quantity: "Anzahl Tickets",
        privateQuantity: "Anzahl Teilnehmer",
        privateQuantityPlaceholder: "Auswählen",
        privateQuantityNoTicketsSelected:
          "Teile uns die Anzahl der Teilnehmer mit",
        book: "In den Warenkorb",
        bookGiftcard: "Gutschein kaufen",
        giftcardPageBookGiftcard: "In den Warenkorb",
        selectDate: "Termin auswählen",
        eventsThisDay:
          "kein Kurs an diesem Tag | {count} Kurs an diesem Tag | {count} Kurse an diesem Tag",
        availableTimes: "Termin auswählen",
        noMoreDatesAvailable: "Neue Termine bald verfügbar",
        newDatesSoon: {
          title: "Zu wenig Kurse in {city}?",
          content:
            "Tipp: Werfe einen Blick auf Kurse und Workshops in der Nähe",
        },
        loadingMoreDates: "Loading more dates",
        giftcard: {
          sectionTitle: "Als Gutschein verschenken",
          giftcardPageSectionTitle: "Gutschein kaufen",
          giftExperiences: "Das perfekte Geschenk",
          forAnyKonfetti: "Einlösbar für über 4.500 Kurse",
          validity: "Ab Kauf 3 Jahre lang gültig",
          options: "Geschenkgutschein Optionen",
          amount: "Wert",
          bookAction: "Als Geschenkgutschein buchen",
        },
        embeddedGiftcard: {
          iconsLabels: {
            first: "Ab Kauf 3 Jahre lang gültig",
            second: "Beschenkte wählt Kurs und Termin",
            third: "Spaß garantiert :)",
          },
        },

        teamEvent: {
          title: "Teamevent anfragen",
          titleWithPrice: "Teamevent ab {price} pro Person",
          description:
            "Für Teamevents oder private Gruppen. Individuelle Termine verfügbar. Unverbindliches Angebot erhalten.",
          contactUs: "Teamevent anfragen",
        },
        info: {
          publicEvent:
            "Buche ein oder mehrere Tickets für einen öffentlichen Kurs.",
          privateEvent:
            "Buche den Kurs für eine private Gruppe und gebe die Anzahl der Teilnehmer ein. Der Preis pro Gruppe startet bei {price}.",
          giftcard:
            "Der Gutschein kann für alle Kurse eingelöst werden und ist 3 Jahre gültig.",
        },
      },
    },

    card: {
      addToCart: "Tickets kaufen",
      duration: "Dauer: {duration}",
      durationDefaultContent: "auf Anfrage",
      next: "Nächster",
    },

    teamEventsForm: {
      title: "Jetzt unverbindlich anfragen!",
      noscript:
        "Falls Du kein Formular siehst, wird dieses durch die Einstellungen Deines Browsers oder Adblocker blockiert. Nutze einen anderen Browser oder schreibe uns an hallo@gokonfetti.com",
    },

    availableQuantityModal: {
      minPublicTicketLimitText: "Mindestanzahl an Tickets: 1",
      minPrivateTicketLimitText:
        "Mindestanzahl an Tickets: {privateMinTicketsQty}.",
      maxTicketLimitText:
        "Maximale Anzahl an Tickets: {maxTicketsQty}. Stelle eine Anfrage für größere Gruppen.",
    },

    preview: {
      alert: {
        title: "Dies ist eine Vorschau",
        content: "Dies ist eine Vorschau und das Event ist nicht indexiert.",
      },
    },

    subscribeToEventUpdatesModal: {
      requestDateButtonLabel: "Konkreten Termin anfragen",
      firstStep: {
        title: "Kein passender Termin dabei?",
        description:
          "Trage Dich jetzt auf die Warteliste ein oder frage unten konkrete Termine an.",
        dateRequestTitle: "Neue Termine anfragen",
        submitButtonLabel: "Warteliste beitreten",
        finishedButton: "Weiter stöbern",
        requestDatesButtonLabel: "Terminvorschläge absenden",
        requestDatesDoneButtonLabel: "Anfrage gesendet",
        waitlistSectionTitle: "Auf Warteliste eintragen",
        waitlistSectionTitleTooltip:
          "Trage Deine E-Mail ein und wir melden uns wenn es neue Termine gibt.",
        requestDatesSectionSubtitle: "Schlage bis zu drei Termine vor",
        requestDateFormat: "DD.MM.YYYY HH:mm",
        requestDatesDescription: "Je mehr Terminvorschläge desto besser.",
        form: {
          email: {
            label: "E-Mail",
            placeholder: "erika@mustermann.de",
          },
          phone: {
            label: "Telefon für Rückfragen (optional)",
            placeholder: "+49 123 456 789",
          },
          notifySimilar: {
            label:
              "Mit Klick auf 'Warteliste beitreten', stimme ich zu bei neuen Terminen kontaktiert zu werden.",
          },
          name: {
            label: "Vorname",
            placeholder: "Erika",
          },
          surname: {
            label: "Nachname",
            placeholder: "Mustermann",
          },
          numberOfTickets: {
            label: "Anzahl Tickets",
            placeholder: "4",
          },
          dateType: {
            label: "Als private Veranstaltung anfragen",
          },
          date1: {
            label: "Termin 1",
            placeholder: "auswählen",
          },
          date2: {
            label: "Termin 2",
            placeholder: "auswählen",
          },
          date3: {
            label: "Termin 3",
            placeholder: "auswählen",
          },
          time1: {
            label: "Uhrzeit 1",
            placeholder: "auswählen",
          },
          time2: {
            label: "Uhrzeit 2",
            placeholder: "auswählen",
          },
          time3: {
            label: "Uhrzeit 3",
            placeholder: "auswählen",
          },
        },
      },
      secondStep: {
        title: "Erfolgreich eingetragen!",
        description: "",
        submitButtonLabel: "Fenster schließen",
        iconBlockText: "Geschafft! Wir haben Deine Anfrage erhalten.",
        requestDatesSuccessMessage:
          "Geschafft! Wir haben Deine Anfrage erhalten.",
      },
    },

    ticketCardsSection: {
      title: "Verfügbare Termine",
      perGroup: "Pro Gruppe",
      addToCart: "In den Warenkorb",
    },
  },
};
