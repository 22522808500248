<template>
  <div v-if="filters && filters.locality && filters.locality.name">
    {{
      $t("search.searchResults.nResultsInCity", {
        n: hitsLength,
        locality: filters.locality.name,
      })
    }}
  </div>
  <div v-else-if="filters.categories && filters.categories.length > 0">
    {{
      $t("search.searchResults.nResultsForCategory", {
        n: hitsLength,
        category: filters.categories[0],
      })
    }}
  </div>
  <div v-else-if="filters.searchquery">
    {{
      $t("search.searchResults.nResultsForSearchQuery", {
        n: hitsLength,
        searchQuery: filters.searchquery,
      })
    }}
  </div>
  <div v-else>
    {{
      $t("search.searchResults.nResultsEverywhere", {
        n: hitsLength,
      })
    }}
  </div>
</template>
<script>
export default {
  name: "SearchResultsCount",
  props: {
    hitsLength: {
      type: Number,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
};
</script>
