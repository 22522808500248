var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kft-checkbox",class:{
    'is-active': _vm.isChecked,
    'is-disabled': _vm.disabled,
    'has-error': !_vm.valid,
    'is-required': _vm.required,
  },attrs:{"data-testid":_vm.name}},[_c('label',{staticClass:"kft-checkbox__container",class:{
      'align-items-center': _vm.alignCenter,
      'align-items-start': !_vm.alignCenter,
    }},[_c('input',{directives:[{name:"focus",rawName:"v-focus"}],staticClass:"kft-checkbox__input",attrs:{"disabled":_vm.disabled,"name":_vm.name,"type":"checkbox"},domProps:{"checked":_vm.isChecked,"value":_vm.value},on:{"change":_vm.inputHandler}}),_vm._v(" "),_vm._t("checkmark",function(){return [_c('div',{staticClass:"kft-checkbox__checkmark",class:{ 'kft-checkbox__checkmark is-active': _vm.isChecked }},[(_vm.isChecked)?_c('i',{staticClass:"bi bi-check",staticStyle:{"color":"white"}}):_vm._e()])]},null,{ isChecked: _vm.isChecked, disabled: _vm.disabled }),_vm._v(" "),_vm._t("label",function(){return [(_vm.label)?_c('div',{staticClass:"kft-checkbox__label"},[_vm._v(_vm._s(_vm.label))]):_vm._e()]},null,{ label: _vm.label, isChecked: _vm.isChecked, disabled: _vm.disabled })],2),_vm._v(" "),_c('div',{staticClass:"kft-checkbox__message"},[_c('transition',{attrs:{"name":"sf-fade"}},[(!_vm.disabled)?_vm._t(_vm.computedMessageSlotName,function(){return [_c('div',{class:_vm.computedMessageClass},[_vm._v(_vm._s(_vm.computedMessage))])]},null,{ computedMessage: _vm.computedMessage }):_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }