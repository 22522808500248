











































import { KftLink, SfMenuItem } from "@konfetti-ui/vue";

export default {
  name: "KftHeaderNavigationItem",
  components: {
    KftLink,
    SfMenuItem,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    link: {
      type: [String, Object],
      default: null,
    },
  },
};
