<template>
  <div
    v-if="teameventPagesLinks"
    :class="{ 'text-center': $device.isMobile }"
    class="useful-links"
  >
    <i class="useful-links__icon bi bi-check-circle-fill d-block d-md-none"></i>
    <h2 class="kft-heading--left useful-links__heading my-5">
      {{ teameventPagesLinks.title }}
    </h2>
    <div class="row">
      <div
        v-for="(item, i) in teameventPagesLinks.items"
        :key="`link-${item.label}-tab-links-${i}`"
        :class="{
          'col-4': $device.isDesktopOrTablet,
          'col-6': !$device.isDesktopOrTablet,
        }"
      >
        <KftLink :link="localePath(item.link)" class="mb-5"
          >{{ item.label }}
        </KftLink>
      </div>
    </div>
  </div>
</template>

<script>
import { KftLink } from "@konfetti-ui/vue";
import { useStrapiSingleType } from "@konfetti/composables";
import { defineComponent, computed } from "@nuxtjs/composition-api";
import { onSSR } from "@konfetti-core/core";

export default defineComponent({
  components: {
    KftLink,
  },

  setup(props, { root: { $i18n } }) {
    const { singleType: teameventPagesLinksFooterData, loadSingleType } =
      useStrapiSingleType("teamevent-pages-links-footer");

    onSSR(async () => {
      if (!teameventPagesLinksFooterData.value) {
        await loadSingleType(
          `teamevent-pages-links-footer?locale=${$i18n.locale}&populate[teameventPages][populate]=*`,
          {
            locale: $i18n.locale,
          },
        );
      }
    });

    const teameventPagesLinks = computed(() => {
      const data =
        teameventPagesLinksFooterData?.value?.data?.attributes
          ?.teameventPages[0];
      return data;
    });

    return {
      teameventPagesLinks,
    };
  },
});
</script>
