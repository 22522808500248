



































import { KftButton } from "@konfetti-ui/vue";
import { ref } from "@vue/composition-api";
import { withTrailingSlash } from "~/helpers/url";

export default {
  name: "LocaleSelector",
  components: {
    KftButton,
  },
  setup(props, { root: { $i18n, $cookies } }) {
    const { locales, locale } = $i18n;
    const localeLabel = locales.find((l) => l.code === locale)?.label;

    const isLocaleMenuOpen = ref(false);

    const removeLocaleCookies = () => {
      // This ensures the correct cookie is sent for the next request
      const cookiesToRemove = ["kft-currency", "kft-locale", "kft-country"];

      cookiesToRemove.forEach((key) => {
        $cookies.remove(key);
      });
    };

    return {
      locales,
      locale,
      localeLabel,
      isLocaleMenuOpen,

      withTrailingSlash,
      removeLocaleCookies,
    };
  },
};
