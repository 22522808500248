import KftArticleQuote from "../molecules/KftArticleQuote/KftArticleQuote";

export default {
  props: {
    componentName: {
      type: [Object, String],
      default: "div",
    },
    props: {
      type: Object,
      default: () => {},
    },
  },
  render(h) {
    const componentMap = {
      [KftArticleQuote.name]: KftArticleQuote,
      div: "div",
    };

    return h(
      componentMap[this.componentName],
      { props: this.props },
      this.$slots.default,
    );
  },
};
