/* eslint-disable  */

export default {
  survey: {
    instagram: 'Instagram',
    tiktok: 'TikTok',
    facebook: 'Facebook',
    pinterest: 'Pinterest',
    googleBing: 'Google / Bing',
    blogMagazine: 'Blog / Magazine',
    friend: 'Freund oder Freundin',
    other: 'Sonstiges',
    otherInputLabel: 'Wo hast Du von uns gehört? (optional)',

    surveyAnsweredMessage: {
      title: 'Dankeschön!',
      message: 'Vielen Dank für die Beantwortung der Umfrage, wir wünschen Ihnen viel Spaß mit Konfetti!'
    }

  }
};
