<template>
  <div id="paypal-button-container" data-test-id="submit-paypal-button" />
</template>

<script>
/* eslint-disable camelcase */

import { useCart, useOrder, usePaypal } from "@konfetti/composables";
import { computed, onMounted, watch } from "@vue/composition-api";
import { useErrorStore, ErrorStoreErrorType } from "~/composables";

const loadScript = (url, callback) => {
  const el = document.querySelector(`script[src="${url}"]`);
  if (!el) {
    const s = document.createElement("script");
    s.setAttribute("src", url);
    s.setAttribute("data-namespace", "paypal_sdk");
    s.onload = callback;
    document.head.insertBefore(s, document.head.firstElementChild);
  }
};

export default {
  name: "KftPaypalCheckout",

  props: {
    isValid: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    shippingDetails: {
      type: [Object, null],
      default: null,
    },
    invoiceDetails: {
      type: [Object, null],
      default: null,
    },
    external: {
      type: Boolean,
      default: false,
    },
    useBalance: {
      type: Boolean,
      default: true,
    },
  },

  emits: {
    success: null,
    fail: null,
    validate: null,
  },

  setup(props, { emit, root: { $config } }) {
    let paypalActions = null;

    /**
     * VARIABLES
     * */
    const { cart, loadCart } = useCart("general-instance");
    const { coupon } = useOrder("general-instance", true);
    const { error, order, sendOrder, captureOrder } =
      usePaypal("general-instance");
    const { handleError } = useErrorStore();

    const renderSdk = () => {
      if (typeof window.paypal_sdk === "undefined") {
        return;
      }

      try {
        window.paypal_sdk
          .Buttons({
            style: {
              // label: 'checkout'
              // label: 'pay
            },

            onClick: (data, actions) => {
              emit("validate", {
                data,
                actions,
              });
            },

            onInit: (data, actions) => {
              paypalActions = actions;
              if (props.isValid) {
                actions.enable();
              } else {
                actions.disable();
              }
            },

            /**
             * Set up the order in the backend,
             * this will create the order intent and save as PENDING in the DB
             *  */
            createOrder: async () => {
              await sendOrder(
                props.items,
                props.shippingDetails,
                props.invoiceDetails,
                coupon.value ? coupon.value.data.coupon.id : null,
                props.external,
                props.useBalance,
              );

              if (error.value !== null) {
                emit("fail", error);
                handleError({
                  type: ErrorStoreErrorType.PaypalOrderError,
                  error: error.value,
                });
                console.error(error.value);
              }

              return order.value?.meta?.custom?.paypal_order_id || null;
            },

            /**
             * After the confirmation, the order will be captured in the backend
             *  */
            onApprove: async (data) => {
              await captureOrder(data.orderID);

              if (error.value !== null) {
                emit("fail", error);
                console.error(error.value);
              }

              emit("success", data);
            },
          })
          .render("#paypal-button-container");
      } catch (e) {
        console.error(e);
      }
    };

    const loadPaypal = () => {
      loadScript(
        `https://www.paypal.com/sdk/js?client-id=${$config.paypalClientKey}&currency=EUR&locale=en_US&enable-funding=paypal&disable-funding=card,credit,paylater,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo`,
        () => renderSdk(),
      );
    };

    /**
     * HOOKS
     * */
    onMounted(async () => {
      await loadCart();
      await loadPaypal();
      await renderSdk();
    });

    watch(
      () => props.isValid,
      (newValue) => {
        if (newValue === true) {
          paypalActions.enable();

          return;
        }

        paypalActions.disable();
      },
    );

    return {
      cart,
      isFormValid: computed(() => props.isValid),
    };
  },
};
</script>

<style lang="scss">
#paypal-button-container {
  background-color: #ffc439;
  border-radius: 4px;
  display: flex;
  justify-content: center;

  > div {
    z-index: 0;
  }
}
</style>
