<template>
  <div class="modal-header modal-header--no-border">
    <div class="modal-title">
      <slot name="title" />
    </div>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      @click="$emit('close')"
    ></button>
  </div>
</template>

<script>
export default {
  name: "KftModalSimpleHeader",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    return {};
  },
};
</script>
