import Url from "url-parse";
import consola from "consola";

const util = require("util");
const fs = require("graceful-fs");
const readFile = util.promisify(fs.readFile);

export default async (req, res, next) => {
  return readFile(require.resolve("./legacy-route-map.json")).then((file) => {
    const parsedRedirects = JSON.parse(file);
    const legacyPermalink = `https://gokonfetti.com/event/${req.url.replace(
      /\//g,
      "",
    )}/`;

    if (parsedRedirects[legacyPermalink] !== undefined) {
      consola.log("Redirecting route...", legacyPermalink);

      const newUrl = new Url(parsedRedirects[legacyPermalink]);
      res.writeHead(301, {
        location: newUrl.href,
      });

      res.end();
    } else {
      next();
    }
  });
};
