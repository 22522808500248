import { PriceItem } from "../types";

export type OrderItemCharacteristicsDescription = {
  id: string;
  type: string;
  focus: string;
  title: string;
  address: string | null;
  subtitle: string;
  timezone: string;
  permalink: string;
  description: string;
  what_to_bring: string;
  covid_19_summary: string;
  duration_summary: string | null;
  what_is_included: string;
  what_is_included_summary: string;
  team_events_and_gift_cards_summary: string;
};

export type OrderItemCharacteristics = {
  date: string;
  start_time: string;
  end_time: string;
  supplier: {
    name: string;
    email: string;
    phone: string;
    website: string;
    timezone: string | null;
    description: string;
  };
  description: OrderItemCharacteristicsDescription;
};

export type OrderItem = {
  id: string;
  object: string;
  type: string | null;
  title: string;
  quantity: number;
  status: string;
  review_exists: boolean;
  review_url: string;
  created_at: string;
  updated_at: string;
  readable_created_at: string;
  readable_updated_at: string;
  real_id: number;

  price: PriceItem;
  total: PriceItem;
  characteristics: OrderItemCharacteristics;
};

export const getId = (orderItem: OrderItem): string => {
  return orderItem.id;
};

export const getTitle = (orderItem: OrderItem): string => {
  return orderItem.title;
};

export const getQuantity = (orderItem: OrderItem): number => {
  return orderItem.quantity;
};

export const getDate = (orderItem: OrderItem): string => {
  return orderItem.characteristics.date;
};

export const getStartTime = (orderItem: OrderItem): string => {
  return orderItem.characteristics.start_time;
};

export const getEndTime = (orderItem: OrderItem): string => {
  return orderItem.characteristics.end_time;
};

export const getStartDateTime = (orderItem: OrderItem): string => {
  return `${orderItem.characteristics.date} ${orderItem.characteristics.start_time}`;
};

export const getEndDateTime = (orderItem: OrderItem): string => {
  return `${orderItem.characteristics.date} ${orderItem.characteristics.end_time}`;
};

export const getTotalFormatted = (orderItem: OrderItem): string => {
  return orderItem.total.formatted;
};

export const isGiftcard = (orderItem: OrderItem): boolean => {
  return orderItem.type === "GIFT_CARD";
};

export const isPrivate = (orderItem: OrderItem): boolean => {
  return orderItem.type === "PRIVATE_EVENT";
};

export const isEvent = (orderItem: OrderItem): boolean => {
  return orderItem.type === "EVENT";
};

export const orderItemGetters = {
  getId,
  getTitle,
  getQuantity,
  getDate,
  getStartTime,
  getEndTime,
  getTotalFormatted,
  isGiftcard,
  isPrivate,
  isEvent,
  getStartDateTime,
  getEndDateTime,
};
