import { sharedRef } from "@konfetti-core/core";
import { loadStripe } from "@stripe/stripe-js/pure";
import { computed, onUnmounted } from "@vue/composition-api";

/**
 * @name useStripeInstance
 * @description All composables that uses stripe should use this stripe instance
 * in order to re-use code
 * */

const useStripeInstance = (id: string): any => {
  const stripeLoaded = sharedRef(false, `useStripeInstance/stripeLoaded-${id}`);
  const stripeInstance = sharedRef(
    null,
    `useStripeInstance/stripeInstance-${id}`,
  );
  const loading = sharedRef(false, `useStripeInstance/loading-${id}`);
  const error = sharedRef(null, `useStripe/error-${id}`);

  const fnResetStripeInstance = () => {
    stripeInstance.value = null;
    stripeLoaded.value = false;
    error.value = null;
    loading.value = false;
  };

  const fnLoadStripe = async (key: string, options: object) => {
    if (stripeLoaded.value) {
      return;
    }

    loading.value = true;
    const stripePromise = loadStripe(key, options);

    await stripePromise
      .then((instance) => {
        stripeInstance.value = instance;
      })
      .catch((err) => {
        error.value = err;
      });

    stripeLoaded.value = true;
    loading.value = false;
  };

  onUnmounted(() => {
    // If we don't reset stripe instance, the user can't checkout a second time
    // without reloading the screen (for credit card, at least)
    fnResetStripeInstance();
  });

  return {
    stripeInstance,

    loading: computed(() => loading.value),
    error: computed(() => error.value),
    stripeLoaded: computed(() => stripeLoaded.value),

    fnLoadStripe,
    fnResetStripeInstance,
  };
};

export { useStripeInstance };
