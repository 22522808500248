<template>
  <KftButton
    class="kft-brush-stroke-button p-0 kft-button--pure kft-link--black position-relative"
    v-bind="$attrs"
    :class="{ 'kft-brush-stroke-button--disabled': disabled }"
    v-on="$listeners"
  >
    <img
      class="kft-brush-stroke-button__brush-stroke"
      src="/brush-stroke.svg"
      alt=""
    />
    <span class="kft-brush-stroke-button__content"
      ><slot></slot> {{ hideArrow ? "" : "›" }}</span
    >
  </KftButton>
</template>
<script>
import { KftButton } from "@konfetti-ui/vue";
export default {
  name: "KftBrushStrokeButton",
  components: {
    KftButton,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.kft-brush-stroke-button {
  cursor: pointer;
  z-index: 1;

  &__content {
    z-index: 1;
    padding: var(--spacer-2) 0;
    color: #343a3f;
    @include font(
      --kft-brush-stroke-button-font,
      var(--font-weight--medium),
      var(--h6-font-size),
      1.4,
      var(--font-family--heading)
    );
  }

  &:hover {
    .kft-brush-stroke-button__content {
      color: #373a40;
    }
    .kft-brush-stroke-button__brush-stroke {
      opacity: 1;
      width: 100%;
    }
  }

  &--75w :hover .kft-brush-stroke-button__brush-stroke {
    width: 75%;
  }

  &--50w :hover .kft-brush-stroke-button__brush-stroke {
    width: 50%;
  }

  &:focus {
    .kft-brush-stroke-button__content {
      color: #222222;
    }
    .kft-brush-stroke-button__brush-stroke {
      opacity: 0;
      width: 10%;
    }
  }
  &:active {
    .kft-brush-stroke-button__content {
      color: #343a3f;
    }
    .kft-brush-stroke-button__brush-stroke {
      opacity: 0;
      width: 10%;
    }
  }

  &__brush-stroke {
    position: absolute;
    left: calc(-1 * var(--spacer-3));
    top: 2px;
    width: 10%;
    opacity: 0;
    height: 107%;
    z-index: -1;
    transition: width ease 0.5s;
  }

  &--disabled {
    cursor: default;
    .kft-brush-stroke-button__content {
      color: #666971;
    }
    .kft-brush-stroke-button__brush-stroke {
      opacity: 0;
      width: 10%;
    }
  }
}
</style>
