/* eslint-disable  */

// @francesca --- imgs are here:   t.ly/eK7l

export default {
  partnerPricing: {
    header: {
      title: "Für jedes Unternehmen",
      description:
        "Wähle das Paket, das am besten zu Dir passt. Unsere Tarife sind auf Dein Unternehmen abgestimmt.",
      titleTextColor: "white",
      descriptionTextColor: "white",
      buttonLabel: "Demo vereinbaren",
      buttonColor: "var(--kft-primary)",
      buttonHoverColor: "var(--kft-primary)",
      buttonBgColor: "white",
      buttonHoverBgColor: "#dde7e8",
      bgColor: "var(--kft-primary)",
    },

    plans: {
      sectionTitle: "Pakete und Preise",
      primaryCircleHtml: "Best<br>seller",
      options: {
        1: {
          title: "Free",
          isPrimary: false,
          actionText: "Kostenfrei starten",
          descriptionHtml: "Bis zu 5 Kurse",
          items: {
            "0 € monatlich": true,
            "Monatliche Umsätze bis 1.000 €": true,
            "1 Standort + online": true,
            Ticketverkäufe: true,
            "Online-Buchungslösung": false,
            Gutscheinverkauf: false,
            Kommunikationsmodul: false,
            "Support via Hilfebereich": true,
          },
        },
        2: {
          title: "Starter",
          descriptionHtml: "Bis zu 10 Kurse",
          isPrimary: false,
          actionText: "Jetzt Loslegen",
          items: {
            "Ab 29 € monatlich": true,
            "Monatliche Umsätze bis 2.000 €": true,
            "5 Standorte + online": true,
            Ticketverkäufe: true,
            "Online-Buchungslösung": true,
            Gutscheinverkauf: true,
            Kommunikationsmodul: false,
            "Support via E-Mail": true,
          },
        },
        3: {
          title: "Professional",
          descriptionHtml: "Bis zu 20 Kurse",
          isPrimary: true,
          actionText: "Jetzt Loslegen",
          items: {
            "Ab 99 € monatlich": true,
            "Monatliche Umsätze bis 5.000 €": true,
            "10 Standorte + online": true,
            Ticketverkäufe: true,
            "Online-Buchungslösung": true,
            Gutscheinverkauf: true,
            Kommunikationsmodul: true,
            "Support via Telefon": true,
          },
        },
        4: {
          title: "Enterprise",
          descriptionHtml: "Beliebig viele Kurse",
          isPrimary: false,
          actionText: "Jetzt Loslegen",
          items: {
            "Ab 299 € monatlich": true,
            "Unbegrenzte Umsätze": true,
            "Beliebig viele Standorte": true,
            Ticketverkäufe: true,
            "Online-Buchungslösung": true,
            Gutscheinverkauf: true,
            Kommunikationsmodul: true,
            "Persönlicher Account Manager": true,
          },
        },
      },
    },

    softwareInfo: {
      sectionTitle: "Unsere Software",
      actionText: "Demo Version anfordern",
      cards: [
        {
          title: "Buchungslösung",
          subtitle:
            "Verkaufe Deine Tickets rund um die Uhr auf Deiner Website und auf konfetti.\n",
          imageUrl: "/partner/pricing/backoffice-screenshot-1.png",
          imageAlt: "Img description",
        },
        {
          title: "Zentrale Verwaltung",
          subtitle:
            "Manage all Deine Kurse und Buchungen zentral in einem System.\n",
          imageUrl: "/partner/pricing/backoffice-screenshot-2.png",
          imageAlt: "Img description",
        },
        {
          title: "Einfache Abrechnung",
          subtitle:
            "Deine Abrechnung erhältst Du automatisch, ohne etwas machen zu müssen.\n",
          imageUrl: "/partner/pricing/backoffice-screenshot-3.png",
          imageAlt: "Img description",
        },
      ],
    },

    processSteps: {
      title: "Mache Dein Unternehmen noch profitabler",
      items: {
        1: {
          image: "/team-events/michael-l-geschaftsfuhrer-hyrise-academy.webp",
          title: "Steigere Deinen Umsatz",
          description:
            "Erweitere Deine Website um einen leistungsstarken Ticketshop. Mit der Online Buchungslösung sind Deine Kurse jederzeit auf Deiner Website und auf konfetti buchbar. Erreiche mehr Kunden und verkaufe Tickets und Gutscheine.",
          link: "",
          linkText: "",
          circleContent: '<i class="bi bi-bar-chart-line-fill"></i>',
        },
        2: {
          image: "/team-events/ceo-pospulse.webp",
          title: "Spürbar Verwaltungzeit einsparen",
          description:
            "Mit konfetti hast Du mehr Zeit für das Wesentliche. All Deine Events lassen sich an einem Ort verwalten. Schluss mit Papierkram und endlosen E-Mails, mehr Zeit für Deine Events und Deine Kunden.",
          link: "",
          linkText: "",
          circleContent: '<i class="bi bi-clock-history"></i>',
        },
        3: {
          image: "/team-events/Ostrom_Founders_2-800x533.webp",
          title: "Bester Service",
          description:
            "Wir sind für Dich erreichbar, unterstützen Dich bei der Integration der Buchungslösung auf Deiner Website und teilen unsere Erfahrung mit Dir - für Deinen Erfolg.",
          link: "",
          linkText: "",
          circleContent: '<i class="bi bi-people-fill"></i>',
        },
      },
    },

    variety: {
      title: "Top Teamevent Ideen",
      items: {
        1: {
          image: "/team-events/cocktailkurse.webp",
          title: "Outdoor Teamevents",
          description:
            "Egal ob Kräuterwanderungen, Weinwanderungen oder Schifffahrten - Outdoor Teamevents sind ein absoluter Renner für Dein nächstes Firmenevent und steigern den Teamgeist.",
        },
        2: {
          image: "/team-events/kreativworkshops.webp",
          title: "Kreative Teamevents",
          description:
            "Nimm Dir mit Deinem Team eine kreative Auszeit und entdecke in unseren DIY Kursen einzigartige Bastelideen, die Dich lange an dieses außergewöhnliche Teamevent mit Deinen Kollegen erinnern werden.",
        },
        3: {
          image: "/team-events/kochkurse-und-tastings.webp",
          title: "Virtuelle Teamevents",
          description:
            "Du bist auf der Suche nach virtuellen Teamevents oder Online Teambuilding, bei denen Du mit Deinem Team online kreativ werden oder kulinarische Genüsse erleben kannst? Dafür sind unsere Online-Teamevents wie Kochkurse, Cocktailkurse und Tastings perfekt geeignet.",
        },
      },
    },

    workshops: {
      title: "Top Kurse für Teamevents",
    },

    courses: {
      title: "Was für ein Teamevent suchst Du?",
    },

    heroes: {
      first: {
        title: "Erfahre mehr über unsere Features",
        subtitle:
          "Unsere vier verschiedenen Pakete sind auf Deine individuellen Bedürfnisse ausgerichtet. Sehr gern können wir auch gemeinsam schauen, welches Paket am besten zu Dir passt.",
        image: "/partner/pricing/read-pdf.png",
        mobileImage: "/partner/pricing/read-pdf.png",
        buttonText: "Weitere Features",
      },
    },

    faq: [
      {
        title: "Häufig gestellte Fragen",
        questions: [
          {
            question: "Wie und wann werde ich bezahlt?",
            answer:
              "Jeweils in den ersten Tagen des Folgemonats nach der Leistungserbringung erhältst Du automatisch eine Gutschrift mit anschließend direkter Auszahlung.",
          },
          {
            question:
              "Erhalte ich Unterstützung bei der Einrichtung von konfetti?",
            answer:
              "Nach Deiner Anmeldung erhältst Du professionelle Onboarding-Materialien von uns, mit denen der Start mit konfetti leicht fällt. Gern unterstützen wir Dich dabei, die Buchungslösung optimal auf Deiner Website zu platzieren.",
          },
          {
            question: "Kann ich mit jemandem von konfetti sprechen?",
            answer:
              "Melde Dich gern bei uns über den Live-Chat oder telefonisch unter +49 30 257706800.",
          },
          {
            question:
              "Ist konfetti die richtige Buchungslösung für mein Unternehmen?",
            answer:
              "Du bietest Kurse, Workshops oder Teambuilding-Events an? Dann ist konfetti die perfekte Softwarelösung für Dich. Wir haben konfetti in enger Abstimmung mit unseren Partnern entwickelt. Ziel unserer All-in-One Lösung ist es, Dir den Arbeitsalltag zu erleichtern, indem wir alltägliche Prozesse automatisieren und Tickets online verkaufen.",
          },
          {
            question: "Kann ich meinen Vertrag kündigen?",
            answer:
              "Deinen Vertrag kannst Du monatlich kündigen. Schreibe uns dafür einfach eine E-Mail und wir kümmern uns direkt um Dein Anliegen.",
          },
          {
            question:
              "Wie lang dauert es bis ich meine Kurse online verkaufen kann?",
            answer:
              "Nach Deiner Anfrage melden wir uns innerhalb von 24 Stunden bei Dir. Wenn Du direkt loslegen möchtest, legen wir Dein Konto gleich an und teilen Deine Zugangsdaten mit Dir. Auf diesem Weg kannst Du Deine Events direkt anlegen und verwalten.",
          },
        ],
      },
    ],
  },
};
