<template>
  <component
    :is='componentIs'
    v-bind='bind'
    v-on='$listeners'
    :data-testid='label'
    class='sf-menu-item'
  >
    <!-- @slot for menu item icon-->
    <slot name='icon' />
    <!-- @slot for menu item label-->
    <slot v-bind='{ label }' name='label'>
      <span class='sf-menu-item__label'>{{ label }}</span>
    </slot>
    <!-- @slot for items count -->
    <slot v-bind='{ count }' name='count'>
      <span class='sf-menu-item__count'>{{ count }}</span>
    </slot>
    <!-- @slot -->
    <slot v-bind='{ icon }' name='mobile-nav-icon' />
  </component>
</template>
<script>
import KftLink from "../../atoms/KftLink/KftLink.vue";
import KftButton from "../../atoms/KftButton/KftButton.vue";

export default {
  name: "SfMenuItem",
  components: {
    KftLink,
    KftButton
  },
  props: {

    /**
     * Menu-item label
     */
    label: {
      type: String,
      default: ""
    },

    /**
     * Menu-item icon (visible on mobile)
     */
    icon: {
      type: [String, Array],
      default: "chevron_right"
    },

    /**
     * Menu-item count of items
     */
    count: {
      type: [String, Number],
      default: ""
    },

    /**
     * Menu-item link (if is empty then SfMenuItem is KftButton)
     */
    link: {
      type: [String, Object],
      default: ""
    }
  },
  computed: {
    bind() {
      const bind = {};
      if (this.link) {
        bind.link = this.link;
      } else {
        bind.class = "kft-button--pure";
      }
      return bind;
    },
    componentIs() {
      return this.link ? "KftLink" : "KftButton";
    }
  }
};
</script>
<style lang='scss'>
@import "~@konfetti-ui/shared/styles/components/molecules/SfMenuItem.scss";
</style>
