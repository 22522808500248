/* eslint-disable  */

export default {
  sitemap: {
    title: "Sitemap",
    sections: {
      general: "General",
      categories: "Kategorien",
      cities: "Städte",
      cityCategories: "Städte und Kategorien",
      generalTransactionalPages: "Verschiedene Themen",
      events: "Kurse und Workshops",
      partnerProfiles: "Partner Profile",
      articles: "Magazin-Artikel",
    },
    pagesLabelTitles: {
      city: "Kurse in {title}",
      category: "{title}",
      cityCategory: "{title}",
      generalTransactionalPage: "{title}",
    },
    pathTitles: {
      "/": "Home",
      "/contactUs/": "Kontakt",
      "/faq/": "Häufige Fragen",
      "/impressum/": "Impressum",
      "/partner/": "Werde Partner",
      "/terms-and-conditions/": "AGB",
      "/terms-supplier/": "Angebotsanbieter-AGB",
      "/teamevent/": "Teamevent",
      "/giftcard/": "Giftcard",
      "/partner/pricing/":
        "Partner Pricings",
    },
  },
};
