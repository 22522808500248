import { sharedRef, UseKftContext } from "@konfetti-core/core";
import { Ref, computed } from "@nuxtjs/composition-api";
import _ from "lodash";
import { AlgoliaQueryItem } from "../types";
import { fnGetEventListIndexName } from "../../helpers/algoliaHelpers";
import {
  fnGetEventListSortByDateDescIndexName,
  fnGetTopEventListIndexName,
  fnGetTopGiftIdeasIndexName,
} from "~/helpers/algoliaHelpers";

import {
  HITS_PER_SHOWCASE,
  useAlgoliaCore,
} from "~/composables/useAlgolia/core";

export const useAlgoliaEventApi = (id: string) => {
  /** Variables
   ** ------------- */
  const {
    token,
    isLoaded,
    fnInit,
    fnSetIndex,
    fnPerformSearch,
    fnPerformMultipleQueriesSearch,
    fnLoadUserTokens,
  } = useAlgoliaCore(id);

  const context = UseKftContext();

  /* Top Events */
  let homepageFoundIds = [];
  const topEvents = sharedRef([], `useAlgoliaEventApi-topEvents-${id}`);
  const topEventsQueryID = sharedRef(
    [],
    `useAlgoliaEventApi-topEventsQueryID-${id}`,
  );

  /* New Events */
  const newEvents = sharedRef([], `useAlgoliaEventApi-newEvents-${id}`);
  const newEventsQueryID = sharedRef(
    [],
    `useAlgoliaEventApi-newEventsQueryID-${id}`,
  );

  /* Gift Ideas */
  const topGiftIdeasEvents = sharedRef(
    [],
    `useAlgoliaEventApi-topGiftIdeasEvents-${id}`,
  );
  const topGiftIdeasEventsQueryID = sharedRef(
    [],
    `useAlgoliaEventApi-topGiftIdeasEventsQueryID-${id}`,
  );

  /* Supplier Events */
  const supplierEvents = sharedRef(
    [],
    `useAlgoliaEventApi-supplierEvents-${id}`,
  );
  const supplierEventsQueryID = sharedRef(
    [],
    `useAlgoliaEventApi-supplierEventsQueryID-${id}`,
  );
  /* Similar events */
  const similarEvents = sharedRef([], `useAlgoliaEventApi-similarEvents-${id}`);
  const similarEventsQueryID = sharedRef(
    [],
    `useAlgoliaEventApi-similarEventsQueryID-${id}`,
  );
  const similarOnlineEvents = sharedRef(
    [],
    `useAlgoliaEventApi-similarOnlineEvents-${id}`,
  );
  const similarOnlineEventsQueryID = sharedRef(
    [],
    `useAlgoliaEventApi-similarOnlineEventsQueryID-${id}`,
  );

  const loading = sharedRef(false, `useAlgoliaEventApi-loading-${id}`);

  const isHomepageListingsLoaded = computed(
    () =>
      topEvents.value.length > 0 ||
      newEvents.value.length > 0 ||
      topGiftIdeasEvents.value.length > 0,
  );

  /** Methods
   ** ------------- */

  const fetchHomePageListings = async () => {
    const homePageQueryParams = [
      {
        indexName: fnGetTopEventListIndexName(context.i18n.locale),
        hitsPerPage: HITS_PER_SHOWCASE,
      },
      {
        indexName: fnGetTopGiftIdeasIndexName(context.i18n.locale),
        hitsPerPage: HITS_PER_SHOWCASE * 2,
      },
      {
        indexName: fnGetEventListSortByDateDescIndexName(context.i18n.locale),
        hitsPerPage: HITS_PER_SHOWCASE,
      },
    ];

    const homePageQueries = homePageQueryParams.map((params) => ({
      ...params,
      userToken: token.value,
      clickAnalytics: true,
      filters: "has_events:true",
      aroundLatLngViaIP: process.client,
      aroundRadius: "all",
      aroundPrecision: [
        { from: 0, value: 15000 },
        { from: 30000, value: 30000 },
        { from: 150000, value: 100000 },
      ],
    }));

    await fnPerformMultipleQueriesSearch(
      homePageQueries,
      (results) => {
        topEvents.value = results[0].hits;
        // top gift ideas could have overlap with top events.
        // To avoid repetition, we remove common elements from second list
        // and return HITS_PER_SHOWCASE items
        topGiftIdeasEvents.value = _.take(
          _.differenceWith(results[1].hits, results[0].hits, _.isEqual),
          HITS_PER_SHOWCASE,
        );
        newEvents.value = results[2].hits;
        topEventsQueryID.value = results[0].queryID;
        topGiftIdeasEventsQueryID.value = results[1].queryID;
        newEventsQueryID.value = results[2].queryID;
      },
      { shouldCacheResult: !process.client },
    );
  };

  /**
   * @name fnPushFoundIDs
   * @desc Push found IDs in query to a given array, this is useful when we want to avoid duplications
   *
   * @param currentArray the array of found items
   * @param hits The elements to be pushed
   *  */
  const fnPushFoundIDs = (currentArray, hits) => {
    for (let i = 0; i < hits.length; i++) {
      const hit = hits[i];
      currentArray.push(hit.hashed_id);
    }

    return currentArray;
  };

  /**
   * @name fnGetTopEvents
   * @desc Get top events, used mainly in homepage
   * */
  const fnGetTopEvents = async () => {
    loading.value = true;
    const results = await fnPerformSearch({
      shouldCacheResult: !process.client,
    });
    topEvents.value = results.hits;
    topEventsQueryID.value = results.queryID;
    loading.value = false;
  };

  /**
   * @name fnGetNewEvents
   * @desc Get new events, mainly for homepage
   * */
  const fnGetNewEvents = async () => {
    loading.value = true;
    const results = await fnPerformSearch({
      shouldCacheResult: !process.client,
    });
    newEvents.value = results.hits;
    newEventsQueryID.value = results.queryID;
    loading.value = false;
  };

  /**
   * @name fnGetTopGiftIdeasEvents
   * @desc Get top sold giftcards, mainly for homepage
   * */
  const fnGetTopGiftIdeasEvents = async () => {
    loading.value = true;
    const results = await fnPerformSearch({
      shouldCacheResult: !process.client,
    });
    topGiftIdeasEvents.value = results.hits;
    topGiftIdeasEventsQueryID.value = results.queryID;
    loading.value = false;
  };

  /**
   * @name fnGetSupplierEvents
   * @desc Get events from the supplier
   *
   * @param name The full name of the supplier
   * @param options Options to opera
   * * * *  field Slug for the field to be searched
   * * * *  perPage Amount of hits per page
   * */
  const fnGetSupplierEvents = async (
    name: string,
    options: {
      field?: string;
      perPage?: number;
      shouldCacheResult?: boolean;
    } = {
      field: "supplier",
      perPage: 100,
      shouldCacheResult: false,
    },
  ) => {
    const defaultOptions = {
      field: "supplier",
      perPage: 100,
      shouldCacheResult: false,
    };

    const { field, perPage, shouldCacheResult } = {
      ...defaultOptions,
      ...options,
    };

    loading.value = true;
    const params = {
      userToken: token.value,
      hitsPerPage: perPage,
      filters: `${field}:"${name}"`,
    };

    const results = await fnPerformSearch({
      customParams: params,
      shouldCacheResult,
    });
    supplierEvents.value = results.hits;
    supplierEventsQueryID.value = results.queryID;
    loading.value = false;
  };

  /**
   * @name getSimilarEvents
   * @desc Get events from a category
   *
   * @param category the category to search
   * @param id The id of the EVENT to exclude
   * @param perPage
   * */
  const getSimilarEvents = async (
    category,
    id: string | null,
    coordinates,
    perPage = 10,
  ) => {
    try {
      loading.value = true;
      // Generate multiple queries for offline and online events
      const queries = ["false", "true"].map((onlineStatus) => ({
        indexName: fnGetEventListIndexName(context.i18n.locale),
        userToken: token.value,
        hitsPerPage: perPage,
        facetFilters: [[`categories.name:${category}`]],
        filters: `is_online:${onlineStatus} AND NOT hashed_id:${id}`,
        params: "",
        ...(onlineStatus === "false" &&
          coordinates && {
            aroundLatLng: coordinates,
            aroundRadius: 30000,
            aroundPrecision: [
              { from: 0, value: 1000 },
              { from: 30000, value: 5000 },
              { from: 100000, value: 10000 },
            ],
          }),
        clickAnalytics: true,
      }));
      await fnPerformMultipleQueriesSearch(
        queries,
        (results) => {
          similarEvents.value = results[0].hits;
          similarOnlineEvents.value = results[1].hits;
          similarOnlineEventsQueryID.value = results[0].queryID;
          similarEventsQueryID.value = results[1].queryID;
        },
        { shouldCacheResult: !process.client },
      );
    } catch (error) {
      console.error("Error loading similar events", error);
    } finally {
      loading.value = false;
    }
  };

  return {
    /* query IDs */
    topEventsQueryID,
    newEventsQueryID,
    topGiftIdeasEventsQueryID,
    supplierEventsQueryID,
    similarEventsQueryID,
    similarOnlineEventsQueryID,

    /* listings */
    topEvents,
    newEvents,
    topGiftIdeasEvents,
    supplierEvents,
    similarEvents,
    similarOnlineEvents,
    token,

    isLoaded,
    loading,
    isHomepageListingsLoaded,

    /* Methods */
    fnInit,
    fnSetIndex,
    fnGetTopEvents,
    fnGetNewEvents,
    fnGetTopGiftIdeasEvents,
    fnGetSupplierEvents,
    getSimilarEvents,
    fetchHomePageListings,
    fnLoadUserTokens,
  };
};
