import { registerLogger } from '@konfetti-core/core';
import { loggerConfig } from '@konfetti/config';

const loggerPlugin = () => {
  const {
    verbosity,
    customLogger,
    ...args
  } = loggerConfig;
  registerLogger(customLogger || args, verbosity || 'error');
};

export default loggerPlugin;
