import Vue from "vue";
import { Context as NuxtContext } from "@nuxt/types";
import { Ref } from "@nuxtjs/composition-api";
import { Cart } from "@konfetti/composables/src/types";
import { KftModal } from "@konfetti-ui/vue";
import { KftValidationModal } from "~/components";

interface ModalComponent extends Vue {
  alertModal: Ref<null | KftModal>;
  cart: Ref<Cart>;
  errors: Ref<Array<{ message: string; type: string }>>;
  hide: Ref<(() => void) | undefined>;
  show: () => void;
  onHiddenAlertModal: () => void;
  goToEvent: (permalink: string) => void;
}

interface KftValidationModalError {
  title: string;
  permalink: string;
  message: string;
}

declare module "vue/types/vue" {
  // this.$kftValidationModal inside Vue components
  interface Vue {
    $showKftValidationModal: (errors: KftValidationModalError[]) => void;
  }
}

declare module "@nuxt/types" {
  // nuxtContext.app.$kftValidationModal inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $showKftValidationModal: (errors: KftValidationModalError[]) => void;
  }

  // nuxtContext.$kftValidationModal
  interface Context {
    $showKftValidationModal: (errors: KftValidationModalError[]) => void;
  }
}
export default (ctx: NuxtContext, inject): void => {
  const { app } = ctx;
  const { i18n, router } = app;

  // Create a new component instance
  const modalComponent = new Vue({
    i18n,
    router,
    components: { KftValidationModal },
    render: (h) => h(KftValidationModal, { ref: "modal" }),
  }) as ModalComponent;

  // Append the component to the document once
  if (!document.getElementById("kft-validation-modal")) {
    const modalEl = modalComponent.$mount().$el;
    modalEl.id = "kft-validation-modal";
    document.body.appendChild(modalEl);
  }

  const modal = modalComponent.$refs.modal as KftValidationModal;

  inject("showKftValidationModal", (errors) => modal.show(errors));
};
