import { GtmItemListId, SendViewItemListConfig } from "./types";
import { parseKftAlgoliaEventItemToGtmItemListItem } from "./parsers";

export const sendViewItemListConfig: SendViewItemListConfig = {
  [GtmItemListId.GeneralPageRecommendedEvents]: {
    listName: "General Page Recommended Events",
    parser: parseKftAlgoliaEventItemToGtmItemListItem,
  },
  [GtmItemListId.GeneralPageTopEvents]: {
    listName: "General Page Top Events",
    parser: parseKftAlgoliaEventItemToGtmItemListItem,
  },
  [GtmItemListId.GeneralPageGiftcardEvents]: {
    listName: "General Giftcard Classes",
    parser: parseKftAlgoliaEventItemToGtmItemListItem,
  },
  [GtmItemListId.GeneralPageTopGiftIdeas]: {
    listName: "General Page Top Gift Ideas",
    parser: parseKftAlgoliaEventItemToGtmItemListItem,
  },
  [GtmItemListId.GeneralPageNewEvents]: {
    listName: "General Page New Events",
    parser: parseKftAlgoliaEventItemToGtmItemListItem,
  },
  [GtmItemListId.GeneralPageLastViewedEvents]: {
    listName: "General Page Last Viewed Events",
    parser: parseKftAlgoliaEventItemToGtmItemListItem,
  },
  [GtmItemListId.EventPageSimilarClasses]: {
    listName: "Event Page Similar Classes",
    parser: parseKftAlgoliaEventItemToGtmItemListItem,
  },
  [GtmItemListId.EventPageSameSupplierClasses]: {
    listName: "Event Page Same Supplier Classes",
    parser: parseKftAlgoliaEventItemToGtmItemListItem,
  },
  [GtmItemListId.EventPageSimilarOnlineClasses]: {
    listName: "Event Page Similar Online Classes",
    parser: parseKftAlgoliaEventItemToGtmItemListItem,
  },
  [GtmItemListId.GiftcardPageGiftcardEvents]: {
    listName: "Giftcard Page Giftcard Classes",
    parser: parseKftAlgoliaEventItemToGtmItemListItem,
  },
  [GtmItemListId.MagazineArticlePageSupplierClasses]: {
    listName: "Magazine Article Page Supplier Classes",
    parser: parseKftAlgoliaEventItemToGtmItemListItem,
  },
  [GtmItemListId.TeameventPageTopEvents]: {
    listName: "Teamevent Page Top Events",
    parser: parseKftAlgoliaEventItemToGtmItemListItem,
  },
  // Example of a different type of data below
  // [GtmItemListId.GeneralPageCategories]: {
  //   listName: "General Page Categories",
  //   parser: parseKftCategoryToGtmItemListItem,
  // },
};
