



















import { KftTooltip } from "@konfetti-ui/vue";

export default {
  name: "SectionTitle",

  components: {
    KftTooltip,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      required: true,
    },
  },
};
