var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.filters && _vm.filters.locality && _vm.filters.locality.name)?_c('div',[_vm._v("\n  "+_vm._s(_vm.$t("search.searchResults.nResultsInCity", {
      n: _vm.hitsLength,
      locality: _vm.filters.locality.name,
    }))+"\n")]):(_vm.filters.categories && _vm.filters.categories.length > 0)?_c('div',[_vm._v("\n  "+_vm._s(_vm.$t("search.searchResults.nResultsForCategory", {
      n: _vm.hitsLength,
      category: _vm.filters.categories[0],
    }))+"\n")]):(_vm.filters.searchquery)?_c('div',[_vm._v("\n  "+_vm._s(_vm.$t("search.searchResults.nResultsForSearchQuery", {
      n: _vm.hitsLength,
      searchQuery: _vm.filters.searchquery,
    }))+"\n")]):_c('div',[_vm._v("\n  "+_vm._s(_vm.$t("search.searchResults.nResultsEverywhere", {
      n: _vm.hitsLength,
    }))+"\n")])}
var staticRenderFns = []

export { render, staticRenderFns }