<template>
  <div class="kft-buy-box-no-dates">
    <div>
      <span class="kft-buy-box-no-dates__title">
        {{ altTitle ? altTitle : $t(`product.show.noAvailableDatesBox.title`) }}
      </span>
    </div>

    <div class="d-flex gap-2 flex-wrap mt-2">
      <WhiteButton
        v-if="requestActive"
        class="py-3 px-2"
        @click="$emit('click:request-date')"
      >
        <img src="/icons/calendar.svg" alt="calendar icon" />

        {{
          actionBtnTxt ||
          $t("product.show.noAvailableDatesBox.ctaTextOnlyProposeDate")
        }}
      </WhiteButton>
      <WhiteButton
        v-if="waitlistActive"
        class="py-3 px-2"
        @click="$emit('click:waitlist')"
      >
        <img src="/icons/alert-bell.svg" alt="bell icon" />

        {{
          actionBtnTxt ||
          $t("product.show.noAvailableDatesBox.ctaTextOnlyWaitlist")
        }}
      </WhiteButton>
    </div>
  </div>
</template>

<script>
import WhiteButton from "@/components/atoms/WhiteButton/WhiteButton.vue";

export default {
  name: "KftBuyBoxNoDates",
  components: { WhiteButton },
  props: {
    requestActive: {
      type: Boolean,
      default: false,
    },
    waitlistActive: {
      type: Boolean,
      default: false,
    },
    altTitle: {
      type: String,
      default: null,
    },
    actionBtnTxt: {
      type: String,
      default: "",
    },
    primaryButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.kft-buy-box-no-dates {
  ::v-deep .kft-heading .kft-heading__title {
    --heading-title-font-size: 1.25rem;
  }

  &__title {
    font-size: 1rem;
    font-weight: 500;
    font-family: var(--font-family--heading);
  }
}
</style>
