var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showModal)?_c('KftModal',{ref:"modal",attrs:{"id":"subscribeToEventUpdatesModal","size":"sm"},on:{"hidden":_vm.fnHandleHide},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('KftModalSimpleHeader',{staticClass:"pb-2",on:{"close":function($event){return _vm.modal.hide()}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('KftHeading',{staticClass:"kft-heading--left",attrs:{"level":4,"title":_vm.$t(
                ("product.subscribeToEventUpdatesModal." + (_vm.subscribed ? 'secondStep' : 'firstStep') + ".title")
              )}})]},proxy:true}],null,false,3581741289)})]},proxy:true}],null,false,3957428941)},[_vm._v(" "),(!_vm.subscribed)?_c('div',[_c('p',{staticClass:"overline"},[_vm._v("\n        "+_vm._s(_vm.$t("product.subscribeToEventUpdatesModal.firstStep.description"))+"\n      ")]),_vm._v(" "),_c('form',{staticClass:"w-100",on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.fnSubmit()}}},[_c('div',{staticClass:"el-form-validation-margin mt-2"},[_c('label',{staticClass:"required form-label overline",attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t(
              "product.subscribeToEventUpdatesModal.firstStep.form.email.label"
            )))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control w-100",attrs:{"id":"email","disabled":_vm.isLoading,"placeholder":_vm.$t(
                'product.subscribeToEventUpdatesModal.firstStep.form.email.placeholder'
              ),"autocomplete":"email","name":"email"},domProps:{"value":(_vm.form.email)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)},function($event){return _vm.clearErrors('email')}]}}),_vm._v(" "),(_vm.invalidKeys.includes('email'))?_c('span',{staticClass:"text-red fs-6"},[_vm._v("\n            "+_vm._s(_vm.errors.email)+"\n          ")]):_vm._e()])]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary mt-4 w-100 d-flex align-items-center justify-content-center",attrs:{"disabled":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.fnSubmit.apply(null, arguments)}}},[_c('span',{staticClass:"ms-2"},[_vm._v("\n          "+_vm._s(_vm.$t(
              "product.subscribeToEventUpdatesModal.firstStep.submitButtonLabel"
            ))+"\n        ")])])]):_c('div',[_c('p',{staticClass:"mb-0 overline"},[_vm._v("\n        "+_vm._s(_vm.$t("product.subscribeToEventUpdatesModal.secondStep.description"))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"subscribe-to-event-updates__icon-block mt-7 py-2 px-4 d-flex align-items-center"},[_c('i',{staticClass:"bi bi-envelope-check-fill me-2"}),_vm._v(" "),_c('span',{staticClass:"overline"},[_vm._v("\n          "+_vm._s(_vm.$t(
              "product.subscribeToEventUpdatesModal.secondStep.iconBlockText"
            )))])]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary mt-7 w-100",on:{"click":function($event){$event.preventDefault();return _vm.modal.hide()}}},[_vm._v("\n        "+_vm._s(_vm.$t(
            "product.subscribeToEventUpdatesModal.secondStep.submitButtonLabel"
          ))+"\n      ")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }