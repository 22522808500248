import { useCart, useProduct } from "@konfetti/composables";
import { computed } from "@vue/composition-api";
import { sharedRef, UseKftContext } from "@konfetti-core/core";
import { CartKeys, useEventDates } from "@konfetti/composables/src";
import { useBooking } from "../useBooking";
import { getSupplierSubdomain } from "~/helpers";

export const useEmbeddedCheckout = (): any => {
  const context = UseKftContext();
  const supplierScope = sharedRef(
    getSupplierSubdomain(
      context?.ssrContext?.nuxt || context.nuxtState,
      context.$config,
    ),
    "supplier-scope",
  );

  const {
    /* Variables */
    event,
    availability,

    /* Methods */
    getEventById,
    getAvailabilityByEventIds,
  } = useProduct();

  const { dates, fetchDates } = useEventDates(
    "embedded-checkout__checkout-page",
    false,
  );

  const isSupplierScope = computed(() => supplierScope.value !== null);
  const eventId = computed(() => context.route.value.query.eventId || null);
  const quantity = computed(() => context.route.value.query.quantity || null);
  const isGiftcard = computed(() =>
    context.route.value.query?.type
      ? context.route.value.query.type === "giftcard"
      : null,
  );
  const eventDescriptionId = computed(
    () =>
      availability.value?.[0]?.event_description_id ||
      context.route.value.query.eventDescriptionId ||
      null,
  );

  const isEmbedded = computed(() =>
    context.route.value.query.embedded
      ? context.route.value.query.embedded === "true"
      : null,
  );
  const isPrivate = computed(() =>
    context.route.value.query.private
      ? context.route.value.query.private === "true"
      : false,
  );

  const { addItem } = useCart(
    isEmbedded.value ? CartKeys.EMBEDDED : CartKeys.GENERAL,
  );

  const {
    /* Variables */
    selectedTime,
    selectedTicketQuantity,
    selectedGiftcardQuantity,

    /* Methods */
    prepareEventForBooking,
    prepareGiftcardForBooking,
  } = useBooking("embedded-instance");

  /** @name fnSetupSelectedTime
   * @desc Reads the eventId and selects the time
   */
  const fnSetupSelectedTime = async () => {
    await getEventById(eventDescriptionId.value);
    await getAvailabilityByEventIds([eventId.value]);
    await fetchDates(eventDescriptionId.value, {
      search: { id: [eventId.value] },
    });
    selectedTime.value = dates.value?.find(
      (date) => date.id && date.id === eventId.value,
    );
  };

  /**
   * @name fnPrepareEventTicketToAddToCart
   * @desc Prepares all data needed to add an event-ticket from params (informing eventId and ticketsAmount)
   * to be added to the cart
   * The function doesn't add the event-ticket to cart right away because it will be called in SSR, meaning there is no cart.
   * Rather, we prepare a function to add the fetched event-ticket to cart to be called inside onMounted hook
   */
  const fnPrepareEventTicketToAddToCart = async () => {
    if (isGiftcard.value) {
      selectedGiftcardQuantity.value = Number(quantity.value);
      if (eventId.value) {
        await fnSetupSelectedTime();
      }

      return;
    }

    selectedTicketQuantity.value = Number(quantity.value);
    await fnSetupSelectedTime();

    if (!selectedTime.value) {
      throw new Error("Event not found");
    }
  };

  /**
   * @name fnAddPreparedEventTicketToCart
   * @desc Add the event or giftcard to the card, in order to prepare the checkout
   * */
  const fnAddPreparedEventTicketToCart = () => {
    if (
      !event.value ||
      (!selectedTicketQuantity.value && !selectedGiftcardQuantity.value)
    ) {
      return;
    }

    const itemObj = isGiftcard.value
      ? prepareGiftcardForBooking(event.value)
      : prepareEventForBooking(event.value, isPrivate.value);

    addItem(itemObj);
  };

  return {
    /* Variables */
    eventId,
    quantity,
    isEmbedded,
    isSupplierScope,
    isGiftcard,

    /* Methods */
    fnPrepareEventTicketToAddToCart,
    fnAddPreparedEventTicketToCart,
  };
};
