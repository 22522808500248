import * as KTUtil from "./_utils";
import * as components from "./components";

declare global {
  interface Window {
    keenthemes: any;
  }
}

if (typeof window !== "undefined") {
  window.keenthemes = {
    components: {
      Drawer: components.DrawerComponent,
      Menu: components.MenuComponent,
    },
    utils: KTUtil,
  };
}
