<template functional>
  <div
    v-bind='data.attrs'
    v-on='listeners'
    :class="[data.class, data.staticClass, 'kft-price']"
    :style='[data.style, data.staticStyle]'
  >
    <!--@slot Custom regular price -->
    <slot v-bind='{ props }' name='regular'>
      <span v-if='!props.special' class='kft-price__regular'>
        {{ props.regular }}
      </span>
    </slot>
    <!--@slot Custom old price (value from regular)-->
    <slot v-bind='{ props }' name='old'>
      <del v-if='props.special' class='kft-price__old'>
        {{ props.regular }}
      </del>
    </slot>
    <!--@slot Custom special price -->
    <slot v-bind='{ props }' name='special'>
      <ins v-if='props.special' class='kft-price__special'>
        {{ props.special }}
      </ins>
    </slot>
  </div>
</template>
<script>
export default {
  name: "KftPrice",
  props: {

    /**
     * Regular/old price value. Crossed out if `special` is provided
     */
    regular: {
      type: [String, Number],
      default: null
    },

    /**
     * Special price value
     */
    special: {
      type: [String, Number],
      default: null
    }
  }
};
</script>
<style lang='scss'>
@import "~@konfetti-ui/shared/styles/components/atoms/KftPrice.scss";
</style>
