<template>
  <div class="kft-event-header">
    <!-- BEGIN::Title and subtitle -->
    <div class="kft-event-header__title pe-lg-0">
      <KftHeading
        :h-class="'h3'"
        :level="1"
        :title="title"
        class="kft-heading--left"
        data-test-id="event-title"
      />
      <KftReadMore :lines="4">
        <div v-html="description"></div>
      </KftReadMore>
    </div>
    <!-- END::Title and subtitle -->
    <KftHeaderWithIcon v-if="supplierName || rating" icon="bi-star-fill">
      <template #before-icon>
        <div
          class="kft-event-header__supplier-name"
          @click="$emit('click:supplier')"
        >
          {{ supplierName }}
        </div>
      </template>
      <template #after-icon>
        <div class="kft-event-header__rating" @click="$emit('click:rating')">
          {{ rating ? rating : $t("product.reviews.newEventLabel") }}
        </div>
        {{
          !isSupplierNew && rating
            ? ` - ${$tc(
                "product.reviews.reviewAmountLabel",
                Number(reviewAmount),
              )}`
            : null
        }}
      </template>
    </KftHeaderWithIcon>
  </div>
</template>
<script>
import { KftHeaderWithIcon, KftHeading, KftReadMore } from "@konfetti-ui/vue";

export default {
  name: "KftEventHeader",
  components: {
    KftHeading,
    KftReadMore,
    KftHeaderWithIcon,
  },
  props: {
    supplierName: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    rating: {
      type: String,
      default: null,
    },
    reviewAmount: {
      type: Number,
      default: 0,
    },
    isSupplierNew: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss">
@import "~/assets/components/molecules/KftEventHeader.scss";
</style>
