<template>
  <!-- This page is only used for health-checking -->
  <KftHorizontalScrollWrapper
    class="kft-ticket-card-showcase"
    :items-length="dates.length"
  >
    <KftTicketCard
      v-for="date in dates"
      :key="date.id"
      :date="date"
      @click:ticket-card-cta="$emit('click:ticket-card-cta', $event)"
    />
    <slot name="date-request-card"></slot>
  </KftHorizontalScrollWrapper>
</template>

<script>
import KftTicketCard from "@/components/molecules/KftTicketCard/KftTicketCard";
import { KftHorizontalScrollWrapper } from "~/components";

export default {
  name: "KftTicketCardShowcase",
  components: { KftTicketCard, KftHorizontalScrollWrapper },
  layout: "blank",
  props: {
    dates: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.kft-ticket-card-showcase {
  margin-left: calc(-1 * var(--spacer-8));
  ::v-deep .kft-ticket-card {
    min-width: 235px;
    max-width: 260px;

    &.more-dates-ticket {
      min-width: 300px;
      max-width: 310px;
      margin-right: 40px;
    }
  }

  ::v-deep .kft-horizontal-scroll-wrapper__cards-container {
    padding-left: calc(var(--spacer-8));
  }
}
</style>
