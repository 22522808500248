import { UseKftContext } from "@konfetti-core/core";
import { formatDate, formatTime } from "~/helpers/dateHelpers";

export const useEventDateHelpers = () => {
  const { i18n } = UseKftContext();

  const fnGetDateLabelHtml = (date, currentDate = new Date()) => {
    if (typeof currentDate === "string") {
      currentDate = new Date(currentDate);
    }
    const eventDate = new Date(date.start ?? date.start_time ?? date.startTime);

    // Check if the event is more than 12 months after the current date
    const twelveMonthsLater = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 12,
      1,
    );
    const dateStr = formatDate(
      date.start || date.start_time || date.date,
      i18n.locale,
    );
    const startTimeStr = formatTime(
      date.start || date.start_time || date.startTime,
    );
    const endTimeStr = formatTime(date.end || date.end_time || date.endTime);

    const dateLabel =
      eventDate > twelveMonthsLater
        ? `${dateStr} ${eventDate.getFullYear()} ${startTimeStr} - ${endTimeStr}`
        : `${dateStr} ${startTimeStr} - ${endTimeStr}`;
    return dateLabel;
  };

  const fnGetChildrenHtmlArr = (item) => {
    return item.children?.data?.map((x) => fnGetDateLabelHtml(x)) || [];
  };

  return {
    fnGetDateLabelHtml,
    fnGetChildrenHtmlArr,
  };
};
