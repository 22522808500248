/* eslint-disable camelcase */
import { GTMItem, Order } from "../types";

/** @name parseItemsForGtm
 ** @desc As the name states, it will parse an order items to push the "purchase" event to GTM */
export const parseItemsForGtm = (order: Order): GTMItem[] => {
  const items = [];
  let giftbox_price = 0;
  let giftbox_quantity = 0;
  for (let i = 0; i < order.items.data.length; i++) {
    const item = order.items.data[i];
    const price = parseInt(item.price.amount) / 100;
    const item_id = item.characteristics.description
      ? item.characteristics.description.id
      : "giftcard-" + price;
    const item_category = item.characteristics.description
      ? item.characteristics.description.category
      : "giftcard";
    const item_brand = item.characteristics.supplier
      ? item.characteristics.supplier.name
      : "";
    if (item.characteristics.gift_box) {
      giftbox_quantity += 1;
      // incrementing price, but it will be divided by quantity later to get unit price
      giftbox_price += item.characteristics.gift_box.price.amount / 100;
    }
    items.push({
      index: i,
      item_id: item_id,
      item_name: item?.characteristics?.description?.title || item.title,
      item_category: item_category,
      item_brand: item_brand,
      quantity: item.quantity,
      item_variant: item?.type,
      price: price?.toFixed(2) || price,
    });
  }
  // add the gift box object in the purchase event after all other items
  if (giftbox_quantity > 0) {
    items.push({
      index: order?.items?.data?.length || "",
      item_id: "gift-box",
      item_name: "Gift Box",
      item_category: "Gift Box",
      quantity: giftbox_quantity,
      type: "GIFT_BOX",
      price: (giftbox_price / giftbox_quantity).toFixed(2),
    });
  }
  return items;
};

export const getId = (order: Order): string => {
  return order.id;
};

export const getPaymentGateway = (order: Order): string => {
  return order.payment_gateway;
};

export const getStatus = (order: Order): string => {
  return order.status;
};

export const getCreatedAt = (order: Order): string => {
  return order.created_at;
};

export const getFormattedTotal = (order: Order): string => {
  return order.total.formatted;
};

export const getShaEmailAddress = (order: Order): string => {
  return order.user?.data?.sha256_email_address;
};

export const orderGetters = {
  getId,
  getPaymentGateway,
  parseItemsForGtm,
  getStatus,
  getCreatedAt,
  getFormattedTotal,
  getShaEmailAddress,
};
