

























import { useUserComplete } from "@konfetti/composables";

import { nextTick } from "@nuxtjs/composition-api";
import { useNotifications } from "~/composables";

/**
 * @filetype Component
 * @description This file contains the social login data, responsible for sign-in/registration with social media
 * */

export default {
  name: "KftSocialLogin",

  setup(props, { emit, root: { $config, $wait } }) {
    const { addNotification } = useNotifications();

    const { getUser } = useUserComplete("general-instance");

    const handleClickSocialLogin = async (socialMediaSlug) => {
      $wait.start("validating-user");

      const optionsToString = (options) =>
        Object.keys(options)
          .map((key) => `${key}=${options[key]}`)
          .join(",");

      const windowOptions = {
        width: 500,
        height: 500,
        menubar: "no",
        resizable: "no",
        location: "yes",
        scrollbars: "no",
        centered: true,
      };

      const socialLoginWindow = window.open(
        `${$config.apiUrl}v1/auth/${socialMediaSlug}/redirect`,
        null,
        optionsToString(windowOptions),
      );

      window.addEventListener("message", async (event) => {
        if (
          event.source === socialLoginWindow &&
          event.data === "loginWindowClosed"
        ) {
          $wait.start("validating-user");
          emit("user-logged-in", socialMediaSlug);
          $wait.end("validating-user");
        } else if (
          event.source === socialLoginWindow &&
          event.data === "loginWindowClosedWithError"
        ) {
          await addNotification({
            type: "error",
            message: "Could not login with social media",
          });
        }
      });

      await nextTick();

      $wait.end("validating-user");
    };
    return {
      /* Variables */
      /* Methods */
      handleClickSocialLogin,
    };
  },
};
