<template>
  <div class="card kft-team-event-request-card">
    <div class="card-body">
      <div class="mb-3">
        <div class="kft-team-event-request-card__title kft-heading--left fs-5">
          {{
            price
              ? $t("product.show.checkout.teamEvent.titleWithPrice", {
                  price: price,
                })
              : $t("product.show.checkout.teamEvent.title")
          }}
        </div>
        <p class="overline mt-3">
          {{ $t("product.show.checkout.teamEvent.description") }}
        </p>
      </div>
      <button
        class="kft-team-event-request-card__button"
        data-bs-target="#eventPageTeamEventModal"
        data-bs-toggle="modal"
        data-test-id="contact-us-button"
        @click="$emit('click:contact-us')"
      >
        <i class="bi bi-people-fill"></i>
        {{ $t("product.show.checkout.teamEvent.contactUs") }}
      </button>
    </div>
  </div>
</template>
<script>
import { KftLink } from "@konfetti-ui/vue";

export default {
  name: "KftTeamEventRequestCard",
  components: {
    KftLink,
  },
  props: {
    price: {
      type: [String, Boolean],
      default: "",
    },
  },
  emits: ["click:contact-us"],
};
</script>

<style lang="scss">
.kft-team-event-request-card {
  --heading-description-margin: var(--spacer-2) 0;
  --heading-description-font-size: var(--overline-font-size);
  --heading-description-font-line-height: var(--overline-line-height);

  &__title {
    font-family: var(--font-family--heading);
    font-weight: var(--font-weight--medium);
    line-height: 1;
  }

  .card-body {
    padding: 1rem;
    text-align: left;
  }

  p {
    line-height: 1.2;
  }

  border: 1px solid $gray-ultralight;

  &__button {
    width: 100%;
    padding: var(--spacer-2) var(--spacer-2);
    border: solid 1px #e7e7e7;
    border-radius: 6px;
    font-size: 14px;
    background-color: #ffffff;
    font-weight: 500;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #373a40;

    &:hover {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 0px 5px;
      color: #000000;
    }

    i {
      margin-right: var(--spacer-1);
      color: #000;
      font-size: 16px;
    }
  }
}
</style>
