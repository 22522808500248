/* eslint-disable  */

export default {
  search: {
    title: "",
    youMayAlsoLike: "Vielleicht gefällt Dir auch",
    searchBoxTitle: "Search for anything here...",
    searchBoxPlaceholder: "Gebe ein, wonach Du suchst",

    algoliaAutocomplete: {
      placeholder: "Stadt eingeben und los",
    },

    algoliaFederatedSearch: {
      placeholder: "Stadt oder Erlebnis suchen",
    },

    selectLocalityComponent: {
      title: "Stadt auswählen und los",
      searchPlaceholder: "Gebe den Namen der Stadt ein",
      typeHint: "Tippe, um weitere Ergebnisse zu sehen",
    },

    selectCategoryComponent: {
      title: "What do you want to do?",
      searchPlaceholder: "Enter a category name",
      typeHint: "Type to see more results",
      numberOfResults: "kein Kurs | 1 Kurs | {n} Kurse",
    },

    selectFiltersComponent: {
      label: "Filtern",
      showInPersonEvents: "Onlinekurse einblenden",
      showEventsWithAvailableDates:
        "Nur Kurse mit verfügbaren Terminen anzeigen",
      showEventsInPreferredLanguage: "Kurssprache auswählen",
      price: "Preis",
    },

    orderByComponent: {
      clickHint: "Wähle eine Option zum Sortieren aus",
    },

    searchResults: {
      default: "Finde Kurse in Deiner Nähe",
      results: "Dein Suchergebnisse: {n} Kurse",
      numberOfResults: "kein Kurs | 1 Kurs | {n} Kurse",
      searchPageTitle: "Suche: {searchQuery}",
      showingAdditionalResults:
        "Finde hier weitere Kurse, die deine Suchkriterien erweitern.",
      theResultsAreOver: "Das waren alle Kurse Deiner Suche.",
      nResultsInCity: "{n} Ergebnisse in {locality}",
      nResultsForCategory: "{n} Ergebnisse für {category}",
      nResultsForSearchQuery: "{n} Ergebnisse für '{searchQuery}'",
      nResultsEverywhere: "{n} Ergebnisse",
    },

    fewResults: {
      similar: "Weitere Suchergebnisse",
      online: "Ähnliche Online-Kurse",
    },

    filters: {
      title: "mehr Filter",
      seeResults: "alle Ergebnisse anzeigen",
      searchAllCities: "Alle Städte",
      pricing: {
        label: "Preis",
        chipLabel: "Preis",
      },
      distance: {
        label: "Suchradius",
        null: "kein",
        withinTen: "plus 10km",
        withinFifty: "plus 50km",
        withinHundred: "plus 100km",
      },
      showMore: "Mehr anzeigen",
      showLess: "Weniger anzeigen",
      keywords: {
        label: "Stichwortsuche",
        chipLabel: "Suche",
        placeholder: "Nach was suchst Du?",
      },
      dates: {
        label: "Datum",
        placeholder: "Datum wählen",
        selectButtonLabel: "Datum wählen",
        clear: "Datum löschen",
      },
      city: {
        label: "Stadt",
        chipLabel: "Stadt",
        all: "Überall suchen",
      },
      category: {
        label: "Kategorie",
        chipLabel: "Kategorie",
      },
      languages: {
        label: "Kurssprache",
        chipLabel: "Kurssprache",
      },
      tag: {
        label: "Tags",
        chipLabel: "Tag",
      },
      minimumTickets: {
        label: "Mindestanzahl Tickets",
        chipLabel: "Minimum Tickets",
      },
      maximumTickets: {
        label: "Maximum Tickets",
        chipLabel: "Maximum Tickets",
      },
      hasAvailableDates: {
        label: "Sofort buchen",
      },
      online: {
        label: "Online",
      },
    },

    buttons: {
      clearAll: "Zurücksetzen",
      filter: "Anwenden",
      confirm: "Ergebnisse anzeigen",
    },

    callToAction: {
      buttonTitle: "Jetzt anmelden",
      title: "Melde Dich für unseren Newsletter an",
      relativePath: "/team-event",
      text: "Bleibe auf dem Laufenden über neue Kurse und Aktionen. Du kannst den Newsletter jederzeit abbestellen.",
    },
  },
};
