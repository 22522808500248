function getCurrentPage(records: Record<any, any>): number {
  const pagination = records?.value?.meta?.pagination || records;
  return pagination?.current_page || records?.current_page || 1;
}

function getTotal(records: Record<any, any>): number {
  const pagination = records?.value?.meta?.pagination || records;

  return pagination?.total || 0;
}

function getTotalPages(records: Record<any, any>): number {
  const pagination = records?.value?.meta?.pagination || records;

  return pagination?.total_pages || 1;
}

function getEmptyPaginationObject(): any {
  return {
    data: [],
    meta: [],
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function validatePagination(pagination): any {
  if (pagination?.data) {
    return pagination;
  }

  return getEmptyPaginationObject();
}

export const paginationGetters = {
  getCurrentPage,
  getTotal,
  getTotalPages,
  getEmptyPaginationObject,
  validatePagination,
};
