<template>
  <div class="kft-header-with-star bold-gray">
    <slot name="before-icon"></slot>
    <i v-if="icon" :class="icon" :style="{color: iconColor, 'font-size': iconFontSize}" class="bi"></i>
    <slot name="after-icon"></slot>
  </div>
</template>
<script>
export default {
  name: "KftHeaderWithIcon",
  props: {
    author: {
      type: String
    },
    iconColor: {
      type: String
    },
    icon: {
      type: String
    },
    iconFontSize: {
      type: String,
      default: "18px"
    },
    date: {
      type: String
    }
  }
};
</script>
<style lang="scss">
@import "~@konfetti-ui/shared/styles/components/molecules/KftHeaderWithStar.scss";
</style>
