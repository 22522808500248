// Modified from https://github.com/commit-intl/micro-down

import { latinize } from "./latinize";

export const kftMicroDown = (function () {
  /*
   * slug helpers
   */
  const serialize = (value) => {
    return (
      value
        .toLowerCase()
        .trim()
        // remove html tags
        .replace(/<[!\/a-z].*?>/gi, "")
        // remove unwanted chars
        .replace(
          /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,./:;<=>?@[\]^`{|}~]/g,
          "",
        )
        .replace(/\s/g, "-")
    );
  };

  const slug = (str) => {
    return latinize(serialize(str));
  };

  /*
   * tag helper
   */
  const tag = (tag, text, values) =>
    `<${
      tag +
      (values
        ? " " +
          Object.keys(values)
            .map((k) => (values[k] ? `${k}="${encode(values[k]) || ""}"` : ""))
            .join(" ")
        : "")
    }>${text}</${tag}>`;
  /**
   * outdent all rows by first as reference
   */
  const outdent = (text) => {
    return text.replace(
      new RegExp("^" + (text.match(/^\s+/) || "")[0], "gm"),
      "",
    );
  };
  /**
   * encode double quotes and HTML tags to entities
   */
  const encode = (text) => {
    return text
      ? text.replace(/"/g, "&quot;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
      : "";
  };
  /**
   * recursive list parser
   */
  const listR = /(?:(^|\n)([+-]|\d+\.) +(.*(\n[ \t]+.*)*))+/g;
  const list = (text, temp) => {
    temp = text.match(/^[+-]/m) ? "ul" : "ol";
    return text
      ? `<${temp}>${text.replace(
          /(?:[+-]|\d+\.) +(.*)\n?(([ \t].*\n?)*)/g,
          (match, a, b) =>
            `<li>${inlineBlock(
              `${a}\n${outdent(b || "").replace(listR, list)}`,
            )}</li>`,
        )}</${temp}>`
      : "";
  };

  /**
   * function chain of replacements
   */
  const chain = (t, regex, replacement, parser) => (match, a) => {
    match = match.replace(regex, replacement);
    return tag(t, parser ? parser(match) : match);
  };
  const block = (text, options) =>
    p(
      text,
      [
        // BLOCK STUFF ===============================

        // comments
        /<!--((.|\n)*?)-->/g,
        "<!--$1-->",

        // pre format block
        /^("""|```)(.*)\n((.*\n)*?)\1/gm,
        (match, wrapper, c, text) =>
          wrapper === "\"\"\""
            ? tag("div", parse(text, options), { class: c })
            : options && options.preCode
            ? tag("pre", tag("code", encode(text), { class: c }))
            : tag("pre", encode(text), { class: c }),

        // blockquotes
        /(^>.*\n?)+/gm,
        chain("blockquote", /^> ?(.*)$/gm, "$1", inline),

        // tables
        /((^|\n)\|.+)+/g,
        chain("table", /^.*(\n\|---.*?)?$/gm, (match, subline) =>
          chain("tr", /\|(-?)([^|]*)\1(\|$)?/gm, (match, type, text) =>
            tag(type || subline ? "th" : "td", tag("div", inlineBlock(text))),
          )(match.slice(0, match.length - (subline || "").length)),
        ),

        // lists
        listR,
        list,
        // anchor
        /#\[([^\]]+?)]/g,
        '<a name="$1"></a>',

        // headlines
        /^(#+) +(.*)(?:$)/gm,
        (match, h, text) =>
          tag("h" + h.length, inlineBlock(text), {
            id: slug(text),
          }),

        // horizontal rule
        /^(===+|---+)(?=\s*$)/gm,
        "<hr>",
      ],
      parse,
      options,
    );
  const inlineBlock = (text, dontInline) => {
    const temp = [];
    const injectInlineBlock = (text) =>
      text.replace(/\\(\d+)/g, (match, code) =>
        injectInlineBlock(temp[Number.parseInt(code) - 1]),
      );

    text = (text || "")
      .trim()
      // inline code block
      .replace(
        /`([^`]*)`/g,
        (match, text) => "\\" + temp.push(tag("code", encode(text))),
      )
      // inline media (a / img / iframe)
      .replace(
        /[!&]?\[([!&]?\[.*?\)|[^\]]*?)]\((.*?)( .*?)?\)|(\w+:\/\/[$\-.+!*'()/,\w]+)/g,
        (match, text, href, title, link) => {
          if (link) {
            return dontInline
              ? match
              : "\\" + temp.push(tag("a", link, { href: link }));
          }
          if (match[0] == "&") {
            text = text.match(/^(.+),(.+),([^ \]]+)( ?.+?)?$/);
            return (
              "\\" +
              temp.push(
                tag("iframe", "", {
                  width: text[1],
                  height: text[2],
                  frameborder: text[3],
                  class: text[4],
                  src: href,
                  title,
                }),
              )
            );
          }
          return (
            "\\" +
            temp.push(
              match[0] == "!"
                ? tag("img", "", { src: href, alt: text, title })
                : tag("a", inlineBlock(text, 1), { href, title }),
            )
          );
        },
      );
    text = injectInlineBlock(dontInline ? text : inline(text));
    return text;
  };
  const inline = (text) =>
    p(
      text,
      [
        // bold, italic, bold & italic
        /([*_]{1,3})((.|\n)+?)\1/g,
        (match, k, text) => {
          k = k.length;
          text = inline(text);
          if (k > 1) text = tag("strong", text);
          if (k % 2) text = tag("em", text);
          return text;
        },

        // strike through
        /(~{1,3})((.|\n)+?)\1/g,
        (match, k, text) => tag([, "u", "s", "del"][k.length], inline(text)),

        // replace remaining newlines with a <br>
        / {2}\n|\n {2}/g,
        "<br>",
      ],
      inline,
    );
  const p = (text, rules, parse, options) => {
    let i = 0;
    let f;
    while (i < rules.length) {
      if ((f = rules[i++].exec(text))) {
        return (
          parse(text.slice(0, f.index), options) +
          (typeof rules[i] === "string"
            ? rules[i].replace(/\$(\d)/g, (m, d) => f[d])
            : rules[i].apply(this, f)) +
          parse(text.slice(f.index + f[0].length), options)
        );
      }
      i++;
    }
    return text;
  };
  const parse = (text, options) => {
    // clean input
    text = text
      .replace(/[\r\v\b\f]/g, "")
      .replace(/\\./g, (match) => `&#${match.charCodeAt(1)};`);

    let temp = block(text, options);

    if (temp === text && !temp.match(/^[\s\n]*$/i)) {
      temp = inlineBlock(temp)
        // handle paragraphs
        .replace(/((.|\n)+?)(\n\n+|$)/g, (match, text) => tag("p", text));
    }

    return temp.replace(/&#(\d+);/g, (match, code) =>
      String.fromCharCode(parseInt(code)),
    );
  };
  return { parse, block, inline, inlineBlock };
})();
