export const middlewareConfig = {
  integrations: {
    konfetti: {
      location: "@konfetti/api/server",
      configuration: {
        api: {
          url: process.env.API_BASE_URL || "https://api.hml.gokonfetti.com/"
        },
        strapiApi: {
          url:
            process.env.STRAPI_API_BASE_URL ||
            "https://api-cms.gokonfetti.com/api/"
        },
        algolia: {
          appId: process.env.ALGOLIA_APP_ID,
          apiClientKey: process.env.ALGOLIA_API_CLIENT_KEY
        },
        appBaseUrl: process.env.BASE_URL || "https://gokonfetti.com/",
        currency: "EUR",
        country: "DE"
      }
    }
  }
};
