<template>
  <div :class="{ 'text-center': $device.isMobile }" class="useful-links">
    <i class="useful-links__icon bi bi-check-circle-fill d-block d-md-none"></i>
    <KftHeading
      :h-class="'h2'"
      :level="2"
      :title="$t('footer.usefulLinks')"
      :use-h-tag="false"
      class="mb-8 mb-md-4 kft-product-showcase__heading kft-heading--left"
    />
    <div
      class="d-flex flex-wrap justify-content-center justify-content-md-start useful-links__chips"
    >
      <KftChip
        v-for="tab in footerData"
        :key="`app-links-tab-chip-${tab.id}`"
        :label="tab.title"
        class="me-4 chip--interactive mt-4"
        @pressed="currentLinksTab = tab.id"
      />
    </div>
    <div
      v-for="(section, i) in footerData"
      v-show="section.id === currentLinksTab"
      :key="`${section.id}-tabs`"
      class="row useful-links__links-list"
      :data-test-id="`useful-links-${i}`"
    >
      <div
        v-for="(item, i) in section.items"
        :key="`section-${section.id}-${item.label}-tab-links-${i}`"
        :class="{
          'col-4': $device.isDesktopOrTablet,
          'col-6': !$device.isDesktopOrTablet,
        }"
      >
        <KftLink :link="localePath(item.link)" class="mb-5"
          >{{ item.label }}
        </KftLink>
      </div>
    </div>
  </div>
</template>

<script>
import { KftLink, KftHeading } from "@konfetti-ui/vue";
import { useStrapiSingleType } from "@konfetti/composables";
import { defineComponent, ref, computed } from "@nuxtjs/composition-api";
import { onSSR } from "@konfetti-core/core";
import KftChip from "./atoms/KftChip/KftChip.vue";

export default defineComponent({
  components: {
    KftChip,
    KftLink,
    KftHeading,
  },

  setup(props, { root: { $i18n } }) {
    const currentLinksTab = ref(null);

    const { singleType: eventListPagesLinksFooterData, loadSingleType } =
      useStrapiSingleType("event-list-pages-links-footer");

    onSSR(async () => {
      if (!eventListPagesLinksFooterData.value) {
        await loadSingleType(
          `event-list-pages-links-footer?locale=${$i18n.locale}&populate[footerBlock][populate]=*`,
          {
            locale: $i18n.locale,
          },
        );
      }
    });

    const footerData = computed(() => {
      const data =
        eventListPagesLinksFooterData?.value?.data?.attributes?.footerBlock;

      currentLinksTab.value = currentLinksTab.value ?? data?.[0]?.id ?? 0;

      return data;
    });

    return {
      currentLinksTab,
      footerData,
    };
  },
});
</script>

<style lang="scss">
.useful-links {
  margin: 0 auto;

  .kft-link a {
    color: #373a40;
    font-family: var(--font-family--heading);

    &:hover {
      color: $primary;
      text-decoration: underline !important;
    }
  }

  &__bar {
    height: 5px;
    width: 60px;
    background-color: $teal;
    margin-bottom: var(--spacer-5);
  }

  &__icon {
    color: black;
    font-size: var(--h3-font-size);
    text-align: center;
    margin-bottom: var(--spacer-5);
  }

  &__heading {
    text-align: center;
    margin: 0;
  }

  &__chips {
    margin-bottom: var(--spacer-7);
    row-gap: var(--spacer-2);

    .chip__content {
      font-size: var(--font-size--sm);
    }
  }

  &__links-list {
    text-align: left;
  }

  @include for-tablet-and-up {
    margin: 0;

    &__bar {
      margin-bottom: var(--spacer-4);
    }

    &__heading {
      text-align: left;
      line-height: 45px !important;
    }

    &__chips {
      margin-bottom: var(--spacer-9);
    }
  }
}

.link-heading {
  @include for-desktop {
    font-size: 1.5rem !important;
  }
}
</style>
