/* eslint-disable camelcase */
import { Logger, sharedRef } from "@konfetti-core/core";
import { computed } from "@vue/composition-api";
import { JwtService } from "@konfetti/api";
import { useApiHandler } from "../useApiHandler";
import { fnCreateOrderObject } from "../helpers";

export const useStripe = (
  id: string,
  hasAccessToWindowObject: boolean,
): any => {
  const order = sharedRef(false, `useOrder/useStripe-order-${id}`);

  /* Stripe */
  const error = sharedRef(null, `useOrder/useStripe-error-${id}`);
  const loading = sharedRef(false, `useOrder/useStripe-loading-${id}`);

  /** Wrapper for API requests */
  const { setJwtToken, makeRequest } = useApiHandler(hasAccessToWindowObject);

  const sendOrder = async (
    cardToken: string,
    items: Array<any>,
    shippingAddress: any = null,
    invoiceAddress: any = null,
    coupon: string = null,
    paymentIntentId: string = null,
    customToken: string = null,
    external = false,
    paymentMethod = "CREDIT_CARD",
    useBalance = true,
  ) => {
    Logger.debug(`useOrder/${id}/sendOrder`, cardToken);

    loading.value = true;

    try {
      let token =
        process.client && hasAccessToWindowObject
          ? JwtService.getToken()
          : undefined;
      if (customToken !== null) {
        token = customToken;
      }

      const params = fnCreateOrderObject(
        "STRIPE",
        {
          cardToken: cardToken,
          paymentIntentId: paymentIntentId,
        },
        items,
        shippingAddress,
        invoiceAddress,
        coupon,
        external,
        paymentMethod,
        useBalance,
      );

      setJwtToken(token);
      order.value = await makeRequest("sendOrder", params);
      error.value = null;
    } catch (err) {
      if (err?.response?.status === 406) {
        const error = err.response.data?.[0] || err.response.data;

        order.value = {
          code: 406,
          clientSecret: null,
          message: null,
          data: {
            id: null,
          },
        };

        order.value.clientSecret = error.client_secret;
        order.value.data.id = error.order_id;
        order.value.message = error.message;

        return;
      }

      error.value = err?.response?.data || err;
      Logger.error(`useOrder/${id}/sendOrder`, err);
    } finally {
      loading.value = false;
    }
  };

  return {
    order: computed(() => order.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value),

    sendOrder,
  };
};
