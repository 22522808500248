import { Context } from '@konfetti-core/core';

export async function validateCartItems (context: Context, params: any): Promise<any> {
  const url = new URL('v1/checkout/cart/validate', context.config.api.url);

  // Use axios to send a GET request
  const { data } = await context.client.post(url.href, { items: params.items });
  // Return data from the API
  return data;
}
