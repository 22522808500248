var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kft-modal"},[_c('div',{class:{
      'modal fade': true,
      show: _vm.showModal,
    },attrs:{"aria-hidden":_vm.showModal,"tabindex":"-1"},on:{"click":_vm.hide}},[(!_vm.cardContainer)?_c('div',{staticClass:"raw-modal"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hide),expression:"hide"}]},[_vm._t("default")],2)]):_vm._e(),_vm._v(" "),(_vm.cardContainer)?_c('div',{staticClass:"modal-dialog",class:{
        'modal--lg': _vm.size === 'lg', // helps purge discover this class
        'modal--xl': _vm.size === 'xl', // helps purge discover this class
        'modal--full': _vm.size === 'full', // helps purge discover this class
        'modal--auto': _vm.size === 'auto', // helps purge discover this class
      },on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"modal-content"},[_vm._t("modal-header",function(){return [(!_vm.hideHeader)?_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[_vm._t("title",function(){return [_vm._v("\n                "+_vm._s(_vm.title)+"\n                "),(_vm.tooltipText)?_c('KftTooltip',{key:_vm.tooltipText,attrs:{"styles":{ 'font-size': '16px' },"tooltip-text":_vm.tooltipText,"length":"large","parent":"<i class='bi bi-info-circle-fill'></i>","type":"span"}}):_vm._e()]})],2),_vm._v(" "),_c('button',{staticClass:"btn-close",attrs:{"aria-label":"Close","data-bs-dismiss":"modal","type":"button"},on:{"click":_vm.hide}})]):_vm._e()]}),_vm._v(" "),_c('div',{directives:[{name:"dragscroll",rawName:"v-dragscroll.y",modifiers:{"y":true}}],staticClass:"modal-body"},[_vm._t("default")],2),_vm._v(" "),_vm._t("modal-footer")],2)]):_vm._e()]),_vm._v(" "),(_vm.showModal)?_c('div',{class:{
      'modal-backdrop fade': true,
      'background-transparent': _vm.size === 'full',
      show: _vm.showModal,
    },on:{"click":_vm.hide}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }