import { LogLevelStyle, LogName } from "./types";
import { mountLog } from "./utils";

export function makeMessageStyle(logEnum: LogName) {
  switch (logEnum) {
    case LogName.Error:
      return mountLog(
        "[KFT][error]",
        LogLevelStyle.Error
      );
    case LogName.Info:
      return mountLog(
        "[KFT][info]",
        LogLevelStyle.Info
      );
    case LogName.Warn:
      return mountLog(
        "[KFT][warn]",
        LogLevelStyle.Warn
      );
    case LogName.Debug:
      return mountLog(
        "[KFT][debug]",
        LogLevelStyle.Log
      );
    case LogName.None:
    default:
      return mountLog(
        "[KFT]",
        LogLevelStyle.Log
      );
  }
}
