import { Tag } from "../tags/tag";

export enum EventDateType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
  PUBLIC_PRIVATE = "PUBLIC_PRIVATE",
}

export enum EventDateStatus {
  OPEN = "OPEN",
  SOLD_OUT = "SOLD_OUT",
}
export type Price = {
  amount: string | number;
  currency: string;
  formatted: string;
};

type TODO = unknown;

export type Photo = TODO;
export type Category = TODO;
export type Address = TODO;
export type Supplier = TODO;
export type Review = TODO;

export type EventDescription = {
  object: "EventMetaData";
  id: string;
  description: string;
  title: string;
  required_address: number;
  is_team_event: number;
  default_price: Price;
  type: string;
  status: string;
  approved: true;
  languages: string[];
  permalink: string;
  meta_description: string;
  timezone: string;
  subtitle: string;
  location_summary: null;
  duration_summary: null;
  default_duration: number;
  what_is_included_summary: string;
  private_event_description: null;
  team_events_and_gift_cards_summary: string;
  covid_19_summary: string;
  what_to_bring: string;
  what_is_included: string;
  next_instance: EventDate;
  min_price: Price;
  max_price: Price;
  min_date: number;
  max_date: number;
  total_tickets_available: number;
  min_duration: number;
  max_duration: number;
  total_tickets_sold: number;
  total_giftcards_sold: number;
  created_at: number;
  updated_at: string;
  readable_created_at: string;
  readable_updated_at: string;
  photos: Photo[];
  content_locale: string;
  tags: { data: Tag[] };
  avg_review: number;
  total_reviews: number;
  categories: {
    data: Category[];
  };
  address: {
    data: Address;
  };
  supplier: {
    data: Supplier;
  };
  reviews?: {
    data: {
      average: number;
      total: number;
      reviews: Review[];
    };
  };
  reviewsNew?: {
    data: Review[];
  };
};

export type EventDate = {
  availableTicketsQuantity: number;
  children?: TODO;
  dateType: EventDateType;
  duration: number;
  end: string;
  id: string;
  privateMaxTickets: number;
  privateMinTickets: number;
  product: {
    data: {
      id: string;
      object: string;
      price: Price;
      ship: boolean;
      sku: string;
      title: string;
      type: string;
    };
  };
  start: string;
  status: EventDateStatus;
};
