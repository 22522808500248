<template>
  <div>
    <KftModal
      v-if="showModal"
      id="subscribeToEventUpdatesModal"
      ref="modal"
      size="sm"
      @hidden="fnHandleHide"
    >
      <template #modal-header>
        <KftModalSimpleHeader class="pb-2" @close="modal.hide()">
          <template #title>
            <KftHeading
              :level="4"
              :title="
                $t(
                  `product.subscribeToEventUpdatesModal.${
                    subscribed ? 'secondStep' : 'firstStep'
                  }.title`,
                )
              "
              class="kft-heading--left"
            />
          </template>
        </KftModalSimpleHeader>
      </template>

      <div v-if="!subscribed">
        <p class="overline">
          {{ $t("product.subscribeToEventUpdatesModal.firstStep.description") }}
        </p>

        <form class="w-100" @submit.prevent.stop="fnSubmit()">
          <div class="el-form-validation-margin mt-2">
            <label class="required form-label overline" for="email">{{
              $t(
                "product.subscribeToEventUpdatesModal.firstStep.form.email.label",
              )
            }}</label>
            <input
              id="email"
              v-model="form.email"
              :disabled="isLoading"
              :placeholder="
                $t(
                  'product.subscribeToEventUpdatesModal.firstStep.form.email.placeholder',
                )
              "
              autocomplete="email"
              class="form-control w-100"
              name="email"
              @input="clearErrors('email')"
            />

            <span v-if="invalidKeys.includes('email')" class="text-red fs-6">
              {{ errors.email }}
            </span>
          </div>
        </form>
        <button
          :disabled="isLoading"
          class="btn btn-primary mt-4 w-100 d-flex align-items-center justify-content-center"
          @click.prevent="fnSubmit"
        >
          <span class="ms-2">
            {{
              $t(
                "product.subscribeToEventUpdatesModal.firstStep.submitButtonLabel",
              )
            }}
          </span>
        </button>
      </div>

      <div v-else>
        <p class="mb-0 overline">
          {{
            $t("product.subscribeToEventUpdatesModal.secondStep.description")
          }}
        </p>

        <div
          class="subscribe-to-event-updates__icon-block mt-7 py-2 px-4 d-flex align-items-center"
        >
          <i class="bi bi-envelope-check-fill me-2"></i>
          <span class="overline">
            {{
              $t(
                "product.subscribeToEventUpdatesModal.secondStep.iconBlockText",
              )
            }}</span
          >
        </div>

        <button
          class="btn btn-primary mt-7 w-100"
          @click.prevent="modal.hide()"
        >
          {{
            $t(
              "product.subscribeToEventUpdatesModal.secondStep.submitButtonLabel",
            )
          }}
        </button>
      </div>
    </KftModal>
  </div>
</template>

<script>
import { KftHeading, KftModal } from "@konfetti-ui/vue";
import { nextTick, ref } from "@nuxtjs/composition-api";
import VsToast from "@vuesimple/vs-toast";
import { useProduct } from "@konfetti/composables";
import KftModalSimpleHeader from "../../molecules/KftModalSimpleHeader/KftModalSimpleHeader.vue";
import { useFormValidation } from "@/composables/useFormValidation";

export default {
  name: "KftSubscribeToEventUpdates",
  components: {
    KftHeading,
    KftModal,
    KftModalSimpleHeader,
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
  },
  emits: {},
  setup(props, context) {
    const { $i18n, $route } = context.root;
    const form = ref({ email: "" });
    const modal = ref(null);
    const subscribed = ref(false);
    const showModal = ref(false);

    const rules = ref({
      email: "required|email",
    });

    const {
      subscribeEmailToEventUpdates,
      error: requestErrors,
      loading: isLoading,
    } = useProduct(`product-page-${$route.params.event?.toString()}`);

    const { errors, clearErrors, validate, invalidKeys } = useFormValidation(
      form,
      rules,
    );

    const fnHandleShow = async () => {
      showModal.value = true;
      await nextTick();
      modal.value.show();
    };

    const fnHandleHide = () => {
      showModal.value = false;
      subscribed.value = false;
      form.value = { email: "" };
      modal.value.hide();
    };

    const fnSubmit = async () => {
      context.root.$wait.start("validating-email");

      await validate();

      if (errors.value !== null && Object.keys(errors.value)?.length > 0) {
        VsToast.show({
          title: $i18n.t("general.error"),
          position: "bottom-right",
          variant: "error",
          message: errors.value?.[0]?.message || $i18n.t("general.error"),
        });
        setTimeout(() => context.root.$wait.end("validating-email"), 1000);

        return;
      }

      setTimeout(() => context.root.$wait.end("validating-email"), 1000);

      context.root.$wait.start("subscribing-email-to-event");
      await subscribeEmailToEventUpdates({
        permalink: props.eventId,
        email: form.value.email,
      });

      if (requestErrors.value !== null) {
        VsToast.show({
          title: $i18n.t("general.error"),
          position: "bottom-right",
          variant: "error",
          message: $i18n.t("general.error"),
        });
        setTimeout(
          () => context.root.$wait.end("subscribing-email-to-event"),
          1000,
        );

        return;
      }
      setTimeout(
        () => context.root.$wait.end("subscribing-email-to-event"),
        1000,
      );

      subscribed.value = true;
    };

    return {
      /* Variables */
      form,
      isLoading,
      invalidKeys,
      errors,
      modal,
      subscribed,
      showModal,
      fnHandleHide,
      fnHandleShow,

      /* Methods */
      fnSubmit,
      clearErrors,
    };
  },
};
</script>

<style lang="scss">
@import "~/assets/components/organisms/KftSubscribeToEventUpdates.scss";
</style>
