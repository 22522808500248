export const render = {
  http2: {
    push: true,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    pushAssets: (request, response, publicPath, preloadFiles) => {
      const files = preloadFiles
        .filter(({ asType }) => asType === "script")
        .map(
          ({ file, asType }) =>
            `<${publicPath}${file}>; rel=preload; as=${asType}`
        );

      return [...files];
    }
  },

  ssrLog:
    process.env.APP_ENV !== "production" && process.env.APP_ENV !== "prod",

  fallback: false,

  compressor: {
    threshold: 8192
  },

  static: {
    maxAge: 1000 * 60 * 60 * 24 * 7
  },

  resourceHints: true,
  asyncScripts: true,

  bundleRenderer: {
    // shouldPrefetch: (file, type) => {
    //   return true;
    // },

    shouldPreload: (file, type) => {
      return ["font"].includes(type);
    }
  }
};
