const getReviewRating = (review): any => {
  return review?.rating || null;
};

const getCustomer = (review): any => {
  return review?.customer || null;
};

const getReview = (review): any => {
  return review?.review || null;
};

const getCreatedAt = (review): any => {
  return review?.created_at || null;
};

const getId = (review): any => {
  return review?.id || null;
};
const getAuthor = (review): any => {
  return review?.customer.split(" ")[0] || review?.customer || null;
};

export const reviewGetters = {
  getReviewRating,
  getCustomer,
  getReview,
  getCreatedAt,
  getId,
  getAuthor,
};
