/* eslint-disable  */

// @francesca - imgs are here: t.ly/YPPq  - they're fine already on the home, just for reference

export default {
  home: {
    supplierEvents: "Kursübersicht",
    browseTopEvents: "Top Erlebnisse in Deiner Nähe",
    inspirationForYou: "Endlich kreative Gutscheine verschenken",
    recommendedForYou: "Entdecke die beliebtesten Erlebnisse",
    newEventsForYou: "Neue Kurse und Erlebnisse in Deiner Nähe",
    lastViewedEvents: "Zuletzt angesehen",
    browseCategories: "Entdecke Kurse nach Kategorie",
    header: {
      title: 'Kurse in Deiner <span class="brush-wrap">Nähe</span>',
      description:
        "Über 4.500 Kurse und Erlebnisse - als Gutschein oder Ticket. Das perfekte Weihnachtsgeschenk.",
      descriptionMobile:
        "Über 4.500 Kurse und Erlebnisse - als Gutschein oder Ticket. Das perfekte Weihnachtsgeschenk.",
      titleTextColor: "#FFFFFF",
      bgColor: "#1B7175",
      image: "/homepage/homepage-header.webp",
      imageMobile: "/homepage/homepage-header-mobile.webp",
      search: {
        selectCity: "Stadt",
        selectCategory: "Alle Kurse",
        button: "Suchen",
      },
    },
    headerButtonTitle: "Teamevent anfragen",
    callToAction: {
      title: "Melde Dich für unseren Newsletter an",
      subtitle:
        "Bleibe auf dem Laufenden über neue Kurse und Aktionen. Du kannst den Newsletter jederzeit abbestellen.",
      buttonText: "ANMELDEN",
      inputPlaceholderText: "hello@gokonfetti.com",
      image: "/homepage/CTA.webp",
      mobileImage: "/homepage/CTA.webp",
    },
    heroes: {
      first: {
        title: "Der konfetti Gutschein für Kurse & Erlebnisse",
        subtitle:
          "Überrasche jemanden mit dem perfekten Geschenk! Verschenke mit unserem Gutschein mehr als 4.500 einzigartige Kurse und Workshops. Ein tolles Geschenk für jeden Anlass. Bei der großen Auswahl an Möglichkeiten zauberst Du Beschenkten ein Lächeln ins Gesicht. Einfach mal etwas Neues ausprobieren und eine tolle Zeit schenken. Verschenke Erinnerungen, die ein Leben lang bleiben.",
        image: "/homepage/heroFirst.webp",
        mobileImage: "/homepage/heroFirstMobile.webp",
        buttonText: "Gutschein kaufen",
      },
      second: {
        title: "Einzigartiges Teamevent buchen",
        subtitle:
          "Auf der Suche nach dem passenden Teamevent? Wähle aus mehr als 4.500 spannenden Erlebnissen. Gern unterstützen wir Euch mit unserer Expertise und übernehmen die Planung. Frage jetzt ein unverbindliches Angebot an.",
        image: "/homepage/TeameventCTA.webp",
        mobileImage: "/homepage/heroSecondMobile.webp",
        buttonText: "Teamevent anfragen",
      },
      third: {
        title: "Werde konfetti Partner",
        subtitle:
          "Du möchtest noch mehr Menschen Spaß und Freude mit Deinen Kursen und Workshops schenken? Wir machen das für Dich möglich. Bei konfetti kannst Du Deine Kurse managen und veröffentlichen und somit noch mehr Menschen mit Deinen Events begeistern.",
        image: "/homepage/GitcardCTA.webp",
        mobileImage: "/homepage/heroThirdMobile.webp",
        buttonText: "Jetzt bewerben",
      },
    },
    organizationSchemaJson: {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "konfetti",
      url: `https://gokonfetti.com/de-de/`,
      sameAs: [
        "https://www.linkedin.com/company/gokonfetti/",
        "https://www.facebook.com/gokonfetti/",
        "https://www.instagram.com/gokonfetti/",
        "https://www.pinterest.de/gokonfetti/",
      ],
      logo: "https://gokonfetti.com/logos/full-logo-color-black.svg",
      contactPoint: [
        {
          "@type": "ContactPoint",
          telephone: "+4930257706800",
          email: "hallo@gokonfetti.com",
          contactType: "customer service",
        },
      ],
    },
  },
};
