const buildModules = [
  // @todo: enable this modules later
  // '@nuxtjs/pwa',
  "nuxt-purgecss",
  "@nuxtjs/style-resources",
  "@nuxtjs/device",
  "@nuxt/typescript-build",
  "@konfetti/composables/nuxt",
  [
    "@konfetti-core/nuxt",
    {
      useRawSource: {
        dev: ["@konfetti/composables", "@konfetti-core/core"],
        prod: ["@konfetti/composables", "@konfetti-core/core"],
      },
    },
  ],
  ["@nuxtjs/router", { keepDefaultRouter: true }],
  "@nuxtjs/router-extras",
];

if (process.env.APP_ENV === "production" || process.env.APP_ENV === "homolog") {
  // buildModules.push('nuxt-webpack-optimisations');
}

export { buildModules };
