import { Logger, sharedRef } from "@konfetti-core/core";
import { computed } from "@vue/composition-api";
import { useApiHandler } from "../useApiHandler";
import { SendReviewParams } from "@konfetti/api";

export const useReview = (id: string): any => {
  const review = sharedRef(null, `useReview/review-${id}`);
  const loading = sharedRef(false, `useReview/loading-${id}`);
  const error = sharedRef(null, `useReview/error-${id}`);

  const { makeRequest } = useApiHandler();

  const sendEventReview = async (params: SendReviewParams) => {
    Logger.debug(`useReview/${id}/sendEventReview`, params);

    try {
      loading.value = true;
      review.value = await makeRequest("sendEventReview", params);

      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useReview/${id}/sendEventReview`, err);
    } finally {
      loading.value = false;
    }
  };

  return {
    // Variables
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    review: computed(() => review.value),

    // Methods
    sendEventReview,
  };
};
