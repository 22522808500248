import { Context } from "@konfetti-core/core";

export async function getEvents(
  context: Context,
  params: string,
): Promise<any> {
  const url = new URL("v1/store/events" + params, context.config.api.url);
  // Use axios to send a GET request
  const { data } = await context.client.get(url.href);
  // Return data from the API
  return data;
}

export async function getEventAvailableDatesWithSoldOutAndExpired(
  context: Context,
  id: string,
  params: string,
): Promise<any> | null {
  const url = new URL(
    `v1/store/events/${id}/dates?${params}`,
    context.config.api.url,
  );
  // Use axios to send a GET request
  const { data } = await context.client.get(url.href);

  // Return data from the API
  return data;
}

export async function getEventByPermalink(
  context: Context,
  permalink: string,
): Promise<any> | null {
  if (typeof permalink !== "string") {
    return null;
  }

  const url = new URL(
    `v1/store/events/${permalink}?include=supplier.logo,address.locality,reviews,reviewsNew`,
    context.config.api.url,
  );
  // Use axios to send a GET request
  const { data } = await context.client.get(url.href);
  // Return data from the API
  return data;
}

export async function subscribeEmailToEventUpdates(
  context: Context,
  permalink: string,
  payload: any,
): Promise<any> | null {
  if (typeof permalink !== "string") {
    return null;
  }

  const url = new URL(
    `v1/store/events/${permalink}/subscribe`,
    context.config.api.url,
  );
  // Use axios to send a GET request
  const { data } = await context.client.post(url.href, payload);
  // Return data from the API
  return data;
}

export async function requestDates(
  context: Context,
  params: any,
): Promise<any> | null {
  const url = new URL("v1/store/date-requests", context.config.api.url);
  const { data } = await context.client.post(url.href, params);
  return data;
}

export async function getEventPreviewByPermalink(
  context: Context,
  permalink: string,
): Promise<any> | null {
  if (typeof permalink !== "string") {
    return null;
  }

  const url = new URL(
    `v1/store/events/${permalink}/preview?include=supplier.logo,address.locality`,
    context.config.api.url,
  );
  // Use axios to send a GET request
  const { data } = await context.client.get(url.href);
  // Return data from the API
  return data;
}

export async function getEventById(
  context: Context,
  id: string,
): Promise<any> | null {
  if (typeof id !== "string") {
    return null;
  }

  const url = new URL(`v1/store/events/id-${id}`, context.config.api.url);
  // Use axios to send a GET request
  const { data } = await context.client.get(url.href);
  // Return data from the API
  return data;
}

export async function getEventAvailableDates(
  context: Context,
  id: string,
): Promise<any> | null {
  const url = new URL(
    `v1/store/events/${id}/available-dates?include=product`,
    context.config.api.url,
  );
  // Use axios to send a GET request
  const { data } = await context.client.get(url.href);
  // Return data from the API
  return data;
}

export async function getAvailabilityByEventIds(
  context: Context,
  ids: string[],
): Promise<any> | null {
  const params = ids.map((x) => `ids[]=${x}`).join("&");

  const url = new URL(
    `v1/store/events/availability?${params}`,
    context.config.api.url,
  );
  // Use axios to send a GET request
  const { data } = await context.client.get(url.href);
  // Return data from the API
  return data;
}

export async function getGiftboxPrice(context: Context): Promise<any> {
  const url = new URL("v1/store/gift-card/box-price", context.config.api.url);

  const { data } = await context.client.get(url.href);

  return data;
}
