var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"separator my-3"}),_vm._v(" "),_c('div',{staticClass:"mt-6 font-family-heading kft-buy-box__giftcard-title"},[_vm._v("\n    "+_vm._s(_vm.sectionTitle
        ? _vm.sectionTitle
        : _vm.$t("product.show.checkout.giftcard.sectionTitle"))+"\n  ")]),_vm._v(" "),_c('label',{staticClass:"kft-buy-box__label overline"},[_vm._v(_vm._s(_vm.$t("product.show.checkout.quantity")))]),_vm._v(" "),_c('multiselect',{staticClass:"w-100",attrs:{"allow-empty":false,"options":Array.from({ length: 25 }, function (_, i) { return i + 1; }),"placeholder":_vm.$t('product.show.checkout.quantity'),"searchable":false,"show-labels":false},scopedSlots:_vm._u([{key:"noResult",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.$t("general.select.noResultsFound"))+"\n    ")]},proxy:true}]),model:{value:(_vm.selectedGiftcardQuantity),callback:function ($$v) {_vm.selectedGiftcardQuantity=$$v},expression:"selectedGiftcardQuantity"}},[_c('div',{staticClass:"kft-buy-box__checkout-container__multiselect-arrow"},[_c('i',{staticClass:"bi bi-chevron-down"})])]),_vm._v(" "),_c('button',{class:[
      'btn btn-shadow w-100 mt-4',
      {
        'btn-primary': _vm.primaryButton,
      },
      {
        'btn-light-primary': !_vm.primaryButton,
      },
      'kft-buy-box__add-giftcard-button',
      {
        'embedded-btn mt-5': _vm.enableEmbedded,
      } ],style:(_vm.cssVars),on:{"click":_vm.gift}},[_vm._v("\n    "+_vm._s(_vm.bookGiftcardText
        ? _vm.bookGiftcardText
        : _vm.$t("product.show.checkout.bookGiftcard"))+"\n  ")]),_vm._v(" "),_c('hr',{staticClass:"my-2"}),_vm._v(" "),_c('KftGiftcardBookingIcons',{staticClass:"my-4",attrs:{"first-label":_vm.enableEmbedded || _vm.enableSubdomain
        ? _vm.$t('product.show.checkout.embeddedGiftcard.iconsLabels.first')
        : _vm.$t('product.show.checkout.giftcard.validity'),"second-label":_vm.enableEmbedded || _vm.enableSubdomain
        ? _vm.$t('product.show.checkout.embeddedGiftcard.iconsLabels.second')
        : _vm.$t('product.show.checkout.giftcard.forAnyKonfetti'),"third-label":_vm.enableEmbedded || _vm.enableSubdomain
        ? _vm.$t('product.show.checkout.embeddedGiftcard.iconsLabels.third')
        : _vm.$t('product.show.checkout.giftcard.giftExperiences')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }