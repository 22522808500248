/* eslint-disable  */

export default {
  account: {
    balance: "beträgt Dein Kontostand",
    generalInfo: {
      label: "Allgemeine Informationen",
      latestPurchase: "Letzte Bestellung",
      latestPurchaseSubtitle: "Deine letzte Bestellung bei konfetti.",
      accountBalance: "Kontostand",
      accountBalanceSubtitle: "Benutze Dein Guthaben zum Kauf von weiteren Tickets. Es wird automatisch im Checkout angerechnet.",
      yourAccountBalance: "Dein Kontostand",
    },
    orders: {
      label: "Bestellungen",
      review: "Bewertung abgeben",
      downloadPdf: "PDF herunterladen",
    },
    userInfo: {
      label: "Benutzerinformationen",
      currentPassword: "Aktuelles Passwort",
      newPassword: "Neues Passwort",
      passwordConfirmation: "Passwortbestätigung",
    },
    referral: {
      label: "Freunde werben",
      instructions: {
        title: "Empfehle uns weiter und erhalte 20 €.",
        list: [
          "Teile Deinen persönlichen Code",
          "Deine Freunde erhalten 20 € Rabatt",
          "Du erhältst 20 € Guthaben nach dem Kauf",
        ]
      },
      codeInfo: {
        title: "Hier ist Dein persönlicher Code",
        shareButton: "Jetzt teilen"
      },
      faq: {
        items: [
          {
            question: "Wieviele Freunde kann ich werben, gibt es ein Limit?",
            answer: "Du kannst so viele Freunde einladen wie Du möchtest. Es besteht kein Limit."
          },
          {
            question: "Ist der Gutschein nur für Neukunden gültig?",
            answer: "Ja, der Gutschein ist nur für Neukunden gültig."
          },
          {
            question: "Wann erhalte ich mein Guthaben?",
            answer: "Du erhältst Dein Guthaben nach dem erfolgreichen Kauf Deiner Freunde."
          },
          {
            question: "Gibt es ein Mindestbestellwert für meinen Freund?",
            answer: "Ja, der Warenkorb muss mindestens 75 € betragen."
          },
          {
            question: "Wie kann ich mein Guthaben nutzen?",
            answer: "Du kannst Dein Guthaben für zukünftige Käufe bei konfetti nutzen und es wird automatisch im Checkout berücksichtigt."
          },
        ]

      }
    },
    notifications: {
      label: "Benachrichtigungen",
      newsletterSubscribe: "Newsletter abonnieren",
    },
    password: {
      label: "Passwort",
    },
  },
};
