import { Logger, sharedRef } from "@konfetti-core/core";
import { computed } from "@vue/composition-api";
import { useApiHandler } from "../useApiHandler";

export const useArticle = (id: string): any => {
  const articles = sharedRef(null, `useArticle-articles-${id}`);
  const loading = sharedRef(false, `useArticle-loading-${id}`);
  const error = sharedRef(null, `useArticle-error-${id}`);

  /** Wrapper for Strapi API requests */
  const { makeStrapiRequest } = useApiHandler();

  // Method to call an API endpoint and update `categories`, `loading` and `error` properties
  const loadArticle = async (params: string) => {
    Logger.debug(`useArticles/${id}/loadArticles`, params);

    try {
      loading.value = true;
      articles.value = await makeStrapiRequest("getArticleBySlug", params);

      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useArticle/${id}/loadArticle`, err);
    } finally {
      loading.value = false;
    }
  };

  return {
    // Variables
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    articles: computed(() => articles.value),

    // Methods
    loadArticle,
  };
};
