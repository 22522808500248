<template>
  <div class="kft-simple-card-wrapper">
    <div class="kft-simple-card d-md-block">
      <div
        v-if="circleContent"
        class="kft-simple-card__circle mb-5"
        v-html="circleContent"
      ></div>

      <div
        v-if="image || $slots.image"
        class="kft-simple-card__image-wrapper d-block"
      >
        <div
          class="kft-button--pure kft-simple-card__link text-center"
          v-on="$listeners"
        >
          <slot
            name="image"
            v-bind="{ image, title, link, imageHeight, imageWidth }"
          >
            <SfImage
              :alt="title"
              :height="$device.isDesktopOrTablet ? 280 : 270"
              :src="image"
              class="kft-simple-card__image mx-auto d-block"
              width="100%"
            />
          </slot>
        </div>
      </div>

      <div class="kft-simple-card__content text-start">
        <div class="kft-button--pure kft-simple-card__link">
          <slot name="title" v-bind="{ title, link }">
            <div class="kft-simple-card__title">
              {{ title }}
            </div>
          </slot>
        </div>
        <div
          v-if="description"
          class="kft-button--pure kft-simple-card__link mt-md-3"
        >
          <slot name="description" v-bind="{ description, link }">
            <span class="kft-simple-card__description">
              {{ description }}
            </span>
          </slot>
        </div>
        <slot name="linkText" v-bind="{ linkText, link }">
          <KftBrushStrokeButton
            v-if="linkText && link"
            :link="link"
            class="kft-simple-card__link mt-3"
            v-on="$listeners"
          >
            {{ linkText }}
          </KftBrushStrokeButton>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import { KftButton, SfImage } from "@konfetti-ui/vue";
import KftBrushStrokeButton from "@/components/molecules/KftBrushStrokeButton/KftBrushStrokeButton";

export default {
  name: "KftSimpleCard",
  components: {
    KftBrushStrokeButton,
    KftButton,
    SfImage,
  },
  props: {
    /**
     * Card image
     */
    image: {
      type: [Array, Object, String],
      default: "",
    },

    /**
     * Card image width, without unit
     */
    imageWidth: {
      type: [String, Number],
      default: "100%",
    },

    /**
     * Card image height, without unit
     */
    imageHeight: {
      type: [String, Number],
      default: "100%",
    },

    /**
     * Card title
     */
    title: {
      type: String,
      default: "",
    },

    /**
     * Card description
     */
    description: {
      type: String,
      default: "",
    },

    /**
     * Link
     */
    link: {
      type: [String, Object],
      default: "",
    },

    /**
     * Link Text
     */
    linkText: {
      type: [String, Object],
      default: "",
    },

    /**
     * Link
     */
    handleClick: {
      type: Boolean,
      default: false,
    },

    /**
     * Item index
     */
    circleContent: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isAddingToCart: false,
    };
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
@import "~/assets/components/molecules/KftSimpleCard.scss";
</style>
