































































































import { KftHeading } from "@konfetti-ui/vue";
import { algoliaEventGetters, eventGetters } from "@konfetti/composables";
import { ContentLoader } from "vue-content-loader";
import { watch, ref } from "@nuxtjs/composition-api";
import { onMounted } from "@vue/composition-api";
import { AlgoliaEventItem } from "@konfetti/composables/src";
import KftProductCard from "../../molecules/KftProductCard/KftProductCard.vue";
import { fnGetFormattedDuration } from "~/helpers/dateHelpers";
import { fnGetEventListIndexName } from "~/helpers/algoliaHelpers";
import { useGtm } from "~/composables";
import { uuid4 } from "~/helpers/cryptoHelpers";
import { formatPriceAmount } from "~/helpers/currencyHelpers";
import { fnConvertLocaleIntoBCP47 } from "@/helpers";

export default {
  name: "KftProductShowcase",
  components: {
    ContentLoader,
    KftProductCard,
    KftHeading,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    rowClasses: {
      type: String,
      default:
        "row-cols-xl-5 row-cols-lg-5 row-cols-md-4 row-cols-sm-3 row-cols-2",
    },
    queryId: {
      type: [Array, String],
      default: "",
    },
    titleLevel: {
      type: Number,
      default: 2,
    },
    titleHClass: {
      type: String,
      default: "",
    },
    tags: {
      type: Array,
      default: () => [],
    },
    products: {
      type: Array,
      default: () => [],
    },
    promotionSlotIndices: {
      type: Array,
      default: () => [2, 10],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disableSendEventToAlgolia: {
      type: Boolean,
      default: false,
    },
    algoliaInsightsLabel: {
      type: String,
      default: "",
    },
    gtmItemListId: {
      type: String,
      default: null,
    },
    algoliaInsightsClient: {
      type: [Object, Function, null],
      default: () => {},
    },
    giftCardEvent: {
      type: Boolean,
      default: false,
    },
    useHTag: {
      type: Boolean,
      default: true,
    },
  },
  emits: {},
  setup(props, { root: { $i18n, $router, $route, localePath } }) {
    const { locale } = $i18n;
    const { sendViewItemList, sendSelectListItem } = useGtm();

    const shouldRunSendViewItemList = ref(false);
    const localeBCP47 = fnConvertLocaleIntoBCP47($i18n.locale);
    const defaultDurationString = $i18n.t(
      "product.card.durationDefaultContent",
    );
    const routerPushToEvent = (object: AlgoliaEventItem, queryId: string) => {
      $router.push(
        algoliaEventGetters.getRouterPushToEvent(
          object,
          queryId,
          $i18n.locale,
          $route.query.layout === "sup",
        ),
      );
    };

    const fnSendEventToAlgolia = (objectID, position) => {
      if (!window?.localStorage) {
        return;
      }
      let token = window?.localStorage?.getItem(
        "konfetti-algolia-tracking-user-token",
      );
      if (!token || token === "undefined") {
        token = uuid4();
      }

      if (
        props.algoliaInsightsClient !== null &&
        props.algoliaInsightsClient !== undefined &&
        token
      ) {
        props.algoliaInsightsClient("clickedObjectIDs", {
          userToken: token,
          index: fnGetEventListIndexName($i18n.locale),
          eventName: "Event Clicked - " + props.algoliaInsightsLabel,
          objectIDs: [objectID],
          positions: [position],
          queryId: props.queryId,
        });
      }
    };

    const fnHandleClickOnCard = (item: AlgoliaEventItem, i) => {
      fnSendEventToAlgolia(item.objectId, i + 1);

      routerPushToEvent(item, props.queryId);
      sendSelectListItem(props.gtmItemListId, [item], i);
    };

    onMounted(() => {
      if (shouldRunSendViewItemList.value) {
        sendViewItemList(props.gtmItemListId, props.products);
      }
    });

    watch(
      () => props.products,
      (newValue) => {
        if (newValue.length > 0 && props.gtmItemListId) {
          if (process.client) {
            sendViewItemList(props.gtmItemListId, props.products);
          } else {
            shouldRunSendViewItemList.value = true;
          }
        }
      },
      { immediate: true },
    );

    return {
      eventGetters,
      algoliaEventGetters,
      locale,
      localeBCP47,
      formatPriceAmount,
      fnGetFormattedDuration,
      defaultDurationString,
      fnSendEventToAlgolia,
      fnHandleClickOnCard,
    };
  },
};
