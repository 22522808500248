import { strapiImageGetters } from "./strapiImageGetters";

enum eventListPageType {
  city,
  category,
  "city-category",
  general,
}

const getId = (eventListPage): string => {
  return eventListPage?.id || null;
};

const getSlug = (eventListPage): string => {
  return eventListPage?.attributes?.slug || "";
};

const getTitle = (eventListPage): string => {
  return eventListPage?.attributes?.title || null;
};

const getDescription = (eventListPage): string => {
  return eventListPage?.attributes?.description || "";
};

const getSearchQuery = (eventListPage): string => {
  return eventListPage?.attributes?.searchQuery || null;
};

const getSearchQueryObj = (eventListPage): any => {
  const query = {};

  const queryArr = eventListPage?.attributes?.searchQuery?.split("&").map((x) => x.split("=")) || [];
  queryArr.forEach((x) => {
    query[x[0]] = x[1];
  });

  return queryArr.length > 0 ? query : null;
};

const getType = (eventListPage): eventListPageType => {
  return eventListPage?.attributes?.type || null;
};

const getSeoTitle = (eventListPage): string => {
  return eventListPage?.attributes?.seo?.metaTitle || null;
};

const getSeoImageUrl = (eventListPage, format = null): string => {
  return (
    strapiImageGetters.getImageUrl(
      eventListPage?.attributes?.seo?.metaImagcae?.data,
      format,
    ) || null
  );
};

const getSeoMetaDescription = (eventListPage): string => {
  return eventListPage?.attributes?.seo?.metaDescription || null;
};

const getCreatedAt = (eventListPage): string => {
  return eventListPage?.attributes?.createdAt || null;
};

const getShowcase = (eventListPage): any => {
  return eventListPage?.attributes?.showcase || null;
};

const getShowcaseTitle = (eventListPage): string => {
  return eventListPage?.attributes?.showcase?.title || null;
};

const getHorizontalCardListTitle = (eventListPage): string => {
  return eventListPage?.attributes?.horizontalCardListTitle || null;
};

const getHorizontalCardListLink = (eventListPage): string => {
  return eventListPage?.attributes?.horizontalCardListLink || null;
};

const getHorizontalCardListLinkText = (eventListPage): string => {
  return eventListPage?.attributes?.horizontalCardListLinkText || null;
};

const getHorizontalCardList = (eventListPage): any[] => {
  return eventListPage?.attributes?.horizontalCardList || [];
};

const getGiftcardSection = (eventListPage): any => {
  return eventListPage?.attributes?.giftcardSection || null;
};
const getGiftcardSectionTitle = (eventListPage): string => {
  return eventListPage?.attributes?.giftcardSection?.title || null;
};

const getGiftcardSectionSectionTitle = (eventListPage): string => {
  return eventListPage?.attributes?.giftcardSection?.sectionTitle || null;
};
const getGiftcardSectionSubtitle = (eventListPage): string => {
  return eventListPage?.attributes?.giftcardSection?.subtitle || null;
};

const getCitySlug = (eventListPage): string => {
  return eventListPage?.attributes?.citySlug || null;
};

const getCategorySlug = (eventListPage): string => {
  return eventListPage?.attributes?.categorySlug || null;
};


const getLocale = (eventListPage): string => {
  return eventListPage?.attributes?.locale || null;
};

const getSearchHeading = (eventListPage): string => {
  return eventListPage?.attributes?.searchHeading || null;
};

const getSearchSubtitle = (eventListPage): string => {
  return eventListPage?.attributes?.searchSubtitle || null;
};

const getLocalizations = (eventListPage): any[] => {
  return eventListPage?.attributes?.localizations?.data || null;
};

const getLocalizationByLocale = (eventListPage, locale): any => {
  return getLocalizations(eventListPage)?.find((obj) => {
    return getLocale(obj).toLowerCase() === locale
  }) || null;
};

const getSearchFilters = (eventListPage): any => {
  return eventListPage?.attributes?.searchFilters || null;
};

const getKonfettiTeameventPageSlug = (eventListPage): string => {
  return getSlug(eventListPage)?.split("-").splice(1).join("-")
}

const getKonfettiPagePath = (eventListPage): string => {
  const map = {
    city: `${getCitySlug(eventListPage)}`,
    category: `c/${getCategorySlug(eventListPage)}`,
    "city-category": `${getCitySlug(eventListPage)}/${getCategorySlug(eventListPage)}`,
    general: `t/${getSlug(eventListPage)}`,
    teamevent: `teamevent/${getKonfettiTeameventPageSlug(eventListPage)}`,
  };

  return `/${map[getType(eventListPage)]}`;
};


export const strapiEventListPageGetters = {
  getId,
  getSlug,
  getCitySlug,
  getCategorySlug,
  getTitle,
  getDescription,
  getSearchQuery,
  getSearchQueryObj,
  getType,
  getSeoTitle,
  getSeoImageUrl,
  getSeoMetaDescription,
  getCreatedAt,
  getShowcase,
  getShowcaseTitle,
  getHorizontalCardList,
  getGiftcardSection,
  getGiftcardSectionTitle,
  getGiftcardSectionSectionTitle,
  getGiftcardSectionSubtitle,
  getHorizontalCardListTitle,
  getHorizontalCardListLink,
  getHorizontalCardListLinkText,
  getLocale,
  getSearchHeading,
  getSearchSubtitle,
  getLocalizations,
  getLocalizationByLocale,
  getSearchFilters,
  getKonfettiPagePath,
  getKonfettiTeameventPageSlug,
};
