<template>
  <div class="VuePagination" :class="props.theme.wrapper">
    <nav :class="props.theme.nav">
      <ul v-if="props.showPagination" :class="props.theme.list">
        <li
          v-if="props.hasEdgeNav"
          :class="props.theme.firstPage"
          @click="props.setFirstPage"
        >
          <a v-bind="{ ...props.aProps, ...props.firstPageProps }">{{
            props.texts.first
          }}</a>
        </li>

        <li
          v-if="props.hasChunksNav"
          :class="props.theme.prevChunk"
          @click="props.setPrevChunk"
        >
          <a v-bind="{ ...props.aProps, ...props.prevChunkProps }">
            <i class="bi bi-chevron-double-left"></i>
          </a>
        </li>

        <li :class="props.theme.prev" @click="props.setPrevPage">
          <a v-bind="{ ...props.aProps, ...props.prevProps }">
            <i class="bi bi-chevron-left"></i>
          </a>
        </li>

        <li
          v-for="page in pages"
          :key="page"
          :class="props.pageClasses(page)"
          v-on="props.pageEvents(page)"
        >
          <a
            v-bind="props.aProps"
            :href="fnGetPageUrl(page)"
            :class="props.theme.link"
            @click.prevent
            >{{ page }}
          </a>
        </li>

        <li :class="props.theme.next" @click="props.setNextPage">
          <a v-bind="{ ...props.aProps, ...props.nextProps }">
            <i class="bi bi-chevron-right"></i>
          </a>
        </li>

        <li
          v-if="props.hasChunksNav"
          :class="props.theme.nextChunk"
          @click="props.setNextChunk"
        >
          <a v-bind="{ ...props.aProps, ...props.nextChunkProps }">
            <i class="bi bi-chevron-double-right"></i>
          </a>
        </li>

        <li
          v-if="props.hasEdgeNav"
          :class="props.theme.lastPage"
          @click="props.setLastPage"
        >
          <a v-bind="{ ...props.aProps, ...props.lastPageProps }">{{
            props.texts.last
          }}</a>
        </li>
      </ul>

      <p v-show="props.hasRecords" :class="props.theme.count">
        {{ props.count }}
      </p>
    </nav>
  </div>
</template>

<script>
import { computed } from "@nuxtjs/composition-api";
import { withTrailingSlash } from "~/helpers/url";

export default {
  name: "KftPagination",
  props: ["props"],

  setup(props, { root: { $fullLocalizedUrl, $route } }) {
    const pages = computed(() =>
      props.props.pages.slice(
        Number(props.props.page) - (Number(props.props.page) === 1 ? 1 : 2),
        1 + Number(props.props.page),
      ),
    );

    const fnGetPageUrl = (page) => {
      const url = new URL($fullLocalizedUrl($route.fullPath));
      url.searchParams.set("page", page.toString());
      return withTrailingSlash(url.toString(), true);
    };

    return {
      pages,
      fnGetPageUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/_pagination.scss";
</style>
