/* eslint-disable  */

// @francesca - imgs are here: t.ly/8hvG

export default {
  partner: {
    header: {
      title: "Mehr als eine Buchungsplattform",
      description:
        "Verwalte Dein Unternehmen mit einer All-in-One-Software und steigere Deinen Umsatz.",
      buttonLabel: "Jetzt loslegen oder anrufen: 030/257706807",
      bgColor: "#8B84D7",
    },

    benefits: {
      title: "Entdecke konfetti in Praxis",
      items: {
        1: {
          image: "/partner/advantages-1.webp",
          title: "Maximale Sichtbarkeit",
          description:
            "Wir betreiben für Dich aktives Marketing und bringen Dir mehr Gäste und breitere Zielgruppen.",
        },
        2: {
          image: "/partner/advantages-2.webp",
          title: "Weniger administrative Arbeit",
          description:
            "Wir sparen Dir Zeit, in dem Du Deine Kurse, Buchungen und Zahlungen ganz einfach über unsere Plattform verwaltest.",
        },
        3: {
          image: "/partner/advantages-3.webp",
          title: "Attraktives Preismodell",
          description:
            "Nur für erfolgreiche Buchungen fällt eine Provision an.",
        },
      },
    },

    stepsToJoin: {
      title: "So kannst Du konfetti für Dein Unternehmen nutzen",
      items: {
        1: {
          image: "/partner/join-1.webp",
          title: "Erzähle uns von Dir",
          description:
            'Klicke unten auf "Jetzt loslegen" und schicke uns in weniger als einer Minute Deine Anfrage. Anschließend treten wir mit Dir in Kontakt.',
        },
        2: {
          image: "/partner/join-2.webp",
          title: "Kostenlose Demo",
          description:
            "Nach der Demo, kannst Du Dein Unternehmen anlegen und Events erstellen. Wir schauen drüber – und dann geht’s schon los mit dem Ticketverkauf.",
        },
        3: {
          image: "/partner/join-3.webp",
          title: "Begeistere Deine Kunden",
          description:
            "Mit unseren benutzerfreundlichen Software hast Du weniger Verwaltungsaufwand und kannst Dich vollends auf Deine Kursteilnehmer konzentrieren.",
        },
      },
    },

    credentials: {
      title: "Das sagen unsere Partner",
      items: {
        1: {
          image: "/partner/leyla-r.webp",
          title: "Leyla R. - Gründerin von Kas Käsekurse",
          description:
            '"konfetti hilft uns dabei, dass wir uns auf das Wesentliche konzentrieren können: Möglichst viele Menschen mit unseren Kursen glücklich zu machen."',
        },
        2: {
          image: "/partner/jens-h.webp",
          title: "Jens H. - Gründer von Drink Syndikat",
          description:
            '"Die Zusammenarbeit mit konfetti macht einfach Spaß. Der Austausch mit dem Team ist top, das konfetti Tool ist einfach und flexibel - und außerdem bringt konfetti uns jede Menge Online-Teamevents."',
        },
        3: {
          image: "/partner/deborah-t.webp",
          title: "Deborah T. - Gründerin von Druckrausch",
          description:
            '"Wir kennen konfetti schon von Anfang an. Das Tool entwickelt sich stetig weiter und wird so gebaut, dass es für uns als Anbieter Sinn macht. Wir freuen uns auf eine lange, fruchtbare Zusammenarbeit."',
        },
      },
    },

    heroes: {
      first: {
        title: "konfetti Buchungslösung: Alles in einem Portal",
        subtitle:
          "Mit der konfetti Buchungslösung können Deine Kunden jetzt noch einfacher Tickets und Gutscheine für Deine Events direkt auf Deiner Website, Instagram & Co. kaufen.",
        image: "/homepage/heroFirst.webp",
        mobileImage: "/homepage/heroFirst.webp",
        buttonText: "Hier mehr erfahren",
      },
      second: {
        title: "Unsere Pakete und Preise",
        subtitle:
          "Ergänze Deine Website um einen leistungsstarken Ticketshop und verkaufe Deine Kurse 24/7 auf Deiner Website und bei konfetti. Verwalte Deine Kurse zentral an einem Ort und behalte stets die volle Kontrolle über Deine Buchungen. Finde das Paket mit den Funktionen, die zu Deinem Business passen.",
        image: "/partner/focus-on-the-important.webp",
        mobileImage: "/partner/focus-on-the-important.webp",
        buttonText: "Preise & Pakete entdecken",
      },

      // @francesca- this third might need to go, we only have 2 posts
      third: {
        title: "Book Events for Private Groups",
        subtitle:
          "Danish fontina feta cheese and wine. Jarlsberg emmental rubber cheese feta halloumi taleggio danish fontina taleggio. Pecorino stinking bishop taleggio cheddar goat jarlsberg dolcelatte the big cheese. Cut the cheese cream cheese ricotta.",
        image: "/partner/apply-in-few-steps.webp",
        mobileImage: "/partner/apply-in-few-steps.webp",
        buttonText: "Book a team event!",
      },
    },

    form: {
      title: "Partnerformular",
      description: "Gebe Deine Daten ein und lege los",
      noscript:
        "Falls Du kein Formular siehst, wird dieses durch die Einstellungen Deines Browsers oder Adblocker blockiert. Nutze einen anderen Browser oder schreibe uns an hallo@gokonfetti.com",
    },
  },
};
