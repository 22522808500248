import { strapiImageGetters } from "./strapiImageGetters";

const getId = (magazineHome): string => {
  return magazineHome?.id || null;
};

const getSeoTitle = (magazineHome): string => {
  return magazineHome?.attributes?.seo?.metaTitle || null;
};

const getSeoImageUrl = (magazineHome, format = null): string => {
  return (
    strapiImageGetters.getImageUrl(
      magazineHome?.attributes?.seo?.metaImage?.data,
      format,
    ) || null
  );
};

const getSeoMetaDescription = (magazineHome): string => {
  return magazineHome?.attributes?.seo?.metaDescription || null;
};

const getSummaryList = (magazineHome): any => {
  return magazineHome?.attributes?.summary_list || null;
};

export const magazineHomeGetters = {
  getId,
  getSeoTitle,
  getSeoImageUrl,
  getSeoMetaDescription,
  getSummaryList,
};
