import { PriceItem } from "../types";

export type Customer = {
  id: string;
  company_name: string;
};

export type CustomerData = {
  data: Customer;
};

export type ReferralCoupon = {
  data: { code: string; status: string };
};

export type User = {
  balance: PriceItem;
  birth: string;
  created_at: string;
  customer: CustomerData;
  email: string;
  email_verified_at: string;
  first_name: string;
  gender: string;
  id: string;
  last_name: string;
  object: string;
  readable_created_at: string;
  readable_updated_at: string;
  updated_at: string;
  referralCoupon: ReferralCoupon;
};

export const getId = (user: User): string => user.id;

export const getFirstName = (user: User): string => user.first_name;

export const getLastName = (user: User): string => user.last_name;

export const getEmail = (user: User): string => user.email;

export const getFullName = (user: User): string =>
  `${user.first_name} ${user.last_name}`;

export const getBalance = (user: User): PriceItem => user.balance;

export const getFormattedBalance = (user: User): string =>
  user.balance?.formatted || "";

export const getReferralCouponCode = (user: User): ReferralCoupon =>
  user?.referralCoupon || null;

export const getCustomerId = (user: User): string => user.customer?.data?.id;

export const getCompanyName = (user: User): string =>
  user.customer?.data?.company_name;

export const userGetters = {
  getId,
  getFirstName,
  getLastName,
  getEmail,
  getFullName,
  getBalance,
  getCustomerId,
  getCompanyName,
  getFormattedBalance,
  getReferralCouponCode,
};
