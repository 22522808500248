export const formatMoney = (
  amount: number,
  locale = "de-DE",
  currency = "EUR",
  maximumFractionDigits = 0,
) =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    maximumFractionDigits,
    ...(maximumFractionDigits === 0 && { minimumFractionDigits: 0 }),
  }).format(amount);

export const formatPriceAmount = (
  amount: number,
  locale: string,
  currency: string,
  maximumFractionDigits = 2,
) => formatMoney(amount / 100, locale, currency, maximumFractionDigits);
