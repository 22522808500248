/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  eventGetters,
  parseEventToCartItem,
  parseGiftcardToCartItem,
} from "@konfetti/composables";

// @ts-ignore
import { sharedRef } from "@konfetti-core/core";
// @ts-ignore
import { EventDate, EventDescription } from "@konfetti/composables/src";
import { formatTime } from "~/helpers";

export const useBooking = (id: string) => {
  /** Refs & Variables
   * ---------------------------- */
  const selectedTime = sharedRef(null, `useBooking/selectedTime/${id}`);
  const selectedTicketQuantity = sharedRef(
    null,
    `useBooking/selectedTicketQuantity/${id}`,
  );
  const selectedGiftcardQuantity = sharedRef(
    1,
    `useBooking/selectedGiftcardQuantity/${id}`,
  );
  const availableTickets = sharedRef([], `useBooking/availableTickets/${id}`);

  /** Methods
   * -------------------------- */

  /** @name prepareEventForBooking
   ** @desc Prepare an event date/time to put in the cart
   * @param isBookingAsPrivate Whether the date is being book as private or not (PUBLIC_PRIVATE can be)
   ** @param {Event} event The event description */
  const prepareEventForBooking = (
    event: { data: EventDate },
    isBookingAsPrivate = false,
  ) => {
    return parseEventToCartItem(
      event.data,
      selectedTicketQuantity,
      selectedTime,
      formatTime(selectedTime.value.start) +
        " - " +
        formatTime(selectedTime.value.end),
      isBookingAsPrivate,
    );
  };

  /** @name prepareGiftcardForBooking
   ** @desc Prepare a giftcard to put in the cart
   ** @param {Event} event The event description */
  const prepareGiftcardForBooking = (event: { data: EventDescription }) => {
    return parseGiftcardToCartItem(
      event.data,
      selectedTicketQuantity.value || selectedGiftcardQuantity.value,
      selectedTime,
    );
  };

  /** @name setAvailableTickets
   ** @desc Sets the number of available tickets
   ** @param {Number} ticketNumber The amount of available tickets
   ** @param {Number} min The minimum amount of tickets for booking */
  const setAvailableTickets = (ticketNumber, min = 1) => {
    availableTickets.value = [];

    for (let i = min; i <= ticketNumber; i++) {
      availableTickets.value.push(i);
    }

    const availableTicketsAmount = availableTickets.value?.length || 0;

    if (
      selectedTicketQuantity.value &&
      selectedTicketQuantity.value > availableTicketsAmount
    ) {
      selectedTicketQuantity.value = availableTicketsAmount;
    } else {
      selectedTicketQuantity.value = availableTickets.value?.[0] || null;
    }
  };

  /** @name onSelectTime
   ** @desc Triggered when the time is changed by the user. It sets the available tickets amount to select
   * @param {Object} time The event date/time object */
  const onSelectTime = (time) => {
    if (!time) {
      return setAvailableTickets(0);
    }

    const qty =
      time.dateType === "PUBLIC"
        ? time?.privateMaxTickets || time.availableTicketsQuantity
        : time.availableTicketsQuantity;

    const minQty = 1;

    setAvailableTickets(qty, minQty);
  };

  /* @todo move this function to a getter + start using getters for each atomic action */
  /** @name getEventPrice
   ** @desc Get event price, amount, value or default
   ** @param {Object} event - Event object
   * @param {Boolean} raw - The raw price will return the amount in ISO4217
   * */
  const getEventPrice = (event: any, raw = false) => {
    if (selectedTime.value !== null) {
      return raw
        ? eventGetters.getPriceAmount(selectedTime.value?.product?.data)
        : eventGetters.getPrice(selectedTime.value?.product?.data);
    }

    const defaultPrice = raw
      ? eventGetters.getDefaultPriceAmount(event.data)
      : eventGetters.getDefaultFormattedPrice(event.data);

    if (defaultPrice) {
      return defaultPrice;
    }

    return raw
      ? eventGetters.getDefaultPriceAmount(event.data)
      : eventGetters.getDefaultFormattedPrice(event.data);
  };

  /**
   * @returns {boolean} true if it has the selected number of tickets available, false otherwise
   * */
  const updateAvailableTickets = (availableDates) => {
    const currentSelectedEvent = availableDates.data.find(
      (item) => item.id === selectedTime.value.id,
    );

    if (!currentSelectedEvent) {
      setAvailableTickets(0);
      return false;
    }

    if (
      availableTickets.value.length <
      currentSelectedEvent.availableTicketsQuantity
    ) {
      setAvailableTickets(currentSelectedEvent.availableTicketsQuantity);
    }

    return true;
  };

  /** @name nextTimeAvailable
   ** @desc Get the next available time (not sold out and not closed)
   * @param {Object} availableDates The available date list */
  const nextTimeAvailable = (availableDates) => {
    return availableDates.find((item) => item.status === "OPEN") || null;
  };

  /**
   ** @name setNextTimeAvailable
   ** @description Sets the select time as the next available time option in the array
   ** @param {Array} availableDates - Array of available dates
   *  */
  const setNextTimeAvailable = (availableDates) => {
    if (!availableDates?.length) {
      selectedTime.value = null;
      return;
    }

    /* set next time available as selected */
    selectedTime.value = nextTimeAvailable(availableDates);
    /* triggers select-time events (such as changing amount of available tickets in the select dropdown) */
    onSelectTime(selectedTime.value);

    // if (availableTickets.value.length >= 1) {
    //   selectedTicketQuantity.value = 1;
    // }
  };

  return {
    /* Refs */
    selectedTime,
    selectedTicketQuantity,
    selectedGiftcardQuantity,
    availableTickets,

    /* Methods */
    getEventPrice,
    prepareEventForBooking,
    prepareGiftcardForBooking,
    setAvailableTickets,
    onSelectTime,
    updateAvailableTickets,
    setNextTimeAvailable,
  };
};
