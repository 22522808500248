/* eslint-disable comma-dangle */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-unused-vars
/* eslint-disable comma-dangle */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-unused-vars
export const getRoutes = (resolve: any, dirname = __dirname): Array<any> => {
  // We need to keep the old routes for the redirects to work properly
  return [
    {
      name: "home",
      path: "/root-domain",
      component: resolve(dirname, "pages/root-domain/home.vue"),
      props: true,
    },
    {
      name: "gifcard-page",
      meta: {
        contentGroup: "giftcard",
      },
      path: "/root-domain/giftcard",
      component: resolve(dirname, "pages/root-domain/giftcard.vue"),
      props: true,
    },
    {
      name: "supplier-home",
      path: "/sub-domain",
      component: resolve(dirname, "pages/sub-domain/home.vue"),
      props: true,
    },
    {
      name: "supplier-giftcard-page",
      path: "/sub-domain/giftcard",
      component: resolve(dirname, "pages/sub-domain/giftcard.vue"),
      props: true,
    },
    {
      name: "city-page",
      meta: {
        contentGroup: "city",
      },
      path: "/:citySlug",
      component: resolve(dirname, "pages/city/_city.vue"),
      props: true,
    },
    {
      name: "city-categories-page",
      meta: {
        contentGroup: "city category",
      },
      path: "/:citySlug/:categorySlug",
      component: resolve(dirname, "pages/city-category/city-category.vue"),
      props: true,
    },
    {
      name: "general-event-list-page",
      meta: {
        contentGroup: "tag",
      },
      path: "/t/:slug",
      component: resolve(dirname, "pages/t/general-event-list-page.vue"),
      props: true,
    },
    {
      name: "team-event-page",
      meta: {
        contentGroup: "team event",
      },
      path: "/teamevent/:city",
      component: resolve(dirname, "pages/teamevent/_city.vue"),
      props: true,
    },
    {
      name: "supplier-profile",
      meta: {
        contentGroup: "partner",
      },
      path: "/p/:permalink",
      component: resolve(dirname, "pages/p/_permalink.vue"),
      props: true,
    },
    {
      name: "city-page",
      meta: {
        contentGroup: "city",
      },
      path: "/:citySlug",
      component: resolve(dirname, "pages/city/_city.vue"),
      props: true,
    },
    {
      name: "city-categories-page",
      meta: {
        contentGroup: "city category",
      },
      path: "/:citySlug/:categorySlug",
      component: resolve(dirname, "pages/city-category/city-category.vue"),
      props: true,
    },
    {
      name: "general-event-list-page",
      meta: {
        contentGroup: "tag",
      },
      path: "/t/:slug",
      component: resolve(dirname, "pages/t/general-event-list-page.vue"),
      props: true,
    },
    {
      name: "team-event-page",
      meta: {
        contentGroup: "team event",
      },
      path: "/teamevent/:city",
      component: resolve(dirname, "pages/teamevent/_city.vue"),
      props: true,
    },
    {
      name: "supplier-profile",
      meta: {
        contentGroup: "partner",
      },
      path: "/p/:permalink",
      component: resolve(dirname, "pages/p/_permalink.vue"),
      props: true,
    },
    {
      name: "magazine-pages",
      meta: {
        contentGroup: "magazine",
      },
      path: "/magazine/:slug",
      component: resolve(dirname, "pages/magazine/magazine-wrapper.vue"),
      props: true,
    },
    {
      name: "special-giftcards",
      meta: {
        contentGroup: "giftcard",
      },
      path: "/giftcard/:event/",
      component: resolve(
        dirname,
        "pages/special-giftcard/special-giftcard.vue",
      ),
      props: true,
      pathToRegexpOptions: { strict: true },
    },
    {
      name: "special-giftcards",
      meta: {
        contentGroup: "giftcard",
      },
      path: "/giftcard/:event/",
      component: resolve(
        dirname,
        "pages/special-giftcard/special-giftcard.vue",
      ),
      props: true,
      pathToRegexpOptions: { strict: true },
    },
    {
      name: "social-login-google",
      meta: {
        socialLoginType: "google",
      },
      path: "/auth/google/callback",
      component: resolve(dirname, "pages/auth/social-login.vue"),
    },
    {
      name: "social-login-facebook",
      meta: {
        socialLoginType: "facebook",
      },
      path: "/auth/facebook/callback",
      component: resolve(dirname, "pages/auth/social-login.vue"),
    },
    {
      name: "event-page",
      meta: {
        contentGroup: "event",
      },
      path: "/e/:event/",
      component: resolve(dirname, "pages/e/_event.vue"),
      props: true,
      pathToRegexpOptions: { strict: true },
    },
  ];
};
