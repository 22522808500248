import { Context } from "../../types";

interface ContextConfiguration {
  UseKftContext: () => Context;
}

let UseKftContext = () => ({} as Context);

const configureContext = (config: ContextConfiguration) => {
  UseKftContext = config.UseKftContext || UseKftContext;
};

const generateContext = (factoryParams) => {
  const kftContext = UseKftContext();
  if (factoryParams.provide) {
    return { ...kftContext.$kft, ...factoryParams.provide(kftContext.$kft) };
  }

  return kftContext.$kft;
};

export { generateContext, UseKftContext, configureContext };
