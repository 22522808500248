import { Context } from "@konfetti-core/core";
import { AxiosResponse } from "axios";

export type GetSupplierParams = {
  permalink: string;
};

export async function getSupplier(
  context: Context,
  params: GetSupplierParams,
): Promise<AxiosResponse> {
  const url = new URL(
    `v1/store/suppliers/${params}?include=logo`,
    context.config.api.url,
  );

  const { data } = await context.client.get(url.href);
  return data;
}

export async function getSupplierByAlias(
  context: Context,
  alias: string,
): Promise<AxiosResponse> {
  const url = new URL(
    `v1/store/suppliers/${alias}/subdomain?include=logo,headerLogo`,
    context.config.api.url,
  );

  const { data } = await context.client.get(url.href);
  return data;
}

export async function getSupplierById(
  context: Context,
  id: string,
): Promise<AxiosResponse> {
  const url = new URL(
    `v1/store/supplier/${id}?include=logo,headerLogo`,
    context.config.api.url,
  );

  const { data } = await context.client.get(url.href);
  return data;
}

export async function getSupplierLocalitiesById(
  context: Context,
  id: string,
): Promise<AxiosResponse> {
  const url = new URL(
    `v1/store/suppliers/${id}/localities`,
    context.config.api.url,
  );

  const { data } = await context.client.get(url.href);
  return data;
}
