/* eslint-disable camelcase */
import _ from "lodash";
import { sharedRef, UseKftContext } from "@konfetti-core/core";
import { Ref, ref } from "@nuxtjs/composition-api";
import {
  fnGetEventListRelevancyIndexName,
  fnGetEventListSortByDateDescIndexName,
  fnGetEventListSortByPriceAscIndexName,
  fnGetEventListSortByPriceDescIndexName,
} from "~/helpers/algoliaHelpers";

import { fnGetAvailableLanguages } from "~/helpers/localeHelpers";
import { removeNullValuesfromObject } from "~/helpers/filterHelpers";

export const FILTERS_LOCAL_STORAGE_KEY_BASE = "konfetti-search-filters";
export const FILTERS_LOCAL_STORAGE_KEY = (key: string = null) => {
  const keySuffix = key ? `-${key}` : "";
  return `${FILTERS_LOCAL_STORAGE_KEY_BASE}${keySuffix}`;
};

export const EMPTY_FILTERS_INSTANCE = {
  searchquery: null,
  radius: 0,
  online: false,
  hasAvailableDates: false,
  dates: [null, null],
  suppliers: [],
  tags: [],
  categories: [],
  categoriesBySlug: [],
  languages: [],
  price: {
    min: 0,
    max: 120000,
  },
  locality: {
    id: null,
    slug: null,
    name: null,
    _geoloc: null,
  },
};

export const EMPTY_PAGINATION_OBJECT = {
  nbPages: 1,
  nbHits: 0,
  page: 1,
  // @todo change this later
  orderBy: {
    label: "Relevanz",
    value: "events_de",
    slug: "relevancy",
    icon: "clipboard-data",
  },
};

const whitelistParamNames = [
  "utm_term",
  "utm_campaign",
  "utm_source",
  "utm_medium",
  "hsa_acc",
  "hsa_cam",
  "hsa_grp",
  "hsa_ad",
  "hsa_src",
  "hsa_tgt",
  "hsa_kw",
  "hsa_mt",
  "hsa_net",
  "hsa_ver",
  "gclid",
  "city",
  "category",
  "languages",
  "online",
  "hasAvailableDates",
  "orderBy",
  "dates",
  "searchquery",
  "gad_source",
];

export const useSearchFilters = (
  id: string,
  currentPageCustomEmptyFilters: Ref<any> = ref(null),
): any => {
  const context = UseKftContext();

  /** @name filters
   ** @desc The filters that will be used on the search
   ** The empty object is treated, else it would be overwritten **/
  const filters = sharedRef(
    _.cloneDeep(EMPTY_FILTERS_INSTANCE),
    `useSearchFilters/filters-${id}`,
  );

  /** @name pagination
   ** @desc Pagination data, such as orderBy, page, number of pages, etc.
   ** The empty object is treated, else it would be overwritten **/
  const pagination = sharedRef(
    _.cloneDeep(EMPTY_PAGINATION_OBJECT),
    `useSearchFilters/pagination-${id}`,
  );

  const orderByOptions = sharedRef(
    [
      {
        label: context.i18n.t("general.pagination.orderByOptions.relevancy"),
        value: fnGetEventListRelevancyIndexName(context.i18n.locale),
        slug: "relevancy",
        icon: "clipboard-data",
      },
      {
        label: context.i18n.t("general.pagination.orderByOptions.newest"),
        value: fnGetEventListSortByDateDescIndexName(context.i18n.locale),
        slug: "newest",
        icon: "calendar2-fill",
      },
      {
        label: context.i18n.t("general.pagination.orderByOptions.priceAsc"),
        value: fnGetEventListSortByPriceAscIndexName(context.i18n.locale),
        slug: "price-asc",
        icon: "piggy-bank-fill",
      },
      {
        label: context.i18n.t("general.pagination.orderByOptions.priceDesc"),
        value: fnGetEventListSortByPriceDescIndexName(context.i18n.locale),
        slug: "price-desc",
        icon: "piggy-bank-fill",
      },
    ],
    `useSearchFilters/orderByOptions-${id}`,
  );

  /** @name availableFacets
   ** @desc The updated list of available facets, with number of hits */
  const availableFacets = sharedRef(
    {
      categories: [],
      localities: [],
    },
    `useSearchFilters/availableFacets-${id}`,
  );

  /** @name availableLanguages
   ** @desc The list of available event spoken languages */
  const availableLanguages = sharedRef(
    fnGetAvailableLanguages(),
    `useSearchFilters/availableLanguages-${id}`,
  );

  /** @name availableLocalities
   ** @desc All options of localities, got from `cities` index in Algolia */
  const availableLocalities = sharedRef(
    [],
    `useSearchFilters/availableLocalities-${id}`,
  );

  const fnGetOrderByObjectFromSlug = (slug: string) => {
    for (const option in orderByOptions.value) {
      // @ts-ignore
      if (option?.slug === slug) {
        return option;
      }
    }

    return orderByOptions.value[0];
  };

  /** @name fnUpdateFacetValues
   ** @desc Update current facets with the values given
   ** @param facet The facet to be updated
   ** @param value The facets response from Algolia, that will be used to update the values
   *
   * @deprecated
   * */
  const fnUpdateFacetValues = (facet, value) => {
    const keys = Object.keys(value);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      /* note that value[key] would be the count, if we are updating all facets we should take into consideration
       * that 0 hits don't return in the facets array */
      if (availableFacets.value[facet].includes(key)) {
        continue;
      }

      availableFacets.value[facet].push(key);
    }
  };

  /** @name queryHasAnyFilters
   ** @desc Checks if the URL query contains any search filter */
  const queryHasAnyFilters = () => {
    const query = context.route?.value?.query || [];
    const queriesArr = Object.keys(query);
    const filtersArray = Object.keys(EMPTY_FILTERS_INSTANCE);

    if (query.page) {
      return true;
    }

    for (let i = 0; i < queriesArr.length; i++) {
      for (let j = 0; j < filtersArray.length; j++) {
        if (queriesArr[i] === filtersArray[j]) {
          return true;
        }
      }
    }

    return false;
  };

  /** @name fnSaveLocalStorageFilters
   ** @desc parses filters to string and saves into localStorage
   * may accept a custom key to be used in different pages */
  const fnSaveLocalStorageFilters = (key: string = null) => {
    if (process.server) {
      return;
    }

    localStorage.setItem(
      FILTERS_LOCAL_STORAGE_KEY(key),
      JSON.stringify({
        filters: filters.value,
        pagination: pagination.value,
      }),
    );
  };

  /** @name fnUpdatePagination
   ** @desc Updates the pagination object with the values included in the object
   ** @param obj The pagination object **/
  const fnUpdatePagination = (obj) => {
    /** Filtering Values **/
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      pagination.value[key] = obj[key];
    }
  };

  const isDefaultFilterValue = (key) => {
    return _.isEqual(
      filters.value[key],
      currentPageCustomEmptyFilters?.value?.[key] ||
        EMPTY_FILTERS_INSTANCE[key],
    );
  };

  const isDefaultPaginationValue = (key) => {
    return _.isEqual(pagination.value[key], EMPTY_PAGINATION_OBJECT[key]);
  };

  const parseFiltersAndPaginationToUrlParamsObj = (
    filters,
    pagination,
  ): URLSearchParams => {
    const searchParams: URLSearchParams = new URLSearchParams();

    if (!isDefaultFilterValue("searchquery")) {
      searchParams.set("searchquery", filters.searchquery);
    }

    /* Filters and merges min and maximum values */
    if (!isDefaultFilterValue("price")) {
      searchParams.set(
        "price",
        [
          filters.price?.min || filters.price?.min === 0
            ? filters.price.min
            : "",
          filters.price?.max || "",
        ]
          .filter((item) => {
            return typeof item !== "undefined" && item !== "" && item !== null;
          })
          .join(","),
      );
    }

    if (!isDefaultFilterValue("dates")) {
      searchParams.set(
        "dates",
        [filters.dates?.[0], filters.dates?.[1]].filter(Boolean).join(","),
      );
    }

    /* Category: Filter is string[], since we are dealing with facets. Filters and merges. */
    if (!isDefaultFilterValue("categories")) {
      searchParams.set(
        "category",
        filters.categories
          .filter(
            (category) =>
              category !== undefined && category !== "" && category !== null,
          )
          .join(","),
      );
    }

    /* Category: Filter is string[], since we are dealing with facets. Filters and merges. */
    if (!isDefaultFilterValue("suppliers")) {
      searchParams.set(
        "suppliers",
        filters.suppliers
          .filter(
            (supplier) =>
              supplier !== undefined && supplier !== "" && supplier !== null,
          )
          .join(","),
      );
    }

    /* Languages: Filter is string[], since we are dealing with facets. Filters and merges. */
    if (!isDefaultFilterValue("languages")) {
      searchParams.set(
        "languages",
        filters.languages
          .filter(
            (language) =>
              language !== undefined && language !== "" && language !== null,
          )
          .join(","),
      );
    }

    /* Include online events */
    if (!isDefaultFilterValue("online")) {
      searchParams.set("online", filters.online);
    }

    if (!filters.online) {
      /* City */
      if (
        filters.locality?.slug &&
        currentPageCustomEmptyFilters?.value?.locality?.slug !==
          filters.locality.slug
      ) {
        searchParams.set("city", filters.locality.slug);
      }

      /* City's Geo position */
      if (
        filters.locality?._geoloc &&
        !_.isEqual(
          currentPageCustomEmptyFilters?.value?.locality?._geoloc,
          filters.locality._geoloc,
        )
      ) {
        searchParams.set(
          "geoloc",
          filters.locality._geoloc.lat + "," + filters.locality._geoloc.lng,
        );
      }
    }

    /* Show only classes with available dates */
    if (!isDefaultFilterValue("hasAvailableDates")) {
      searchParams.set("hasAvailableDates", filters.hasAvailableDates);
    }

    /* Add radius to search */
    if (!isDefaultFilterValue("radius")) {
      searchParams.set("radius", filters.radius);
    }

    if (!isDefaultPaginationValue("page")) {
      searchParams.set("page", pagination.page);
    }

    /* Order by */
    if (!isDefaultPaginationValue("orderBy")) {
      searchParams.set("orderBy", pagination.orderBy?.slug);
    }

    return searchParams;
  };

  /** @name fnConvertFilterValuesToRouteParams
   ** @desc Transforms a filter object in string **/
  const fnConvertFilterValuesToRouteParams = () => {
    const searchParams = parseFiltersAndPaginationToUrlParamsObj(
      filters.value,
      pagination.value,
    );
    return searchParams;
  };

  /** @name fnUpdateURLQueryFilters
   ** @desc Parses filters and updates URL with it */
  const fnUpdateURLQueryFilters = () => {
    const filterParams = fnConvertFilterValuesToRouteParams();
    fnSaveLocalStorageFilters();
    const currentParams = context.route?.value?.query || {};
    const newParams = new URLSearchParams(filterParams);

    for (const filterKey in currentParams) {
      const filterValue = currentParams[filterKey];
      if (whitelistParamNames.includes(filterKey)) {
        newParams.set(filterKey, filterValue);
      }
    }

    const newParamsStr = newParams.toString();

    const url = `${context.route.value?.path}${
      newParamsStr !== "" ? "?" + newParamsStr : ""
    }`;

    if (!process.server) {
      history.pushState({}, null, url);
    }
  };

  /** @name fnLoadLocalStorageFilters
   ** @desc Loads, parses and apply the storage filters
   * */
  const fnLoadLocalStorageFilters = (key = null) => {
    if (process.server) {
      return;
    }
    const localStorageFilters = JSON.parse(
      localStorage.getItem(FILTERS_LOCAL_STORAGE_KEY(key)),
    );

    if (localStorageFilters) {
      filters.value = localStorageFilters?.filters || filters.value;
      pagination.value = localStorageFilters?.pagination || pagination.value;
      fnUpdateURLQueryFilters();
    }

    return localStorageFilters;
  };

  /** @name fnParseQueryFilters
   ** @desc Parse the query into a filters object **/
  // eslint-disable-next-line complexity
  const fnParseQueryFilters = (query: any = [], ignoreFilters: any = {}) => {
    if (ignoreFilters.page && query.page) {
      pagination.value.page = parseInt(query.page);
    }

    if (ignoreFilters.orderBy && query.orderBy) {
      pagination.value.orderBy = fnGetOrderByObjectFromSlug(query.orderBy);
    }

    if (ignoreFilters.radius && query.radius) {
      filters.value.radius = parseInt(query.radius);
    }

    /* Price */
    if (ignoreFilters.price && query.price && query.price.includes(",")) {
      const price = query.price.split(",");
      filters.value.price.min = price[0] !== "" ? price[0] : null;
      filters.value.price.max = price[1] !== "" ? price[1] : null;
    }

    /* Dates */
    if (ignoreFilters.dates && query.dates && query.dates.includes(",")) {
      const dates = query.dates.split(",");
      filters.value.dates = [
        dates[0] !== "" ? parseInt(dates[0]) : null,
        dates[1] !== "" ? parseInt(dates[1]) : null,
      ];
    }

    /* Categories */
    if (ignoreFilters.category && query.category) {
      filters.value.categories = query.category.split(",");
    }

    /* suppliers */
    if (ignoreFilters.suppliers && query.suppliers) {
      filters.value.suppliers = query.suppliers.split(",");
    }

    /* Languages */
    if (ignoreFilters.languages && query.languages) {
      filters.value.languages = query.languages.split(",");
    }

    /* Has available dates */
    if (ignoreFilters.hasAvailableDates && query.hasAvailableDates) {
      filters.value.hasAvailableDates = query.hasAvailableDates === "true";
    }

    /* Is online? */
    if (ignoreFilters.online && query.online) {
      const isOnline = query.online === "true";
      filters.value.online = isOnline;

      if (isOnline) {
        return;
      }
    }

    /* Locality */
    if (ignoreFilters.city && query.city) {
      filters.value.locality =
        filters.value.locality || _.cloneDeep(EMPTY_FILTERS_INSTANCE.locality);
      filters.value.locality.slug = query.city;
    }

    /* Coordinates */
    if (ignoreFilters.geoloc && query.geoloc && query.geoloc.includes(",")) {
      const geoloc = query.geoloc.split(",");
      filters.value.locality._geoloc = {
        lat: geoloc[0],
        lng: geoloc[1],
      };
    }

    filters.value.searchquery = query.searchquery || null;

    return filters.value;
  };

  /** @name fnParseURLQueryFilters
   ** @desc Parse the URL query into a filters object **/
  const fnParseURLQueryFilters = (ignoreFilters: object = {}) => {
    fnParseQueryFilters(context.route?.value?.query || [], ignoreFilters);
  };

  const isKeyPresentAndValid = (key, object) => {
    return (
      typeof object[key] !== "undefined" &&
      object[key] !== null &&
      object[key] !== ""
    );
  };

  const fnGenerateOptionalFilters = (
    filtersParams,
    optionalFilters = {},
  ): string[] => {
    const result = [];
    const keys = Object.keys(filtersParams);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (
        !isKeyPresentAndValid(key, optionalFilters) ||
        !isKeyPresentAndValid(key, filtersParams)
      ) {
        continue;
      }

      result.push(`${filtersParams[key]}<score=${optionalFilters[key]}>`);
    }

    return result;
  };

  const fnGenerateMainFilter = (filtersParams, optionalFilters): string => {
    const result = [];

    const keys = Object.keys(filtersParams);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (
        isKeyPresentAndValid(key, optionalFilters) ||
        !isKeyPresentAndValid(key, filtersParams)
      ) {
        continue;
      }

      result.push(`(${filtersParams[key]})`);
    }

    const resultStr = result.join(" AND ");

    return resultStr === "" ? null : resultStr;
  };
  /** @name fnMountAlgoliaSearchParams
   ** @desc Uses the converted algolia filters to mount the params object
   ** @param price The price range filter
   ** @param tags
   ** @param tags
   ** @param categoriesBySlug
   ** @param categories The list of categories
   ** @param categoriesBySlug
   ** @param suppliers The list of suppliers
   ** @param languages The list of languages
   ** @param city The city name
   ** @param online The online filter param
   ** @param geoloc The geolocation, when applicable
   ** @param radius Total radius to be searched in a city
   ** @param aroundPrecision determines the segments to use for geo-search
   ** @param hasAvailableDates has_event filter
   ** @param dates The date range filter
   ** @param searchquery The query string in searchbox
   ** @param overwriteFilters The configuration or filters to be overwritten
   ** @param optionalFilters filters list and their weights on the rank
   **/
  const fnMountAlgoliaSearchParams = (
    price: string,
    tags: string,
    categories: string,
    categoriesBySlug: string,
    suppliers: string,
    languages: string,
    city: any,
    online: string,
    geoloc: any,
    radius = 30000,
    aroundPrecision = 15000,
    hasAvailableDates: string,
    dates: string,
    searchquery = "",
    overwriteFilters: any,
    optionalFilters = {},
  ) => {
    const filtersParams = {
      price,
      categories,
      tags,
      categoriesBySlug,
      suppliers,
      languages,
      online,
      hasAvailableDates,
      dates,
    };

    /* For the filter string, we remove the city if geoloc is active */
    const filterString = fnGenerateMainFilter(
      {
        ...filtersParams,
        city: geoloc === null ? city : null,
      },
      optionalFilters,
    );

    /* We keep the city as an optional filter here */
    const optionalFiltersArray = fnGenerateOptionalFilters(
      {
        ...filtersParams,
        city,
      },
      optionalFilters,
    );

    const results = {
      filters: filterString,
      query: searchquery,
      optionalFilters: optionalFiltersArray,
      page: overwriteFilters?.page || pagination.value.page - 1,
      aroundLatLngViaIP: false,
      aroundLatLng: geoloc,
      aroundRadius: overwriteFilters?.radius || radius,
      aroundPrecision,
    };

    return removeNullValuesfromObject(results);
  };

  /** @name fnGetStringListFromArray
   ** @desc  Transform an array of strings in a conjunction of arguments, using a given operator
   ** @param array The array of filters applied
   ** @param name The name of the filter/facet in algolia
   ** @param operator The operator name used in the join **/
  const fnGetStringListFromArray = (
    array: string[],
    name: string,
    operator: string,
  ) => {
    const aux = [];
    for (let i = 0; i < array.length; i++) {
      const value = array[i];
      aux.push(`${name}:"${value}"`);
    }

    return aux.join(operator);
  };

  /** @name fnGetConvertedFiltersObject
   ** @desc Parse filters to algolia string, each parsed element will be retrieved individually
   ** @param setDisjunctiveFacets Sets the disjunctive facets, the primary search doesn't set them here because it needs to be set in the helper (search.ts)
   ** @param enableOnline Wether the online param should be set - for example, in the category pages, online shouldn't be filtered at first
   ** @param overwriteFilters The filter params, if you want to force inputs - this is used when se change filters for recommendation **/
  const fnGetConvertedFiltersObject = (
    overwriteFilters: any,
    setDisjunctiveFacets = false,
    enableOnline = true,
  ) => {
    /* Price */
    const price = [
      filters.value.price?.min || filters.value.price?.min === 0
        ? `default_price.amount >= ${filters.value.price.min}`
        : null,
      filters.value.price?.max
        ? `default_price.amount <= ${filters.value.price.max}`
        : null,
    ]
      .filter((item) => {
        return typeof item !== "undefined" && item !== "" && item !== null;
      })
      .join(" AND ");

    const searchquery = filters.value.searchquery;
    const startDate = parseInt(filters.value.dates?.[0])
      ? parseInt(filters.value.dates[0]) / 1000
      : null;

    let endDate = parseInt(filters.value.dates?.[1])
      ? parseInt(filters.value.dates[1]) / 1000
      : null;

    if (startDate !== null && startDate === endDate) {
      endDate = startDate + 24 * 60 * 60;
    }

    let dates = null;
    if (startDate || endDate) {
      dates = `dates_ts:${startDate || "*"} TO ${
        endDate || "*"
      } OR dates_private_ts:${startDate || "*"} TO ${endDate || "*"}`;
    }

    /* Tags */
    // TODO add setDesjunctiveFacets logic here after making sure that disjunctive facets are being added for tags as well
    // can't find where that is happening
    const tags = fnGetStringListFromArray(
      overwriteFilters?.tags || filters.value.tags,
      "tags.name",
      " OR ",
    );

    /* Categories */
    const categories = setDisjunctiveFacets
      ? fnGetStringListFromArray(
          overwriteFilters?.categories || filters.value.categories,
          "categories.name",
          " OR ",
        )
      : null;

    const categoriesBySlug = setDisjunctiveFacets
      ? fnGetStringListFromArray(
          overwriteFilters?.categoriesBySlug || filters.value.categoriesBySlug,
          "categories.slug",
          " OR ",
        )
      : null;
    /* Suppliers */
    const suppliers = fnGetStringListFromArray(
      overwriteFilters?.suppliers || filters.value.suppliers,
      "supplier",
      " OR ",
    );

    /* Languages */
    const languages = setDisjunctiveFacets
      ? fnGetStringListFromArray(filters.value.languages, "languages", " OR ")
      : null;

    /* Setting up Is Online */
    const onlineValue =
      typeof overwriteFilters?.online !== "undefined"
        ? overwriteFilters.online
        : filters.value.online;

    let online = null;
    if (enableOnline) {
      online =
        onlineValue === null
          ? null
          : onlineValue
          ? "is_online:true"
          : "is_online:false";
    }
    /* Show only classes with available dates */
    const hasAvailableDates = filters.value.hasAvailableDates
      ? "has_events:true OR has_direct_events:true"
      : "";

    if (online === "is_online:true") {
      return {
        city: null,
        tags,
        categories,
        suppliers,
        categoriesBySlug,
        online,
        price,
        geoloc: null,
        languages,
        hasAvailableDates,
        dates,
        searchquery,
      };
    }

    /* Locality name, without geoloc */
    let city = null;
    if (typeof overwriteFilters?.city !== "undefined") {
      city = overwriteFilters.city;
    } else if (
      filters.value?.locality?.slug &&
      !filters.value?.locality?._geoloc
    ) {
      city = `city_slug:"${filters.value.locality.slug}"`;
    }

    /* If online is not active and the user wants to filter by radius */
    let geoloc = null;
    if (filters.value.locality?._geoloc) {
      geoloc = `${filters.value.locality._geoloc.lat}, ${filters.value.locality._geoloc.lng}`;
    }
    let radius;
    if (filters.value.locality?.radius) {
      radius = filters.value.locality.radius;
    }
    let aroundPrecision;
    if (filters.value.locality?.precision) {
      aroundPrecision = filters.value.locality.precision;
    }

    return {
      city,
      categories,
      tags,
      categoriesBySlug,
      suppliers,
      online,
      price,
      geoloc,
      radius,
      aroundPrecision,
      languages,
      hasAvailableDates,
      dates,
      searchquery,
    };
  };

  /** @name fnConvertFiltersToAlgoliaSearchParams
   ** @desc Uses the current filters to set up the Algolia's search filters **/
  const fnConvertFiltersToAlgoliaSearchParams = (options: {
    overwriteFilters?: any;
    setDisjunctiveFacets?: boolean;
    enableOnline?: boolean;
  }) => {
    const defaultOptions = {
      overwriteFilters: undefined,
      setDisjunctiveFacets: false,
      enableOnline: true,
    };

    const { overwriteFilters, setDisjunctiveFacets, enableOnline } = {
      ...defaultOptions,
      ...options,
    };

    const {
      price,
      tags,
      categories,
      categoriesBySlug,
      suppliers,
      languages,
      city,
      online,
      geoloc,
      radius,
      aroundPrecision,
      hasAvailableDates,
      dates,
      searchquery,
    } = fnGetConvertedFiltersObject(
      overwriteFilters,
      setDisjunctiveFacets,
      enableOnline,
    );

    return fnMountAlgoliaSearchParams(
      price,
      tags,
      categories,
      categoriesBySlug,
      suppliers,
      languages,
      city,
      online,
      geoloc,
      radius,
      aroundPrecision,
      hasAvailableDates,
      dates,
      searchquery,
      overwriteFilters,
    );
  };

  /** @name fnGetUsedFiltersArray
   ** @desc The method gives us an array of used algolia filters, this is mainly used to send a filter-event to algolia **/
  const fnGetUsedFiltersArray = () => {
    const { categories, languages, city, online, hasAvailableDates } =
      fnGetConvertedFiltersObject({
        locality: { name: filters.value?.locality?.slug },
      });

    return [categories, languages, city, online, hasAvailableDates].filter(
      (val) => {
        return val !== "" && val !== null;
      },
    );
  };

  return {
    filters,
    availableFacets,
    availableLanguages,
    availableLocalities,
    orderByOptions,
    pagination,

    fnUpdateFacetValues,
    fnUpdatePagination,
    fnSaveLocalStorageFilters,
    fnUpdateURLQueryFilters,
    fnLoadLocalStorageFilters,
    queryHasAnyFilters,
    fnParseQueryFilters,
    fnParseURLQueryFilters,
    fnGetUsedFiltersArray,
    fnConvertFiltersToAlgoliaSearchParams,
  };
};
