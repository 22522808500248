/**
 * Core Konfetti 2 library.
 *
 * @remarks
 * The `@konfetti-core/core` library is a core of the whole Konfetti 2 application.
 * It defines common interfaces for all eCommerce integrations, factories for creating
 * composables, logger, SSR helpers and more.
 *
 * @packageDocumentation
 */

/* istanbul ignore file */
export * from "./utils";
export * from "./factories";
export * from "./types";

declare global {
  interface Window {
    $konfetti: any;
  }
}

if (typeof window !== "undefined") {
  window.$konfetti = window.$konfetti || { integrations: [] };
}

export function track(id: string): void {
  if (typeof window !== "undefined") {
    if (window.$konfetti) {
      window.$konfetti.integrations.push(id);
    }
  }
}
