/* eslint-disable  */

export default {
  cart: {
    estimatedTotal: "Estimated Total",
    giftboxTotal: "Geschenkbox Total",
    giftcardTitle: "Geschenkgutschein",
    emptyBag: "Warenkorb ist leer",
    privateEvent: "Privat",
    giftbox: {
      title: "Geschenkkarte inkl. Versand",
      price: "nur {price}",
      addButtonLabel: "Dazu",
      addedButtonLabel: "Entfernen",
    },
    shippingTotal: "Versandkosten",
    grandTotal: "Gesamtsumme (inkl. MwSt.)",
    removeGiftbox: "Geschenkbox entfernen",
    checkoutButton: "Zur Kasse",
    continueShopping: "Weiter einkaufen",
    multiDateCard: {
      title: "Your class has {count} dates",
    },
    notEnoughTicketsError: {
      title: "Error",
      description: "There are not enough tickets available to add the cart!",
    },
    emptyCart: {
      title: "Ups! Der Warenkorb ist leer",
      description:
        "Sieht so aus, als hättest Du noch keine Artikel in den Warenkorb gelegt.",
    },
    removeItem: {
      message: "Artikel wurde entfernt",
      cancel: "Rückgängig machen",
      label: "Artikel entfernen",
    },
    itemRemoved: {
      title: "Artikel entfernt",
      message: ":Der Artikel wurde erfolgreich entfernt.",
    },
    validation: {
      modalTitle: "Dein Warenkorb wurde aktualisiert",
      goToEvent: "Zur Kursseite",
    },
    giftcard: "Gutschein",

    itemsInfo: {
      title: "Gut zu wissen",
      normalTicket: [
        {
          icon: "bi-envelope-fill",
          text: "Tickets werden via E-Mail versendet",
        },
        { icon: "bi-clock-fill", text: "Tickets sind übertragbar" },
        { icon: "bi-chat-dots-fill", text: "Bei Fragen melde Dich jederzeit" },
      ],
      giftcardAndGiftbox: [
        { icon: "bi-envelope-fill", text: "Gutschein zum Selbstausdrucken" },
        { icon: "bi-gift-fill", text: "Einlösbar für über 6.000 Kurse" },
        {
          icon: "bi-calendar-event-fill",
          text: "Gültig für drei Jahre ab Kauf",
        },
      ],
      various: [
        { icon: "bi-envelope-fill", text: "Gutschein zum Selbstausdrucken" },
        { icon: "bi-chat-dots-fill", text: "Tickets sind übertragbar" },
        {
          icon: "bi-gift-fill",
          text: "Bei Fragen melde Dich jederzeit bei uns",
        },
      ],
    },
  },
};
