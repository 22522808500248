/* eslint-disable  */

export default {
  passwordReset: {
    heading: 'Passwort vergessen?',
    submitButton: 'Passwort zurücksetzen',
    form: {
      password: 'Passwort',
      passwordConfirmation: 'Passwort bestätigen'
    },
    formSuccessMessage: 'Passwort wurde erfolgreich geändert!',
    formErrorMessage: 'Es gab einen Fehler. Bitte überprüfe Deine Eingabe.',
  },
};
