<template>
  <div class="kft-category-card">
    <div class="kft-category-card__image-wrapper">
      <slot
        name="image"
        v-bind="{ image, title, link, imageHeight, imageWidth }"
      >
        <KftLink
          :link="link"
          class="kft-button--pure kft-category-card__link"
          v-on="$listeners"
          @click:link="$emit('click:goto-category')"
        >
          <SfImage
            :alt="title"
            :src="image"
            class="kft-category-card__image"
            fit="cover"
          />
        </KftLink>
      </slot>
      <slot name="badge" v-bind="{ badgeLabel, badgeColor }">
        <KftBadge
          v-if="badgeLabel"
          :class="badgeColorClass"
          class="kft-category-card__badge"
          >{{ badgeLabel }}
        </KftBadge>
      </slot>
    </div>
    <slot name="title" v-bind="{ title, link }">
      <KftLink
        :link="link"
        class="kft-button--pure kft-category-card__link"
        v-on="$listeners"
        @click:link="$emit('click:goto-category')"
      >
        <span class="kft-category-card__title h6">
          {{ title }}
        </span>
      </KftLink>
    </slot>
  </div>
</template>
<script>
import { colorsValues as SF_COLORS } from "@konfetti-ui/shared/variables/colors";
import { KftBadge, KftLink, SfImage } from "@konfetti-ui/vue";

export default {
  name: "KftCategoryCard",
  components: {
    SfImage,
    KftBadge,
    KftLink,
  },
  props: {
    /**
     * Category image
     * It should be an url of the category
     */
    image: {
      type: [Array, Object, String],
      default: "",
    },

    /**
     * Category image width, without unit
     */
    imageWidth: {
      type: [String, Number],
      default: 270,
    },

    /**
     * Category image height, without unit
     */
    imageHeight: {
      type: [String, Number],
      default: 330,
    },

    /**
     * Badge label
     */
    badgeLabel: {
      type: String,
      default: "",
    },

    /**
     * Badge color
     * It can be according to our standard colors, or legitimate CSS color such as `#fff`, `rgb(255,255,255)`), and `lightgray` or nothing.
     * Standard colors: `primary`, `secondary`, `white`, `black`, `accent`, `green-primary`, `green-secondary`, `gray-primary`, `gray-secondary`, `light-primary`, `light-secondary`, `pink-primary`, `pink-secondary`, `yellow-primary`, `yellow-secondary`, `blue-primary`, `blue-secondary`.
     */
    badgeColor: {
      type: String,
      default: "",
    },

    /**
     * Category title
     */
    title: {
      type: String,
      default: "",
    },

    /**
     * Link to category page
     */
    link: {
      type: [String, Object],
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    isSFColors() {
      return SF_COLORS.includes(this.badgeColor.trim());
    },
    badgeColorClass() {
      return this.isSFColors ? `${this.badgeColor.trim()}` : "";
    },
  },
  methods: {},
};
</script>
<style lang="scss">
@import "~/assets/components/molecules/KftCategoryCard.scss";
</style>
