import { Context } from '@konfetti-core/core';

export type SendReviewBody = {
  rating: number,
  review: string
};

export type SendReviewParams = {
  orderItem?: string,
  signature: string,
  body: SendReviewBody
};

export async function sendEventReview (
  context: Context,
  params: SendReviewParams
): Promise<any> {
  const url = new URL(
    `v1/store/reviews/${params.orderItem}/?signature=${params.signature}`,
    context.config.api.url
  );

  const { data } = await context.client.post(url.href, params.body);

  return data;
}
