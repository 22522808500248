import type { Ref } from "@vue/composition-api";

const min = (form: Ref<any>, key: string, min: string): boolean => {
  if (typeof form.value[key] === "number") {
    return form.value[key] >= parseInt(min);
  }

  return form.value[key].length >= parseInt(min);
};

export default min;
