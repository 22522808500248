














import RenderProps from "~/components/utils/RenderProps";

export default {
  name: "KftParsedArticle",
  components: {
    RenderProps,
  },
  props: {
    components: {
      type: Array,
      default: () => [],
    },
  },
};
