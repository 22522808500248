<template>
  <div class="kft-product-card-wrapper" data-test-id="product-card">
    <nuxt-link v-slot="{ route, href }" :to="link" custom>
      <a
        :href="href"
        :target="openInNewTab ? '_blank' : '_self'"
        @click.prevent="
          handleClick ? $emit('click', route) : fnNavigateToRoute(route)
        "
      >
        <div class="kft-product-card">
          <div>
            <!--BEGIN::IMAGE WRAPPER  -->
            <div class="kft-product-card__image-wrapper">
              <slot
                name="image"
                v-bind="{ image, title, link, imageHeight, imageWidth }"
              >
                <div class="kft-button--pure kft-product-card__link">
                  <SfImage
                    :alt="title"
                    :not-responsive="true"
                    :src="image"
                    class="kft-product-card__image"
                    fit="cover"
                    sizes="xs:300"
                  />
                </div>
              </slot>
            </div>
            <!-- END::IMAGE WRAPPER -->

            <!-- BEGIN::TITLE -->
            <slot name="title" v-bind="{ title, link }">
              <div
                :title="title"
                class="kft-button--pure kft-product-card__link"
                v-on="$listeners"
              >
                <span
                  class="kft-product-card__title"
                  data-test-id="kft-product-card-title"
                >
                  {{ title }}
                </span>
              </div>
            </slot>
            <!-- END::TITLE -->
          </div>
          <div>
            <!-- BEGIN::QUICK ICONS -->
            <div class="kft-product-card__icons-wrapper">
              <slot name="duration" v-bind="{ duration, rating }">
                <div class="d-flex align-items-center">
                  <span class="kft-product-card__duration">
                    <i class="bi bi-clock-fill" :title="durationTitle"></i>
                    {{ duration }}
                  </span>
                </div>
                <div v-if="rating !== null" class="d-flex align-items-center">
                  <i class="bi bi-star-fill"></i>
                  <span>{{
                    rating || $t("product.reviews.newEventLabel")
                  }}</span>
                </div>
              </slot>
            </div>
            <!-- END::QUICK ICONS -->

            <!-- BEGIN::PRICE -->
            <slot name="price" v-bind="{ specialPrice, regularPrice }">
              <KftPrice
                v-if="regularPrice"
                :regular="regularPrice"
                :special="specialPrice"
                class="kft-product-card__price"
              />
            </slot>
            <!-- END::PRICE -->
          </div>
        </div>
        <slot></slot>
      </a>
    </nuxt-link>
  </div>
</template>
<script>
import { colorsValues as SF_COLORS } from "@konfetti-ui/shared/variables/colors";
import { KftPrice, SfImage } from "@konfetti-ui/vue";

export default {
  name: "KftProductCard",

  components: {
    KftPrice,

    SfImage,
  },
  props: {
    /**
     * Should the event open in a new tab?
     * Mind that this behavior happens only for not-mobile applications
     * */
    openInNewTab: {
      type: Boolean,
      default: false,
    },

    /**
     * Product image
     * It should be an url of the product
     */
    image: {
      type: [Array, Object, String],
      default: "",
    },

    /**
     * Product image width, without unit
     */
    imageWidth: {
      type: [String, Number],
      default: 270,
    },

    /**
     * Product image height, without unit
     */
    imageHeight: {
      type: [String, Number],
      default: 330,
    },

    /**
     * Badge label
     */
    badgeLabel: {
      type: String,
      default: "",
    },

    /**
     * Event Type
     */
    productType: {
      type: String,
      default: "",
    },

    /**
     * Event Duration Estimate
     */
    duration: {
      type: String,
      default: "",
    },

    /**
     * Event Duration Title
     */
    durationTitle: {
      type: String,
      default: "",
    },

    /**
     * Event Rating Estimate
     */
    rating: {
      type: [Number, String],
      default: null,
    },

    /**
     * Event Category
     */
    productCategory: {
      type: String,
      default: "",
    },

    /**
     * Badge color
     * It can be according to our standard colors, or legitimate CSS color such as `#fff`, `rgb(255,255,255)`), and `lightgray` or nothing.
     * Standard colors: `primary`, `secondary`, `white`, `black`, `accent`, `green-primary`, `green-secondary`, `gray-primary`, `gray-secondary`, `light-primary`, `light-secondary`, `pink-primary`, `pink-secondary`, `yellow-primary`, `yellow-secondary`, `blue-primary`, `blue-secondary`.
     */
    badgeColor: {
      type: String,
      default: "",
    },

    /**
     * Product title
     */
    title: {
      type: String,
      default: "",
    },

    /**
     * Link to product page
     */
    link: {
      type: [String, Object],
      default: "",
    },

    /**
     * Link element tag
     * @deprecated will be removed in 1.0.0 use slot to replace content
     */
    linkTag: {
      type: String,
      default: undefined,
    },

    /**
     * Product rating
     */
    scoreRating: {
      type: [Number, Boolean],
      default: false,
    },

    handleClick: {
      type: Boolean,
      default: false,
    },

    /**
     * Product reviews count
     */
    reviewsCount: {
      type: [Number, Boolean],
      default: false,
    },

    /**
     * Maximum product rating
     */
    maxRating: {
      type: [Number, String],
      default: 5,
    },

    /**
     * Product regular price
     */
    regularPrice: {
      type: [Number, String],
      default: null,
    },

    /**
     * Product special price
     */
    specialPrice: {
      type: [Number, String],
      default: null,
    },

    /**
     * Wish list icon
     * This is the default icon for product not yet added to wish list.
     * It can be a icon name from our icons list, or array or string as SVG path(s).
     */
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: "heart",
    },

    /**
     * Wish list icon for product which has been added to wish list
     * This is the icon for product added to wish list. Default visible on mobile. Visible only on hover on desktop.
     * It can be a icon name from our icons list, or array or string as SVG path(s).
     */
    isOnWishlistIcon: {
      type: [String, Array],
      default: "heart_fill",
    },

    /**
     * Status of whether product is on wish list or not
     */
    isOnWishlist: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { root: { $router } }) {
    const fnNavigateToRoute = (route) => {
      if (props.openInNewTab) {
        window.open(route.path, "_blank");
        return;
      }

      $router.push(route);
    };

    return {
      fnNavigateToRoute,
    };
  },

  computed: {
    isSFColors() {
      return SF_COLORS.includes(this.badgeColor.trim());
    },
    badgeColorClass() {
      return this.isSFColors ? `${this.badgeColor.trim()}` : "";
    },
    currentWishlistIcon() {
      return this.isOnWishlist ? this.isOnWishlistIcon : this.wishlistIcon;
    },
    ariaLabel() {
      return this.isOnWishlist ? "Remove from wishlist" : "Add to wishlist";
    },
    wishlistIconClasses() {
      const defaultClass = "kft-button--pure kft-product-card__wishlist-icon";
      return `${defaultClass} ${this.isOnWishlist ? "on-wishlist" : ""}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~/assets/components/molecules/KftProductCard.scss";
</style>
