import { Logger, sharedRef } from "@konfetti-core/core";
import { computed } from "@vue/composition-api";
import { useApiHandler } from "../useApiHandler";

export const useEventListPage = (id: string): any => {
  const eventListPages = sharedRef(null, `useEventListPage-articles-${id}`);
  const loading = sharedRef(false, `useEventListPage-loading-${id}`);
  const error = sharedRef(null, `useEventListPage-error-${id}`);

  /** Wrapper for Strapi API requests */
  const { makeStrapiRequest } = useApiHandler();

  // Method to call an API endpoint and update `categories`, `loading` and `error` properties
  const loadEventListPage = async (params: string) => {
    Logger.debug(`useEventListPages/${id}/loadEventListPages`, params);

    try {
      loading.value = true;
      eventListPages.value = await makeStrapiRequest(
        "getEventListPages",
        params,
      );

      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useEventListPage/${id}/loadEventListPage`, err);
    } finally {
      loading.value = false;
    }
  };

  const clearEventListPages = () => {
    eventListPages.value = null;
  };

  return {
    // Variables
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    eventListPages: computed(() => eventListPages.value),
    eventListPageObj: computed(
      () =>
        eventListPages.value?.[0] || eventListPages.value?.data?.[0] || null,
    ),

    // Methods
    loadEventListPage,
    clearEventListPages,
  };
};
