const ID_ACCESS_TOKEN = 'access_token' as string;
const ID_REFRESH_TOKEN = 'refresh_token' as string;
const ID_EXPIRES_AT = 'expires_at';

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_ACCESS_TOKEN);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_ACCESS_TOKEN, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_ACCESS_TOKEN);
};

export const getRefreshToken = (): string | null => {
  return window.localStorage.getItem(ID_REFRESH_TOKEN);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveRefreshToken = (token: string): void => {
  window.localStorage.setItem(ID_REFRESH_TOKEN, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyRefreshToken = (): void => {
  window.localStorage.removeItem(ID_REFRESH_TOKEN);
};

export const getExpiresAt = (): string | null => {
  return window.localStorage.getItem(ID_EXPIRES_AT);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveExpiresAt = (token: string): void => {
  window.localStorage.setItem(ID_EXPIRES_AT, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyExpiresAt = (): void => {
  window.localStorage.removeItem(ID_EXPIRES_AT);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getRefreshToken,
  saveRefreshToken,
  destroyRefreshToken,
  getExpiresAt,
  saveExpiresAt,
  destroyExpiresAt
};
