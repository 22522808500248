var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kft-header",class:{ sticky: _vm.sticky, 'is-hidden': _vm.hidden }},[_c('div',{staticClass:"kft-header__wrapper"},[_c('header',{ref:"header",staticClass:"kft-header__header"},[_vm._t("nav-icon",function(){return [_c('i',{staticClass:"bi bi-list smartphone-only toggle-menu mx-4",on:{"click":_vm.toggleNavigationSidebar}})]}),_vm._v(" "),_c('div',{staticClass:"kft-header__navigation-container",class:{
          'kft-header__navigation-container--is-mobile': _vm.$device.isMobile,
        }},[_c('div',{staticClass:"kft-header__navigation-top-row"},[_c('div',{staticClass:"kft-header__logo-container",class:{
              'kft-header__logo--booking-solution': _vm.isBookingSolution,
              'kft-header__logo-container--is-mobile': _vm.$device.isMobile,
            }},[_vm._t("logo",function(){return [_c('KftLink',{attrs:{"link":"/"}},[(_vm.logo)?_c('SfImage',{staticClass:"kft-header__logo",attrs:{"alt":_vm.title,"src":_vm.logo}}):_vm._e()],1)]},null,{ logo: _vm.logo, title: _vm.title })],2),_vm._v(" "),(!_vm.$device.isMobileOrTablet)?_vm._t("search",function(){return [_c('div',{staticClass:"w-100"})]}):_vm._e(),_vm._v(" "),_vm._t("dropdown-menus")],2),_vm._v(" "),_c('div',{staticClass:"kft-header__navigation-bottom-row",class:{
            'kft-header__navigation-bottom-row-booking-solution':
              _vm.isBookingSolution,
          }},[_c('div',{staticClass:"kft-header__nav-buttons"},[_c('nav',{staticClass:"kft-header__navigation",class:{ 'is-visible': _vm.isNavVisible }},[_vm._t("navigation")],2)])])]),_vm._v(" "),_c('div',{staticClass:"kft-header__sidebar"},[_vm._t("sidebar")],2),_vm._v(" "),(_vm.$device.isMobileOrTablet)?_c('div',{staticClass:"kft-header__search-mobile"},[_vm._t("search")],2):_vm._e(),_vm._v(" "),(_vm.useIcons)?_c('div',{staticClass:"kft-header__icons align-items-center m-0"},[_vm._t("icons")],2):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }