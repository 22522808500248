<template>
  <div :style="variables" class="sf-image--wrapper" data-testid="image-wrapper">
    <template v-if="src && !error">
      <slot>
        <KftPicture
          :alt="alt"
          :height="pictureHeight"
          :key="src"
          :imgAttrs="{ class: classes }"
          :loading="loading"
          :preload="preload"
          :sizes="sizes"
          :src="src"
          :style="cssProps"
          :width="pictureWidth"
          format="webp"
          :legacyFormat="pictureLegacyFormat"
          v-bind="$attrs"
          :should-load-on-ssr="loading === 'eager'"
          @error="onError"
          @load="onLoad"
          v-on="$listeners"
        />
      </slot>
    </template>

    <slot v-else-if="$slots.placeholder"></slot>

    <img
      v-else-if="placeholder"
      :alt="alt"
      :class="classes"
      :loading="loading"
      :src="placeholder"
      :style="cssProps"
      class="sf-image"
      v-bind="$attrs"
    />

    <div
      v-else
      :style="cssProps"
      class="sf-image--placeholder d-flex justify-content-center align-items-center"
      style="aspect-ratio: 4/3"
    >
      <i class="bi bi-exclamation-triangle"></i>
      <span class="sf-image--placeholder__text">Image error</span>
    </div>
  </div>
</template>
<script>
import KftPicture from "../../atoms/KftPicture/KftPicture";

export default {
  name: "SfImage",
  components: {
    KftPicture,
  },
  props: {
    /**
     * Main source url for the image
     */
    src: {
      type: [String],
      default: "",
    },

    /**
     * Array of images' source, dimension and breakpoints to generate srcset attribute
     */
    srcsetRaw: {
      type: String,
      default: null,
    },

    /**
     * Alternative text in case image is not loaded. Use empty string " " for decorative-only image and full text otherwise
     */
    alt: {
      type: String,
      default: "",
    },

    /**
     * Width of the image
     */
    width: {
      type: [String, Number],
      default: "",
    },

    /**
     * Height of the image
     */
    height: {
      type: [String, Number],
      default: "",
    },

    /**
     * Width of the KftPicture component to be used as basis for cloud resizing
     */
    pictureWidth: {
      type: [String, Number],
      default: "",
    },

    /**
     * Height of the KftPicture component to be used as basis for cloud resizing
     */
    pictureHeight: {
      type: [String, Number],
      default: "",
    },

    /**
     * Url source of the image's placeholder while it is loading.
     */
    placeholder: {
      type: String,
      default: null,
    },

    pictureLegacyFormat: {
      type: String,
      default: "jpeg",
    },

    /**
     * Native loading attribute supported, either "eager", "lazy" or none.
     */
    loading: {
      type: String,
      default: "lazy",
      validator: (value) => ["", "lazy", "eager"].includes(value),
    },

    /**
     * Responsive aspect ratios
     * */

    /* Default aspect ratio */
    aspectRatio: {
      type: String,
      default: null,
    },

    /* Aspect ratio for smartphones */
    aspectRatioSm: {
      type: String,
      required: false,
    },

    /* Aspect ratio for tablets and smartphones */

    aspectRatioMd: {
      type: String,
      required: false,
    },

    /* Aspect ratio for desktops */
    aspectRatioLg: {
      type: String,
      required: false,
    },

    /* Sizes */
    sizes: {
      type: String,
      required: false,
      default: "sm:100vw md:50vw lg:400px",
    },

    /* Sizes */
    sizesRaw: {
      type: String,
      required: false,
      default: null,
    },

    /* Preload image */
    preload: {
      type: Boolean,
      required: false,
      default: false,
    },

    /* Don't setup responsive picture + img tags if true */
    picture: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldLoadOnSsr: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loaded: false,
      error: false,
    };
  },
  computed: {
    cssProps() {
      return {
        "--aspect-ratio": this.aspectRatio,
        "--aspect-ratio-sm": this.aspectRatioSm || this.aspectRatio,
        "--aspect-ratio-md": this.aspectRatioMd || this.aspectRatio,
        "--aspect-ratio-lg": this.aspectRatioLg || this.aspectRatio,
      };
    },
    sortedSrcsets() {
      const arr = [...this.srcsets];

      arr.sort((setA, setB) =>
        setA.width && setB.width
          ? Number.parseInt(setA.width) - Number.parseInt(setB.width)
          : Number.parseInt(setA.resolution) - Number.parseInt(setB.resolution),
      );
      return arr;
    },
    srcset() {
      return this.sortedSrcsets.reduce(
        (str, set) =>
          `${this.prefix(str)}${set.src} ${this.srcsetDescriptor(set)}`,
        "",
      );
    },

    classes() {
      return `sf-image ${!this.error ? "sf-image-loaded" : ""}`;
    },

    variables() {
      const width =
        this.width && `--image-width: ${this.formatDimension(this.width)}`;
      const height =
        this.height && `--image-height: ${this.formatDimension(this.height)}`;

      return [width, height]
        .filter((el) => {
          return el !== "" && el !== 0;
        })
        .join(";");
    },
  },
  methods: {
    onLoad() {
      this.loaded = true;
    },
    onError() {
      this.error = true;
      this.loaded = true;
    },
    formatResolution(resolution) {
      return String(resolution).endsWith("x") ? resolution : `${resolution}x`;
    },
    formatDimension(size) {
      if (size === "") {
        return "auto";
      }

      if (typeof size === "string" && size.includes("%")) {
        return size;
      }

      return ["em", "px", "vw"].includes(`${size}`.slice(-2))
        ? size
        : `${size}px`;
    },
    prefix(str) {
      return str ? `${str}, ` : "";
    },
    srcsetDescriptor(srcset) {
      return srcset.width
        ? `${Number.parseInt(srcset.width) || ""}w`
        : this.formatResolution(srcset.resolution);
    },
  },
};
</script>
<style lang="scss">
@import "~@konfetti-ui/shared/styles/components/atoms/SfImage.scss";
</style>
