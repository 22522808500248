const getTitle = (termsAndConditions): string => {
  return termsAndConditions?.attributes?.title || null;
};

const getContent = (termsAndConditions): string => {
  return termsAndConditions?.attributes?.content || null;
};

export const termsAndConditionsGetters = {
  getTitle,
  getContent,
};
