var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner-with-search"},[_c('div',{staticClass:"banner-with-search__container"},[_c('div',{staticClass:"banner-with-search__search-card"},[(_vm.text)?_c('h1',{staticClass:"kft-heading__title h3 mb-3",style:(Object.assign({}, {color: (_vm.color + " !important")}, _vm.textStyle)),domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e(),_vm._v(" "),_c(_vm.descriptionTag || 'h2',{tag:"component",staticClass:"kft-heading__description",style:(Object.assign({}, {color: (_vm.color + " !important")}, _vm.textStyle))},[_vm._v("\n        "+_vm._s(_vm.description)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"mt-2 w-100 banner-with-search__search-input"},[_c('form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMounted),expression:"!isMounted"}],staticClass:"aa-Form",attrs:{"action":"","novalidate":"","role":"search"}},[_c('div',{staticClass:"aa-InputWrapperPrefix"},[_c('label',{staticClass:"aa-Label",attrs:{"id":"autocomplete-0-label"}},[_c('button',{staticClass:"aa-SubmitButton",style:({
                  backgroundColor: _vm.$device.isDesktopOrTablet
                    ? 'var(--c-primary)'
                    : 'white',
                }),attrs:{"title":"Submit","type":"submit"}},[_c('em',{staticClass:"bi bi-search aa-SubmitIcon",style:({
                    color: _vm.$device.isDesktopOrTablet
                      ? 'white'
                      : 'var(--c-primary)',
                  })})])])]),_vm._v(" "),_c('div',{staticClass:"aa-InputWrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.placeholderSearchQuery),expression:"placeholderSearchQuery"}],staticClass:"aa-Input",attrs:{"placeholder":_vm.$t('search.algoliaAutocomplete.placeholder'),"maxlength":"56","type":"search"},domProps:{"value":(_vm.placeholderSearchQuery)},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.$router.push(
                  _vm.localePath(("/search/?query=" + _vm.placeholderSearchQuery))
                )},"input":function($event){if($event.target.composing){ return; }_vm.placeholderSearchQuery=$event.target.value}}})])]),_vm._v(" "),_c('client-only',[_c('AlgoliaFederatedSearch',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMounted),expression:"isMounted"}]})],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }