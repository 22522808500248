<template>
  <div class='sf-footer-column'>
    <div class="sf-footer-column__title-container">
      <div v-focus class='sf-footer-column__title' @click='toggle(title)'>
        {{ title }}
      <div class='sf-footer-column__chevron'>
        <KftChevron :class="{ 'kft-chevron--top': open }"/>
      </div>
      </div>
    </div>

    <transition name='sf-fade'>
      <!-- @todo not using the v-if=open means that toggling in the sfFooter wont work
      had to comment for now it since this was implying in ssr problems (not showing content in ssr)
       -->
      <!--<div v-if='open' class='sf-footer-column__content'>-->
      <div class='sf-footer-column__content'>
        <slot/>
      </div>
    </transition>
  </div>
</template>
<script>
import KftChevron from "../../../atoms/KftChevron/KftChevron.vue";
import {focus} from "../../../../utilities/directives";

export default {
  name: "SfFooterColumn",
  directives: {focus},
  components: {KftChevron},
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  computed: {
    open() {
      return this.$parent.isOpen.includes(this.title);
    }
  },
  mounted() {
    this.$parent.items.push(this.title);
  },
  methods: {
    toggle(payload) {
      this.$parent.toggle(payload);
    }
  }
};
</script>
