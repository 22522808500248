const getISO2Locale = (locale: string): string => {
  const ISO2LocaleParts = locale.split("-");
  return ISO2LocaleParts[0];
};

const fnGetEventListIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}`;
};

const fnGetFederatedSearchIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}_federated_search`;
};

/* @todo removed for now, while testing */
const fnGetEventListRelevancyIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}`;
};

const fnGetTopEventListIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}_top_sold_events`;
};

const fnGetEventListSortByDateAscIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}_sort_by_created_at_asc`;
};

const fnGetEventListSortByDateDescIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}_sort_by_created_at_desc`;
};

const fnGetEventListSortByPriceAscIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}_sort_by_price_asc`;
};

const fnGetEventListSortByPriceDescIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}_sort_by_price_desc`;
};

const fnGetAutocompleteQuerySuggestionsIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}_query_suggestions`;
};

const fnGetTopGiftIdeasIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}_top_gift_ideas`;
};

const fnGetCitiesIndexName = (): string => {
  return "localities";
};

const fnGetCategoriesIndexName = (locale: string): string => {
  return `categories_${getISO2Locale(locale)}`;
};

export {
  getISO2Locale,
  fnGetTopEventListIndexName,
  fnGetEventListIndexName,
  fnGetEventListSortByDateAscIndexName,
  fnGetEventListSortByDateDescIndexName,
  fnGetEventListSortByPriceAscIndexName,
  fnGetEventListSortByPriceDescIndexName,
  fnGetAutocompleteQuerySuggestionsIndexName,
  fnGetCitiesIndexName,
  fnGetEventListRelevancyIndexName,
  fnGetTopGiftIdeasIndexName,
  fnGetCategoriesIndexName,
  fnGetFederatedSearchIndexName,
};
