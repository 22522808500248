import localea0810cbe from '../../lang/de_DE/index.ts'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: true,
}
export const options = {
  vueI18n: {"fallbackLocale":"de-de","numberFormats":{"en-us":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"de-de":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"en-us","label":"English","file":"en_US/index.ts","iso":"en","flag":"united-states.svg"},{"code":"de-de","label":"Deutsch","file":"de_DE/index.ts","flag":"germany.svg","iso":"de"}],
  defaultLocale: "de-de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/codebuild/output/src1109366943/src/packages/theme/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"kft-locale","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "https://gokonfetti.com",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  currency: "EUR",
  country: "US",
  countries: [{"name":"US","label":"United States"},{"name":"AT","label":"Austria"},{"name":"DE","label":"Germany"},{"name":"NL","label":"Netherlands"}],
  currencies: [{"name":"EUR","label":"Euro"},{"name":"USD","label":"Dollar"}],
  noPrefixDefaultLocale: false,
  normalizedLocales: [{"code":"en-us","label":"English","file":"en_US/index.ts","iso":"en","flag":"united-states.svg"},{"code":"de-de","label":"Deutsch","file":"de_DE/index.ts","flag":"germany.svg","iso":"de"}],
  localeCodes: ["en-us","de-de"],
  additionalMessages: [],
}

export const localeMessages = {
  'de_DE/index.ts': () => Promise.resolve(localea0810cbe),
  'en_US/index.ts': () => import('../../lang/en_US/index.ts' /* webpackChunkName: "lang-en_US/index.ts" */),
}
