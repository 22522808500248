import { Logger, sharedRef } from "@konfetti-core/core";
import { computed, ref } from "@vue/composition-api";
import { paginationGetters } from "../getters";
import { useApiHandler } from "../useApiHandler";

/* @todo: Translate this */
const COUNTRIES_MOCKED = [
  "Austria",
  "Azerbaijan",
  "Belarus",
  "Belgium",
  "Bosnia and Herzegovina",
  "Bulgaria",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Georgia",
  "Germany",
  "Greece",
  "Hungary",
  "Iceland",
  "Ireland",
  "Italy",
  "Kosovo",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Malta",
  "Moldova",
  "Monaco",
  "Montenegro",
  "The Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "San Marino",
  "Serbia",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
  "Turkey",
  "Ukraine",
  "United Kingdom",
  "Vatican City",
];

export const useAddress = (id: string): any => {
  const localities = sharedRef(null, `useAddress-localities-${id}`);
  const loading = sharedRef(false, `useAddress-loading-${id}`);
  const error = sharedRef(null, `useCustom-error-${id}`);
  const countries = ref(COUNTRIES_MOCKED);
  const localitiesWithEvents = sharedRef(
    [],
    `useCustom-citiesWithEvents-${id}`,
  );

  /** Wrapper for API requests */
  const { makeRequest } = useApiHandler();

  const clearLocalities = () => {
    localities.value = null;
  };

  // Method to call an API endpoint and update `localities`, `loading` and `error` properties
  const loadLocalities = async (params: string) => {
    Logger.debug(`useAddress/${id}/loadLocalities`, params);

    try {
      loading.value = true;
      localities.value = await makeRequest("getLocalities", params);

      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useAddress/${id}/loadLocalities`, err);
    } finally {
      loading.value = false;
    }
  };

  const loadLocalitiesWithEvents = async () => {
    Logger.debug(`useAddress/${id}/loadLocalitiesWithEvents`);

    try {
      loading.value = true;
      localitiesWithEvents.value = await makeRequest(
        "getLocalitiesWithEvents",
        "",
      );

      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useAddress/${id}/loadLocalitiesWithEvents`, err);
    } finally {
      loading.value = false;
    }
  };

  return {
    /* Attributes */
    localities: computed(() =>
      paginationGetters.validatePagination(localities.value),
    ),
    countries: computed(() => countries.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    localitiesWithEvents: computed(() => localitiesWithEvents.value),

    /* Methods */
    loadLocalities,
    loadLocalitiesWithEvents,
    clearLocalities,
  };
};
