export const createElements = (instance, options) => {
  if (typeof instance === "undefined") {
    const errorMsg =
      "Instance object is not defined, make sure you initialized Stripe before creating elements";
    console.error(errorMsg);
    return errorMsg;
  }

  return instance.elements(options);
};

export const createElement = (elements, elementType, options) => {
  if (typeof elements === "undefined") {
    const errorMsg =
      "Elements object is not defined. You can not create stripe element without it";
    console.error(errorMsg);
    return errorMsg;
  }
  if (typeof elementType === "undefined") {
    const errorMsg =
      "elementType is required. You can not create stripe element without it";
    console.error(errorMsg);
    return errorMsg;
  }

  return elements.create(elementType, options);
};

export function destroy(instance) {
  instance = undefined;
  return instance;
}
