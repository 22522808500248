/* eslint-disable  */

export default {
  magazine: {
    magazine: "Magazin",
    article: "Artikel",
    category: "Themengebiet",

    articlePage: {
      header: {
        description:
          "Werde selbst kreativ und entdecke über 4.500 Kurse. Jetzt finden und buchen.",
      },
      datePrefix: "Aktualisiert am",
      supplierProfileSubtitle: "Partner und Autor",
      relatedArticles: {
        sectionTitle: "Artikel zu ähnlichen Themen",
      },
      supplierEvents: {
        sectionTitle: "Entdecke Kurse von {partnerName}",
      },
    },
  },
};
