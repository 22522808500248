import { EventDateType, EventDateStatus, EventDate } from "../types";

const getDateType = (date: EventDate): EventDateType => {
  return date?.dateType || EventDateType.PUBLIC;
};

const getIsPrivate = (date: EventDate, strict = false) => {
  if (strict) {
    return getDateType(date) === EventDateType.PRIVATE;
  }

  return getDateType(date) !== EventDateType.PUBLIC;
};

const getIsPublic = (date: EventDate, strict = false) => {
  if (strict) {
    return getDateType(date) === EventDateType.PUBLIC;
  }
  return getDateType(date) !== EventDateType.PRIVATE;
};

const getDateIsOpen = (date: EventDate) => {
  return date.status === EventDateStatus.OPEN;
};

const getDateIsSoldOut = (date: EventDate) => {
  return date.status === EventDateStatus.SOLD_OUT;
};

// const getPrivateEventDates = (dateList) => {
//   const dates = [];
//   for (let i = 0; i < dateList.data.length; i++) {
//     const date = dateList.data[i];
//     if (!getIsPrivate(date)) {
//       continue;
//     }
//
//     date.$isDisabled = !getDateIsOpen(date);
//     dates.push(date);
//   }
//
//   return dates;
// };
//
// const getPublicEventDates = (dateList) => {
//   const dates = [];
//   for (let i = 0; i < dateList.data.length; i++) {
//     const date = dateList.data[i];
//     if (!getIsPublic(date)) {
//       continue;
//     }
//
//     date.$isDisabled = !getDateIsOpen(date);
//     dates.push(date);
//   }
//
//   return dates;
// };

const getAvailableTicketsQuantity = (date: EventDate) => {
  return date?.availableTicketsQuantity || 0;
};

const getPrivateMinTickets = (date: EventDate) => {
  return date?.privateMinTickets || 1;
};

const getPrivateMaxTickets = (date: EventDate) => {
  return date?.privateMaxTickets || null;
};

const getPrice = (date: EventDate) => {
  return date?.product?.data?.price || null;
};

const getStartTime = (date: EventDate) => {
  return date?.start || null;
};

const getEndTime = (date: EventDate) => {
  return date?.end || null;
};

export const eventDateGetters = {
  getIsPublic,
  getDateType,
  getIsPrivate,
  getDateIsOpen,
  getStartTime,
  getEndTime,
  // getPrivateEventDates,
  // getPublicEventDates,
  getAvailableTicketsQuantity,
  getPrivateMinTickets,
  getPrivateMaxTickets,
  getPrice,
  getDateIsSoldOut,
};
