<template functional>
  <div
    v-bind='data.attrs'
    v-on='listeners'
    :class="[data.class, data.staticClass, 'kft-badge']"
    :style='[data.style, data.staticStyle]'
  >
    <!--@slot Use this slot to place content inside the badge-->
    <slot />
  </div>
</template>
<script>
export default {
  name: "KftBadge"
};
</script>
<style lang='scss'>
@import "~@konfetti-ui/shared/styles/components/atoms/KftBadge.scss";
</style>
