/* eslint-disable camelcase */
import { CategoryItem, AlgoliaEventItem, ImageItem } from "../types";
import { round } from "../helpers/math";
import { eventGetters } from "./eventGetters";
// @todo add to env
// @todo use the correct formats for old browsers in getThumbnail
const THUMBNAIL_WIDTH = 200;
const MEDIA_BASE_URL = "https://media.gokonfetti.com";

const fnGetMainCategory = (item: AlgoliaEventItem): string | null => {
  return item?.categories?.[0]?.name || null;
};

const fnGetCategories = (item: AlgoliaEventItem): CategoryItem[] => {
  return Array.isArray(item?.categories)
    ? item?.categories
    : Array.isArray(item?.categories?.data)
    ? item.categories.data
    : null;
};

const fnGetPrincipalImage = (item: AlgoliaEventItem): string => {
  if (item?.main_image?.thumb) {
    return item?.main_image?.thumb;
  }
  // Fallback thumbnails
  if (item?.images?.find((image) => image?.isPrincipal)) {
    return item?.images?.find((image) => image?.isPrincipal)?.thumb;
  }

  return item?.images?.[0]?.thumb;
};

const fnGetPermalink = (item: AlgoliaEventItem): string => {
  return item?.permalink || "";
};

const fnGetPrice = (item: AlgoliaEventItem, prefix = ""): string => {
  let price = item.default_price?.formatted || null;

  const dates = item.dates;
  if (typeof dates !== "undefined" && dates.length > 0) {
    const date = dates[0];

    price = date.price?.formatted || null;
  }

  return prefix + " " + price;
};

const getPriceAmount = (item: AlgoliaEventItem): number =>
  parseInt(item?.default_price?.amount || "0");

const getAverageRating = (
  event: AlgoliaEventItem,
  locale = "de-DE",
): string | number | null =>
  isGiftcardEvent(event)
    ? null
    : event?.rating
    ? new Intl.NumberFormat(locale, {
        maximumSignificantDigits: 2,
        minimumSignificantDigits: 2,
      }).format(event?.rating)
    : 0;

const fnGetDuration = (item: AlgoliaEventItem): number => {
  /* Default duration is in minutes */
  const duration = item.default_duration;
  if (duration) {
    return duration;
  }

  const dates = item.dates;
  if (typeof dates !== "undefined" && dates.length > 0) {
    const date = dates[0];

    /* Duration is in minutes */
    return date.duration;
  }

  return null;
};

/**
 * @desc
 * Builds an associative array of category => event[]
 * Used in the city pages, mainly
 * */
const fnLoadEventsByCategory = (items: AlgoliaEventItem[]) => {
  const eventsByCategory = [];
  const existingCategoriesAndIndexes = [];

  for (let i = 0; i < items.length; i++) {
    const event = items[i];
    const cat = event?.categories?.[0] || null;
    const slug = cat?.slug || null;
    if (!slug) {
      continue;
    }

    if (typeof existingCategoriesAndIndexes[slug] === "undefined") {
      existingCategoriesAndIndexes[slug] = eventsByCategory.length;
      eventsByCategory.push({
        categorySlug: slug,
        category: cat?.name,
        data: [],
      });
    }

    const categoryIndex = existingCategoriesAndIndexes[slug];
    if (eventsByCategory[categoryIndex]?.data?.length >= 5) {
      continue;
    }

    eventsByCategory[categoryIndex].data.push(event);
  }

  return eventsByCategory;
};

const fnGetTitle = (item: AlgoliaEventItem): string => {
  return item.title;
};

const fnGetSubtitle = (item: AlgoliaEventItem): string => {
  return item.subtitle;
};

const fnGetNextDateAvailableStart = (item: AlgoliaEventItem): number | null => {
  return item?.next_event || null;
};

const fnGetNextDateAvailableEnd = (item: AlgoliaEventItem): number | null => {
  const dates = item.dates;
  if (typeof dates !== "undefined" && dates.length > 0) {
    const date = dates[0];

    return date.start_date;
  }

  return null;
};

const fnGetType = (item: AlgoliaEventItem): string => {
  return item.type || "";
};

const getLanguages = (item: AlgoliaEventItem): string[] => {
  return item.languages || null;
};

const getMinDate = (item: AlgoliaEventItem): number => {
  return item.min_date || null;
};

const getMaxDate = (item: AlgoliaEventItem): number => {
  return item.max_date || null;
};

const getTotalTicketsAvailable = (item: AlgoliaEventItem): number => {
  return item.total_tickets_available || null;
};

const getCreatedAt = (item: AlgoliaEventItem): number => {
  return item.created_at || null;
};

const getRating = (item: AlgoliaEventItem): number => {
  return item.rating || null;
};

const getTotalRating = (item: AlgoliaEventItem): number => {
  return item.totalRating || null;
};

const fnGetImages = (item: AlgoliaEventItem): ImageItem[] => {
  return item.images;
};

const fnGetMinimumPriceAmount = (item: AlgoliaEventItem): number => {
  return item.min_price
    ? parseInt(item.min_price.amount)
    : parseInt(item.default_price.amount);
};

const fnGetMaximumPriceAmount = (item: AlgoliaEventItem): number => {
  return item.max_price && Number(item.max_price.amount) > 0
    ? parseInt(item.max_price.amount)
    : getPriceAmount(item);
};

const fnGetMinimumPriceCurrency = (item: AlgoliaEventItem): string => {
  return item.min_price ? item.min_price.currency : item.default_price.currency;
};

const fnGetMaximumPriceCurrency = (item: AlgoliaEventItem): string => {
  return item.max_price ? item.max_price.currency : item.default_price.currency;
};

// This is supplier name
const fnGetSupplier = (item: AlgoliaEventItem): string => {
  return item?.supplier || "";
};

const getSupplierId = (item: AlgoliaEventItem): string => {
  return item?.supplier_id || "";
};

const getSupplierAlias = (item: AlgoliaEventItem): string => {
  return item?.supplier_alias || "";
};

const fnGetCity = (item: AlgoliaEventItem): string => {
  return item.city || "";
};

const fnGetCitySlug = (item: AlgoliaEventItem): string => {
  return item.city_slug || "";
};

const fnGetFormattedAddress = (item: AlgoliaEventItem): string => {
  return item.address?.formatted || null;
};

const fnGetFormattedZipcode = (item: AlgoliaEventItem): string => {
  return item?.address?.zipcode || "";
};

const fnGetHashedId = (item: AlgoliaEventItem): string => {
  return item?.hashed_id || "";
};

const isGiftcardEvent = (event: AlgoliaEventItem): boolean =>
  fnGetSupplier(event) === "konfetti Gutscheine";

const fnGetLink = (
  eventItem: AlgoliaEventItem,
  queryId = null,
  addSupplierLayoutQuery = false,
) => {
  let query = null;

  let link = `/${
    isGiftcardEvent(eventItem) ? "giftcard" : "e"
  }/${fnGetPermalink(eventItem)}/`;

  if (addSupplierLayoutQuery) {
    query = "layout=sup";
  }

  if (queryId) {
    query = query ? `${query}&qid=${queryId}` : `qid=${queryId}`;
  }

  if (query) {
    link = `${link}?${query}`;
  }

  return link;
};

const getRouterPushToEvent = (
  algoliaItem: AlgoliaEventItem,
  queryId: string,
  locale: string,
  addSupplierLayoutQuery = false,
) => {
  return {
    name: `${
      isGiftcardEvent(algoliaItem) ? "special-giftcards" : "event-page"
    }___${locale}`,
    query: {
      ...(queryId && { qid: queryId }),
      ...(addSupplierLayoutQuery && { layout: "sup" }),
    },
    path: fnGetLink(algoliaItem, queryId, addSupplierLayoutQuery),
    params: {
      event: fnGetPermalink(algoliaItem),
      algoliaEventFromPreviousPage: algoliaItem,
    },
  };
};

export const algoliaEventGetters = {
  fnGetPrincipalImage,
  fnGetMainCategory,
  fnGetCategories,
  fnGetPermalink,
  fnGetPrice,
  getPriceAmount,
  getAverageRating,
  fnGetDuration,
  fnLoadEventsByCategory,
  fnGetTitle,
  fnGetSubtitle,
  fnGetNextDateAvailableStart,
  fnGetNextDateAvailableEnd,
  fnGetType,
  fnGetImages,
  fnGetMinimumPriceAmount,
  fnGetMinimumPriceCurrency,
  fnGetSupplier,
  fnGetCity,
  fnGetCitySlug,
  fnGetFormattedAddress,
  fnGetFormattedZipcode,
  fnGetMaximumPriceAmount,
  fnGetMaximumPriceCurrency,
  fnGetHashedId,
  fnGetLink,
  isGiftcardEvent,
  getLanguages,
  getMinDate,
  getMaxDate,
  getTotalTicketsAvailable,
  getCreatedAt,
  getSupplierId,
  getSupplierAlias,
  getTotalRating,
  getRating,
  getRouterPushToEvent,
};
