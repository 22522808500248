var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 multiselect__option__content",class:{ 'multiselect__option__content--active': _vm.multiDateIsOpen }},[_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"option__desc",domProps:{"innerHTML":_vm._s(_vm.fnGetDateLabelHtml(_vm.optionData))}}),_vm._v(" "),(_vm.optionData.status === 'SOLD_OUT')?_c('span',{staticClass:"multiselect__option-label"},[_vm._v(_vm._s(_vm.$t("product.status.soldOut"))+"\n      ")]):(_vm.optionData.status === 'EXPIRED')?_c('span',{staticClass:"multiselect__option-label"},[_vm._v("\n        "+_vm._s(_vm.$t("product.status.expired"))+"\n      ")]):(
          _vm.optionData.availableTicketsQuantity <=
            _vm.optionData.totalTicketsSold ||
          _vm.optionData.availableTicketsQuantity <= 3
        )?_c('span',{staticClass:"multiselect__option-label few-tickets-left"},[_vm._v("\n        "+_vm._s(_vm.$t("product.status.fewTicketsLeft"))+"\n      ")]):_c('span')]),_vm._v(" "),(_vm.showCollapseButton && !_vm.alwaysShowMultidate && _vm.hasChildren)?_c('span',{staticClass:"fs-6",staticStyle:{"color":"#dadada"},on:{"click":function($event){$event.stopPropagation();_vm.multiDateIsOpen = !_vm.multiDateIsOpen}}},[_c('i',{staticClass:"bi",class:{
          'bi-plus-circle-fill': !_vm.multiDateIsOpen,
          'bi-dash-circle-fill': _vm.multiDateIsOpen,
        }})]):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"mode":"out-in","name":"sf-collapse-top"}},[(_vm.computedShowChildrenDates)?_c('div',{staticClass:"mt-2"},[_c('KftBulletPoints',{attrs:{"items-html-arr":_vm.childrenHtmlArr}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }