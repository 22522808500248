<template>
  <li class="kft-buy-box-multiselect-after-list d-block">
    <span class="multiselect__option">
      <div class="option__desc d-flex gap-2 w-100 justify-content-between">
        <button
          v-if="enableDateRequest"
          class="open-modal-button"
          @click="toggleDateRequestSidebar"
        >
          <img src="/icons/calendar.svg" alt="calendar icon" />
          <span class="ml-2">
            {{ $t("product.show.noAvailableDatesBox.ctaTextOnlyProposeDate") }}
          </span>
        </button>

        <button
          v-if="enableWaitlist"
          class="open-modal-button"
          @click="toggleDateWaitlistSidebar"
        >
          <img src="/icons/alert-bell.svg" alt="bell icon" />
          <span class="ml-2">
            {{ $t("product.show.noAvailableDatesBox.ctaTextOnlyWaitlist") }}
          </span>
        </button>
      </div>
    </span>
  </li>
</template>

<script>
import { useUiState } from "@/composables";

export default {
  name: "KftBuyBoxMultiselectAfterList",

  props: {
    enableDateRequest: {
      type: Boolean,
      default: true,
    },
    enableWaitlist: {
      type: Boolean,
      default: true,
    },
    actionBtnTxt: {
      type: String,
      default: "",
    },
  },

  setup() {
    const { toggleDateRequestSidebar, toggleDateWaitlistSidebar } =
      useUiState();

    return {
      toggleDateRequestSidebar,
      toggleDateWaitlistSidebar,
    };
  },
};
</script>

<style lang="scss" scoped>
.kft-buy-box-multiselect-after-list {
  position: sticky;
  bottom: 0;
  border-top: 1px solid #f5f5f5;
  background-color: #fff;
}

::v-deep .kft-link a {
  --link-color: #35495e;
  --link-font-size: var(--label-font-size);
  --link-font-weight: var(--label-font-weight);
  --link-font-line-height: var(--label-font-line-height);
  text-decoration-line: underline;
}

.open-modal-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: var(--spacer-1) var(--spacer-2);
  background-color: white;
  cursor: pointer;
  gap: var(--spacer-2);
  color: #35495e;
  font-size: var(--label-font-size);
  font-weight: var(--label-font-weight);
  line-height: var(--label-font-line-height);
  border-radius: 5px;
  border: solid 1px #e7e7e7;

  @include for-tablet-and-up {
    font-size: 12px;
  }

  &:hover {
    color: #026166;
    img {
      filter: brightness(0) saturate(100%) invert(25%) sepia(48%)
        saturate(1410%) hue-rotate(145deg) brightness(101%) contrast(98%);
    }
  }

  img {
    width: 12px;
  }
}
</style>
