






























































































import { algoliaEventGetters, useProduct } from "@konfetti/composables/src";
import { computed } from "@nuxtjs/composition-api";
import { SfImage, KftHeading } from "@konfetti-ui/vue";
import {
  fnGetFormattedDuration,
  parseEventDescriptionToAlgoliaEventItem,
} from "@/helpers";
import { KftNotificationType, useNotifications } from "@/composables";

export default {
  name: "DateRequestComplete",
  components: {
    SfImage,
    KftHeading,
  },
  props: {
    categoryName: {
      type: String,
      required: false,
      default: "",
    },
    cityName: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props, { root: { $route, $i18n, $router, localePath, $scrollToTop } }) {
    const { similarEvents } = useProduct(
      `product-page-${$route.params.event?.toString()}`,
    );

    const { addNotification } = useNotifications();

    const parsedSimilarEvents = computed(() => {
      return similarEvents.value.data
        .map((el) => parseEventDescriptionToAlgoliaEventItem(el))
        .splice(0, 4);
    });

    const defaultDurationString = $i18n.t(
      "product.card.durationDefaultContent",
    );

    const handleClickSeeMore = async () => {
      addNotification({
        type: KftNotificationType.SUCCESS,
        message: $i18n.t(
          "product.dateRequest.eventSuggestions.seeMoreNotification",
        ),
      });

      $router.push({
        path: localePath("search"),
        query: {
          category: props.categoryName,
          ...(Boolean(props.cityName) && { city: props.cityName }),
        },
      });

      await nextTick();
      await $scrollToTop();
    };

    return {
      similarEvents,
      parsedSimilarEvents,
      defaultDurationString,
      algoliaEventGetters,
      handleClickSeeMore,
      fnGetFormattedDuration,
    };
  },
};
