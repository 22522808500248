import { sharedRef, UseKftContext } from "@konfetti-core/core";

import algoliasearchHelper from "algoliasearch-helper";
import { useCache } from "@konfetti/composables";
import { HITS_PER_SHOWCASE, useAlgoliaCore } from "./core";
import { hashString } from "~/helpers/cryptoHelpers";

export const useAlgoliaSearch = (id: string) => {
  /** @name availableFacets
   ** @desc The updated list of available facets, with number of hits */
  const availableFacets = sharedRef(
    {
      categories: [],
    },
    `useAlgoliaSearch/availableFacets-${id}`,
  );
  const { getKey, setKey } = useCache();

  const helper = sharedRef<algoliasearchHelper.AlgoliaSearchHelper | null>(
    null,
    `useAlgoliaSearch-${id}-helper`,
  );
  const hits = sharedRef<Array<any>>([], `useAlgoliaSearch-${id}-hits`);
  const queryID = sharedRef<string>("", `useAlgoliaSearch-${id}-queryID`);
  /** Viewed events in the search, used to remove them from further recommendations */
  const viewedEvents = sharedRef<string[]>(
    [],
    `useAlgoliaSearch-${id}-viewedEvents`,
  );
  /** Array of visited pagination pages, used to prevent adding duplicates in viewedEvents */
  const viewedPages = sharedRef<string[]>(
    [],
    `useAlgoliaSearch-${id}-viewedPages`,
  );

  /** Used to prevent duplicate queries */
  const lastQuery = sharedRef<string>("", `useAlgoliaSearch-${id}-lastQuery`);

  /** Variables
   ** ------------- */
  const { token, client, indexName, isLoaded, fnInit } = useAlgoliaCore(id);

  const context = UseKftContext();

  /** Methods
   ** ------------- */

  /**
   * @name fnInitHelper
   * @desc Init algolia helper. If algolia core is not loaded, it calls fnInit
   * */
  const fnInitHelper = async () => {
    await fnInit();

    if (helper.value !== null) {
      return;
    }

    if (process.server) {
      return;
    }

    helper.value = await algoliasearchHelper(client.value, indexName.value, {
      userToken: token.value,
      disjunctiveFacets: ["categories.name", "languages"],
      facets: [""],
    });
  };

  const fnCopyParamsForCache = (params, categories, languages) => {
    const copy = {
      place: "helper",
      ...params,
      categories,
      languages,
    };

    if (!params.aroundLatLngViaIP && !params.enablePeronalization) {
      copy.userToken = null;
    }

    return copy;
  };

  /**
   * @name fnPerformSearch
   * @desc Perform a general algolia search based on the given params
   * */
  const fnPerformSearch = async (
    options: {
      customParams?: any;
      query?: string;
      categories?: any[];
      languages?: any[];
      shouldCacheResult?: boolean;
    } = {
      customParams: undefined,
      shouldCacheResult: false,
      query: "",
      categories: [],
      languages: [],
    },
  ): Promise<void> => {
    const defaultOptions = {
      customParams: undefined,
      shouldCacheResult: false,
      query: "",
      categories: [],
      languages: [],
    };

    const { query, customParams, shouldCacheResult, categories, languages } = {
      ...defaultOptions,
      ...options,
    };

    if (helper.value === null) {
      await fnInitHelper();
    }

    const params = customParams || {
      userToken: token.value,
      hitsPerPage: HITS_PER_SHOWCASE,
      aroundLatLngViaIP: false,
      aroundRadius: "all",
    };

    try {
      /* Using searchOnce instead of search because we already had our own state scheme
       * We might change it later
       */
      helper.value.clearRefinements();
      helper.value.clearCache();

      for (let i = 0; i < categories.length; i++) {
        helper.value.addDisjunctiveFacetRefinement(
          "categories.name",
          categories[i],
        );
      }

      for (let i = 0; i < languages.length; i++) {
        helper.value.addDisjunctiveFacetRefinement("languages", languages[i]);
      }

      let cacheKey = null;
      let cacheResults = null;
      // Getting cache key on client-side should throw errors
      if (!process.client || shouldCacheResult) {
        cacheKey = hashString(
          JSON.stringify(fnCopyParamsForCache(params, categories, languages)),
        );
        cacheResults = await getKey(cacheKey);
      }

      if (
        cacheResults !== null &&
        cacheResults !== "" &&
        !params.aroundLatLngViaIP
      ) {
        return cacheResults;
      }

      return await helper.value
        .setIndex(indexName.value)
        .searchOnce({
          clickAnalytics: true,
          query,
          ...params,
        })
        .then((res) => {
          if (cacheKey) {
            setKey(cacheKey, res);
          }
          return res;
        });
    } catch (e) {
      console.error(e);
    }
  };

  return {
    /* query IDs */
    availableFacets,
    hits,
    queryID,
    viewedEvents,
    viewedPages,
    lastQuery,
    /* Methods */
    fnInitHelper,
    fnPerformSearch,
  };
};
