<template>
  <div
    :class="{ 'kft-user-profile-header--transparent-background': $slots.image }"
    class="kft-user-profile-header"
  >
    <div
      v-if="$slots.image && !hideImage"
      class="kft-user-profile-header__image-container"
    >
      <div class="kft-user-profile-header__image">
        <slot name="image"></slot>
      </div>
    </div>
    <div style="margin: auto 0">
      <KftHeading
        :use-h-tag="useHTag"
        :h-class="titleHClass || 'h5'"
        :level="titleLevel || 5"
        :title="title"
        class="kft-user-profile-header__title kft-heading--left"
        data-test-id="supplier-profile-title"
      />
      <slot v-if="$slots.subtitle" name="subtitle"></slot>
      <div v-else class="kft-user-profile-header__subtitle">{{ subtitle }}</div>
    </div>
  </div>
</template>

<script>
import { KftHeading } from "@konfetti-ui/vue";

export default {
  name: "KftUserProfileHeader",
  components: {
    KftHeading,
  },
  props: {
    title: {
      type: String,
    },
    titleLevel: {
      type: Number,
    },
    titleHClass: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    date: {
      type: String,
    },
    hideImage: {
      type: Boolean,
      default: false,
    },
    useHTag: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss">
@import "~@konfetti-ui/shared/styles/components/molecules/KftUserProfileHeader.scss";
</style>
