var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kft-product-showcase"},[_c('KftHeading',{staticClass:"mb-8 mb-md-4 kft-product-showcase__heading kft-heading--left",attrs:{"h-class":_vm.titleHClass || ("h" + _vm.titleLevel),"level":_vm.titleLevel,"title":_vm.title,"use-h-tag":_vm.useHTag}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading && _vm.products.length === 0),expression:"loading && products.length === 0"}],staticClass:"kft-product-showcase__container"},[_c('div',{staticClass:"kft-product-showcase__cards-container row",class:_vm.rowClasses},_vm._l((_vm.$device.isDesktopOrTablet ? 10 : 4),function(index){return _c('content-loader',{key:("search-events__loading__event-" + index),staticClass:"kft-product-card loading p-0",attrs:{"height":200,"speed":2,"width":165,"primary-color":"#ebebeb","secondary-color":"#ffffff"}},[_c('rect',{attrs:{"height":"120","rx":"5","ry":"5","width":"160","x":"0","y":"0"}}),_vm._v(" "),_c('rect',{attrs:{"height":"15","rx":"5","ry":"5","width":"160","x":"0","y":"130"}}),_vm._v(" "),_c('rect',{attrs:{"height":"10","rx":"5","ry":"5","width":"87","x":"0","y":"150"}}),_vm._v(" "),_c('rect',{attrs:{"height":"11","rx":"5","ry":"5","width":"87","x":"0","y":"165"}}),_vm._v(" "),_c('rect',{attrs:{"height":"15","rx":"5","ry":"5","width":"160","x":"0","y":"180"}})])}),1)]),_vm._v(" "),[_c('div',{staticClass:"kft-product-showcase__cards-container row",class:_vm.rowClasses},[_vm._l((_vm.products),function(item,i){return [(_vm.algoliaEventGetters.fnGetMaximumPriceAmount(item) > 0)?_c('KftProductCard',{key:("kft-search-product-card-" + i),staticClass:"products__product-card",style:({ '--index': i }),attrs:{"data-insights-object-id":item.objectID,"duration":_vm.fnGetFormattedDuration(
              _vm.algoliaEventGetters.fnGetDuration(item),
              _vm.defaultDurationString
            ),"duration-title":_vm.$t('product.card.duration'),"image":_vm.algoliaEventGetters.fnGetPrincipalImage(item),"link":_vm.localePath(
              _vm.algoliaEventGetters.fnGetLink(
                item,
                null,
                _vm.$route.query.layout === 'sup'
              )
            ),"next":null,"product-category":_vm.algoliaEventGetters.fnGetMainCategory(item),"product-type":'',"rating":_vm.algoliaEventGetters.getAverageRating(item, _vm.localeBCP47),"regular-price":_vm.formatPriceAmount(
              _vm.algoliaEventGetters.getPriceAmount(item),
              _vm.locale
            ),"show-add-to-cart-button":false,"title":item.title || 'simple-event-showcase',"wishlist-icon":false,"handle-click":""},on:{"click":function($event){return _vm.fnHandleClickOnCard(item, i)}}}):_vm._e(),_vm._v(" "),_vm._l((_vm.promotionSlotIndices),function(position,index){return [(i === position)?_vm._t(("promotion" + (index + 1))):_vm._e()]})]})],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }