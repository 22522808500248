/* eslint-disable  */

export default {
  cityCategory: {
    header: {
      title: '{category} in {city} ',
      subtitle:
        'Bei konfetti neue Kurse und Workshops in Deiner Stadt entdecken. Für jeden Anlass.',
      titleTextColor: '#1B7175',
      image: '/category/header.png',
      imageMobile:  '/category/header.png',
      button: 'Suchen',
      bgColor: '#b3d1d0',
      descriptionTextColor: '#1B7175',
    },
    categories: 'Pick from our favorite categories',
    categoryEventsInCity: '{category} classes in {city}',

    heroes: {
      giftcard: {
        title: 'Auf der Suche nach einem Geschenk in Deiner Stadt?',
        subtitle: 'Verschenke jetzt unseren Gutschein. Einlösbar für mehr als 4.500 Workshops und Erlebnisse. Das perfekte Geschenk für (fast) jeden Anlass.',
        buttonText: 'Geschenkgutschein kaufen',
        image: '/homepage/heroFirst.webp',
        mobileImage: '/homepage/heroFirst.webp',
      },
      teamEvent: {
        title: 'Buche Dein nächstes Teamevent für Dein Unternehmen',
        subtitle:
          'Mit konfetti findest Du für Dein nächstes Teamevent über 4.500 spannende' +
          ' Kurse und Workshops, die den Teamgeist stärken. Entdecke Top Teambuilding Ideen! ',
        image: '/partner/apply-in-few-steps.webp',
        mobileImage: '/partner/apply-in-few-steps.webp',
        buttonText: 'Teamevent anfragen',
      },
    },

    eventShowcaseSection: {
      title: '{category} in {city}'
    },

    showcase: {
      title: 'Das sagen unsere Partner'
    },
  }
};
