const modules = [
  "~/middleware.ts",
  "@nuxtjs/i18n",
  "cookie-universal-nuxt",
  "@nuxtjs/gtm",
  "nuxt-precompress",
  "@nuxtjs/sitemap",
  "vue-wait/nuxt",
  "nuxt-basic-auth-module"
];

if (process.env.BUGSNAG_ENABLED === "true") {
  // @ts-ignore
  modules.push(["nuxt-bugsnag", {
    publishRelease: true,
    apiKey: process.env.BUGSNAG_API_KEY,
    enabledReleaseStages: ["development", "production"],
    releaseStage: process.env.BUGSNAG_RELEASE_STAGE,
    appVersion: "1.0"
  }]);
}
if (process.env.APP_ENV === "production" || process.env.APP_ENV === "homolog") {
  // modules.push('@nuxtjs/gtm');
  // modules.push('nuxt-precompress');
  // modules.push('@nuxtjs/sitemap');
}

export { modules };
