<template functional>
  <div
    v-bind='data.attrs'
    v-on='listeners'
    :class="[data.class, data.staticClass, 'sf-property']"
    :style='[data.style, data.staticStyle]'
  >
    <!-- @slot Use this slot to replace property name -->
    <slot v-bind='{ props }' name='name'>
      <span class='sf-property__name' data-test-id="name">
        {{ props.name }}
      </span>
    </slot>
    <!-- @slot Use this slot to replace property value -->
    <slot v-bind='{ props }' name='value'>
      <span class='sf-property__value' data-test-id="value">
        {{ props.value }}
      </span>
    </slot>
  </div>
</template>
<script>
export default {
  name: "SfProperty",
  props: {
    name: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number],
      default: ""
    }
  }
};
</script>
<style lang='scss'>
@import "~@konfetti-ui/shared/styles/components/atoms/SfProperty.scss";
</style>
