import { Ref } from "@vue/composition-api";

const regex = (form: Ref<any>, key: string, regex: string): boolean => {
  if (Array.isArray(form.value[key])) {
    return form.value[key].every((value) => new RegExp(regex).test(value));
  }
  return new RegExp(regex).test(form.value[key]);
};

export default regex;
