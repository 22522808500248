export default {
  seo: {
    konfettiOrganizationSchema: {
      "@context": "http://schema.org/",
      "@type": "Organization",
      name: "konfetti",
      logo: "https://gokonfetti.com/logos/full-logo-color-black.svg",
      url: "https://gokonfetti.com/de-de/",
    },
  },
};
