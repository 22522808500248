import type { Ref } from "@vue/composition-api";

const max = (form: Ref<any>, key: string, max: string): boolean => {
  if (typeof form.value[key] === "number") {
    return form.value[key] <= parseInt(max);
  }

  return form.value[key].length <= parseInt(max);
};

export default max;
