import { cartGetters, useCart, CartKeys } from "@konfetti/composables";
import { CartValidationError, Error400 } from "@konfetti/composables/src";
import { UseKftContext } from "@konfetti-core/core";
import { fnGetItemValidationRemovableReasons } from "@konfetti/composables/src/getters/cartGetters";
import { useEmbeddedCheckout } from "../../useEmbeddedCheckout";
import { ErrorStoreError } from "../../useErrorStore";
import { ErrorHandlerComposable } from "../types";

const useCartValidationErrorHandler: ErrorHandlerComposable = () => {
  const { isEmbedded } = useEmbeddedCheckout();
  const {
    $showKftValidationModal,
    app: { router },
    localePath,
  } = UseKftContext();
  const key = isEmbedded.value ? CartKeys.EMBEDDED : CartKeys.GENERAL;

  const { cart, updateItemQuantity, removeItem, cartIsEmpty } = useCart(key);

  const fnParseCartErrors = (
    cartItemsValidationErrorResponse: Error400 | Error400[],
  ) => {
    return (
      cartItemsValidationErrorResponse?.[0]?.errors ||
      (cartItemsValidationErrorResponse as Error400)?.errors
    );
  };

  const handleInvalidItems = (errors: CartValidationError[]): void | [] => {
    const removable = fnGetItemValidationRemovableReasons();
    for (let i = 0; i < errors.length; i++) {
      const error = errors[i];
      if (error?.reason === "notEnough") {
        updateItemQuantity(error?.date, error?.date?.availability);
        continue;
      }

      if (removable.includes(error?.reason)) {
        const obj = cart.value.items.find(
          (x) =>
            error?.date?.id || error?.event?.id === cartGetters.getItemId(x),
        );

        if (obj) {
          removeItem(obj);
        }
      }
    }
  };

  const handle = ({ error }: ErrorStoreError<Error400>) => {
    const parsedCartItemErrors = fnParseCartErrors(error);

    if (!parsedCartItemErrors || parsedCartItemErrors.length === 0) {
      return;
    }

    handleInvalidItems(parsedCartItemErrors);
    $showKftValidationModal(parsedCartItemErrors);

    if (cartIsEmpty.value) {
      router.push(localePath("/"));
    }
  };

  return {
    handle,
  };
};

export { useCartValidationErrorHandler };
