import {
  useOrder,
  ValidationError,
  CartValidationErrors,
  CartKeys,
  useApiHandler,
} from "@konfetti/composables";
import { Logger, sharedRef } from "@konfetti-core/core";
import { computed } from "@vue/composition-api";
import { useErrorStore, ErrorStoreErrorType } from "~/composables";

export const useOrderValidation = (
  id: string,
  hasAccessToWindowObject: boolean,
  customUserToken = null,
): any => {
  const cartValidationErrors = sharedRef<CartValidationErrors>(
    null,
    `useOrderValidation/cartValidationErrors-${id}`,
  );

  const orderValidationErrors = sharedRef<ValidationError[]>(
    null,
    `useOrderValidation/orderValidationErrors-${id}`,
  );

  const validationError = sharedRef<ValidationError>(
    null,
    `useOrderValidation-validationError-${id}`,
  );

  const { fnCreateOrderObject } = useOrder(
    CartKeys.GENERAL,
    hasAccessToWindowObject,
  );

  const { setJwtToken, makeRequest } = useApiHandler(hasAccessToWindowObject);
  const { handleError } = useErrorStore();

  const fnCreateOrderValidationParams = (
    gateway: string,
    token: string | null,
    items: any = null,
    shippingAddress: any = null,
    invoiceAddress: any = null,
    coupon: string = null,
    external = false,
    paymentMethod: string = null,
  ) => {
    const cardToken = token !== null ? { cardToken: token } : {};

    return fnCreateOrderObject(
      gateway,
      cardToken,
      items,
      shippingAddress,
      invoiceAddress,
      coupon,
      external,
      paymentMethod,
    );
  };

  const fnValidateOrderApi = async (params: any, customToken = null) => {
    Logger.debug(`useOrder/${id}/validateOrder`, params);

    if (customToken !== null) {
      setJwtToken(customToken);
    }

    try {
      const resp = await makeRequest("sendOrderValidation", params);
      Logger.debug(`useOrder/${id}/validateOrder`, resp);
    } catch (err) {
      validationError.value = err?.response?.data || err;

      handleError({
        type: ErrorStoreErrorType.OrderValidationError,
        error: validationError.value,
      });
      Logger.error(`useOrder/${id}/validateOrder`, err);
    }
  };

  const resetValidationError = () => {
    validationError.value = null;
  };

  const fnClearValidation = () => {
    resetValidationError();
    cartValidationErrors.value = null;
    orderValidationErrors.value = null;
  };

  const fnFormatValidationErrors = () => {
    if (validationError.value !== null && validationError.value.length > 0) {
      if (
        validationError.value?.[0]?.code === 400 &&
        validationError.value?.[0]?.reason === "cartItemsValidation"
      ) {
        cartValidationErrors.value = validationError.value?.[0]?.errors;
      }

      if (Object.keys(validationError.value?.[0]?.errors).length > 0) {
        orderValidationErrors.value = validationError.value;
      }
    }
  };

  /**
   * @name fnValidateOrder
   * @description sends a validation request to the API in order to make sure we have a valid order
   * @param params The mounted params
   * */
  const fnValidateOrder = async (params) => {
    const validationParams = fnCreateOrderValidationParams(
      params.gateway,
      params.token,
      params.items,
      params.shipping,
      params.invoice,
      params.coupon,
      params.external,
      params.paymentMethod,
    );

    fnClearValidation();

    await fnValidateOrderApi(validationParams, customUserToken);

    fnFormatValidationErrors();
  };

  return {
    cartValidationErrors: computed(() => cartValidationErrors.value),
    orderValidationErrors: computed(() => orderValidationErrors.value),

    fnValidateOrder,
    fnCreateOrderValidationParams,
  };
};
