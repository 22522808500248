/* eslint-disable  */

export default {
  category: {
    header: {
      title: 'Finde Deinen {category}',
      subtitle:
        'Bei konfetti Neues entdecken. Online und Offline.',
      titleTextColor: '#ffffff',
      descriptionTextColor: '#ffffff',
      image: '/category/header.png',
      imageMobile:  '/category/header.png',
      button: 'Suchen',
      bgColor: '#ffbd46',
    },
    categories: 'Pick from our favorite categories',
    categoryEventsInCity: '{category} classes in {city}',

    heroes: {
      discover: {
        sectionTitle: 'Top Kurse und Workshops verschenken',
        title: 'Unsere {category} Auswahl als perfekte Geschenkidee',
        subtitle: 'Mit unserem Gutschein kannst Du über 4.500 Kurse und Workshops verschenken. Egal ob für Anfänger oder Fortgeschrittene:' +
          'Mit unserem Kursangebot findest Du sicher die perfekte Geschenkidee!.',
        buttonText: 'Gutschein kaufen',
        image: '/homepage/heroFirst.webp',
        mobileImage: '/homepage/heroFirst.webp',
      },
      teamEvent: {
        title: 'Buche Dein nächstes Teamevent für Dein Unternehmen',
        subtitle:
          'Mit konfetti findest Du für Dein nächstes Firmenevent über 4.500 spannende' +
          ' Teambuilding Ideen. Deine Mitarbeiter werden sich garantiert freuen!',
        image: '/partner/apply-in-few-steps.webp',
        mobileImage: '/partner/apply-in-few-steps.webp',
        buttonText: 'Teamevent anfragen',
      },
    },

    eventShowcaseSection: {
      title: '{category} in der Nähe'
    },

    showcase: {
      title: 'What our partners say about us'
    },
  }
};
