/* eslint-disable  */
export default {
  eventListPage: {
    city: {
      cityCategoryHorizontalListTitle:
        "Workshops und Kurse in {city} entdecken",
    },
    heroes: {
      giftcard: {
        title: "Auf der Suche nach einem Geschenk in {name}?",
        subtitle:
          "Verschenke jetzt unseren Gutschein. Einlösbar für mehr als 4.500 Workshops und Erlebnisse. Das perfekte Geschenk für (fast) jeden Anlass.",
        buttonText: "Geschenkgutschein kaufen",
        image: "/homepage/heroFirst.webp",
        mobileImage: "/homepage/heroFirst.webp",
      },
    },
    teamEventSlugPrefix: "teamevent",
  },
};
