



















































export default {
  name: "KftGiftcardBookingIcons",
  props: {
    firstLabel: {
      type: String,
      default: "",
    },
    secondLabel: {
      type: String,
      default: "",
    },
    thirdLabel: {
      type: String,
      default: "",
    },
  },
};
