/* eslint-disable  */

export default {
  faqSuppliers: {
    sections: [
      {
        title: 'Häufige Fragen',
        questions: [
          {
            question: 'Was macht konfetti?',
            answer: 
              'konfetti ist eine Plattform für Workshops und Kurse mit dem Ziel, Personen in ihrer eigenen Stadt und online für neue Erlebnisse zu begeistern und gleichzeitig Kursleiter dabei zu unterstützen, mehr Kunden zu erreichen.',
          }, 
          {
            question: 'Wie werde ich Partner?',
            answer:
              'Klicke auf unsere “Werde Partner” Seite, denn dort findest Du Informationen dazu, inwiefern eine Partnerschaft sich für Dich mit konfetti lohnt. Fülle dann unser Partnerformular aus. Wir evaluieren dann später, ob Du zu uns passt. Wenn alles zwischen uns passt, dann kannst Du ganz bequem und unkompliziert Deine Events auf unsere Seite stellen und managen. Wir freuen uns, dass Du Interesse an einer Zusammenarbeit hast!',
          },
          { 
            question: 'Was sind die weiteren Schritte, nachdem ich das Partnerformular ausfülle?',
            answer:
              'Sobald Du das Partnerformular ausgefüllt hast, erfolgen folgende Schritte: Wir prüfen Deine Bewerbung innerhalb von 24 Stunden. Wenn Du direkt loslegen möchtest, erstellen wir direkt Dein Konto und Deine Events. Danach erhältst Du Deine Zugangsdaten, um Deinen Account und Deine Events nach Wunsch anzupassen und zu verwalten. Innerhalb der ersten paar Tage melden wir uns außerdem telefonisch bei Dir, um uns einmal vorzustellen. Du kannst Dich jederzeit bei Fragen an uns wenden. Wir übernehmen auch gerne die Erstellung von neuen Kursen und fügen neue Termine hinzu, wenn Du keine Zeit dafür hast.',
          },
          {
            question: 'Welche Voraussetzungen muss ich erfüllen?',
            answer:
              'Wichtig ist, dass Du regelmäßig Kurse anbietest, damit unsere Kunden auch was vom Kurs haben. Sonst sind wir offen für jeden, der spannende Projekte anbietet.',
          },
          {
            question: 'Eignet sich mein Kurs für eine Partnerschaft?',
            answer:
              'Das werden wir dann bei Deiner Bewerbung als Partner herausfinden. Natürlich soll der Kurs unsere Firma konfetti widerspiegeln. Das heißt, Dein Kurs soll spannend, abwechslungsreich und kreativ sein. Wir wollen, dass Menschen ihrer Kreativität und ihrer Leidenschaft freien Lauf lassen. Genau das soll Dein Kurs bewirken.',
          },
          {
            question: 'Inwiefern bringt mir eine Partnerschaft Vorteile?',
            answer:
              'Die Arbeit mit uns ermöglicht es Dir, noch mehr Menschen mit Deinen Kursen zu erreichen. Wir betreiben für Dich aktives Marketing auf unserer Webseite als auch auf unseren sozialen Medien. Zudem hast Du durch eine Partnerschaft mit uns kaum administrativen Aufwand. Du kannst die Kurse ganz einfach auf unserer Seite veröffentlichen und managen. Und das Wichtigste: Wenn Du unser Partner bist, kannst Du Dich voll und ganz auf Deine Kursexpertise konzentrieren und Dich sorgenlos Deinen Kursteilnehmern widmen.',
          },
          { 
            question: 'Wie und wann werde ich bezahlt?',
            answer:
              'Alle Partner bekommen in den ersten Tagen des Folgemonats nach der Leistungserbringung eine Gutschrift zugeschickt mit anschließender direkter Auszahlung.',
          },
          { 
            question: 'Was passiert bei einer direkten Buchung über die Webseite?',
            answer:
              'Kommt eine Buchung über uns rein, bekommst Du sofort eine E-Mail, in der Du alle benötigten Informationen findest. Wenn ein Produktpaket verschickt werden muss, bekommst Du in der E-Mail ebenfalls die Versandadresse.',
          },
          {
            question: 'Was passiert bei einer Teamevent-Anfrage?',
            answer:
              'Wir setzen uns mit dem Kunden in Verbindung und erhalten alle nötigen Informationen. Wir schreiben die geeigneten Partner an und fragen, ob diese Kapazitäten haben. Wir erstellen in Absprache mit dem Partner das bestmögliche Angebot. Entscheidet sich der Kunde für das Angebot, sagen wir Dir Bescheid und schicken dem Kunden die Rechnung. Spätestens 14 Tage vor dem Event schicken wir Dir und dem Kunden eine E-Mail, damit Ihr in Vorbereitung für den Kurs Kontakt zueinander habt. Während dieses Prozesses bleiben wir immer mit Dir im Kontakt und halten Dich auf dem Laufenden.',
          },
          {
            question: 'Was sind Eure Konditionen?',
            answer: 
              'Keine Fixkosten, keine Vertragsbindung und keine Exklusivität. Wir möchten Dir mit unserer eigens entwickelten Plattform Arbeit abnehmen: wir erstellen Deinen Account, Events, Termine - und nehmen Dir auch die Pflege ab, wenn Du das vorziehst. Wir nennen Dich auf unserer Webseite beim Namen und werben auch auf unseren Social Media Plattformen mit unseren Partnern. Dich erwarten viele Ticketsales & Gruppeneventanfragen (Firmenevents, Geburtstage, JGAs, etc.). Und: Wir verdienen nur eine Provision am verkauften Ticket/Gruppenevent.',
          },
              
    
        ],
      },
    ],
  },
};
