















import { KftSidebar } from "@konfetti-ui/vue";
import useUiState from "~/composables/useUiState";

export default {
  name: "KftHeaderNavigation",
  components: {
    KftSidebar,
  },

  setup() {
    const { isNavigationSidebarOpen } = useUiState();

    return {
      isNavigationSidebarOpen,
    };
  },
};
