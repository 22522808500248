/* eslint-disable camelcase */
import { AgnosticCoupon, AgnosticTotals } from "@konfetti-core/core";
import {
  Cart,
  CartItem,
  Coupon,
  EventDateType,
  GTMItem,
  OrderItem,
  Price,
  ShippingCost,
} from "../types";

interface IGiftboxPrice {
  amount: number;
  currency: string;
  formatted: string;
}

export const fnGetItemValidationRemovableReasons = (): string[] => {
  return ["notAvailable", "notFound", "soldOut", "minAdvance", "maxAdvance"];
};

export const fnGetCartItemsLength = (cart: Cart): number => {
  return cart.items.length;
};

export const getGiftboxPrice = (cart: Cart): IGiftboxPrice => {
  let returnObj: IGiftboxPrice = {
    amount: 100,
    currency: "",
    formatted: "",
  };

  if (cart?.giftboxPrice) {
    returnObj = { ...cart?.giftboxPrice };
  }

  return {
    ...returnObj,
    amount: Number(returnObj.amount) / 100,
  };
};

export const getGiftboxPriceAmount = (cart: Cart): string =>
  cart?.giftboxPrice?.amount ?? 0;

export const getShippingCost = (cart: Cart): ShippingCost => {
  return cart?.shippingCost;
};

export const getShippingPrice = (cart: Cart): Price => {
  return getShippingCost(cart)?.value;
};

export const getShippingPriceAmount = (cart: Cart): number => {
  const shippingCost = getShippingCost(cart);
  return shippingCost ? parseFloat(shippingCost.value.amount) / 100 : 0;
};

export const getTotalItems = (cart: Cart): number => {
  return cart?.items?.length || 0;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getFormattedPrice = (price: number): string => {
  return "";
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCoupons = (cart: Cart): AgnosticCoupon[] => {
  return [];
};

export const getDiscount = (coupon: Coupon): any => {
  return coupon?.discount || 0;
};

/** @deprecated
 * This function does not change the formatting in relation to the user locale
 * Use fnGetDateLabelHtml
 * */
export const getFormattedDate = (item: CartItem): string => {
  return new Date(item.date).toLocaleDateString();
};

export const isGiftboxEnabled = (cart: Cart): boolean => {
  return cart?.isGiftboxEnabled || false;
};

export const getItems = (cart: Cart): CartItem[] => {
  return cart?.items || [];
};

export const getItemPermalink = (item: CartItem): string => {
  return item.permalink || "";
};

export const getItemId = (item: CartItem): string => {
  return item.id || "";
};

export const getEventDescriptionId = (item: CartItem): string => {
  return item.eventDescriptionId || "";
};

export const getItemIdForGtm = (item: CartItem): string => {
  // for GA we want the event description ID's
  if (item.type === "GIFT_CARD" && !getEventDescriptionId(item)) {
    const price = parseInt(item.price.amount) / 100;
    return "giftcard-" + price;
  }
  return getEventDescriptionId(item) || "";
};

export const isGiftcard = (item: CartItem): boolean => {
  return item.type === "GIFT_CARD";
};

export const hasGiftcard = (cart: Cart): boolean => {
  const length = cart.items.length;

  for (let i = 0; i < length; i++) {
    if (cart.items[i].type === "GIFT_CARD") {
      return true;
    }
  }

  return false;
};

export const getItemsForOrder = (cart: Cart): OrderItem[] => {
  const length = cart?.items?.length;
  const items = [];

  for (let i = 0; i < length; i++) {
    const item = cart.items[i];

    if (item.type === "EVENT") {
      items.push({
        type: "EVENT",
        product: item?.id,
        quantity: item?.quantity,
        private: item?.private,
      });
    }

    if (item.type === "GIFT_CARD") {
      const supplierId = item?.supplierId;
      items.push({
        type: "GIFT_CARD",
        event: item?.id,
        quantity: item?.quantity,
        value: item?.price?.amount,
        ...(supplierId && { supplier_id: item?.supplierId }),
        ship: isGiftboxEnabled(cart),
      });
    }
  }

  return items;
};

export const getItemsForGtm = (cart: Cart): GTMItem[] => {
  const length = cart.items.length;
  const items = [];

  for (let i = 0; i < length; i++) {
    const item = cart.items[i];
    const price = getUnitItemPrice(item);
    items.push({
      index: i,
      item_id: getItemIdForGtm(item),
      item_name: item.title,
      item_category: getItemMainCategory(item),
      item_variant: getItemVariantForGtm(item),
      quantity: item.quantity,
      price: price?.toFixed(2) || price,
    });
  }

  return items;
};

export const getItemMainCategory = (item: CartItem): string => {
  if (item.type === "GIFT_CARD") {
    return "giftcard";
  }
  return item.category || "";
};

export const getItemVariantForGtm = (item: CartItem): string => {
  if (item.type === "GIFT_CARD") {
    return item.type;
  }

  if (item.private === true) {
    return "PRIVATE_EVENT";
  }
  // default will be "EVENT"
  return item.type;
};

export const getCartHasItem = (cart: Cart, id: string): boolean => {
  return cart.items.some((item) => item.id === id);
};

export const getItemName = (item: CartItem): string => {
  return item.title || "";
};

export const getItemImage = (item: CartItem): string => {
  return item.thumbnail || "";
};

export const getItemPrice = (item: CartItem): number => {
  const qty =
    item.private && item.privateMinTickets > item.quantity
      ? item.privateMinTickets
      : item.quantity;

  return (parseFloat(item.price.amount) * qty) / 100;
};

export const getUnitItemPrice = (item: CartItem): number => {
  const privateMinTickets = item?.privateMinTickets;
  const price = parseFloat(item?.price?.amount);
  const qty = item?.quantity;

  if (item?.dateTypeToBeBooked === EventDateType.PRIVATE) {
    if (privateMinTickets > qty) {
      return (price * privateMinTickets) / qty / 100;
    }
  }

  return price / 100;
};

export const getItemPriceAmount = (item: CartItem): number => {
  return Number(item.price.amount);
};

export const getItemQty = (item: CartItem): number => {
  return item.quantity || 1;
};

export const getAvailableStock = (item: CartItem): number => {
  if (item.type === "GIFT_CARD") {
    return 999999;
  }

  if (item.private) {
    return item?.privateMaxTickets || item.availableTicketsQuantity || 1;
  }

  return item.availableTicketsQuantity || 1;
};

export const isPrivate = (item: CartItem): boolean => {
  return item.private || false;
};

export const getCartTotalPrice = (cart: Cart, isTotal: boolean): number => {
  if (!cart || !cart.items) {
    return 0;
  }

  let total = 0;
  for (let i = 0; i < cart.items.length; i++) {
    const item = cart.items[i];

    total += getItemPrice(item);

    if (isGiftcard(item) && cart.isGiftboxEnabled) {
      total += getGiftboxPrice(cart).amount;
    }
  }

  if (cart.isGiftboxEnabled && isTotal) {
    total += getShippingPriceAmount(cart);
  }

  return total;
};

export const getTotals = (cart: Cart): AgnosticTotals => {
  return {
    total: getCartTotalPrice(cart, true),
    subtotal: getCartTotalPrice(cart, false),
    special: null,
  };
};

export const cartGetters = {
  getTotals,
  getShippingPrice,
  getItems,
  getItemName,
  getItemImage,
  getItemPrice,
  getItemId,
  getItemQty,
  getFormattedPrice,
  getTotalItems,
  getCoupons,
  getDiscount,
  getAvailableStock,
  getCartHasItem,
  getItemPermalink,
  getCartTotalPrice,
  getItemsForOrder,
  getFormattedDate,
  isGiftboxEnabled,
  getGiftboxPrice,
  getGiftboxPriceAmount,
  isGiftcard,
  hasGiftcard,
  getItemsForGtm,
  getItemMainCategory,
  fnGetItemValidationRemovableReasons,
  fnGetCartItemsLength,
  isPrivate,
  getUnitItemPrice,
  getItemPriceAmount,
  getItemVariantForGtm,
  getItemIdForGtm,
  getEventDescriptionId,
};
