import { onServerPrefetch, ref, Ref } from "@nuxtjs/composition-api";

type KftRef = <T>(data?: T, key?: string) => Ref<T>;

interface SSRConfiguration {
  onSSR: (fn: () => void) => any;
  kftRef: KftRef;
}

let onSSR = onServerPrefetch;
let kftRef: KftRef = ref;

const configureSSR = (config: SSRConfiguration) => {
  onSSR = config.onSSR || onSSR;
  kftRef = config.kftRef || (kftRef as any);
};

export { onSSR, kftRef, configureSSR };
