/* eslint-disable func-names */

import axios from "axios";
import { middlewareConfig } from "@konfetti/config";
import consola from "consola";

const EVENT = "event";
const LOCALITY = "locality";
const CATEGORY = "category";
const MAGAZINE_LEGACY = "magazin";

function splitPath(path) {
  return path.split("/").filter((value) => {
    return value && value.length;
  });
}

function getPathType(pathArray) {
  if (pathArray.length < 3) {
    return null;
  }

  switch (pathArray[1]) {
    case "e":
      return EVENT;

    case "city":
      return LOCALITY;

    case "magazin":
      return MAGAZINE_LEGACY;

    default:
      return null;
  }
}

async function checkRouteExistence(path, language) {
  const apiUrl = middlewareConfig.integrations.konfetti.configuration.api.url;
  let exists = false;
  await axios
    .get(`${apiUrl}${path}`, {
      headers: {
        "Accept-Language": language,
      },
    })
    .then(function (response) {
      exists = true;
      consola.info(response);
    })
    .catch(function (error) {
      consola.error(error);
      exists = false;
    });

  consola.info(apiUrl + path, exists);

  return exists;
}

export default async function (req, res, next) {
  const path = splitPath(req.url);
  const type = getPathType(path);

  if (type === null) {
    return next();
  }

  if (type === LOCALITY) {
    // Redirecting every de-de/city/{city} to de-de/{city}
    res.writeHead(301, {
      location: `/${path[0]}/${path[2]}/`,
    });

    return res.end();
  }

  if (type === MAGAZINE_LEGACY) {
    path[1] = "magazine";
    // Redirecting every de-de/magazin/{article} to de-de/magazine/{article}
    res.writeHead(301, {
      location: `/${path.join("/")}/`,
    });

    return res.end();
  }

  return next();
}
