/* eslint-disable  */

export default {
  login: {
    passwordResetSentAlertLabel:
      "Das hat geklappt! Bitte überprüfe Deine E-Mails und folge den Anweisungen.",
    title: "Wir melden Dich an...",
    button: "Anmelden",
    form: {
      email: "Deine E-Mail Adresse",
      password: "Dein Passwort",
    },

    message: {
      success: "Super. Du wurdest erfolgreich angemeldet.",
      error: "Ups, es gab einen Fehler. Bitte versuche es noch einmal.",
    },

    forgotPassword: {
      title: "Passwort zurücksetzen",
      button: "Passwort vergessen?",
      form: {
        email: "E-Mail",
        submitButton: "Passwort zurücksetzen",
      },
      passwordResetSent:
        "Wir haben Dir eine E-Mail gesendet. Bitte überprüfe Dein Postfach.",
    },
  },
};
