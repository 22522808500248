<template functional>
  <div
    :class="[data.class, data.staticClass, 'kft-heading']"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <!--@slot Heading title. Slot content will replace default <h> tag-->
    <slot name="title" v-bind="{ props }">
      <component
        data-test-id="title"
        :is="props.useHTag? `h${props.level}` : `div`"
        :class="`h${props.level} ${props.hClass}`"
        :style="props.titleColor? `color: ${props.titleColor}!important ` : null"
        class="kft-heading__title"
      >
        {{ props.title }}
      </component>
    </slot>
    <!--@slot Heading description. Slot content will replace default <div> tag-->
    <slot name="description" v-bind="{ props }">
      <div
        v-if="$options.hasDescription(props.description, slots)"
        :class="props.dClass"
        class="kft-heading__description"
        v-html="props.description"
      />
    </slot>
  </div>
</template>
<script>
export default {
  name: "KftHeading",
  props: {

    hClass: {
      default: "",
      type: String
    },

    dClass: {
      default: "",
      type: String
    },
    /**
     * Heading level
     */
    level: {
      type: Number,
      default: 2
    },

    /**
     * Heading title
     */
    title: {
      type: String,
      default: ""
    },

    /**
     * Heading title color
     */
    titleColor: {
      type: String,
      default: ""
    },

    /**
     * Heading description
     */
    description: {
      type: String,
      default: ""
    },

    /**
     * Should the heading be rendered as a heading tag?
     */
    useHTag: {
      type: Boolean,
      default: true
    }
  },
  hasDescription(descriptionProp, slots) {
    return Boolean(descriptionProp) || slots().description;
  }
};
</script>
<style lang="scss">
@import "~@konfetti-ui/shared/styles/components/atoms/KftHeading.scss";
</style>
