var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kft-cart-product-list__container"},[_c('transition',{attrs:{"mode":"out-in","name":"sf-collapse-top"}},[_c('div',{staticClass:"notifications"},[(_vm.lastRemoved)?_c('KftAlert',{staticClass:"mb-5 kft-alert--info",attrs:{"title":_vm.$t('cart.removeItem.title')}},[_c('div',{staticClass:"text-center w-100"},[_c('KftLink',{staticClass:"text-bold",on:{"click:link":_vm.fnUndoRemoveItem}},[_c('div',[_vm._v(_vm._s(_vm.$t("cart.removeItem.message")))]),_vm._v(" "),_c('div',{staticClass:"text-decoration-underline"},[_vm._v("\n              "+_vm._s(_vm.$t("cart.removeItem.cancel"))+"\n            ")])])],1)]):_vm._e()],1)]),_vm._v(" "),_c('transition',{attrs:{"mode":"out-in","name":"sf-fade"}},[(_vm.totalItems)?_c('div',{key:"my-cart",staticClass:"my-cart"},[_c('div',{staticClass:"collected-product-list"},[_c('transition-group',{attrs:{"name":"sf-fade","tag":"div"}},[_vm._l((_vm.items),function(product,index){return [_c('KftCollectedProduct',{key:("cart-sidebar-collected-product-" + index),staticClass:"collected-product",attrs:{"available-qty":_vm.cartGetters.getAvailableStock(product),"gift":_vm.cartGetters.isGiftcard(product),"image":_vm.cartGetters.getItemImage(product),"is-private":_vm.cartGetters.isPrivate(product),"qty":_vm.cartGetters.getItemQty(product),"data-cy":"collected-product-cart-sidebar"},on:{"cart-action":function($event){return _vm.handleCartAction(product, $event)},"input":function($event){return _vm.updateItemQuantity(product, $event)},"click:remove":function($event){return _vm.handleRemove(product)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"kft-collected-product__title"},[_c('KftLink',{attrs:{"link":_vm.localePath(
                        '/e/' + _vm.cartGetters.getItemPermalink(product)
                      )}},[_c('KftHeading',{staticClass:"kft-heading--left heading-title mb-4",attrs:{"level":6,"title":_vm.cartGetters.getItemName(product)}})],1),_vm._v(" "),(!_vm.cartGetters.isGiftcard(product))?_c('div',{staticClass:"kft-collected-product__date-and-time",domProps:{"innerHTML":_vm._s(_vm.fnGetDateLabelHtml(product))}}):_vm._e()],1)]},proxy:true},{key:"configuration",fn:function(){return [_c('div')]},proxy:true},{key:"price",fn:function(){return [_c('KftPrice',{staticClass:"sf-product-card__price",scopedSlots:_vm._u([{key:"special",fn:function(){return [_c('ins',{staticClass:"kft-price__special"},[_vm._v(_vm._s(_vm.$n(_vm.cartGetters.getItemPrice(product), "currency"))+"\n                    ")])]},proxy:true}],null,true)})]},proxy:true}],null,true)}),_vm._v(" "),(
                _vm.fnGetChildrenHtmlArr(product).length > 0 &&
                !_vm.cartGetters.isGiftcard(product)
              )?_c('div',{key:("cart-sidebar-collected-multi-date-details-" + index),staticClass:"multi-date-details-card"},[_c('KftHeading',{attrs:{"level":5,"title":_vm.$t('cart.multiDateCard.title', {
                    count: _vm.fnGetChildrenHtmlArr(product).length + 1,
                  })}}),_vm._v(" "),_c('KftBulletPoints',{staticClass:"mt-4 kft-bullet-points--fw-medium font-family-heading",attrs:{"items-html-arr":_vm.fnGetChildrenHtmlArr({ children: { data: [product] } }).concat( _vm.fnGetChildrenHtmlArr(product) )}})],1):_vm._e()]})],2)],1)]):_c('div',{key:"empty-cart",staticClass:"empty-cart"},[_c('div',{staticClass:"empty-cart__banner"},[_c('KftHeading',{staticClass:"empty-cart__heading text-center mt-4",attrs:{"description":_vm.$t('cart.emptyCart.description'),"level":2,"title":_vm.$t('cart.emptyCart.title')}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }