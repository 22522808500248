<template>
  <div class="kft-modal">
    <div
      :aria-hidden="showModal"
      :class="{
        'modal fade': true,
        show: showModal,
      }"
      tabindex="-1"
      @click="hide"
    >
      <div v-if="!cardContainer" class="raw-modal">
        <div v-click-outside="hide">
          <slot></slot>
        </div>
      </div>

      <div
        v-if="cardContainer"
        class="modal-dialog"
        :class="{
          'modal--lg': size === 'lg', // helps purge discover this class
          'modal--xl': size === 'xl', // helps purge discover this class
          'modal--full': size === 'full', // helps purge discover this class
          'modal--auto': size === 'auto', // helps purge discover this class
        }"
        @click.stop=""
      >
        <div class="modal-content">
          <slot name="modal-header">
            <div v-if="!hideHeader" class="modal-header">
              <div class="modal-title">
                <slot name="title">
                  {{ title }}
                  <KftTooltip
                    v-if="tooltipText"
                    :key="tooltipText"
                    :styles="{ 'font-size': '16px' }"
                    :tooltip-text="tooltipText"
                    length="large"
                    parent="<i class='bi bi-info-circle-fill'></i>"
                    type="span"
                  >
                  </KftTooltip>
                </slot>
              </div>
              <button
                aria-label="Close"
                class="btn-close"
                data-bs-dismiss="modal"
                type="button"
                @click="hide"
              ></button>
            </div>
          </slot>
          <div v-dragscroll.y class="modal-body">
            <slot></slot>
          </div>

          <slot name="modal-footer"></slot>
        </div>
      </div>
    </div>

    <div
      v-if="showModal"
      :class="{
        'modal-backdrop fade': true,
        'background-transparent': size === 'full',
        show: showModal,
      }"
      @click="hide"
    ></div>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import { KftTooltip } from "@konfetti-ui/vue";

export default {
  name: "KftModal",
  components: { KftTooltip },
  props: {
    cardContainer: {
      type: Boolean,
      default: true,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "lg",
    },
    tooltipText: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const showModal = ref(false);

    const toggle = () => {
      showModal.value = !showModal.value;
    };

    const show = () => {
      showModal.value = true;
      document.body.classList.add("prevent-scroll");
      context.emit("shown");
    };

    const hide = () => {
      showModal.value = false;
      document.body.classList.remove("prevent-scroll");
      context.emit("hidden");
    };

    return {
      showModal,
      show,
      toggle,
      hide,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/_modal.scss";
.kft-modal {
  transition: $--konfetti-base-transition;

  .modal-content {
    max-height: calc(100svh - var(--navbar-height) - 2 * var(--spacer-8));
  }
}

.raw-modal {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.raw-modal,
.modal-dialog {
  margin-top: calc(var(--navbar-height) + var(--spacer-8));
}

.modal-backdrop {
  z-index: 99;
}

.modal-body {
  padding-top: 0;
  overflow: auto;
}
.modal--xl {
  margin-top: calc(var(--navbar-height) + var(--spacer-3));

  .modal-content {
    height: calc(100svh - var(--navbar-height) - var(--spacer-3) * 2);
    max-height: unset;
  }
}
@media (min-width: 992px) {
  .modal--lg,
  .modal--xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal--xl {
    max-width: 1140px;
  }
}

.modal--full {
  max-width: 100vw;

  &.modal-dialog {
    margin: 0;
    margin-top: calc(var(--navbar-height));
  }
  .modal-content {
    height: calc(100vh - var(--navbar-height) + 2rem);
    max-height: unset;
  }

  .modal-header,
  .modal-body {
    padding: var(--spacer-5);
  }

  .modal-body {
    height: 100%;
    max-height: unset;
  }

  .modal-content,
  .modal-header,
  .modal-body {
    border-radius: 0;
    padding: 0;
  }
}
</style>
<style>
.prevent-scroll {
  overflow: hidden;
}
</style>
