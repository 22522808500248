<template>
  <KftCheckbox
    :class="{ 'is-active': selected, 'is-color': color }"
    :name="label"
    :selected="selected"
    class="kft-filter"
    v-on="$listeners"
  >
    <template #label>
      <slot v-bind="{ label }" name="label">
        <div class="kft-filter__label">{{ label }}</div>
      </slot>
      <slot v-bind="{ count }" name="count">
        <div class="kft-filter__count">{{ count }}</div>
      </slot>
    </template>
  </KftCheckbox>
</template>
<script>
import KftCheckbox from "../../molecules/KftCheckbox/KftCheckbox.vue";

export default {
  name: "KftFilter",
  components: {
    KftCheckbox,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    count: {
      type: [String, Number],
      default: "",
    },
    selected: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss">
@import "~/assets/components/molecules/KftFilter.scss";
</style>
