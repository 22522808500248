<template>
  <div class="konfetti-hero row flex-row-reverse align-items-center g-5">
    <client-only>
      <div
        v-if="$device.isDesktopOrTablet"
        class="konfetti-hero__image-container col-12 col-lg-6"
      >
        <SfImage
          :src="$device.isDesktopOrTablet ? image : mobileImage"
          alt=""
          :height="$device.isDesktopOrTablet ? 356 : 271"
          width="100%"
          aspect-ratio-lg="5/3"
          aspect-ratio-md="3/1"
          class="d-block mx-lg-auto img-fluid konfetti-hero__image-right"
          fit="cover"
        />
      </div>
    </client-only>
    <LazyHydrate v-if="lazyHydrate" when-visible>
      <div
        :class="{ 'parent-overlay-active': overlay }"
        class="col-lg-6 col-12"
      >
        <div
          :class="{ 'overlay-active': overlay }"
          class="konfetti-hero__text-container"
        >
          <div class="lc-block mb-3 pt-1">
            <!-- Begin::Title -->
            <KftHeading
              :level="3"
              :title="title"
              class="kft-heading--left"
              :class="{ ' mt-2': $device.isDesktopOrTablet }"
              h-class="mb-2"
            />
          </div>

          <div class="lc-block mb-8">
            <p class="lead">{{ subtitle }}</p>
          </div>

          <div class="lc-block d-grid gap-2 d-md-flex justify-content-md-start">
            <component
              :is="link !== '#' ? 'KftLink' : 'div'"
              :link="link"
              class="col-12"
            >
              <button
                class="btn btn-primary btn-shadow w-100"
                @click="$emit('click:button')"
              >
                {{ buttonText }}
              </button>
            </component>
          </div>
        </div>
      </div>
    </LazyHydrate>
  </div>
</template>

<script>
import { SfImage, KftHeading, KftLink } from "@konfetti-ui/vue";
import LazyHydrate from "vue-lazy-hydration";

export default {
  name: "KftHeroImageRight",
  components: {
    KftHeading,
    KftLink,
    SfImage,
    LazyHydrate,
  },
  props: {
    link: {
      type: String,
      default: "#",
    },
    image: {
      type: String,
      default:
        "https://images.unsplash.com/photo-1530435460869-d13625c69bbf?crop=entropy&amp;cs=tinysrgb&amp;fit=crop&amp;fm=jpg&amp;ixid=MnwzNzg0fDB8MXxzZWFyY2h8MTB8fHdlYnNpdGV8ZW58MHwwfHx8MTYyMTQ0NjkyNg&amp;ixlib=rb-1.2.1&amp;q=80&amp;w=1080&amp;h=768",
    },
    mobileImage: {
      type: String,
      default:
        "https://images.unsplash.com/photo-1530435460869-d13625c69bbf?crop=entropy&amp;cs=tinysrgb&amp;fit=crop&amp;fm=jpg&amp;ixid=MnwzNzg0fDB8MXxzZWFyY2h8MTB8fHdlYnNpdGV8ZW58MHwwfHx8MTYyMTQ0NjkyNg&amp;ixlib=rb-1.2.1&amp;q=80&amp;w=1080&amp;h=768",
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: false,
    },
    lazyHydrate: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.konfetti-hero {
  &__image-container {
    padding: 0;
  }

  &__text-container {
    @include for-tablet-and-down {
      padding: 0;
      background-color: transparent;
    }

    background-color: $white;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    border-radius: $border-radius-md;

    &.overlay-active {
      @include for-desktop {
        width: calc(100% + 3rem);
        margin-right: -3rem;
      }
    }
  }

  &__image-right {
    @include for-tablet-and-down {
      max-height: 267px;
    }

    border-radius: $border-radius-md !important;
  }

  .parent-overlay-active {
    position: relative;
  }
}
</style>
