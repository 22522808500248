























































































































































































































































































































































import { useProduct, eventGetters } from "@konfetti/composables";
import { ref, computed, nextTick } from "@nuxtjs/composition-api";
import VsToast from "@vuesimple/vs-toast";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/de";
import "vue2-datepicker/locale/en";
import dayjs from "dayjs";
import { fnGetISO2Locale } from "~/helpers/localeHelpers";
import {
  isTimeSet,
  convertDateTimeFormat,
  convertISO8601ToCustomFormat,
} from "~/helpers/dateHelpers";
import { useFormValidation } from "@/composables/useFormValidation";
import { KftCheckbox } from "~/components";
import {
  KftNotificationType,
  useNotifications,
  useUiState,
  useEvent,
} from "~/composables";
import { useGtm } from "~/composables/useGtm";

export default {
  name: "DateRequestForm",

  components: {
    DatePicker,
    KftCheckbox,
  },

  setup(props, context) {
    const { $i18n, $route, $device } = context.root;
    const ISO8601Timestamps = ref([]);
    const privateDateTypeChecked = ref(false);

    const formDates = ref([]);
    const formDateTimes = ref([]);

    const form = ref({
      email: "",
      phone: "",
      name: "",
      surname: "",
      numberOfTickets: "",
      dateType: "PUBLIC",
      dates: [],
    });

    const isDatePickerOpen = ref([false, false, false]);
    const isTimePickerOpen = ref([false, false, false]);

    const toggleDatePicker = async (index: number) => {
      await nextTick();
      const newDatePickerOpen = [false, false, false];
      newDatePickerOpen[index] = !isDatePickerOpen.value[index];
      isDatePickerOpen.value = [...newDatePickerOpen];
    };

    const toggleTimePicker = async (index: number) => {
      await nextTick();
      const newDatePickerOpen = [false, false, false];
      const newTimePickerOpen = [false, false, false];
      newTimePickerOpen[index] = !isDatePickerOpen.value[index];
      isDatePickerOpen.value = newDatePickerOpen;
      isTimePickerOpen.value = newTimePickerOpen;
    };

    const timeRange = [8, 21];
    const hours = Array.from({
      length: timeRange[1] - timeRange[0],
    }).map((_, i) => i + timeRange[0]);

    const rules = ref({
      email: "required|email",
      name: "required",
      surname: "required",
      numberOfTickets: "required|numeric",
      dates:
        "required|array|regex:^\\d{2}\\.\\d{2}\\.\\d{4}(?:\ \\d{2}:\\d{2})?\$",
    });

    const { addNotification } = useNotifications();

    const { toggleDateRequestSidebar } = useUiState();
    const { errors, clearErrors, validate, invalidKeys } = useFormValidation(
      form,
      rules,
    );
    const datesRequested = ref(false);
    const { requestDates, error: requestErrors } = useProduct(
      `product-page-${$route.params.event?.toString()}`,
    );

    const { event } = useEvent("event-page");

    const computedEventData = computed(() => event.value?.data);

    const { sendDateRequestForm } = useGtm();

    const handleDateOnlyPick = async (id) => {
      if (ISO8601Timestamps.value[id]?.match(/\d{2}:\d{2}/)) {
        return;
      }

      isTimePickerOpen.value[id] = true;
      await nextTick();
    };

    const handleTimeOnlyPick = async (id) => {
      isTimePickerOpen.value[id] = false;
      await nextTick();
    };

    const handleDatePickerChange = async (index: number) => {
      const changedDate = ISO8601Timestamps.value[index];

      if (!isTimeSet(changedDate)) {
        toggleTimePicker(index);
      }

      clearErrors("dates");
    };

    const handleTimePickerChange = (index: number) => {
      isTimePickerOpen.value[index] = false;
      clearErrors("dates");
    };

    // uses the minAdvance setting to determine the first bookable
    // date in ISO 8601 format
    const minDate = dayjs()
      .add(
        eventGetters.getDateRequestMinAdvanceQuantity(event.value?.data),
        eventGetters
          .getDateRequestMinAdvanceUnit(event.value?.data)
          .toLowerCase(),
      )
      .format("YYYY-MM-DD");

    const disableUnavailableDays = (date) => {
      const availableDays = eventGetters.getDateRequestAvailableDays(
        event.value?.data,
      );

      const minAdvanceQuantity = eventGetters.getDateRequestMinAdvanceQuantity(
        event.value?.data,
      );
      const minAdvanceUnit = eventGetters.getDateRequestMinAdvanceUnit(
        event.value?.data,
      );

      const dateToCheck = new Date();
      if (minAdvanceUnit === "day" && minAdvanceQuantity > 0) {
        dateToCheck.setDate(dateToCheck.getDate() + minAdvanceQuantity);
      } else if (minAdvanceUnit === "week" && minAdvanceQuantity > 0) {
        dateToCheck.setDate(dateToCheck.getDate() + minAdvanceQuantity * 7);
      } else if (minAdvanceUnit === "month" && minAdvanceQuantity > 0) {
        dateToCheck.setMonth(dateToCheck.getMonth() + minAdvanceQuantity);
      } else if (minAdvanceUnit === "year" && minAdvanceQuantity > 0) {
        dateToCheck.setFullYear(dateToCheck.getFullYear() + minAdvanceQuantity);
      }

      return !availableDays.includes(date.getDay()) || date < dateToCheck;
    };

    const firstBookableDate = computed(() => {
      const minAdvanceQuantity = eventGetters.getDateRequestMinAdvanceQuantity(
        event.value?.data,
      );

      const minAdvanceUnit = eventGetters.getDateRequestMinAdvanceUnit(
        event.value?.data,
      );

      let date = dayjs();

      if (minAdvanceUnit === "DAY" && minAdvanceQuantity > 0) {
        date = date.add(minAdvanceQuantity, "day");
      } else if (minAdvanceUnit === "WEEK" && minAdvanceQuantity > 0) {
        date = date.add(minAdvanceQuantity, "week");
      } else if (minAdvanceUnit === "MONTH" && minAdvanceQuantity > 0) {
        date = date.add(minAdvanceQuantity, "month");
      } else if (minAdvanceUnit === "YEAR" && minAdvanceQuantity > 0) {
        date = date.add(minAdvanceQuantity, "year");
      }

      return date.toDate();
    });

    const requestEventDates = async () => {
      context.root.$wait.start("validating-date-request-form");

      await validate();

      if (errors.value !== null && Object.keys(errors.value)?.length > 0) {
        VsToast.show({
          title: $i18n.t("general.error"),
          position: "top-center",
          variant: "error",
          message: errors.value?.[0]?.message || $i18n.t("general.error"),
        });
        nextTick(() => context.root.$wait.end("validating-date-request-form"));

        return;
      }

      nextTick(() => context.root.$wait.end("validating-date-request-form"));

      context.root.$wait.start("requesting-dates-for-event");
      await requestDates({
        event_description_id: eventGetters.getId(event?.value?.data),
        email: form.value.email,
        first_name: form.value.name,
        phone: form.value.phone,
        last_name: form.value.surname,
        number_of_tickets: parseInt(form.value.numberOfTickets, 10),
        date_type: privateDateTypeChecked?.value ? "PRIVATE" : "PUBLIC",
        dates: form.value.dates
          .filter((date) => date !== "")
          .map((date) => convertDateTimeFormat(date)),
      });

      if (requestErrors.value !== null) {
        VsToast.show({
          title: $i18n.t("general.error"),
          position: "top-center",
          variant: "error",
          message: $i18n.t("general.error"),
        });
        nextTick(() => context.root.$wait.end("requesting-dates-for-event"));

        return;
      }
      sendDateRequestForm();

      nextTick(() => {
        context.root.$wait.end("requesting-dates-for-event");
        context.emit("on-complete-request");
      });

      datesRequested.value = true;
    };
    return {
      form,
      hours,
      errors,
      invalidKeys,
      ISO8601Timestamps,
      isDatePickerOpen,
      privateDateTypeChecked,
      formDates,
      formDateTimes,
      isTimePickerOpen,
      minDate,
      eventGetters,
      computedEventData,
      firstBookableDate,
      handleTimeOnlyPick,
      toggleTimePicker,
      requestEventDates,
      handleDatePickerChange,
      handleTimePickerChange,
      toggleDatePicker,
      clearErrors,
      fnGetISO2Locale,
      handleDateOnlyPick,
      disableUnavailableDays,
    };
  },
  data() {
    return {};
  },
  computed: {
    computedDateInputs() {
      return [
        this.computedDateInput1Value,
        this.computedDateInput2Value,
        this.computedDateInput3Value,
      ];
    },
    computedTimeInputs() {
      return [
        this.computedTimeInput1Value,
        this.computedTimeInput2Value,
        this.computedTimeInput3Value,
      ];
    },
    computedDateInput1Value: {
      get() {
        return this.dateInputGetter(0);
      },
      set(val) {
        this.dateInputSetter(0, val);
      },
    },
    computedTimeInput1Value: {
      get() {
        return this.timeInputGetter(0);
      },
      set(val) {
        this.timeInputSetter(0, val);
      },
    },
    computedDateInput2Value: {
      get() {
        return this.dateInputGetter(1);
      },
      set(val) {
        this.dateInputSetter(1, val);
      },
    },
    computedTimeInput2Value: {
      get() {
        return this.timeInputGetter(1);
      },
      set(val) {
        this.timeInputSetter(1, val);
      },
    },
    computedDateInput3Value: {
      get() {
        return this.dateInputGetter(2);
      },
      set(val) {
        this.dateInputSetter(2, val);
      },
    },
    computedTimeInput3Value: {
      get() {
        return this.timeInputGetter(2);
      },
      set(val) {
        this.timeInputSetter(2, val);
      },
    },
  },
  methods: {
    dateInputGetter(index) {
      if (this.$device.isMobile) {
        return this.ISO8601Timestamps[index] || null;
      } else {
        return this.form.dates[index]?.split(" ")[0] || null;
      }
    },
    timeInputGetter(index) {
      if (this.$device.isMobile) {
        return this.ISO8601Timestamps[index]?.split(" ")[1] || null;
      } else {
        return this.form.dates[index]?.split(" ")[1] || null;
      }
    },
    async dateInputSetter(index, val) {
      this.clearErrors("dates");
      if (!val || val === "") {
        return;
      }

      if (this.$device.isMobile) {
        const newISO8601Timestamps = [...this.ISO8601Timestamps];
        newISO8601Timestamps[index] = val;

        // Assign specifically in this way to trigger the reactivity
        this.ISO8601Timestamps = newISO8601Timestamps;

        const newDates = [...this.form.dates];

        // Parse the ISO 8601 timestamp and format the date as "YYYY.MM.DD H:mm"
        newDates[index] = convertISO8601ToCustomFormat(
          this.ISO8601Timestamps[index],
        );

        // Assign specifically in this way to trigger the reactivity
        this.form = {
          ...this.form,
          dates: newDates,
        };
      } else {
        this.form.dates[index] = val.replace("00:00", "");
      }
      await this.$nextTick();
    },
    async timeInputSetter(index, val) {
      if (!val || val === "") {
        return;
      }

      if (this.$device.isMobile) {
        const date = this.ISO8601Timestamps[index]?.split(" ")?.[0];
        this.ISO8601Timestamps[index] = `${date} ${val}`;
        this.form.dates[index] = convertISO8601ToCustomFormat(
          this.ISO8601Timestamps[index],
        );

        await this.$nextTick();
      } else {
        this.form.dates[index] = val;
      }
    },
  },
};
