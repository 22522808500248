<template>
  <div
    :class="{ expanded: expanded, 'no-overflow': inMaxRange }"
    class="text-overflow"
  >
    <div ref="to" class="text-overflow-content">
      <slot></slot>
    </div>

    <div v-if="!noShadow" ref="ht" class="hide-text"></div>

    <KftButton
      v-if="!noButton && !inMaxRange"
      :class="{ 'show-less': !noLess }"
      class="kft-link kft-link--black text-bold text-decoration-underline p-0"
      @click="toggle"
    >
      {{
        expanded
          ? $t("general.kftReadMore.readLessLabel")
          : $t("general.kftReadMore.readMoreLabel")
      }}
    </KftButton>
  </div>
</template>

<script>
import { KftButton } from "@konfetti-ui/vue";

export default {
  name: "KftReadMore",
  components: { KftButton },
  props: {
    lines: {
      type: Number,
      default: 3,
    },
    gLines: {
      type: Number,
      default: null,
    },
    maxLines: {
      type: Number,
      default: null,
    },
    noLess: {
      type: Boolean,
      default: false,
    },
    openByDefault: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: null,
    },
    noButton: {
      type: Boolean,
      default: false,
    },
    noShadow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
      inMaxRange: false,
      localMaxLines: 1,
    };
  },
  watch: {
    open(v) {
      if (v !== this.expanded) {
        this.toggle(!v);
      }
    },
  },
  created() {
    if (this.lines && !this.maxLines) {
      this.localMaxLines = this.lines + 1;
    } else {
      this.localMaxLines = this.maxLines - 1;
    }
    if (this.open === true) {
      this.expanded = true;
    }
  },
  mounted() {
    const lh = this.getLineHeight(this.$refs.to);
    if (this.lines) {
      this.$refs.to.style.setProperty("--nlines", this.lines);
    }
    let gLines = 2;
    if (this.gLines) {
      gLines = this.gLines;
    } else if (this.lines > 12) {
      gLines = 4;
    } else if (this.lines > 6) {
      gLines = 3;
    }
    if (this.$refs.ht) {
      this.$refs.ht.style.setProperty("--nlines", gLines);
    }
    if (this.open === true) {
      this.$refs.to.style.setProperty("max-height", "100%");
    }
    setTimeout(() => {
      if (this.$refs.to.scrollHeight <= this.localMaxLines * lh) {
        this.inMaxRange = true;
      }

      this.$refs.to.style.setProperty("--lineHeight", lh + "px");
      if (this.$refs.ht) {
        this.$refs.ht.style.setProperty("--lineHeight", lh + "px");
      }

      if (this.open === true) {
        this.$refs.to.style.setProperty(
          "max-height",
          this.$refs.to.scrollHeight + "px",
        );
      }
    });
  },
  methods: {
    toggle(val) {
      const valExp = typeof val === "boolean" ? val : this.expanded;
      if (valExp) {
        if (!this.noLess) {
          this.$refs.to.style.removeProperty("max-height");
          this.expanded = false;
          this.$emit("update:open", false);
        }
      } else {
        this.expanded = true;
        this.$emit("update:open", true);
        this.$refs.to.style.setProperty(
          "max-height",
          this.$refs.to.scrollHeight + "px",
        );
      }
    },
    getLineHeight(element) {
      let temp = document.createElement(element.children[0].nodeName);
      const cpStyle = getComputedStyle(element.children[0]);
      temp.setAttribute(
        "style",
        "position:absolute;left:-999em;margin:0px;padding:0px;font-family:" +
          cpStyle.fontFamily +
          ";font-size:" +
          cpStyle.fontSize,
      );
      temp.innerHTML = "test";
      temp = document.body.appendChild(temp);
      const ret = temp.clientHeight;
      temp.parentNode.removeChild(temp);
      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-overflow-content {
  --nlines: 3;
  --lineHeight: 1.5;
  max-height: calc(var(--nlines) * var(--lineHeight));
  overflow: hidden;
  transition: max-height 0.3s ease;
  p {
    margin: 0;
  }
}

.text-overflow {
  position: relative;
}

.no-overflow {
  .text-overflow-content {
    max-height: 100%;
    overflow: visible;
  }

  .hide-text,
  .button-read-more {
    display: none;
  }
}

.read-more-button {
  cursor: pointer;
  display: block;
  position: relative;
  border-top: 1px solid #dbdbdb;
  height: 0.1em;
  margin: 2em auto;
  width: 95%;
  text-align: center;

  span {
    background: #fbfbfb;
    color: #b5b5b5;
    display: inline-block;
    font-size: 0.75em;
    padding: 0.4em 0.8em;
    transform: translateY(-1.1em);
    text-align: center;
  }
}

.hide-text {
  --nlines: 6;
  --lineHeight: 1.5;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0) 0,
    #fbfbfb 90%,
    #fbfbfb
  );
  width: 100%;
  height: calc(var(--nlines) * var(--lineHeight));
  position: absolute;
  //noinspection CssInvalidFunction
  transform: translateY(calc(var(--nlines) * -1 * var(--lineHeight)));
}

.hide-text,
.read-more-button {
  transition: opacity 0.3s ease, margin 0.3s ease;
  opacity: 1;
}

.expanded {
  .hide-text,
  .read-more-button:not(.show-less) {
    opacity: 0;
    margin-top: 0;
    margin-bottom: 0;
    pointer-events: none;
  }
}
</style>
