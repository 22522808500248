import { render, staticRenderFns } from "./KftProductCard.vue?vue&type=template&id=4f5f7da2&scoped=true&"
import script from "./KftProductCard.vue?vue&type=script&lang=js&"
export * from "./KftProductCard.vue?vue&type=script&lang=js&"
import style0 from "./KftProductCard.vue?vue&type=style&index=0&id=4f5f7da2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f5f7da2",
  null
  
)

export default component.exports