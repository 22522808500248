<template>
  <div class="kft-generic-gallery">
    <div class="kft-generic-gallery__main">
      <slot v-if="$slots.main" name="main"></slot>

      <div v-else class="kft-generic-gallery__desktop-main-image">
        <slot name="desktop-main-image">
          <SfImage
            v-if="!isLoading"
            @click="$emit('click:image')"
            :height="'auto'"
            :preload="true"
            :src="principalImage.src"
            :alt="principalImage.alt"
            aspect-ratio="auto"
            loading="eager"
            sizes="xs:100vw sm:100vw md:100vw lg:910px xl:910px xxl:910px"
          />
          <slot
            v-else
            class="kft-generic-gallery__thumbnail-container__item"
            name="loader"
          >
          </slot>
        </slot>
      </div>
    </div>

    <!-- TABLET AND DESKTOP THUMBNAILS -->
    <div class="kft-generic-gallery__thumbnail-container" v-dragscroll.x>
      <template v-if="isLoading">
        <slot
          class="kft-generic-gallery__thumbnail-container__item"
          name="loader"
        >
        </slot>
        <slot
          class="kft-generic-gallery__thumbnail-container__item"
          name="loader"
        >
        </slot>
        <slot
          class="kft-generic-gallery__thumbnail-container__item"
          name="loader"
        >
        </slot>
        <slot
          class="kft-generic-gallery__thumbnail-container__item"
          name="loader"
        >
        </slot>
      </template>

      <template v-else>
        <div
          v-for="(image, index) in featuredImages.slice(0, 2)"
          :key="index"
          class="kft-generic-gallery__thumbnail-container__item"
          :class="{
            'kft-generic-gallery__thumbnail-container__item--vertical':
              index === verticalIndex,
          }"
        >
          <component :is="image.url ? 'a' : 'div'" :href="image.url">
            <SfImage
              @click="$emit('click:image')"
              :height="'auto'"
              :preload="true"
              :src="image.src"
              :alt="image.alt"
              aspect-ratio="auto"
              loading="lazy"
              sizes="xs:100vw sm:100vw md:100vw lg:910px xl:910px xxl:910px"
            />
            <slot name="featured-image-extra" v-bind="image"></slot>
          </component>
        </div>
        <slot name="vertical-slot">
          <div
            v-for="(image, index) in featuredImages.slice(2, 4)"
            :key="index + 2"
            class="kft-generic-gallery__thumbnail-container__item"
            :class="{
              'kft-generic-gallery__thumbnail-container__item--vertical':
                index === verticalIndex - 2,
            }"
          >
            <component :is="image.url ? 'a' : 'div'" :href="image.url">
              <SfImage
                @click="$emit('click:image')"
                :height="'auto'"
                :preload="true"
                :src="image.src"
                :alt="image.alt"
                aspect-ratio="auto"
                loading="lazy"
                sizes="xs:100vw sm:100vw md:100vw lg:910px xl:910px xxl:910px"
              />
              <slot name="featured-image-extra" v-bind="image"></slot>
            </component>
          </div>
        </slot>
      </template>

      <div
        class="kft-generic-gallery__thumbnail-container__item--blank"
        v-show="featuredImages.length <= 3 && !isMobile"
      ></div>
      <div
        class="kft-generic-gallery__thumbnail-container__item--blank"
        v-show="featuredImages.length <= 2"
      ></div>
      <div
        class="kft-generic-gallery__thumbnail-container__item--blank"
        v-show="featuredImages.length <= 1"
      ></div>
      <div
        class="kft-generic-gallery__thumbnail-container__item--blank"
        v-show="featuredImages.length === 0"
      ></div>
    </div>
  </div>
</template>

<script>
import { SfImage } from "../../../../";
import { KftButton } from "../../../../";
import { GalleryVideoPlayer } from "@konfetti-ui/vue";
import { ContentLoader } from "vue-content-loader";

export default {
  components: {
    KftButton,
    ContentLoader,
    SfImage,
    GalleryVideoPlayer,
  },
  props: {
    media: {
      type: Array,
      default: () => [],
    },
    featuredImages: {
      type: Array,
      default: () => [],
    },
    principalImage: {
      type: Object,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    cursorPointer: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    verticalIndex: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@konfetti-ui/shared/styles/components/organisms/KftGenericGallery.scss";
</style>
