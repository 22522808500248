





























































































































































































import { computed, onMounted, ref } from "@vue/composition-api";
import { KftHeading } from "@konfetti-ui/vue";
import axios from "axios";
import KftMultiselect from "../../organisms/KftMultiselect/KftMultiselect.vue";
import KftCheckbox from "../../molecules/KftCheckbox/KftCheckbox.vue";
import { useFormValidation } from "~/composables/useFormValidation";

export default {
  name: "KftInvoiceAddressForm",

  components: {
    KftHeading,
    Multiselect: KftMultiselect,
    KftCheckbox,
  },

  props: {
    isInvoiceAddressSameAsShippingAddress: {
      type: Boolean,
      default: false,
    },
    userHasCompanyName: {
      type: Boolean,
      default: false,
    },
    needsShipping: {
      type: Boolean,
      default: false,
    },
    invoiceDetails: {
      first_name: "",
      // eslint-disable-next-line camelcase
      last_name: "",
      // eslint-disable-next-line camelcase
      company_name: "",
      street: "",
      // eslint-disable-next-line camelcase
      street_number: "",
      zipcode: "",
      locality: "",
      country: "",
      // eslint-disable-next-line camelcase
      vat: "",
    },
    controlVariables: {},
  },

  setup(props, { emit, root: { $i18n } }) {
    const form = ref({
      first_name: "",
      // eslint-disable-next-line camelcase
      last_name: "",
      // eslint-disable-next-line camelcase
      company_name: "",
      street: "",
      // eslint-disable-next-line camelcase
      street_number: "",
      zipcode: "",
      country: "DE",
      locality: "",
      // eslint-disable-next-line camelcase
      vat: "",
    });
    const rules = ref({
      first_name: props.isInvoiceAddressSameAsShippingAddress ? "" : "required",
      last_name: props.isInvoiceAddressSameAsShippingAddress ? "" : "required",
      // eslint-disable-next-line camelcase
      company_name: "",
      street: props.isInvoiceAddressSameAsShippingAddress ? "" : "required",
      locality: props.isInvoiceAddressSameAsShippingAddress ? "" : "required",
      zipcode: props.isInvoiceAddressSameAsShippingAddress ? "" : "required",
      country: "required",
      // eslint-disable-next-line camelcase
      street_number: props.isInvoiceAddressSameAsShippingAddress
        ? ""
        : "required",
    });

    const controlVariables = ref(props.controlVariables);
    const { errors, hasError, validate, invalidKeys } = useFormValidation(
      form,
      rules,
      controlVariables,
    );

    const countriesJson = ref<{ name: Record<string, string>; code: string }[]>(
      [],
    );

    const computedCountriesArr = computed(() =>
      countriesJson.value.map((x) => x.code),
    );

    const computedCountryNamesMap = computed(() => {
      const map = {};
      console.log($i18n.locale);
      const localeArr = $i18n.locale.split("-");
      const apiLocale = `${localeArr[0]}_${localeArr[1].toUpperCase()}`;

      countriesJson.value.forEach((x) => {
        map[x.code] = x.name[apiLocale] || x.name.en_US;
      });

      return map;
    });

    /**
     * @name fnValidateForm
     * @description used when an address is inputted, if the form is not valid, it blocks the order
     * @param key the key to be validated, null for all
     * @param showErrors If the view should display the validation errors
     * */
    const fnValidateForm = async (key, showErrors = true) => {
      await validate(key);

      if (showErrors && hasError()) {
        emit("invalid");
      }

      emit("valid", form.value);
    };

    const fetchCountriesJson = () => {
      return axios
        .get("/eu-countries.json")
        .then(({ data }) => {
          countriesJson.value = data;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    onMounted(async () => {
      form.value = props.invoiceDetails;
      await fetchCountriesJson();
    });

    return {
      form,
      errors,
      hasError,
      validate,
      invalidKeys,
      computedCountriesArr,
      computedCountryNamesMap,
      countriesJson,

      fnValidateForm,
    };
  },
};
