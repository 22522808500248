import { sharedRef } from "@konfetti-core/core";
import { computed } from "@nuxtjs/composition-api";

export const useEvent = (id: string): any => {
  const event = sharedRef(null, `useEvent-page-${id}`);

  const setEvent = (newEvent) => {
    event.value = newEvent;
  };

  const computedEventData = computed(() => event.value?.data);

  return { event, setEvent, computedEventData };
};
