import Url from "url-parse";
import axios from "axios";
import { middlewareConfig } from "@konfetti/config";
import consola from "consola";

export default async function (req, res, next) {
  const apiUrl = middlewareConfig.integrations.konfetti.configuration.api.url;
  const appBaseUrl =
    middlewareConfig.integrations.konfetti.configuration.appBaseUrl;

  const oldPermalink = `${appBaseUrl}e/${req.url.replace(/\//g, "")}/`;
  try {
    const oldPermalinksMap = await axios.get(
      `${apiUrl}v1/store/generate-old-links-relation/`,
    );

    if (oldPermalinksMap.data[oldPermalink] !== undefined) {
      consola.log("Redirecting route...", oldPermalink);

      let url = oldPermalinksMap.data[oldPermalink];
      url += url.endsWith("/") ? "" : "/";

      res.writeHead(301, {
        location: url,
      });

      res.end();
    } else {
      next();
    }
  } catch (e) {
    consola.error(e);
    next();
  }
}
