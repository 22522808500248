<template>
  <div class="konfetti-hero align-items-center">
    <!--begin::image container-->
    <div
      v-if="$device.isDesktopOrTablet"
      class="konfetti-hero__image-container"
    >
      <SfImage
        :height="$device.isDesktopOrTablet ? 402 : 181"
        :src="$device.isDesktopOrTablet ? image : mobileImage"
        alt=""
        aspect-ratio-lg="3/1"
        aspect-ratio-md="5/2"
        aspect-ratio-sm="2/1"
        class="d-block mx-lg-auto img-fluid"
        fit="cover"
        width="100%"
      />
    </div>
    <!--end::image container-->
    <LazyHydrate v-if="lazyHydrate" when-visible>
      <div class="konfetti-hero__content">
        <div :class="['konfetti-hero__text-container overlay-active']">
          <!-- Begin::Title -->
          <div class="lc-block mb-3 pt-1">
            <KftHeading
              :class="{ ' mt-2': $device.isDesktopOrTablet }"
              :level="3"
              :title="title"
              class="kft-heading--left"
              h-class="mb-4"
            />
          </div>
          <div class="lc-block mb-8">
            <p class="lead">{{ subtitle }}</p>
          </div>
          <!--End::title-->

          <div class="lc-block d-grid gap-2 d-md-flex justify-content-md-start">
            <KftLink :link="link" class="col-12">
              <button class="btn btn-primary btn-shadow w-100">
                {{ buttonText }}
              </button>
            </KftLink>
          </div>
        </div>
      </div>
    </LazyHydrate>
  </div>
</template>

<script>
import { KftHeading, KftLink, SfImage } from "@konfetti-ui/vue";
import LazyHydrate from "vue-lazy-hydration";

export default {
  name: "KftHeroImageFull",
  components: {
    KftHeading,
    KftLink,
    SfImage,
    LazyHydrate,
  },
  props: {
    link: {
      type: String,
      default: "#",
    },
    image: {
      type: String,
      default:
        "https://images.unsplash.com/photo-1530435460869-d13625c69bbf?crop=entropy&amp;cs=tinysrgb&amp;fit=crop&amp;fm=jpg&amp;ixid=MnwzNzg0fDB8MXxzZWFyY2h8MTB8fHdlYnNpdGV8ZW58MHwwfHx8MTYyMTQ0NjkyNg&amp;ixlib=rb-1.2.1&amp;q=80&amp;w=1080&amp;h=768",
    },
    mobileImage: {
      type: String,
      default:
        "https://images.unsplash.com/photo-1530435460869-d13625c69bbf?crop=entropy&amp;cs=tinysrgb&amp;fit=crop&amp;fm=jpg&amp;ixid=MnwzNzg0fDB8MXxzZWFyY2h8MTB8fHdlYnNpdGV8ZW58MHwwfHx8MTYyMTQ0NjkyNg&amp;ixlib=rb-1.2.1&amp;q=80&amp;w=1080&amp;h=768",
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    lazyHydrate: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.lead {
  max-height: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.konfetti-hero {
  position: relative;

  &__content {
    @include for-desktop {
      position: absolute;
      width: 50%;
      transform: translateY(-50%);
      top: 50%;
      right: -2.65rem;
    }
  }

  &__text-container {
    background: $white;

    @include for-desktop {
      border-radius: $border-radius-md;
      padding: 1rem 2rem 2rem 2rem;
      max-width: 550px;
    }

    @include for-tablet-and-down {
      border-radius: 0 !important;
      padding: 0;
    }
  }
}
</style>
