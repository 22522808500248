/* eslint-disable  */

// @francesca --- imgs are here:   t.ly/eK7l

export default {
  teamEvents: {
    header: {
      title: "Teamevents und Firmenfeiern buchen",
      description:
        "Über 6.000 Kurse und Erlebnisse als Teamevent, Firmenevent oder Betriebsfeier. Von 10 bis 1.000 Personen.",
      buttonLabel: "Anfrage senden oder anrufen: 030/692011960",
      bgColor: "#cced3a",
    },

    processSteps: {
      title:
        "Tausende Firmen vertrauen uns bei der Planung ihrer Betriebsfeiern",
      items: {
        1: {
          image: "/team-events/michael-l-geschaftsfuhrer-hyrise-academy.webp",
          title: "KPMG",
          description:
            '"Unser Abend ist super verlaufen. Mein Team hatte jede Menge Spaß beim Cocktailkurs. Das Erlebnis mit konfetti habe ich als unkompliziert, übersichtlich und sehr zuvorkommend erlebt."',
          link: "https://gokonfetti.com/de-de/teamevent/",
          linkText: "Buche jetzt Dein Firmenevent",
          circleContent: "1",
        },
        2: {
          image: "/team-events/ceo-pospulse.webp",
          title: "E.ON",
          description:
            '"Die Kommunikation war super easy, sympathisch und professionell. Es hat sehr viel Spaß gemacht und den Abend zu etwas ganz Besonderem werden lassen. Alle Kollegen werden noch lange davon berichten."',
          link: "https://gokonfetti.com/de-de/teamevent/",
          linkText: "Betriebsfeier mit konfetti planen",
          circleContent: "2",
        },
        3: {
          image: "/team-events/Ostrom_Founders_2-800x533.webp",
          title: "Boston Consulting Group",
          description:
            '"Das Event war ein voller Erfolg - vom Menü über das Team bis hin zur Eventlocation war alles wunderbar! Auch die Planung und Buchung haben einwandfrei funktioniert. Danke für die Organisation!"',
          link: "https://gokonfetti.com/de-de/teamevent/",
          linkText: "Firmenfeier finden",
          circleContent: "3",
        },
      },
    },

    variety: {
      title: "Beliebte Ideen für Firmenevents",
      items: {
        1: {
          image: "/team-events/cocktailkurse.webp",
          title: "Kulinarische Events",
          description:
            "Kochkurse, Food-Touren, Winetastings oder Cocktailkurse - kulinarische Events sind der absolute Renner unter den Firmenfeiern und kommen immer gut an.",
        },
        2: {
          image: "/team-events/kreativworkshops.webp",
          title: "Kreative Teamevents",
          description:
            "Action-Painting, Graffiti-Workshop, Töpfern, alles was das DIY-Herz begehrt. Jetzt ist Eure Kreativität gefragt!",
        },
        3: {
          image: "/team-events/kochkurse-und-tastings.webp",
          title: "Das passende Event",
          description:
            "Unter mehr als 6.000 Events finden wir das Firmenevent, das zu Euch passt. Sei es ein Backkurs, ein DIY-Workshop oder eine ausgelassene Feier auf einem Partyschiff.",
        },
      },
    },

    workshops: {
      title: "Mehr als 6.000 Aktivitäten für Teamevents",
    },

    courses: {
      title: "Was für ein Teamevent suchst Du?",
    },

    heroes: {
      first: {
        title: "Wir stehen Dir persönlich zur Verfügung",
        subtitle:
          "Wir bei konfetti sind absolute Profis und helfen Dir bei der Planung der nächsten Firmenfeier. Gerne beraten wir Dich kostenfrei. Sende uns einfach eine Anfrage oder rufe an unter 030-69201196-0.",
        image: "/team-events/PE-group.jpg",
        mobileImage: "/team-events/PE-group.jpg",
        buttonText: "Event anfragen",
      },
    },
  },
};
