





















































































































import { ref } from "@vue/composition-api";
import { KftButton } from "@konfetti-ui/vue";
import _ from "lodash";
import { EMPTY_FILTERS_INSTANCE } from "../../../composables";
import { KftSwitch } from "~/components";
import KftChip from "~/components/atoms/KftChip/KftChip.vue";
import KftRangeSlider from "~/components/atoms/KftRangeSlider/KftRangeSlider.vue";

export default {
  name: "KftSearchFiltersMainModal",
  components: {
    KftSwitch,
    KftChip,
    KftRangeSlider,
    KftButton,
  },
  props: {
    filters: {
      type: Object,
      default: () => _.cloneDeep(EMPTY_FILTERS_INSTANCE),
    },
    availableLanguages: {
      type: Object,
      default: null,
    },
  },

  setup(props, { emit }) {
    const temporaryFilters = ref(_.cloneDeep(EMPTY_FILTERS_INSTANCE));
    const filterChipRef = ref(null);
    const priceRangeSliderRef = ref(null);

    const handleConfirmTemporaryFilters = () => {
      emit("confirm", _.cloneDeep(temporaryFilters.value));
      filterChipRef.value?.fnHideModal();
    };

    const handleClearFilters = () => {
      temporaryFilters.value = {
        ..._.cloneDeep(props.filters),
        languages: _.cloneDeep(EMPTY_FILTERS_INSTANCE.languages),
        online: EMPTY_FILTERS_INSTANCE.online,
        hasAvailableDates: EMPTY_FILTERS_INSTANCE.hasAvailableDates,
      };

      priceRangeSliderRef.value?.fnSetValues([
        parseInt(EMPTY_FILTERS_INSTANCE.price.min || "0") / 100,
        parseInt(EMPTY_FILTERS_INSTANCE.price.max || "120000") / 100,
      ]);
    };

    /**
     * @name fnToggleFacet
     * @description Pushes a category|language|other to the filter
     *
     * @param key The name of the filter to be manipulated
     * @param value The value to be included within the selected filters
     * */
    const fnToggleFacet = (key, value) => {
      const index = temporaryFilters.value?.[key].indexOf(value);
      if (index === -1) {
        temporaryFilters.value?.[key].push(value);

        return;
      }

      temporaryFilters.value?.[key].splice(index, 1);
    };

    /** @name fnSetPrice
     *  */
    const fnSetPrice = (value: [number, number]) => {
      temporaryFilters.value.price.min = value[0] * 100;
      temporaryFilters.value.price.max = value[1] * 100;
    };

    const onOpenModal = () => {
      temporaryFilters.value = _.cloneDeep(props.filters);
    };

    return {
      temporaryFilters,
      filterChipRef,
      priceRangeSliderRef,

      onOpenModal,
      fnSetPrice,
      fnToggleFacet,
      handleClearFilters,
      handleConfirmTemporaryFilters,
    };
  },
};
