import { Logger, sharedRef } from "@konfetti-core/core";
import { computed } from "@vue/composition-api";
import { useApiHandler } from "../useApiHandler";

/**
 * Suppliers
 * */

export const useSupplier = (
  id: string,
  hasAccessToWindowObject: boolean,
): any => {
  /** Supplier events */
  const supplier = sharedRef(null, `useSupplier-supplier-${id}`);
  const loadingSupplier = sharedRef(false, `useSupplier-loadingSupplier-${id}`);
  const supplierLocalities = sharedRef(
    null,
    `useSupplier-supplierLocalities-${id}`,
  );
  const loadingSupplierLocalities = sharedRef(
    false,
    `useSupplier-loadingSupplierLocalities-${id}`,
  );

  /** Error */
  const error = sharedRef(null, `useSupplier-error-${id}`);

  /** Wrapper for API requests */
  const { makeRequest } = useApiHandler(hasAccessToWindowObject);

  /**
   * METHODS
   * */
  const fnClearSupplier = () => {
    supplier.value = null;
  };

  const fnLoadSupplier = async (params) => {
    Logger.debug(`useSupplier/${id}/fnLoadSupplier`, params);
    try {
      loadingSupplier.value = true;
      supplier.value = await makeRequest("getSupplier", params);
      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useSupplier/${id}/fnLoadSupplier`, err);
    } finally {
      loadingSupplier.value = false;
    }
  };

  const fnLoadSupplierByAlias = async (alias: string) => {
    Logger.debug(`useSupplier/${id}/fnLoadSupplierByAlias`, alias);
    try {
      loadingSupplier.value = true;
      supplier.value = await makeRequest("getSupplierByAlias", alias);
      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useSupplier/${id}/fnLoadSupplierByAlias`, err);
    } finally {
      loadingSupplier.value = false;
    }
  };

  const loadSupplierById = async (id: string) => {
    Logger.debug(`useSupplier/${id}/getSupplierById`, id);
    try {
      loadingSupplier.value = true;
      supplier.value = await makeRequest("getSupplierById", id);
      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useSupplier/${id}/getSupplierById`, err);
    } finally {
      loadingSupplier.value = false;
    }
  };

  const loadSupplierLocalitiesById = async (id: string) => {
    Logger.debug(`useSupplier/${id}/getSupplierLocalitiesById`, id);
    try {
      loadingSupplierLocalities.value = true;
      supplierLocalities.value = await makeRequest(
        "getSupplierLocalitiesById",
        id,
      );
      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useSupplier/${id}/getSupplierLocalitiesById`, err);
    } finally {
      loadingSupplierLocalities.value = false;
    }
  };

  return {
    /* Single event object */
    supplier: computed(() => supplier.value),
    supplierLocalities: computed(() => supplierLocalities.value?.data),
    error: computed(() => error.value),
    loadingSupplier: computed(() => loadingSupplier.value),
    /* Methods */
    fnClearSupplier,
    fnLoadSupplier,
    fnLoadSupplierByAlias,
    loadSupplierById,
    loadSupplierLocalitiesById,
  };
};
