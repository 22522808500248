<template>
  <div :id="elementId" :class="{ reduced: reduced }"></div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
import { onMounted } from "@vue/composition-api";
import { fnGetCitiesIndexName } from "~/helpers";

export default {
  name: "KftAlgoliaAutocomplete",
  props: {
    elementId: {
      type: String,
      required: true,
    },
    reduced: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root: { $i18n, $config, $router, localePath } }) {
    /**
     * HOOKS
     * */
    const searchClient = algoliasearch(
      $config.algolia.appId,
      $config.algolia.apiClientKey,
    );

    const mountUrlParamsFromItem = (item) => {
      let geoloc = "";
      if (item?._geoloc) {
        geoloc = `geoloc=${item._geoloc.lat},${item._geoloc.lng}`;
      }

      const city = "city=" + encodeURIComponent(item.slug);

      return [geoloc, city].join("&");
    };

    onMounted(() => {
      const el = document.getElementById(props.elementId);

      if (el === null || el.childNodes.length > 0) {
        return;
      }

      autocomplete({
        container: `#${props.elementId}`,
        defaultActiveItemId: 0,
        plugins: [],
        openOnFocus: true,

        translations: {
          detachedCancelButtonText: $i18n.t("general.cancel"),
        },

        getSources() {
          return [
            {
              sourceId: "localities",
              getItemInputValue: ({ item }) => item.query,
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: fnGetCitiesIndexName(),
                      query,
                      params: {
                        hitsPerPage: 6,
                        // aroundLatLngViaIP: true,
                        // aroundRadius: "all",
                        attributesToSnippet: ["name:24"],
                        snippetEllipsisText: "…",
                      },
                    },
                  ],
                });
              },
              templates: {
                item({ item, components, html }) {
                  return html` <div class="aa-ItemContent">
                    ${components.Highlight({
                      hit: item,
                      attribute: "name",
                    })}
                  </div>`;
                },
              },
              onSelect({ item }) {
                /* considering the items we have now are only query suggestions */
                return $router.push(
                  localePath(`/search/?${mountUrlParamsFromItem(item)}`),
                );
              },
            },
          ];
        },
        placeholder: $i18n.t("search.algoliaAutocomplete.placeholder"),
        onSubmit({ state }) {
          if (state?.collections?.[0]?.items.length > 0) {
            const item = state.collections[0].items[0];
            return $router.push(
              localePath(`/search/?${mountUrlParamsFromItem(item)}`),
            );
          }

          return $router.push(localePath("/search/"));
        },
      });
    });
  },
};
</script>

<style lang="scss">
@import "~/assets/components/atoms/KftAlgoliaAutocomplete.scss";
</style>
