/* eslint-disable  */

export default {
  general: {
    bottomNavigationCart: "Warenkorb",
    signIn: "Anmelden",
    cities: "Städte",
    all: "Alles",
    online: "Online",
    close: "Schließen",
    profile: "Mein Konto",
    submit: "Einreichen",
    securePaymentOptions: "Sichere Zahlungsmethoden",
    // For SEO purposes titles and descriptions should follow this:
    // https://gokonfetti.atlassian.net/browse/KON-85
    titles: {
      base: "konfetti",
      embeddedBase: "Embedded Layout",
      "review-event": "Eine Klasse prüfen",
      "order-success": "Ihre Bestellung war erfolgreich",
      "account-general-info": "Allgemeine Informationen",
      "account-user-info": "Benutzerinformationen",
      "account-orders": "Bestellungen",
      "account-referral-info": "Refer a friend",
      error: "Seite nicht gefunden",
      login: "Login",
      checkout: "Checkout",
      contactUs: "Kontakt",
      "data-protection": "Datenschutz",
      faq: "Häufige Fragen",
      home: "konfetti – Kurse, Geschenkideen und Teamevents",
      impressum: "Impressum",
      myAccount: "Konto",
      product: "Produkt",
      search: "Kurse, Workshops und Geschenkideen. Teamevent buchen.",
      partner: "Werde Partner",
      "terms-and-conditions": "AGB",
      "terms-supplier": "Angebotsanbieter-AGB",
      myProfile: "Profil",
      teamevent: "Teamevents 2024 buchen | Über 6.000 Firmenevents",
      giftcard: "Gutschein verschenken für 6.000 Kurse | Top Erlebnisse",
      "event-list-page": {
        city: "Kurse und Workshops in {city} 2024 | Kurse in Deiner Nähe",
        "city-category": "Kurse und Workshops in {city} | Kurse in Deiner Nähe",
        general: "{title}`",
        category: "{category}",
      },
      "password-reset": "Password reset",
      "magazine-slug": "{article}",
      "article-category-slug": "{articleCategory}",
      "magazine-pages": "Das konfetti Magazin", // this is displayed when loading a new content
      "supplier-profile": "{supplierName} buchen: Kurse und Workshops",
      "partner-pricing":
        "Preise & Pakete: Tarife, die zu Deinem Business passen",
      "partner-booking-solution":
        "konfetti Buchungssystem: Tickets & Gutscheine online verkaufen",
      "login-forgot-password": "Reset passwort",
      sitemap: "Sitemap",
    },

    // The {varName} might be used as variables for manipulation in dynamic routes
    // See '~/utilities/stringVariable'
    descriptions: {
      base: "Bei konfetti über 6.000 Kurse und Workshops entdecken. Geschenkidee gesucht? ✓ Kochen ✓ Malen ✓ Cocktails ✓ Kreatives uvm. Top  Ideen. Go konfetti!",
      checkout: "",
      contactUs:
        "Du möchtest Kontakt mit uns aufnehmen und mehr erfahren? Dann kontaktiere uns per E-Mail, telefonisch oder stelle eine Anfrage. Wir freuen uns auf Dich.",
      "data-protection": "",
      faq: "Du hast Fragen zu unseren Kursen und Events? Alle häufigen Fragen findest Du auf unserer Webseite. Falls Du noch mehr wissen möchtest, kontaktiere uns gerne.",
      home: "Bei konfetti über 6.000 Kurse und Erlebnisse entdecken. Das perfekte Geschenk: ✓ Kochkurse ✓ Kreativkurse ✓ Weinproben uvm. ➤ Erlebnisse buchen",
      impressum:
        "Du möchtest Kontakt mit uns aufnehmen und mehr erfahren? Dann kontaktiere uns per E-Mail, telefonisch oder stelle eine Anfrage. Wir freuen uns auf Dich.",
      myAccount: "",
      product: "",
      search: "",
      partner:
        "Werde Partner und mache noch mehr Menschen mit Deinen Kursen glücklich. Bewirb Dich einfach und unkompliziert über unser Partnerformular hier bei konfetti.",
      "terms-and-conditions": "",
      "terms-supplier": "",
      myProfile: "",
      teamevent:
        "Teamevent oder Betriebsausflug gesucht? Mehr als 6.000 Aktivitäten in Eurer Nähe. Kochkurs, Partyboot, Cocktails und mehr. Finde jetzt Dein ❤ Teamevent.",
      giftcard:
        "Gutschein verschenken: ✓ Mehr als 6.000 Kurse ✓ Top Geschenkidee ✓ Verschenke Vorfreude auf tolle Erlebnisse ➤ Einzigartiges Geschenk entdecken",
      "event-list-page": {
        city: "Kurse und Workshops in {city} gesucht? ✓ Kochen ✓ Malen ✓ Cocktails uvm. Auch als Teamevents oder super Geschenkidee. Finde Deine ❤ Kurse in {city}!",
        general: "{title}",
        "city-category":
          "Kurse und Workshops in {city} gesucht? ✓ Kochen ✓ Malen ✓ Cocktails uvm. Auch als Teamevents oder super Geschenkidee. Finde Deine ❤ Kurse in {city}!",
        category:
          "{category} online buchen bei Konfetti - über {eventCount} Events auf unserer Seite.",
      },
      "password-reset": "Password reset",
      "magazine-slug": "{description}",
      "article-category-slug": "{description}",
      "supplier-profile":
        "{supplierName}: ✓ Exklusive Kurse und Teamevents ✓ Einzigartige Eventlocation ➤ Kontakt aufnehmen oder direkt online buchen",
      "account-user-info": "",
      "account-general-info": "",
      "account-orders": "",
      "account-referral-info": "",
      "partner-pricing":
        "Preise & Pakete: Finde das Paket, das perfekt zu Deinem Unternehmen passt. Unsere Tarife sind auf Dein Unternehmen abgestimmt. Jetzt informieren",
      "partner-booking-solution":
        "konfetti Buchungssystem: Verkaufe Tickets, Teamevents und Gutscheine auf Deiner Webseite. Verwalte Deine Events in einer Software ➤ Kostenfreie Demo",
    },

    openGraphImages: {
      default: "/logos/kft-short-logo-white-bg.webp",
      // to be added
    },

    // 404
    "404page": {
      errorAlert: {
        title: "Ups, diese Seite gibt es nicht.",
        message:
          "Aber wir haben über 6.000 weitere Kurse und Erlebnisse im Angebot :).",
      },
      eventSuggestions: {
        title: "Top Vorschläge für Dich",
      },
    },

    /* Pages */
    home: "Kurse buchen",

    /* Header / Topbar */
    allEvents: "Kurse",
    events: "Kurse",
    teamEvents: "Teamevents",
    teameventAnfrage: "Teamevent anfragen",
    beAPartner: "Werde Partner",
    supplierProfileButton: "Kontakt",
    magazine: "Magazin",
    giftCards: "Gutscheine",
    categories: "Kategorien",
    wishlist: "Wunschliste",
    account: "Konto",
    menu: "Menü",
    contactUs: "Kontakt",

    /* Bottom */
    featuredBottom: "Ausgewählte Tags",

    /* General */
    emailPlaceholder: "Bitte gib Deine E-Mail Adresse ein",
    chooseLanguage: "Sprache wählen",
    logout: "Abmelden",
    perPerson: "Person",
    perGroup: "Gruppe",
    or: "oder",
    yes: "Ja",
    no: "Nein",
    cancel: "Abbrechen",
    from: "ab ",
    edit: "Editieren",
    apply: "Anwenden",
    back: "Zurück",
    success: "Das hat geklappt!",
    info: "Hinweis",
    warning: "Hinweis",
    error: "Bitte überprüfe Deine Eingaben",
    noSearchResultsFoundTitle: "Bitte erweitere Deine Suche",
    noSearchResultsFound:
      "Füge weitere Kategorien hinzu oder ändere Deine Ortsangabe.",
    classOnRequest: "Kurs auf Anfrage",
    waitlistSubscribedMessage:
      "Das hat geklappt! Sobald neue Termine für dieses Event online gehen, erhältst Du eine E-Mail von uns.",
    waitlistUnsubscribedMessage:
      "Das hat geklappt! Du erhältst keine E-Mails mehr über neue Termine für dieses Event.",

    /* Pagination */
    pagination: {
      events: "Kurse",
      resultsPerPage: "{results} pro Seite",
      next: "Weiter",
      previous: "Vorherige",
      items: "Artikel",
      foundItems: "Gefundene Kurse: ",
      orderBy: "Sortieren nach",
      orderByOptions: {
        relevancy: "Relevanz",
        newest: "Neueste",
        priceAsc: "Preise aufsteigend",
        priceDesc: "Preise absteigend",
      },
    },

    /* Cart */
    cart: {
      shoppingBag: "Warenkorb",
      items: "Artikel",
    },

    /* Form */
    form: {
      validation: {
        min: "Bitte gib mindestens {count} Zeichen ein",
        required: "Dieses Feld ist erforderlich",
        email: "Bitte gib eine gültige E-Mail ein",
      },
    },

    /* Contact us */
    contactPhone: "030 / 257706800",
    contactPhoneAction: "tel:030257706800",
    contactEmail: "hallo@gokonfetti.com",

    select: {
      noResultsFound: "Kein Kurs gefunden",
      emptySelect: "Nicht verfügbar",
    },

    faqTitle: "Wähle eine Frage aus",

    termsAndConditionsTitle: "AGB",

    dataProtectionTitle: "Datenschutz",

    termsSupplierTitle: "AGB",

    language: "Sprache",
    languages: {
      english: "Englisch",
      german: "Deutsch",
      french: "Französisch",
      spanish: "Spanisch",
      russian: "Russisch",
      italian: "Italienisch",
    },

    promotionCards: {
      teamevents: {
        label: "Jetzt individuelles Teamevent anfragen - kostenfreie Beratung",
      },
      giftcard: {
        label: "Geschenkgutschein für 6.000 Kurse auswählen",
      },
      bookingSolutionGiftcard: {
        label: "Gutschein, einlösbar für alle Kurse, kaufen",
      },
    },

    kftReadMore: {
      readMoreLabel: "mehr lesen",
      readLessLabel: "weniger lesen",
    },

    /* Contact us Form */

    kftContactUsCard: {
      title: "Hast Du noch Fragen?",
      description:
        "Sende uns einfach eine E-Mail oder kontaktiere uns über den Live Chat. Wir helfen Dir gerne weiter und melden uns bei Dir!",
      buttonLabel: "E-Mail senden",
    },
    copyToClipboard: "In die Zwischenablage kopieren",
    copiedToClipboard: "In die Zwischenablage kopiert",
  },
};
