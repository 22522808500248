// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const required = (value: any): boolean => {
  if (typeof value === "number") {
    return true;
  }

  return value?.length > 0;
};

export default required;
