<template>
  <div class="card kft-contact-us-card">
    <div class="card-body">
      <i class="bi bi-question-circle-fill" />

      <div class="mb-3">
        <div class="text-bold kft-heading__title h5 text-center">
          {{ $t("general.kftContactUsCard.title") }}
        </div>
        <p class="overline mt-3">
          {{ $t("general.kftContactUsCard.description") }}
        </p>
      </div>

      <button
        aria-label="Contact us"
        class="btn btn-shadow btn-light-primary btn-primary w-100"
        @click="onClick"
      >
        {{ $t("general.kftContactUsCard.buttonLabel") }}
      </button>
    </div>
  </div>
</template>

<script>
import { KftHeading, KftButton } from "@konfetti-ui/vue";

export default {
  name: "KftContactUsCard",
  components: {
    KftHeading,
    KftButton,
  },

  emits: ["click:contact-us"],

  setup(props, { root: { $i18n } }) {
    const onClick = () => {
      window.location.href = `mailto:${$i18n.t("general.contactEmail")}`;
    };

    return {
      onClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.kft-contact-us-card {
  text-align: center;
  --heading-description-margin: var(--spacer-3) 0 var(--spacer-4);
  --heading-description-font-size: var(--overline-font-size);
  --heading-description-font-line-height: var(--overline-line-height);

  i {
    font-size: 24px;
  }

  .card-body {
    padding: 1rem;
    text-align: center;
  }

  .kft-heading {
    --heading-text-align: center;
  }

  &__description {
    --heading-description-font-size: var(--overline-font-size);
  }

  ::v-deep &__button {
    width: 100%;
    background-color: #e0e7e8;
    border-radius: 5px;
    padding: var(--spacer-3) 0;
    color: $primary !important;
    font-size: 17px;
    font-weight: var(--font-weight--bold);
    &:hover {
      color: $black;
    }
  }

  p {
    line-height: 1.2;
  }

  border: 1px solid $gray-ultralight;
}
</style>
