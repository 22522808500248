const getId = (summaryList): number => {
  return summaryList?.id || null;
};

const getTitle = (summaryList): string => {
  return summaryList?.title || null;
};

const getItems = (summaryList): any[] => {
  return summaryList?.items || null;
};

const getItemLabel = (summaryListItem): string => {
  return summaryListItem?.label || null;
};

const getItemIconClassName = (summaryListItem): string => {
  return summaryListItem?.icon_class_name || null;
};
const getItemDescription = (summaryListItem): string => {
  return summaryListItem?.description || null;
};

export const summaryListGetters = {
  getId,
  getTitle,
  getItems,
  getItemLabel,
  getItemIconClassName,
  getItemDescription,
};
