import Vue from "vue";

import { Plugin } from "@nuxt/types";

declare module "vue/types/vue" {
  // this.$scrollToTop inside Vue components
  interface Vue {
    $scrollToTop(): void;
  }
}

declare module "@nuxt/types" {
  // nuxtContext.app.$scrollToTop inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $scrollToTop(): void;
  }

  // nuxtContext.$fullLocaleUrl
  interface Context {
    $scrollToTop(): void;
  }
}

const scrollToTop: Plugin = ({ app }, inject) => {
  inject("scrollToTop", () => {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    if (!process.client) {
      return;
    }

    if (document && document.body) {
      document.body.scrollIntoView({ behavior: "smooth", block: "start" });

      return;
    }

    if (window) {
      window.scrollTo(0, 0);
    }
  });
};

export default scrollToTop;
