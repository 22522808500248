<template>
  <div id="error" :class="{ 'device-mobile': $device.isMobile }">
    <KftAlert class="my-8 kft-alert--info">
      <h5 class="m-0 text-primary">
        {{ $t("general.404page.errorAlert.title") }}
      </h5>
      <span class="overline">{{
        $t("general.404page.errorAlert.message")
      }}</span>
    </KftAlert>
    <LazyHydrate v-if="supplierScope === null" when-visible>
      <KftProductShowcase
        :algolia-insights-client="algoliaClient"
        :loading="loading"
        :products="topEvents"
        :query-id="topEventsQueryID"
        :tags="[]"
        :title="$t('general.404page.eventSuggestions.title')"
        :title-h-class="`h5`"
        :title-level="2"
        algolia-insights-label="Top Events Suggestions - 404 page"
        class="suggestions-product-showcase py-8"
      />
    </LazyHydrate>
  </div>
</template>
<script>
import { onMounted, ssrRef } from "@nuxtjs/composition-api";
import { onBeforeMount } from "@vue/composition-api";
import LazyHydrate from "vue-lazy-hydration";
import { useDefaultErrorHandlers } from "../composables";
import { KftAlert, KftProductShowcase } from "~/components";
import { useAlgoliaEventApi, useSearchInsights } from "~/composables";
import { getSupplierSubdomain } from "~/helpers";

export default {
  name: "ErrorLayout",

  components: {
    KftAlert,
    KftProductShowcase,
    LazyHydrate,
  },

  layout({ ssrContext }) {
    if (getSupplierSubdomain(ssrContext?.nuxt, null) !== null) {
      return "supplier";
    }

    return "default";
  },

  props: ["error"],

  setup(props, { root: { $i18n, context, $options } }) {
    const supplierScope = ssrRef(
      getSupplierSubdomain(
        context?.nuxtState || context?.ssrContext?.nuxt,
        $options?.config,
      ),
      "supplier-scope",
    );
    const { locale } = $i18n;
    const { client: algoliaClient, fnInit: fnInitSearchInsights } =
      useSearchInsights();

    const {
      topEvents,
      newEvents,
      topGiftIdeasEvents,
      fetchHomePageListings,
      topEventsQueryID,
      newEventsQueryID,
      topGiftIdeasEventsQueryID,
      fnInit: fnInitAlgoliaEventApi,
      isLoaded: isAlgoliaLoaded,
    } = useAlgoliaEventApi("general-instance");
    const { registerDefaultErrorHandlers } = useDefaultErrorHandlers();

    /**
     * HOOKS
     * */

    const loading = ssrRef(true, "loading-404-page");
    onBeforeMount(async () => {
      if (!isAlgoliaLoaded.value) {
        await fnInitAlgoliaEventApi(null);
      }

      await fnInitSearchInsights();
      await fetchHomePageListings();

      loading.value = false;
    });

    onMounted(() => {
      registerDefaultErrorHandlers();
    });

    return {
      locale,
      supplierScope,
      algoliaClient,
      loading,

      /* Variables */
      topEvents,
      newEvents,
      topGiftIdeasEvents,
      topEventsQueryID,
      newEventsQueryID,
      topGiftIdeasEventsQueryID,
    };
  },

  head() {
    return {
      title: this.$t("general.titles.error"),
      titleTemplate: "%s" + " | " + this.$t("general.titles.base"),
    };
  },
};
</script>
<style lang="scss">
#error {
  width: 100%;
  position: relative;
  margin-bottom: 40%;

  @include for-desktop {
    margin-bottom: 0;
  }

  .suggestions-product-showcase {
    border-top: solid 1px #e0e0e0;

    h2 {
      margin-bottom: var(--spacer-8);
    }
  }
}

.title {
  line-height: 1;
}
</style>
