export default {
  validation: {
    min: "Bitte gebe mindestens {min} Zeichen ein",
    max: "Bitte geben Sie maximal {max} Zeichen ein",
    required: "Dieses Feld wird benötigt",
    email: "Bitte gebe eine gültige E-Mail ein",
    sameValueAs: "Dieses Feld sollte übereinstimmen mit {sameValueAs}",
    shouldBeTrue: "Dieses Feld muss angekreuzt werden",
    checked: "",
  },
};
