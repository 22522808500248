/* eslint-disable camelcase */
import { useStripe } from "@konfetti/composables";
import { Logger, sharedRef, UseKftContext } from "@konfetti-core/core";
import { computed } from "@vue/composition-api";
import {
  ErrorStoreErrorType,
  useErrorStore,
  useOrderValidation,
} from "~/composables";
import { addSubdomain, getSupplierSubdomain } from "~/helpers";

/**
 * @name useSEPA
 * @desc This module is responsible for dealing with stripe SEPA checkout
 *
 * @param id used to define the shared Refs
 * @param hasAccessToWindowObject used to block usage of window objects in iFrames
 * @param customUserToken used if we do not have access to window object and we're forcing the user token to the requests
 * */

const useSEPA = (
  id: string,
  hasAccessToWindowObject: boolean,
  customUserToken = null,
): any => {
  const context = UseKftContext();
  const loading = sharedRef(null, `useSEPA/loading-${id}`);
  const error = sharedRef(null, `useSEPA/error-${id}`);
  const stripeInstance = sharedRef(null, `useSEPA/stripeInstance-${id}`);
  const {
    sendOrder,
    order,
    error: orderError,
  } = useStripe("general-instance", hasAccessToWindowObject);

  const { cartValidationErrors, orderValidationErrors, fnValidateOrder } =
    useOrderValidation("general-instance", hasAccessToWindowObject);

  const setStripeInstance = (instance): void => {
    stripeInstance.value = instance;
  };

  const { handleError } = useErrorStore();

  /**
   * @name submitOrder
   * @description sends a validation request to the API in order to make sure we have a valid order
   *
   * @param params The mounted params
   * */

  const submitOrder = async (params) => {
    loading.value = true;
    Logger.debug("checkout-payment-pending:sepa");

    await sendOrder(
      null,
      params.items,
      params.shipping,
      params.invoice,
      params.coupon,
      null,
      customUserToken,
      params.external,
      "SEPA_DIRECT_DEBIT",
    );

    if (orderError.value !== null) {
      Logger.error("checkout-payment-attempt:sepa", {
        error: orderError.value,
      });
      handleError({
        type: ErrorStoreErrorType.SEPAOrderError,
        error: orderError.value,
      });
      error.value = orderError.value;
    }
  };

  /**
   * @name processPayment
   * @description
   * Function process the first stage of a payment,
   * @param params Mounted object with necessary variables
   * */
  const processPayment = async (params, IBANElement, email) => {
    loading.value = true;
    let paymentProcessResult;

    await submitOrder(params);

    if (orderError.value !== null && orderError.value.length > 0) {
      error.value = orderError.value;
      loading.value = false;
      return;
    }

    const sepaIntentSecret = order.value?.meta?.custom?.client_secret || null;
    if (!sepaIntentSecret) {
      loading.value = false;
      return;
    }

    await stripeInstance.value
      .confirmSepaDebitPayment(sepaIntentSecret, {
        payment_method: {
          sepa_debit: IBANElement,
          billing_details: {
            name: params.name,
            email,
          },
        },
      })
      .then((result) => {
        console.log(result);
        if (
          result?.paymentIntent?.status === "processing" ||
          result?.paymentIntent?.status === "succeeded"
        ) {
          paymentProcessResult = true;
        }
      })
      .finally(() => {
        loading.value = false;
      });

    return paymentProcessResult;
  };

  /**
   * @name submitPayment
   * @description Initializes a payment with SEPA, the backend will retrieve an intent token so we can proceed with the purchase
   *
   * @param params Mounted object with necessary variables
   * */
  const submitPayment = async (params, IBANElement, email) => {
    loading.value = true;

    /* Validating order and items */
    await fnValidateOrder(params, customUserToken);

    /* At this point, events were already issued in validateOrder(), we are just stopping the process */
    if (
      cartValidationErrors.value !== null &&
      cartValidationErrors.value.length > 0
    ) {
      error.value = cartValidationErrors.value;
      loading.value = false;
      return;
    }

    /* Process the payment */
    const paymentProcessResult = await processPayment(
      params,
      IBANElement,
      email,
    );

    loading.value = false;
    return paymentProcessResult;
  };

  return {
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    order: computed(() => order.value),

    submitPayment,
    setStripeInstance,
  };
};

export { useSEPA };
