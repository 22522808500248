/* eslint-disable  */

export default {
  faqGiftcards: {
    sections: [
      {
        title: 'Häufige Fragen',
        questions: [
          // @francesca - this refers to a cooking course example. Each of the FAQ will be tailored to fit the category type
          {
            question: 'Brauche ich Vorkenntnisse?',
            answer:
              'Du brauchst keine Vorkenntnisse darin, italienisch  zu kochen. Alles Nötige lernst Du bei uns im Kurs.',
          },
          {
            question: 'Was muss ich zum Kurs mitbringen?',
            answer:
              'Alles, was Du mibringen musst, ist viel Motivation, Leidenschaft und Amore',
          },
          {
            question: 'Was tue ich bei Allergien?',
            answer:
              'Darüber musst Du Dir keine Gedanken machen. Kontaktiere Deinen Veranstalter vor dem Kurs, dass Du bestimmte Nahrungsmittel nicht aufnehmen kannst. Dementsprechend passen wir Dann die Gerichte auch an, damit Du auch Deinen Spaß hast.',
          },
          {
            question: 'Muss ich mich für den Kochkurs entsprechend kleiden?',
            answer:
              'Du kannst tragen, was Du möchtest. Aber wahrscheinlich weißt Du aus Erfahrung: In der Küche kann es ganz schnell vorkommen, dass mal Tomatensoße auf Deinem neuen Pullover landet. Nimm deswegen vielleicht noch Kleidung zum Auswechseln mit, wenn Dir dieser Aspekt wichtig ist.',
          },
        ],
      },
    ],
  },
};
