import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";

require("dayjs/locale/de");
require("dayjs/locale/en");

const formatLocale = (locale: string) => {
  if (locale.includes("-")) {
    return locale.split("-").shift();
  }

  if (locale.includes("_")) {
    return locale.split("-").shift();
  }

  return locale;
};

export const convertMinutesToHumanString = (minutes: number): string => {
  if (!minutes) {
    return "";
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.round(minutes % 60);

  const hourString = hours > 0 ? `${hours}h` : "";
  const minuteString =
    remainingMinutes > 0
      ? `${remainingMinutes}${hours === 0 ? "min" : ""}`
      : "";

  return `${hourString}${minuteString}`;
};

export const removeSeconds = (time: string): string => {
  return time.match(/\d{2}:\d{2}|[AMP]+/g).join(" ");
};

export const formatTime = (dateTime: string): string => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  /* @todo: get tz from event */
  const date = dayjs(dateTime, { utc: true });
  return date.tz("Europe/Berlin").format("HH:mm");
};

export const formatDate = (
  dateTime: string,
  locale: string | undefined,
  formatStr: string = null,
): string => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);

  const defaultFormatting = {
    "en-us": "dd. DD MMM.",
    "de-de": "dd. DD. MMM",
  };

  const format = formatStr || defaultFormatting[locale] || "dd. DD. MMM";

  /* @todo: get tz from event */
  const date = dayjs(dateTime, { utc: true }).tz("Europe/Berlin");

  if (locale) {
    return date.locale(formatLocale(locale)).format(format);
  }

  return date.locale("de").format(format);
};

export const dateToFormat = (
  dateTime: string,
  format: string,
  locale: string | undefined,
): string => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);

  /* @todo: get tz from event */
  const date = dayjs(dateTime).tz("Europe/Berlin");

  if (locale) {
    return date.locale(formatLocale(locale)).format(format);
  }

  return date.locale("de").format(format);
};

export const isDateInlastQuarter = (dateTime: string): boolean => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const created = dayjs(dateTime).tz("Europe/Berlin");
  const today = dayjs().tz("Europe/Berlin");

  return today.diff(created, "month") < 3;
};

export const isDateInLastXMinutes = (
  dateTime: string,
  minutes: number,
): boolean => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const created = dayjs(dateTime).tz("Europe/Berlin");
  const now = dayjs().tz("Europe/Berlin");

  return now.diff(created, "minutes") < minutes;
};

export const getTimeFromDateTime = (dateTime: string): string =>
  dateTime.split(" ").pop();

export const isTimeSet = (dateTime: string): boolean =>
  Boolean(dateTime && getTimeFromDateTime(dateTime) !== "00:00");

export const convertDateFormat = (date: string): string => {
  const [day, month, year] = date.split(".");
  return `${year}-${month}-${day}`;
};

export const convertDateTimeFormat = (dateTime: string): string => {
  const [date, time] = dateTime.split(" ");
  return `${convertDateFormat(date)} ${time}`;
};

export const convertISO8601ToCustomFormat = (
  iso8601Timestamp: string,
): string => {
  // First, parse the ISO 8601 timestamp into a date and time
  const parsedDate = dayjs(iso8601Timestamp, "YYYY-MM-DDTHH:mm");
  // Combine the date and time parts with a space
  return parsedDate.format("DD.MM.YYYY HH:mm");
};

export const fnGetFormattedDuration = (duration, defaultDuration) => {
  const durationText = convertMinutesToHumanString(duration);

  if (durationText === "") {
    return defaultDuration;
  }

  return durationText;
};
