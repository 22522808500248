import {
  AlgoliaEventItem,
  CategoryItem,
  EventDescription,
} from "@konfetti/composables/src";

export interface GtmViewItemListKftEventCategory {
  item_id: string | number;
  item_name: string;
  // index: number,
  // item_brand: string,
  // item_list_id: string,
  // item_list_name: string,
  // item_variant: string,
  // location_id: string,
  // price: number,
  // quantity: 1
}

export interface GtmViewItemListKftEventDescription {
  item_id: string | number;
  item_name: string;
  index: number;
  item_brand: string;
  item_list_id: string;
  item_list_name: string;
  item_variant: string;
  location_id: string;
  price: number;
  // quantity: 1
}

export type KftGtmItem =
  | GtmViewItemListKftEventCategory
  | GtmViewItemListKftEventDescription;

export enum GtmItemListId {
  GeneralPageRecommendedEvents = "general_page_recommended_events",
  GeneralPageTopEvents = "general_page_top_events",
  GeneralPageTopGiftIdeas = "general_page_top_gift_ideas",
  GeneralPageNewEvents = "general_page_new_events",
  GeneralPageLastViewedEvents = "general_page_last_viewed_events",
  GeneralPageGiftcardEvents = "general_page_giftcard_events",
  EventPageSimilarClasses = "event_page_similar_classes",
  EventPageSimilarOnlineClasses = "event_page_similar_online_classes",
  EventPageSameSupplierClasses = "event_page_same_supplier_classes",
  GiftcardPageGiftcardEvents = "giftcard_page_giftcard_events",
  MagazineArticlePageSupplierClasses = "magazine_article_page_supplier_classes",
  TeameventPageTopEvents = "teamevent_page_top_events",
}
export type SendViewItemListParserFunction = (
  data: AlgoliaEventItem | EventDescription | CategoryItem,
) => KftGtmItem;

export interface SendViewItemListConfig {
  [key: string]: {
    listName: string;
    parser: SendViewItemListParserFunction;
  };
}
