export default {
  embedded: {
    title: "Sichere Buchung",
    successTitle: "konfetti: Bestellung war erfolgreich!",
    newsletter:
      "Ja, ich möchte per E-Mail-Newsletter über Trends, Aktionen & Gutscheine informiert werden. Abmeldung jederzeit möglich. (optional)",
    userNotRegistered: "Erstelle Dein konfetti Konto",
    paymentMethods: "Zahlungsart",
    poweredBy: "Powered by",
  },
};
