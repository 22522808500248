












































































import KftGiftcardBookingIcons from "../../molecules/KftGiftcardBookingIcons/KftGiftcardBookingIcons";
import KftMultiselect from "../../organisms/KftMultiselect/KftMultiselect.vue";
import { useBooking } from "../../../composables";

export default {
  components: {
    KftGiftcardBookingIcons,
    multiselect: KftMultiselect,
  },
  props: {
    sharedKey: {
      type: String,
      required: true,
    },
    kftEventDescriptionObj: {
      type: Object,
      default: null,
    },
    enableEmbedded: {
      type: Boolean,
      default: false,
    },
    enableSubdomain: {
      type: Boolean,
      default: false,
    },
    cssVars: {
      type: Object,
      default: null,
    },
    primaryButton: {
      type: Boolean,
      default: false,
    },
    bookGiftcardText: {
      type: String,
      default: null,
    },
    sectionTitle: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const {
      /* Variables */
      selectedGiftcardQuantity,
      prepareGiftcardForBooking,
    } = useBooking("event-page");

    const gift = () => {
      emit("gift", prepareGiftcardForBooking(props.kftEventDescriptionObj));
    };
    return {
      gift,
      selectedGiftcardQuantity,
    };
  },
};
