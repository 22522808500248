import { kftMicroDown } from "~/utilities/kft-micro-down";

// Couldn't find a way to import VNode
type VNode = any;

export const useKftArticleParser = () => {
  const processContentBlock = (
    componentName: string,
    props: Record<string, any>,
    content: any,
  ) => {
    // Generate the Vue component createVueNode with the specified props
    return componentName ? { componentName, props, content } : null;
  };

  const parseParamsStrToParamsObj = (paramsStr) => {
    const regex = /([-\w]+)='(.*?)'/g;
    const keyValuePairs = [];

    paramsStr.replace(regex, (_, key, value) => {
      keyValuePairs.push({ [key]: value });
    });
    return Object.assign({}, ...keyValuePairs);
  };

  const blockParser = (paramsStr: any, content: any) => {
    const paramsObj: any = parseParamsStrToParamsObj(paramsStr);

    const component = paramsObj.component;
    delete paramsObj.component;

    if (!component) {
      return { component: "div", props: {}, content: "" };
    }

    return processContentBlock(component, paramsObj, content);
  };

  const parseMarkdownWithBlock = (content: string): (string | VNode)[] => {
    const components: (string | VNode)[] = [];

    // split rawContent exactly where the /@block\{([\s\S]*?)\}([\s\S]*?)@endblock/g regex matches
    const splittedString = content.split(
      /@block\{([\s\S]*?)\}([\s\S]*?)@endblock/g,
    );

    for (let i = 0; i < splittedString.length; i++) {
      // This will be true for every block index
      if (i % 3 === 1) {
        components.push(blockParser(splittedString[i], splittedString[i + 1]));
        // We need to skip the next index because it's the content of the block
        i++;
      } else {
        // This will be true for every non-block index
        components.push({ content: kftMicroDown.parse(splittedString[i]) });
      }
    }

    return components;
  };

  const parseMarkdownWithBlockLegacy = (
    content: string,
  ): {
    parsedFirstPart: string;
    parsedSecondPart: string;
    parsedBlock: VNode;
  } => {
    let parsedFirstPart = null;
    let parsedSecondPart = null;
    let parsedBlock = null;

    // split rawContent exactly where the /@block\{([\s\S]*?)\}([\s\S]*?)@endblock/g regex matches
    const splittedString = content.split(
      /@block\{([\s\S]*?)\}([\s\S]*?)@endblock/g,
    );
    parsedFirstPart = kftMicroDown.parse(splittedString[0]);

    // in this case we don't have a block and therefore we don't need to parse anything else
    if (splittedString.length === 1) {
      return { parsedFirstPart, parsedSecondPart, parsedBlock };
    }

    parsedSecondPart = kftMicroDown.parse(splittedString[3]);

    const blockParams = splittedString[1];
    const blockContent = splittedString[2];

    parsedBlock = blockParser(blockParams, blockContent);

    return { parsedFirstPart, parsedSecondPart, parsedBlock };
  };

  return {
    parseMarkdownWithBlock,
    parseMarkdownWithBlockLegacy,
  };
};
