import { Logger, sharedRef } from "@konfetti-core/core";
import { computed, ComputedRef } from "@vue/composition-api";
import { useApiHandler } from "../useApiHandler";
import { EventDate, EventDateType } from "../types";
import { eventDateGetters, paginationGetters } from "../getters";

export interface EventDateParamsSearch {
  id?: string[];
  dateType?: EventDateType;
}

export interface EventDateParams {
  search?: EventDateParamsSearch;
  page?: number;
  include?: string[];
  limit?: number;
}

const DEFAULT_PARAMS = {
  include: ["children"],
  limit: 10,
};

/**
 * Event Dates (also called Events in other places)
 * are the actual buyable thing we have on our website.
 * Different than event description, this is what goes into the cart
 * if it does not have a SOLD_OUT or other impeding statuses
 * */

export const useEventDates = (
  refKey: string,
  hasAccessToWindowObject: boolean,
  customDefaultParams: EventDateParams = {},
): any => {
  const getRefKey = (key) => `useEventDates-${key}-${refKey}`;

  /** Event dates */
  const data = sharedRef(null, getRefKey("dates"));
  const hasLoadedOnce = sharedRef(false, getRefKey("hasLoadedOnce"));
  const loading = sharedRef(false, getRefKey("loading"));
  const error = sharedRef(null, getRefKey("error"));

  /** Computed */
  const pagination = computed(() => data.value?.meta?.pagination || null);
  const meta = computed(() => data.value?.meta || null);
  const dates: ComputedRef<EventDate[]> = computed(
    () => data.value?.data || [],
  );
  const hasAnyDates = computed(() => dates.value.length > 0);
  const nonSoldOutDates = computed(() =>
    dates.value.filter((date) => !eventDateGetters.getDateIsSoldOut(date)),
  );
  const areAllDatesSoldOut = computed(() => nonSoldOutDates.value.length === 0);

  /** Wrapper for API requests */
  const { makeRequest } = useApiHandler(hasAccessToWindowObject);

  /**
   * METHODS
   * */

  /**
   * @description Format searchParams for the request query
   * @param searchParams
   **/
  const formatSearchParams = (
    searchParams: EventDateParamsSearch,
  ): string | null => {
    if (!searchParams) {
      return "";
    }

    const { id = [], dateType } = searchParams;
    const searchParamsArr = [];

    searchParamsArr.push(dateType ? `dateType:${dateType}` : "");
    searchParamsArr.push(id.length > 0 ? `id:${id.join(",")}` : "");

    const searchParam = searchParamsArr.filter(Boolean).join(";");

    return searchParam ? `search=${searchParam}` : "";
  };

  /**
   * @description Merges default and custom params to retrieve the request string parameters
   * @param customParams EventDateParams
   **/
  const getRequestParams = (customParams: EventDateParams): string => {
    const params: string[] = [];

    const mergedParams: EventDateParams = {
      ...DEFAULT_PARAMS,
      ...customDefaultParams,
      ...customParams,
    };

    const { page, search, include, limit } = mergedParams;

    const searchParam = formatSearchParams(search);
    const pageParam = page !== undefined ? `page=${page}` : null;
    const includeParam =
      include !== undefined ? `include=${include.join(",")}` : null;
    const limitParam = limit !== undefined ? `limit=${limit}` : null;

    if (pageParam) params.push(pageParam);
    if (searchParam) params.push(searchParam);
    if (includeParam) params.push(includeParam);
    if (limitParam) params.push(limitParam);

    return params.join("&");
  };

  const fetchNextPage = async (eventId, params: EventDateParams = {}) => {
    const currentPage = pagination.value?.current_page;
    const totalPages = pagination.value?.total_pages;

    if (!pagination.value || totalPages <= currentPage || loading.value) {
      return;
    }

    Logger.debug(
      `useEventDates/${refKey}/fetchNextPage`,
      `eventId: ${eventId}`,
    );

    loading.value = true;

    return makeRequest(
      "getEventAvailableDatesWithSoldOutAndExpired",
      eventId,
      getRequestParams({ ...params, page: currentPage + 1 }),
    )
      .then((newDates) => {
        data.value.data = [...data.value.data, ...newDates.data];
        data.value.meta = newDates.meta;

        error.value = null;
      })
      .catch((err) => {
        error.value = err?.response?.data || err;
        Logger.error(`useEventDates/${refKey}/fetchNextPage`, err);
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const fetchDates = (eventId, params: EventDateParams = {}) => {
    Logger.debug(`useEventDates/${refKey}/fetchDates`, eventId, params);

    loading.value = true;

    return makeRequest(
      "getEventAvailableDatesWithSoldOutAndExpired",
      eventId,
      getRequestParams(params),
    )
      .then((dates) => {
        data.value = dates;
        error.value = null;
        hasLoadedOnce.value = true;
      })
      .catch((err) => {
        error.value = err?.response?.data || err;
        Logger.error(`useEventDates/${refKey}/fetchDates`, err);
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const checkIfFetchedAtLeastOneOpenDate = async (eventId: string) => {
    // if all of the fetched dates are sold out, we want to fetch the next page
    // until we find at least one open date or get to the end of the list
    const shouldFetchNextPage = () => {
      const hasFetchedAllDates =
        paginationGetters.getTotal(pagination.value) <=
        paginationGetters.getCurrentPage(pagination.value);

      return (
        !hasFetchedAllDates && areAllDatesSoldOut.value && error.value === null
      );
    };

    for (let i = 0; i < 2 && shouldFetchNextPage(); i++) {
      data.value = {
        ...data.value,
        data:
          data.value?.data?.filter(
            (date: EventDate) => !eventDateGetters.getDateIsSoldOut(date),
          ) || [],
      };

      await fetchNextPage(eventId);
    }
  };

  return {
    loading: computed(() => loading.value),
    data: computed(() => paginationGetters.validatePagination(data.value)),
    hasLoadedOnce: computed(() => hasLoadedOnce.value),
    error: computed(() => error.value),

    /** Computed */
    dates,
    meta,
    pagination,
    hasAnyDates,
    areAllDatesSoldOut,
    nonSoldOutDates,

    /** Methods */
    fetchDates,
    fetchNextPage,
    checkIfFetchedAtLeastOneOpenDate,
  };
};
