<template>
  <li class='sf-list__item'>
    <!-- @slot -->
    <slot />
  </li>
</template>
<script>
export default {
  name: "SfListItem"
};
</script>
