const getId = (cityCategory): string => {
  return cityCategory?.id || null;
};

const getSlug = (cityCategory): string => {
  return cityCategory?.slug || null;
};

const getTitle = (cityCategory): string => {
  return cityCategory?.title || null;
};

const getSeoTitle = (cityCategory): string => {
  return cityCategory?.seo_title || null;
};

const getDescription = (cityCategory): string => {
  return cityCategory?.description || null;
};

const getSeoMetaDescription = (cityCategory): string => {
  return cityCategory?.seo_meta_description || null;
};

const getSubtitle = (cityCategory): string => {
  return cityCategory?.subtitle || null;
};

const getGiftboxTitle = (cityCategory): string => {
  return cityCategory?.title_gift_block || null;
};

const getGiftboxSubtitle = (cityCategory): string => {
  return cityCategory?.subtitle_gift_block || null;
};

const getImage = (cityCategory): string => {
  return cityCategory?.image?.data?.url || null;
};

const getCroppedImage = (cityCategory): string => {
  return cityCategory?.image?.data?.cropped || null;
};

const getImageAlt = (cityCategory): string => {
  return cityCategory?.image?.data?.alt || null;
};

const getCategory = (cityCategory): string => {
  return cityCategory?.category?.data || null;
};

const getLocality = (cityCategory): string => {
  return cityCategory?.locality?.data || null;
};

const getShowcaseTitle = (cityCategory): string => {
  return cityCategory?.showcase_title || null;
};

const getShowcase = (cityCategory): string => {
  return cityCategory?.showcase || null;
};

const getPermalink = (cityCategory): string => {
  return cityCategory?.permalink || null;
};

export const cityCategoryGetters = {
  getId,
  getSlug,
  getTitle,
  getDescription,
  getSeoMetaDescription,
  getSeoTitle,
  getSubtitle,
  getGiftboxTitle,
  getGiftboxSubtitle,
  getImage,
  getImageMobile: getImage,
  getCroppedImage,
  getImageAlt,
  getCategory,
  getLocality,
  getShowcaseTitle,
  getShowcase,
  getPermalink,
};
