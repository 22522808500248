
















































































import { computed, ref, watch } from "@vue/composition-api";
import { KftBadge, KftModal } from "@konfetti-ui/vue";
import { ContentLoader } from "vue-content-loader";

export default {
  name: "KftChip",

  components: {
    KftModal,
    KftBadge,
    ContentLoader,
  },

  props: {
    /** @param label The label showed in the chip  **/
    label: {
      type: String,
      default: "",
    },

    modalSize: {
      type: String,
      default: "lg",
    },

    /** @param modalTitle The title that should show in the selectable modal */
    modalTitle: {
      type: String,
      default: "",
    },

    /** @param size the size of the chip, can be sm, md or lg **/
    size: {
      type: String,
      default: "md",
    },

    /** @param customClasses Used to input classes in the chip **/
    customClasses: {
      type: String,
      default: "",
    },

    /** @param closable If the chip can have the close button **/
    closable: {
      type: Boolean,
      default: false,
    },

    /** @param backgroundColor Used to force the background color of the chip **/
    backgroundColor: {
      type: String,
      default: null,
    },

    /** @param color Used to force the color of the text **/
    color: {
      type: String,
      default: null,
    },

    /** @param stateful Used in select chip, means the options available. By default, we show the attribute 'label' **/
    stateful: {
      type: Boolean,
      default: false,
    },

    /** @param value Used to watch changes that comes from the parent components **/
    value: {
      type: Boolean,
      default: false,
    },

    /** @param selectable Used to open modal and show options to select, it emits an 'input' so it can be catched with v-model **/
    selectable: {
      type: Boolean,
      default: false,
    },

    /** @param selected Flags if an element is selected **/
    selected: {
      type: Boolean,
      default: false,
    },

    badgeNumber: {
      type: Number,
      default: 0,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    close: null,
    input: null,
  },

  setup(props, context) {
    /** VARIABLES
     ** --------------------- */
    const enabled = ref(false);
    const selectableModal = ref(null);

    /** COMPUTED VARIABLES
     ** --------------------- */
    const chipStyle = computed(() => {
      if (props.stateful) {
        return {};
      }

      return {
        color: props.color,
        backgroundColor: props.backgroundColor,
      };
    });

    const computedClasses = computed(() => {
      if (props.stateful) {
        return enabled.value ? "chip-stateful--enabled" : "";
      }

      if (props.selectable) {
        return props.selected ? "chip-selectable--selected" : "";
      }

      return "";
    });

    /** METHODS
     ** --------------------- */

    /** @name fnToggleState
     ** @desc When the application is stateful, it toggles the state value **/
    const fnToggleState = () => {
      enabled.value = !enabled.value;

      context.emit("input", enabled.value);
    };

    /** @name fnEmitNewValue
     ** @desc Emits the new stateful value **/
    const fnEmitNewValue = () => {
      context.emit("input");
    };

    /** @name fnHandleClick
     ** @desc Check props to decide which function should be called **/
    const fnHandleClick = async () => {
      if (props.stateful) {
        await fnToggleState();

        context.emit("input", enabled.value);
      }

      if (props.selectable && selectableModal.value) {
        selectableModal.value.show();
      }

      context.emit("pressed");
    };

    const fnShowModal = () => {
      if (!selectableModal?.value) {
        return;
      }

      selectableModal.value.show();
    };

    const fnHideModal = () => {
      if (!selectableModal?.value) {
        return;
      }

      selectableModal.value.hide();
    };

    /** HOOKS
     ** --------------------- */
    watch(
      () => props.value,
      () => {
        enabled.value = props.value;
      },
      { immediate: true },
    );

    return {
      enabled,
      selectableModal,
      chipStyle,
      computedClasses,

      fnShowModal,
      fnHideModal,
      fnHandleClick,
      fnToggleState,
      fnEmitNewValue,
    };
  },
};
