import { render, staticRenderFns } from "./LocaleSelector.vue?vue&type=template&id=430bbe2f&scoped=true&"
import script from "./LocaleSelector.vue?vue&type=script&lang=ts&"
export * from "./LocaleSelector.vue?vue&type=script&lang=ts&"
import style0 from "./LocaleSelector.vue?vue&type=style&index=0&id=430bbe2f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "430bbe2f",
  null
  
)

export default component.exports