<template>
  <div class="sf-bar">
    <div>
      <slot name="back">
        <KftButton
          v-if="back"
          :id="buttonId"
          aria-label="back"
          class="kft-button--pure sf-bar__icon font-size-6 close-icon"
          type="button"
          @click="$emit('click:back')"
        >
          <i class="bi bi-x"></i>
        </KftButton>
      </slot>
    </div>
    <div>
      <slot name="title" v-bind="{ title }">
        <div class="sf-bar__title">{{ title }}</div>
      </slot>
    </div>
    <div>
      <slot name="close">
        <KftButton
          v-if="close"
          aria-label="close"
          class="kft-button--pure sf-bar__icon"
          type="button"
          @click="$emit('click:close')"
        >
          <i class="bi bi-x"></i>
        </KftButton>
      </slot>
    </div>
  </div>
</template>
<script>
import KftButton from "../../atoms/KftButton/KftButton.vue";

export default {
  name: "SfBar",
  components: {
    KftButton
  },
  props: {
    buttonId: {
      type: String,
      default: "sf-bar-button"
    },
    title: {
      type: String,
      default: ""
    },
    back: {
      type: Boolean,
      default: false
    },
    close: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss">
@import "~@konfetti-ui/shared/styles/components/molecules/SfBar.scss";
</style>
