<template>
  <div
    class="kft-faq-container"
    itemscope
    itemtype="https://schema.org/FAQPage"
  >
    <!--begin::Accordion-->
    <div
      v-for="(section, i) in content"
      :key="`kft-faq-${i}`"
      class="kft-faq mt-12"
    >
      <KftHeading
        :level="2"
        :title="section.title"
        class="kft-heading--left kft-heading--no-underline title mb-4"
      />

      <div
        :id="`kft_accordion_${i}`"
        class="accordion accordion-icon-toggle kft-accordion"
      >
        <!--begin::Item-->
        <div
          v-for="(obj, j) in section.questions"
          :key="`kft-faq-question-${i}-${j}`"
          :name="`acc-${i}-${j}`"
          class="kft-accordion__item"
          itemprop="mainEntity"
          itemscope
          itemtype="https://schema.org/Question"
        >
          <!--begin::Header-->
          <div
            :data-bs-target="`#kft_accordion_${i}_item_${j}`"
            class="kft-accordion__item__header accordion-header d-flex"
            data-bs-toggle="collapse"
          >
            <KftHeading
              :level="6"
              :title="obj.question"
              class="kft-heading--left kft-heading--no-underline"
              itemprop="name"
            />
          </div>
          <!--end::Header-->

          <!--begin::Body-->
          <!--:data-bs-parent="`#kft_accordion_${i}`"-->
          <div
            :id="`kft_accordion_${i}_item_${j}`"
            class="collapse pt-8"
            :class="{ show: !$device.isMobile }"
            itemprop="acceptedAnswer"
            itemscope
            itemtype="https://schema.org/Answer"
          >
            <p itemprop="text">{{ obj.answer }}</p>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Item-->
      </div>
    </div>
    <!--end::Accordion-->
  </div>
</template>

<script>
import { KftHeading } from "@konfetti-ui/vue";

export default {
  name: "KftFaqComponent",
  components: {
    KftHeading,
  },
  transition: "fade",
  props: ["content"],
};
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/_accordion.scss";
.kft-accordion {
  &__item {
    border-bottom: 1px solid $gray-ultralight;
    padding: 2rem 0;

    &__header {
      cursor: pointer;
    }
  }
}
</style>
