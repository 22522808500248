import { Context } from '@konfetti-core/core';

export async function registerUser (context: Context, params: any): Promise<any> {
  const url = new URL('v1/store/customers', context.config.api.url);
  const { data } = await context.client.post(url.href, params);
  return data;
}

export async function login (context: Context, params: any): Promise<any> {
  const url = new URL('v1/clients/web/login', context.config.api.url);

  const { data } = await context.client.post(url.href, params);

  return data;
}

export async function getUser (context: Context): Promise<any> {
  const url = new URL('v1/user/profile?include=customer,referralCoupon', context.config.api.url);

  const { data } = await context.client.get(url.href);

  return data;
}

export async function existsByEmail (context: Context, params: any): Promise<any> {
  const url = new URL('v1/auth/accountlookup', context.config.api.url);

  try {
    const { data } = await context.client.post(url.href, params);
    return data;
  } catch (e) {
    const status = e.response?.status;

    if (status && status === 404) {
      return { status: 404, error: true };
    }

    throw e;
  }
}

export async function verifyRefreshToken (context: Context, params: any): Promise<any> {
  const url = new URL(`v1/clients/web/verify?refresh_token=${params.refreshToken}`, context.config.api.url);

  const { data } = await context.client.get(url.href);

  return data;
}

export async function resetPassword (context: Context, params: any): Promise<any> {
  const url = new URL('v1/password-reset', context.config.api.url);

  const { data } = await context.client.post(url.href, params);

  return data;
}

export async function sendPasswordResetEmail (context: Context, params: any): Promise<any> {
  const url = new URL('v1/password-forgot', context.config.api.url);

  const { data } = await context.client.post(url.href, params);

  return data;
}

export async function updateCustomer (context: Context, params: any): Promise<any> {
  const {
    id,
    form
  } = params;

  const url = new URL(`v1/store/customers/${id}`, context.config.api.url);

  const { data } = await context.client.patch(url.href, form);

  return data;
}

export async function updatePassword (context: Context, params: any): Promise<any> {
  const {
    id,
    form
  } = params;

  const url = new URL(`v1/users/${id}/password`, context.config.api.url);

  const { data } = await context.client.patch(url.href, form);

  return data;
}
