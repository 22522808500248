/* eslint-disable  */

export default {
  faqTeamEvents: {
    sections: [
      {
        title: 'Häufige Fragen zu unseren Team- und Firmenevents',
        questions: [
          {
            question: 'Was ist ein Teamevent?',
            answer: 
              'Bei einem Teamevent geht es darum, die Mitarbeitermotivation und den Teamgeist zu stärken. Team- und Firmenevents sorgen dafür, dass sich Kollegen untereinander besser kennenlernen und das Wir-Gefühl gestärkt wird. Beliebte Teamevents sind zum Beispiel Kochkurse, Cocktailkurse, Tastings, Escape Rooms oder Bootstouren.',
          },
          {
            question: 'Wie plane ich eine Firmenfeier?',
            answer:
              'Gern unterstützen wir Dich bei der Planung Deiner Firmenfeier. Du kannst bereits vorab einige wichtige Punkte klären: Ungefähre Anzahl der Teilnehmer, Euer Wunschtermin, in welcher Stadt die Firmenfeier stattfinden soll, evtl. hast Du bereits eine Vorstellung, nach was für einer Aktivität Du konkret suchst. Wir beraten Dich gerne.',
          },
          {
            question: 'Teamevent Ideen – Was kann man als Team unternehmen?',
            answer: 
              'Es gibt verschiedenste Aktivitäten, die sich für die nächste Firmenfeier eignen. Eine beliebte Teamevent Idee sind kulinarische Erlebnisse: Besucht zum Beispiel einen Cocktailkurs, Kochkurs oder ein Tasting. Wir organisieren auch kreative Workshops für Euch – beispielsweise ein Malkurs, Blumen binden oder Töpfern. Falls Ihr Lust auf ein Abenteuer habt, könnt Ihr als Teamevent einen Escape Room oder eine Kanutour buchen.',
          },
          {
            question: 'Wie kann ich einen Betriebsausflug buchen?',
            answer:
              'Fülle einfach das Formular oben auf dieser Seite aus. Nachdem Du dieses abgeschickt hast, kümmern wir uns so schnell wie möglich um Euer Event. Wir kommen dann entweder telefonisch oder per E-Mail auf Dich zu. Innerhalb von 24-72 Stunden liegt Dir ein unverbindliches Angebot vor.',
          },
          {
            question: 'Welche Zahlungsmöglichkeiten gibt es?',
            answer: 
              'Firmenevents kannst Du per Rechnung oder über alle gängigen Zahlungsmethoden auf gokonfetti.com bezahlen.',
          }, 
          {
            question: 'Wie viel kostet eine Firmenfeier?',
            answer: 
              'Der genaue Preis ist von dem Aktivität, der Location und der Teilnehmeranzahl abhängig. Wir bemühen uns, Dir das beste Angebot zu machen. Du kannst Dich an den Event-Seiten orientieren oder einfach eine Anfrage senden.',
          },
          {
            question: 'Gibt es eine Mindestteilnehmeranzahl für Teamevents?',
            answer:
              'Trage im Kontaktformular ein, wie viele Personen an Euren Firmenevent teilnehmen. Bei der Teilnehmeranzahl kommt es auf das jeweilige Event und die Eventlocation an. Wir organisieren exklusive Events für Gruppen von 8 bis 5.000 Personen. Stelle uns einfach eine Anfrage und wir schlagen Euch passende Aktivitäten vor.',
          },
          {
            question: 'Ich brauche noch heute ein Angebot oder möchte einen Kurs für morgen buchen - Kann ich Euch trotzdem anfragen?',
            answer: 
              'Gerne bearbeiten wir Deine Anfrage so schnell es geht und können das Angebot oft noch am selben Tag verschicken. Schicke uns auf jeden Fall Deine Telefonnummer, da wir so am schnellsten alle notwendigen Fragen klären können.',
          },
          {
            question: 'Kann ich mein privates Event stornieren?',
            answer: 'Die Stornierungsbedigungen findest Du in unseren AGB und auf Deinem Angebot.',
          },
        ],
      },
    ],
  },
};
