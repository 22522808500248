<template>
  <div v-if="elementsUsable">
    <slot :elements="elements" :instance="instance"></slot>
  </div>
</template>

<script>
import { createElements } from "./stripeElements";

export default {
  name: "StripeElements",

  props: {
    stripeKey: {
      type: String,
      required: true,
    },
    instance: {
      type: Object,
      default: () => ({}),
    },
    elementsOptions: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      elements: {},
    };
  },

  computed: {
    elementsUsable() {
      const elms = this.elements;
      if (!elms) return false;
      return Object.keys(elms).length > 0;
    },
  },

  mounted() {
    // Create elements
    this.elements = createElements(this.instance, this.elementsOptions);
  },
};
</script>
