import { IncomingMessage } from "http";
import { Context as NuxtContext } from "@nuxt/types";
import merge from "lodash-es/merge";
import { ApiClientMethod } from "./../../types";

interface CreateProxiedApiParams {
  givenApi: Record<string, ApiClientMethod>;
  client: any;
  tag: string;
}

const shouldReplaceUrl = (url: string) => {
  const hostIsIpAddress = url.match(
    /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}(\:\d{1,5})?$/
  );

  const domainLevel = (url.match(/\./g) || []).length + 1;
  if (!hostIsIpAddress && domainLevel > 1) {
    const subdomain = url.split(".")[0];
    if (
      subdomain !== "www" &&
      subdomain !== "front-hml" &&
      subdomain !== "gokonfetti"
    ) {
      return true;
    }
  }

  return false;
};

export const getBaseUrl = (req: IncomingMessage) => {
  if (!req) return "/api/";
  const { headers } = req;
  const isHttps = require("is-https")(req);
  const scheme = isHttps ? "https" : "http";
  let host = (headers["x-forwarded-host"] as string) || headers.host;

  if (shouldReplaceUrl(host)) {
    host = host.replace(/^[^.]+\./g, "");
  }

  return `${scheme}://${host}/api/`;
};

export const createProxiedApi = ({
  givenApi,
  client,
  tag
}: CreateProxiedApiParams) =>
  new Proxy(givenApi, {
    get: (target, prop, receiver) => {
      const functionName = String(prop);
      if (Reflect.has(target, functionName)) {
        return Reflect.get(target, prop, receiver);
      }

      return async (...args) =>
        client.post(`/${tag}/${functionName}`, args).then((r) => r.data);
    }
  });

export const getCookies = (context: NuxtContext) =>
  context?.req?.headers?.cookie ?? "";

export const getIntegrationConfig = (
  context: NuxtContext,
  configuration: any
) => {
  const cookie = getCookies(context);
  return merge(
    {
      axios: {
        baseURL: getBaseUrl(context?.req),
        headers: {
          ...(cookie ? { cookie } : {})
        }
      }
    },
    configuration
  );
};
