import { UseKftContext } from "@konfetti-core/core";
import { computed } from "@vue/composition-api";

export const useHasAccessToWindowObject = (): any => {
  const { route } = UseKftContext();
  const hasAccessToWindowObject = computed(
  () => route.name.split("___")[0] !== "embedded-booking",
  );

  return {
    hasAccessToWindowObject,
  };
};
