import { Context } from "@nuxt/types";
import { strapiEventListPageGetters } from "@konfetti/composables";
import { AxiosResponse } from "axios";
import { getFromStrapiApi } from "./asyncDataResources";

export enum PageType {
  CITY = "city",
  CATEGORY = "category",
  CITY_CATEGORY = "city-category",
  GENERAL = "general",
  TEAMEVENT = "teamevent",
}

interface EventListPagesAsyncResourceConfig {
  context: Context | any;
  slug?: string;
  citySlug?: string;
  categorySlug?: string;
  cityCategorySlug?: string;
  pageType: PageType;
}

export const setStrapiUrlParams = (
  url,
  pageType,
  {
    slug,
    citySlug,
    categorySlug,
  }: { slug?: string; citySlug?: string; categorySlug?: string },
  locale = null,
) => {
  url.searchParams.set("filters[type][$eq]", pageType);

  // If general, searchers by slug
  // If city, searches by slug
  // If category, searches by slug
  // If city-category, searches by citySlug and categorySlug
  // (not related to strapi fields, only to this function's arguments)
  switch (pageType) {
    case PageType.GENERAL:
    case PageType.TEAMEVENT:
      url.searchParams.set("filters[slug][$eq]", slug);
      break;
    case PageType.CITY:
      url.searchParams.set("filters[citySlug][$eq]", slug);
      url.searchParams.set("filters[categorySlug][$null]", "true");
      break;
    case PageType.CITY_CATEGORY:
      url.searchParams.set("filters[citySlug][$eq]", citySlug);
      url.searchParams.set("filters[categorySlug][$eq]", categorySlug);
      break;
    case PageType.CATEGORY:
      url.searchParams.set("filters[citySlug][$null]", "true");
      url.searchParams.set("filters[categorySlug][$eq]", slug);
      break;
  }

  url.searchParams.set("locale", locale || "all");
};

export const getStrapiRequestUrl = (host) => {
  return new URL(
    "event-list-pages?" +
      "populate[showcase][populate][0]=first.image" +
      "&populate[showcase][populate][1]=second.image" +
      "&populate[showcase][populate][2]=third.image" +
      "&populate[horizontalCardList][populate]=image" +
      "&populate[giftcardSection][populate]=*" +
      // TODO Get rid of description and other big fields
      // reason: localizations array will make the api responses
      // too big at some point, due to the large descriptions in each locale
      "&populate[localizations]=*" +
      "&populate[seo][populate]=*" +
      "&populate[searchFilters][populate]=*",
    host,
  );
};

export class EventListPagesAsyncResource {
  private context: Context | any;
  private pageType: PageType;
  private slug: string | undefined;
  private citySlug: string | undefined;
  private categorySlug: string | undefined;

  constructor({
    context,
    citySlug,
    categorySlug,
    slug,
    pageType,
  }: EventListPagesAsyncResourceConfig) {
    this.slug = slug;
    this.citySlug = citySlug;
    this.categorySlug = categorySlug;
    this.pageType = pageType;
    this.context = context;
  }

  public getData = () => {
    const { error } = this.context;

    return this.searchResource().catch(() => {
      error({
        statusCode: 404,
        message: "Error fetching this page...",
      });
    });
  };

  private mountResponseDataObj = (res: AxiosResponse, data) => {
    return {
      ...data,
    };
  };

  /* This should work for general, city and category pages
   * since they will be found by a single slug equality
   *
   * For searching city-category pages, use searchFromStrapiByCityCategorySlugs */
  private searchFromStrapiBySingleSlug = (slug, locale = null) => {
    const url = getStrapiRequestUrl(this.context.$config.strapiApiUrl);

    setStrapiUrlParams(url, this.pageType, { slug }, locale);

    return getFromStrapiApi(this.context, url);
  };

  private searchFromStrapiByCityCategorySlugs = (
    citySlug,
    categorySlug,
    locale = null,
  ) => {
    const url = getStrapiRequestUrl(this.context.$config.strapiApiUrl);
    setStrapiUrlParams(
      url,
      this.pageType,
      {
        citySlug,
        categorySlug,
      },
      locale,
    );

    return getFromStrapiApi(this.context, url);
  };

  private searchELPFromStrapi = (filterByLocale = false) => {
    const {
      app: {
        i18n: { locale },
      },
    } = this.context;

    let searchFunction;

    switch (this.pageType) {
      case PageType.CITY_CATEGORY:
        searchFunction = filterByLocale
          ? this.searchFromStrapiByCityCategorySlugs(
              this.citySlug,
              this.categorySlug,
              locale,
            )
          : this.searchFromStrapiByCityCategorySlugs(
              this.citySlug,
              this.categorySlug,
            );
        break;
      case PageType.GENERAL:
      case PageType.TEAMEVENT:
      case PageType.CITY:
      case PageType.CATEGORY:
        const slug = this.slug || this.citySlug || this.categorySlug;
        searchFunction = filterByLocale
          ? this.searchFromStrapiBySingleSlug(slug, locale)
          : this.searchFromStrapiBySingleSlug(slug);
        break;
    }

    return searchFunction;
  };

  private searchResource = () => {
    const { redirect, app, route } = this.context;

    return this.searchELPFromStrapi()
      .then((res: AxiosResponse) => {
        return res.data.data.length > 0
          ? res.data.data[0]
          : Promise.reject(new Error("Strapi entry not found"));
      })
      .then((res) => {
        const strapiEntryLocale = strapiEventListPageGetters.getLocale(res);

        if (strapiEntryLocale.toLowerCase() === app.i18n.locale) {
          return this.mountResponseDataObj(res, {
            eventListPageFromStrapi: res,
          });
        }

        const strapiEntryForCurrentLocalization =
          strapiEventListPageGetters.getLocalizationByLocale(
            res,
            app.i18n.locale,
          );

        if (strapiEntryForCurrentLocalization) {
          const correctUrl = `/${strapiEventListPageGetters
            .getLocale(strapiEntryForCurrentLocalization)
            ?.toLowerCase()}${strapiEventListPageGetters.getKonfettiPagePath(
            strapiEntryForCurrentLocalization,
          )}/`;

          if (route.fullPath !== correctUrl) {
            return redirect(301, correctUrl);
          } else {
            return res;
          }
        }

        return Promise.reject(
          new Error("Strapi entry does not correspond the current locale"),
        );
      });
  };
}
