/**
 * It extends given integartion, defined by `tag` in the context.
 */
export const createExtendIntegrationInCtx =
  ({ tag, nuxtCtx, inject }) =>
    (integrationProperties) => {
      const integrationKey = "$" + tag;

      if (!nuxtCtx.$kft || !nuxtCtx.$kft[integrationKey]) {
        inject("kft", { [integrationKey]: {} });
      }

      Object.keys(integrationProperties)
        .filter((k) => !["api", "client", "config"].includes(k))
        .forEach((key) => {
          nuxtCtx.$kft[integrationKey][key] = integrationProperties[key];
        });
    };

/**
 * It creates a function that adds an integration to the context under the given name, defined by `tag`.
 */
export const createAddIntegrationToCtx =
  ({ tag, nuxtCtx, inject }) =>
    (integrationProperties) => {
      const integrationKey = "$" + tag;

      if (nuxtCtx.$kft && !nuxtCtx.$kft[integrationKey]) {
        nuxtCtx.$kft[integrationKey] = integrationProperties;
        return;
      }

      inject("kft", { [integrationKey]: integrationProperties });
    };
