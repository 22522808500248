


























































































import { computed } from "@nuxtjs/composition-api";
import { strapiImageGetters } from "@konfetti/composables";
import {
  KftGenericGallery,
  SfImage,
  GalleryVideoPlayer,
} from "@konfetti-ui/vue";

import { KftBannerWithSearch } from "~/components";
import { createImagePreloadLinks } from "~/utilities/createImagePreloadLinks";

export default {
  name: "KftBannerGallery",
  components: {
    KftBannerWithSearch,
    KftGenericGallery,
    GalleryVideoPlayer,
    SfImage,
  },
  props: {
    homePageSingleType: {
      type: Object,
      required: true,
    },
  },
  setup(props, { root: { $device } }) {
    const mainMediaBlock = computed(
      () => props.homePageSingleType?.data?.attributes?.banner?.mainMediaBlock,
    );
    const bannerMainMediaDesktopSrc = computed(() =>
      strapiImageGetters.getImageUrl(
        mainMediaBlock.value?.media?.data,
        "large",
      ),
    );

    const bannerMainMediaMobileSrc = computed(() =>
      strapiImageGetters.getImageUrl(
        mainMediaBlock.value?.mediaMobile?.data,
        "small",
      ),
    );

    const bannerMainMedia = computed(() => {
      const mainMediaBlock =
        props.homePageSingleType?.data?.attributes?.banner?.mainMediaBlock;

      return {
        src:
          ($device.isMobile &&
            strapiImageGetters.getImageUrl(
              mainMediaBlock?.mediaMobile?.data,
              "medium",
            )) ||
          strapiImageGetters.getImageUrl(mainMediaBlock?.media?.data, "large"),
        color: mainMediaBlock?.color || "#373A40",
        title: mainMediaBlock?.title || "",
        subtitle: mainMediaBlock?.subtitle || "",
        alt: strapiImageGetters.getAlternativeText(mainMediaBlock?.media?.data),
      };
    });

    const bannerSecondaryMedia = computed(() => {
      const secondaryMediaBlocks =
        props.homePageSingleType?.data?.attributes?.banner
          ?.secondaryMediaBlocks;

      if (!secondaryMediaBlocks) {
        return [];
      }

      return secondaryMediaBlocks
        ?.filter((mediaBlock) =>
          mediaBlock.media?.data?.attributes?.mime.includes("image"),
        )
        .map((mediaBlock) => ({
          src:
            ($device.isMobile &&
              strapiImageGetters.getImageUrl(mediaBlock?.mediaMobile?.data)) ||
            strapiImageGetters.getImageUrl(mediaBlock?.media?.data),
          text: mediaBlock.title || "",
          url: mediaBlock.url || "",
          alt: strapiImageGetters.getAlternativeText(mediaBlock.media?.data),
        }));
    });

    const bannerSecondaryMediaVideo = computed(() => {
      const secondaryMediaBlocks =
        props.homePageSingleType?.data?.attributes?.banner
          ?.secondaryMediaBlocks;

      if (!secondaryMediaBlocks) {
        return null;
      }

      const videoBlock = secondaryMediaBlocks?.find((mediaBlock) =>
        mediaBlock.media?.data?.attributes?.mime?.includes("video"),
      );

      if (!videoBlock) {
        return null;
      }

      return {
        src: videoBlock.media?.data?.attributes?.url,
        text: videoBlock.title || "",
        url: videoBlock.url || "",
        alt: videoBlock.media?.data?.attributes?.alternativeText || "",
      };
    });

    return {
      bannerMainMedia,
      bannerSecondaryMedia,
      bannerSecondaryMediaVideo,
      bannerMainMediaDesktopSrc,
      bannerMainMediaMobileSrc,
    };
  },
  head() {
    return {
      link: [
        ...createImagePreloadLinks(this.bannerMainMediaMobileSrc, {
          media: "(max-width: 576px)",
        }),
        ...createImagePreloadLinks(this.bannerMainMediaDesktopSrc, {
          media: "(min-width: 577px)",
        }),
      ],
    };
  },
};
