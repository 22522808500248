


































































































































































































































import { computed, ref } from "@vue/composition-api";
import {
  eventDateGetters,
  EventDateType,
  eventGetters,
} from "@konfetti/composables";
import { ContentLoader } from "vue-content-loader";
import { KftTooltip } from "@konfetti-ui/vue";
import KftMultiselect from "../../organisms/KftMultiselect/KftMultiselect";
import KftSubscribeToEventUpdates from "../../organisms/KftSubscribeToEventUpdates/KftSubscribeToEventUpdates";
import KftBuyBoxDateOption from "../../molecules/KftBuyBoxDateOption/KftBuyBoxDateOption.vue";
import KftBuyBoxMultiselectAfterList from "../../molecules/KftBuyBox/KftBuyBoxMultiselectAfterList/KftBuyBoxMultiselectAfterList";
import KftBuyBoxNoDates from "../../molecules/KftBuyBox/KftBuyBoxNoDates/KftBuyBoxNoDates.vue";
import KftGiftcardBuyBox from "../../organisms/KftGiftcardBuyBox/KftGiftcardBuyBox.vue";
import { fnConvertLocaleIntoBCP47, formatMoney } from "~/helpers";
import { useBooking, useUiState, useEvent } from "~/composables";
import { formatDate, formatTime } from "~/helpers/dateHelpers";

export default {
  name: "KftBuyBox",

  components: {
    KftBuyBoxDateOption,
    KftTooltip,
    Multiselect: KftMultiselect,
    KftSubscribeToEventUpdates,
    KftBuyBoxMultiselectAfterList,
    KftBuyBoxNoDates,
    KftGiftcardBuyBox,
    ContentLoader,
  },

  props: {
    sharedKey: {
      type: String,
      required: true,
    },
    loadingMoreDates: {
      type: Boolean,
      default: false,
    },
    enableGiftcard: {
      type: Boolean,
      default: false,
    },
    dates: {
      type: Array,
      default: () => [],
    },
    datesPagination: {
      type: Object,
      default: () => {},
    },
    kftEvent: {
      type: Object,
      required: true,
      default: () => {},
    },
    type: {
      type: String,
      default: EventDateType.PUBLIC,
    },
    enableEmbedded: {
      type: Boolean,
      default: false,
    },
    enableSubdomain: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "",
    },
  },

  emits: {
    book: null,
    gift: null,
  },

  setup(props, { emit, root: { $device, $i18n } }) {
    /** Variables
     ** ----------------------------------- */
    const subscribeToEventUpdatesRef = ref(null);
    const triedBooking = ref(false);
    const datesMultiselectRef = ref(null);
    const bookButtonRef = ref(null);
    const multiselectCmp = ref(null);
    const ticketsMultiselectRef = ref(null);
    const prevScrollTop = ref(0);

    const {
      /* Variables */
      selectedTime,
      selectedTicketQuantity,
      selectedGiftcardQuantity,
      availableTickets,

      /* Methods */
      onSelectTime,
      prepareEventForBooking,
      setNextTimeAvailable,
      getEventPrice,
    } = useBooking(props.sharedKey);

    /** Methods
     ** ----------------------------------- */

    const getDateLabelHtml = (date) => {
      const dateStr = formatDate(date.start, $i18n.locale);
      const startTimeStr = formatTime(date.start);
      const endTimeStr = formatTime(date.end);

      return `<strong>${dateStr}</strong>, ${startTimeStr} - ${endTimeStr}`;
    };

    const { computedEventData } = useEvent("event-page");
    const { toggleDateRequestSidebar, toggleDateWaitlistSidebar } =
      useUiState(multiselectCmp);
    const setNextAvailableDate = async (
      options: {
        shouldNotSetTicketsQuantity: boolean;
      } = { shouldSetTicketsQuantity: true },
    ) => {
      const defaultOptions = {
        shouldSetTicketsQuantity: true,
      };

      const { shouldSetTicketsQuantity } = {
        ...defaultOptions,
        ...options,
      };

      await setNextTimeAvailable(props.dates);

      if (!shouldSetTicketsQuantity) {
        selectedTicketQuantity.value = null;
      }
    };

    const book = () => {
      triedBooking.value = true;

      if (selectedTicketQuantity.value === null) {
        return;
      }

      emit(
        "book",
        prepareEventForBooking(
          props.kftEvent,
          props.type === EventDateType.PRIVATE,
        ),
      );
    };

    const handleOpenMultiselect = () => {
      emit("open-multiselect");

      if ($device.isMobile) {
        datesMultiselectRef?.value?.scrollIntoView?.({
          behavior: "smooth",
          block: "center",
        });
      }
    };

    const handleScrollDatesMultiselect = ({ scrollTop, scrollHeight }) => {
      const scrollThreshold = 30;
      const scrollDirectionDown = scrollTop > prevScrollTop.value;
      prevScrollTop.value = scrollTop;

      // We only want to emit the event when the user is scrolling down
      // and wnen the scroll position is close to the bottom of the list
      if (
        scrollDirectionDown &&
        scrollHeight - scrollTop <= scrollHeight - scrollThreshold
      ) {
        emit("scrolling-down");
      }
    };

    const handleSelectTime = (value) => {
      onSelectTime(value);

      if ($device.isMobile) {
        datesMultiselectRef?.value?.scrollIntoView?.({
          behavior: "smooth",
          block: "center",
        });
      }
    };

    /** Computed
     * ----------------------------- */
    const price = computed(() => {
      if (!props.kftEvent) {
        return "-";
      }

      const p = getEventPrice(props.kftEvent, true);
      let qty = 1;
      if (props.type === EventDateType.PRIVATE) {
        qty =
          eventDateGetters.getPrivateMinTickets(selectedTime.value) >
          selectedTicketQuantity.value
            ? eventDateGetters.getPrivateMinTickets(selectedTime.value)
            : selectedTicketQuantity.value;
      }

      return formatMoney(
        (p * qty) / 100,
        fnConvertLocaleIntoBCP47($i18n.locale),
        "EUR",
        2,
      );
    });

    const hasAvailableDates = computed(() => {
      if (!props.dates?.length || props.dates.length === 0) {
        return false;
      }

      return props.dates.some((date) => {
        return eventDateGetters.getDateIsOpen(date);
      });
    });

    const isLastPage = computed(() => {
      return (
        props.datesPagination.current_page === props.datesPagination.total_pages
      );
    });

    const datesWithOrder = computed(() => {
      if (!props.dates?.length || props.dates.length === 0) {
        return [];
      }

      return props.dates.map((x, i) => ({
        ...x,
        $isDisabled: !eventDateGetters.getDateIsOpen(x),
        order: i + 1,
      }));
    });

    const childrenHtmlArr = computed(
      () =>
        selectedTime.value?.children?.data?.map((x) => getDateLabelHtml(x)) ||
        [],
    );

    const showBuyBoxNoDates = computed(
      () =>
        !hasAvailableDates.value &&
        !eventGetters.isGiftcardEvent(props.kftEvent.data) &&
        eventGetters.isDateRequestOrWaitingActive(
          computedEventData.value,
          props.enableSubdomain,
        ),
    );

    return {
      locale: $i18n.locale,
      eventGetters,
      subscribeToEventUpdatesRef,

      hasAvailableDates,
      selectedTime,
      selectedTicketQuantity,
      selectedGiftcardQuantity,
      availableTickets,
      price,
      datesWithOrder,
      childrenHtmlArr,
      isLastPage,
      EventDateType,
      triedBooking,
      datesMultiselectRef,
      multiselectCmp,
      ticketsMultiselectRef,
      bookButtonRef,
      computedEventData,
      showBuyBoxNoDates,

      handleOpenMultiselect,
      handleScrollDatesMultiselect,
      getDateLabelHtml,
      setNextAvailableDate,
      toggleDateRequestSidebar,
      onSelectTime,
      handleSelectTime,
      book,
      formatDate,
      formatTime,
      toggleDateWaitlistSidebar,
    };
  },

  computed: {
    tooltipText() {
      return this.$t(
        !this.hasAvailableDates
          ? "product.show.checkout.info.giftcard"
          : this.type === EventDateType.PUBLIC
          ? "product.show.checkout.info.publicEvent"
          : "product.show.checkout.info.privateEvent",
        {
          price: this.price,
        },
      );
    },
    cssVars() {
      return {
        "--embedded-btn-background-color": "#" + this.backgroundColor,
        "--embedded-btn-color": "#" + this.textColor,
      };
    },
  },
};
