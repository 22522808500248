<template>
  <div
    class="w-100 multiselect__option__content"
    :class="{ 'multiselect__option__content--active': multiDateIsOpen }"
  >
    <div class="d-flex justify-content-between w-100">
      <div class="d-flex">
        <div class="option__desc" v-html="fnGetDateLabelHtml(optionData)"></div>
        <!-- @todo: component for labeling availability -->
        <span
          v-if="optionData.status === 'SOLD_OUT'"
          class="multiselect__option-label"
          >{{ $t("product.status.soldOut") }}
        </span>
        <span
          v-else-if="optionData.status === 'EXPIRED'"
          class="multiselect__option-label"
        >
          {{ $t("product.status.expired") }}
        </span>
        <span
          v-else-if="
            optionData.availableTicketsQuantity <=
              optionData.totalTicketsSold ||
            optionData.availableTicketsQuantity <= 3
          "
          class="multiselect__option-label few-tickets-left"
        >
          {{ $t("product.status.fewTicketsLeft") }}
        </span>
        <span v-else />
      </div>

      <span
        v-if="showCollapseButton && !alwaysShowMultidate && hasChildren"
        class="fs-6"
        style="color: #dadada"
        @click.stop="multiDateIsOpen = !multiDateIsOpen"
        ><i
          :class="{
            'bi-plus-circle-fill': !multiDateIsOpen,
            'bi-dash-circle-fill': multiDateIsOpen,
          }"
          class="bi"
        ></i
      ></span>
    </div>
    <transition mode="out-in" name="sf-collapse-top">
      <div v-if="computedShowChildrenDates" class="mt-2">
        <KftBulletPoints :items-html-arr="childrenHtmlArr" />
      </div>
    </transition>
  </div>
</template>

<script>
import { multiselectMixin } from "vue-multiselect";
import { formatDate, formatTime } from "~/helpers/dateHelpers";
import { useEventDateHelpers } from "~/composables";
import { KftBulletPoints } from "~/components";

export default {
  name: "KftBuyBoxDateOption",
  components: { KftBulletPoints },
  mixins: [multiselectMixin],

  props: {
    optionData: {
      type: Object,
      required: true,
    },
    alwaysShowMultidate: {
      type: Boolean,
      default: false,
    },
    forceShowMultidate: {
      type: Boolean,
      default: false,
    },
    showCollapseButton: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const { fnGetChildrenHtmlArr, fnGetDateLabelHtml } = useEventDateHelpers();
    return {
      childrenHtmlArr: fnGetChildrenHtmlArr(props.optionData),
      fnGetDateLabelHtml,
    };
  },

  data() {
    return {
      multiDateIsOpen:
        this.optionData.children?.data?.length > 0 &&
        this.optionData.order === 1,
      formatDate,
      formatTime,
    };
  },

  computed: {
    hasChildren() {
      return this.optionData?.children?.data?.length > 0;
    },
    isSoldOut() {
      return this.optionData.status === "SOLD_OUT";
    },
    computedShowChildrenDates() {
      return (
        (!this.isSoldOut &&
          (this.alwaysShowMultidate || this.forceShowMultidate) &&
          this.hasChildren) ||
        this.multiDateIsOpen
      );
    },
  },
};
</script>
