// https://github.com/unjs/ufo

const TRAILING_SLASH_RE = /\/$|\/\?/;

export function hasTrailingSlash(input = "", queryParams = false): boolean {
  if (!queryParams) {
    return input.endsWith("/");
  }
  return TRAILING_SLASH_RE.test(input);
}

export function withTrailingSlash(input = "", queryParams = false): string {
  if (!queryParams) {
    return input.endsWith("/") ? input : input + "/";
  }
  if (hasTrailingSlash(input, true)) {
    return input || "/";
  }
  const [s0, ...s] = input.split("?");
  return s0 + "/" + (s.length ? `?${s.join("?")}` : "");
}
