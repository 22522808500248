<template>
  <div class="kft-alert">
    <div v-if="$slots.default" class="kft-alert__wrapper">
      <slot></slot>
    </div>

    <div v-else class="kft-alert__wrapper d-flex align-items-center">
      <slot v-if="$slots.icon" name="icon"></slot>
      <div class="kft-alert__content-wrapper">
        <div>
          <div v-if="title" class="ms-3 kft-alert__title">{{ title }}</div>
          <div v-if="content" class="ms-3 kft-alert__content">
            {{ content }}
          </div>
        </div>
        <div v-if="closeCard" class="kft-alert__close-alert">
          <span @click="$emit('close')">+</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "KftAlert",
  props: {
    /**
     * Card title
     */
    title: {
      type: String,
      default: null,
    },

    /**
     * Card content
     */
    content: {
      type: String,
      default: null,
    },

    /**
     * Card close
     */
    closeCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
@import "~/assets/components/molecules/KftAlert.scss";
</style>
