import { cityGetters } from "../cityGetters";

enum eventListPageType {
  city,
  category,
  "city-category",
  general,
}

const getId = (eventListPage): string => {
  return cityGetters.getId(eventListPage);
};

const getSlug = (eventListPage): string => {
  return cityGetters.getSlug(eventListPage);
};

const getTitle = (eventListPage): string => {
  return cityGetters.getTitle(eventListPage);
};

const getDescription = (eventListPage): string => {
  return cityGetters.getDescription(eventListPage);
};

const getSearchQuery = (eventListPage): string => {
  return null;
};

const getSearchQueryObj = (eventListPage): any => {
  return null;
};

const getType = (eventListPage): eventListPageType => {
  return eventListPageType.city;
};

const getSeoTitle = (eventListPage): string => {
  return cityGetters.getSeoTitle(eventListPage);
};

const getSeoImageUrl = (eventListPage, format = null): string => {
  return cityGetters.getImage(eventListPage);
};

const getSeoMetaDescription = (eventListPage): string => {
  return cityGetters.getSeoMetaDescription(eventListPage);
};

const getCreatedAt = (eventListPage): string => {
  return null;
};

const getShowcase = (eventListPage): any => {
  return cityGetters.getShowcase(eventListPage);
};

const getShowcaseTitle = (eventListPage): string => {
  return cityGetters.getShowcaseTitle(eventListPage);
};

const getHorizontalCardListTitle = (eventListPage): string => {
  return "";
};

const getHorizontalCardListLink = (eventListPage): string => {
  return "";
};

const getHorizontalCardListLinkText = (eventListPage): string => {
  return "";
};

const getHorizontalCardList = (eventListPage): any[] => {
  return [];
};

const getGiftcardSection = (eventListPage): any => {
  return { title: cityGetters.getGiftboxTitle(eventListPage), subtitle: cityGetters.getGiftboxSubtitle(eventListPage) };

};
const getGiftcardSectionTitle = (eventListPage): string => {
  return cityGetters.getGiftboxTitle(eventListPage);
};

const getGiftcardSectionSectionTitle = (eventListPage): string => {
  return "";
};
const getGiftcardSectionSubtitle = (eventListPage): string => {
  return cityGetters.getGiftboxSubtitle(eventListPage);
};

const getCitySlug = (eventListPage): string => {
  return cityGetters.getSlug(eventListPage);
};

const getCategorySlug = (eventListPage): string => {
  return null;
};


const getLocale = (eventListPage): string => {
  return null;
};

const getSearchHeading = (eventListPage): string => {
  return null;
};

const getLocalizations = (eventListPage): any[] => {
  return null;
};

const getLocalizationByLocale = (eventListPage, locale): any => {
  return null;
};


export const strapiCityMigrationEventListPageGetters = {
  getId,
  getSlug,
  getCitySlug,
  getCategorySlug,
  getTitle,
  getDescription,
  getSearchQuery,
  getSearchQueryObj,
  getType,
  getSeoTitle,
  getSeoImageUrl,
  getSeoMetaDescription,
  getCreatedAt,
  getShowcase,
  getShowcaseTitle,
  getHorizontalCardList,
  getGiftcardSection,
  getGiftcardSectionTitle,
  getGiftcardSectionSectionTitle,
  getGiftcardSectionSubtitle,
  getHorizontalCardListTitle,
  getHorizontalCardListLink,
  getHorizontalCardListLinkText,
  getLocale,
  getSearchHeading,
  getLocalizations,
  getLocalizationByLocale,
};
