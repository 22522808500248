import { EventDate, EventDateType, EventDescription } from "../types";
import { eventDateGetters, eventGetters } from "../getters";
import { Ref } from "@vue/composition-api";

const parseEventToCartItem = (
  event: EventDescription,
  selectedTicketQuantity: any = null,
  selectedTime: Ref<EventDate> = null,
  formattedTime = "",
  isPrivate = false,
) => {
  return {
    type: "EVENT",
    id: selectedTime.value.id,
    children: selectedTime?.value?.children || [],
    quantity: selectedTicketQuantity?.value || null,
    eventDescriptionId: eventGetters.getId(event),
    title: eventGetters.getTitle(event),
    category: eventGetters.getMainCategory(event),
    permalink: eventGetters.getPermalink(event),
    thumbnail: eventGetters.getFirstThumb(event),
    requiredAddress: eventGetters.getIsAddressRequired(event) || false,
    price: eventDateGetters.getPrice(selectedTime.value) || null,
    availableTicketsQuantity:
      eventDateGetters.getAvailableTicketsQuantity(selectedTime.value) || null,
    privateMinTickets:
      eventDateGetters.getPrivateMinTickets(selectedTime.value) || null,
    privateMaxTickets:
      eventDateGetters.getPrivateMaxTickets(selectedTime.value) || null,
    dateType: eventDateGetters.getDateType(selectedTime.value) || null,
    date: eventDateGetters.getStartTime(selectedTime.value) || null,
    startTime: eventDateGetters.getStartTime(selectedTime.value) || null,
    endTime: eventDateGetters.getEndTime(selectedTime.value) || null,
    formattedTime: formattedTime || "",
    private:
      isPrivate ||
      eventDateGetters.getIsPrivate(selectedTime.value, true) ||
      false,
    dateTypeToBeBooked: isPrivate
      ? EventDateType.PRIVATE
      : EventDateType.PUBLIC,
  };
};

export default parseEventToCartItem;
