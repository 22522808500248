import { strapiImageGetters } from "./strapiImageGetters";
import {
  StrapiArticle,
  StrapiImage,
  StrapiSEO,
  StrapiArticleCategory,
  StrapiFaq,
} from "../types/index";

const getId = (article: StrapiArticle): string => {
  return article?.id || null;
};

const getSlug = (article: StrapiArticle): string => {
  return article?.attributes?.slug || null;
};

const getHeaderImage = (article: StrapiArticle): StrapiImage => {
  return article?.attributes?.header_image?.data || null;
};

const getHeaderImageUrl = (article: StrapiArticle, format = null): string => {
  return (
    strapiImageGetters.getImageUrl(getHeaderImage(article), format) || null
  );
};

const getHeaderImageAlt = (article: StrapiArticle): string => {
  return strapiImageGetters.getAlternativeText(getHeaderImage(article)) || "";
};

const getTitle = (article: StrapiArticle): string => {
  return article?.attributes?.title || null;
};

const getContent = (article: StrapiArticle): string => {
  return article?.attributes?.content || null;
};

const getDescription = (article: StrapiArticle): string => {
  return article?.attributes?.description || null;
};

const getSeoTitle = (article: StrapiArticle): string => {
  return article?.attributes?.seo?.metaTitle || null;
};

const getSeoImageUrl = (article: StrapiArticle, format = null): string => {
  return (
    strapiImageGetters.getImageUrl(
      article?.attributes?.seo?.metaImage?.data,
      format,
    ) || null
  );
};

const getSeoMetaDescription = (article: StrapiArticle): string => {
  return article?.attributes?.seo?.metaDescription || null;
};

const getCreatedAt = (article: StrapiArticle): string => {
  return article?.attributes?.createdAt || null;
};

const getUpdatedAt = (article: StrapiArticle): string => {
  return article?.attributes?.updatedAt || null;
};

const getAuthor = (article: StrapiArticle): string => {
  if (article?.attributes?.external_author_name) {
    return article?.attributes?.external_author_name;
  }

  const adminUserAttributes =
    article?.attributes?.admin_user?.data?.attributes || "";
  return (
    `${adminUserAttributes?.firstname || ""} ${
      adminUserAttributes?.lastname || ""
    }` || ""
  );
};

const getAuthorUrl = (article: StrapiArticle): string => {
  return article?.attributes?.author_url || null;
};

const getSummaryList = (article: StrapiArticle): any => {
  return article?.attributes?.summary_list || null;
};

const getCategories = (article: StrapiArticle): StrapiArticleCategory[] => {
  return article?.attributes?.categories?.data || null;
};

const getFirstCategory = (article: StrapiArticle): StrapiArticleCategory => {
  const categoriesArr = getCategories(article);
  return categoriesArr && categoriesArr.length > 0 ? categoriesArr[0] : null;
};

const getEventPermalink = (article: StrapiArticle): string => {
  return article?.attributes?.event_permalink || null;
};

const getSupplierId = (article: StrapiArticle): string => {
  return article?.attributes?.supplier_id || null;
};
const getFaq = (article: StrapiArticle): StrapiFaq => {
  return article?.attributes?.faq || null;
};

const getArticleShowcaseArticles = (
  article: StrapiArticle,
): StrapiArticle[] => {
  return article?.attributes?.article_showcase?.articles?.data || null;
};

const getArticleShowcaseSectionTitle = (article: StrapiArticle): string => {
  return article?.attributes?.article_showcase?.section_title || null;
};
const getAlertText = (article: StrapiArticle): string => {
  return article?.attributes?.alert_text || null;
};

export const articleGetters = {
  getId,
  getSlug,
  getHeaderImage,
  getHeaderImageUrl,
  getHeaderImageAlt,
  getTitle,
  getContent,
  getDescription,
  getSeoTitle,
  getSeoImageUrl,
  getSeoMetaDescription,
  getAuthor,
  getAuthorUrl,
  getCreatedAt,
  getUpdatedAt,
  getSummaryList,
  getCategories,
  getFirstCategory,
  getEventPermalink,
  getSupplierId,
  getFaq,
  getArticleShowcaseArticles,
  getArticleShowcaseSectionTitle,
  getAlertText,
};
