/* eslint-disable  */

export default {
  Categories: 'Categories',
  Filters: 'Filter',
  'Sort by': 'Sort by',
  'Products found': 'Products found',
  'About us': 'About us',
  'Who we are': 'Who we are',
  'Quality in the details': 'Quality in the details',
  'Customer Reviews': 'Customer Reviews',
  Departments: 'Departments',
  'Women fashion': 'Women fashion',
  'Men fashion': 'Men fashion',
  Kidswear: 'Kidswear',
  Home: 'Home',
  Help: 'Help',
  'Customer service': 'Customer service',
  'Size guide': 'Size guide',
  'Contact us': 'Contact us',
  'Payment & Delivery': 'Payment & Delivery',
  'Purchase terms': 'Purchase terms',
  Guarantee: 'Guarantee',
  Description: 'Description',
  'Read reviews': 'Read reviews',
  'Additional Information': 'Additional Information',
  'Add to compare': 'Add to compare',
  'Match it with': 'Match it with',
  'Share your look': 'Share your look',
  'Product description': `The Karissa V-Neck Tee features a semi-fitted shape that's flattering for every figure. You can hit the gym with confidence while it hugs curves and hides common "problem" areas. Find stunning women's cocktail dresses and party dresses.`,
  Brand: 'Brand',
  Instruction1: 'Take care of me',
  Instruction2: 'Just here for the care instructions?',
  Instruction3: 'Yeah, we thought so',
  Items: 'Items',
  View: 'View',
  'Show on page': 'Show on page',
  Done: 'Done',
  'Clear all': 'Clear all',
  Empty:
    'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.',
  'Help & FAQs': 'Help & FAQs',
  Download: 'Download our application.',
  'Find out more': 'Find out more',
  Login: 'Login',
  'Forgotten password?': 'Forgotten password?',
  'No account': `Don't have an account yet?`,
  'Register today': 'Register today',
  'Go to checkout': 'Go to checkout',
  'Go back shopping': 'Go back shopping',
  'Personal details': 'Personal details',
  Edit: 'Edit',
  'Shipping details': 'Shipping details',
  'Billing address': 'Billing address',
  'Same as shipping address': 'Same as shipping address',
  'Payment method': 'Payment method',
  Apply: 'Apply',
  'Update password': 'Update password',
  'Update personal data': 'Update personal data',
  Item: 'Item',
  'Go back': 'Go back',
  'Continue to shipping': 'Continue to shipping',
  'I agree to': 'I agree to',
  'Terms and conditions': 'Terms and conditions',
  'Pay for order': 'Pay for order',
  'Log into your account': 'Log into your account',
  'or fill the details below': 'or fill the details below',
  'Enjoy your free account': 'Enjoy these perks with your free account!',
  'Continue to payment': 'Continue to payment',
  'Order No.': 'Order No.',
  'Successful placed order':
    'You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.',
  'Info after order':
    'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, check history of transactions, edit subscription to newsletter.',
  'Allow order notifications': 'Allow order notifications',
  Feedback:
    'Your feedback is important to us. Let us know what we could improve.',
  'Send my feedback': 'Send my feedback',
  'Go back to shop': 'Go back to shop',
  'Read all reviews': 'Read all reviews',
  Color: 'Color',
  'Contact details updated': 'Keep your addresses and contact details updated.',
  'Manage billing addresses':
    'Manage all the billing addresses you want (work place, home address...) This way you won"t have to enter the billing address manually with each order.',
  Change: 'Change',
  Delete: 'Delete',
  'Add new address': 'Add new address',
  'Set up newsletter':
    'Set up your newsletter and we will send you information about new products and trends from the sections you selected every week.',
  'Sections that interest you': 'Sections that interest you',
  'Save changes': 'Save changes',
  'Read and understand': 'I have read and understand the',
  Privacy: 'Privacy',
  'Cookies Policy': 'Cookies Policy',
  'Commercial information':
    'and agree to receive personalized commercial information from Brand name by email',
  'Feel free to edit':
    'Feel free to edit any of your details below so your account is always up to date',
  'Use your personal data':
    'At Brand name, we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the',
  'Privacy Policy': 'Privacy Policy',
  'Change password your account':
    'If you want to change the password to access your account, enter the following information',
  'Your current email address is': 'Your current email address is',
  Product: 'Product',
  'Details and status orders':
    'Check the details and status of your orders in the online store. You can also cancel your order or request a return.',
  'You currently have no orders': 'You currently have no orders',
  'Start shopping': 'Start shopping',
  'Download all': 'Download all',
  'View details': 'View details',
  'Manage shipping addresses':
    'Manage all the shipping addresses you want (work place, home address...) This way you won"t have to enter the shipping address manually with each order.',
  Quantity: 'Quantity',
  Price: 'Price',
  'Back to homepage': 'Back to homepage',
  'Select shipping method': 'Select shipping method',
  'Review my order': 'Review my order',
  'Select payment method': 'Select payment method',
  'Make an order': 'Make an order',
  or: 'or',
  'login in to your account': 'login in to your account',
  'Create an account': 'Create an account',
  'Your bag is empty': 'Your bag is empty',
};
