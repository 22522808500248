<template>
  <SfFooter :column="3" class="footer" multiple>
    <SfFooterColumn :title="$t('footer.aboutUs')">
      <SfList>
        <SfListItem v-for="(item, i) in aboutUs" :key="i">
          <SfMenuItem :label="item.name" :link="item.url" class="footer-item" />
        </SfListItem>
      </SfList>
    </SfFooterColumn>
    <SfFooterColumn :title="$t('footer.help')">
      <SfList>
        <SfListItem v-for="(item, i) in help" :key="i">
          <SfMenuItem :label="item.name" :link="item.url" class="footer-item" />
        </SfListItem>
      </SfList>
    </SfFooterColumn>
    <SfFooterColumn :title="$t('footer.workWithUs')">
      <SfList>
        <SfListItem v-for="(item, i) in workWithUs" :key="i">
          <SfMenuItem :label="item.name" :link="item.url" class="footer-item" />
        </SfListItem>
        <SfListItem>
          <SfMenuItem class="footer-item p-0">
            <template #label>
              <a
                :href="$t('general.contactPhoneAction')"
                class="sf-menu-item__label"
              >
                <i class="bi bi-phone"></i>
                {{ $t("general.contactPhone") }}
              </a>
            </template>
          </SfMenuItem>
        </SfListItem>
      </SfList>
    </SfFooterColumn>
    <SfFooterColumn :title="$t('footer.paymentMethods')">
      <div class="footer__payment-methods">
        <SfImage
          v-for="item in paymentMethods"
          :key="item.slug"
          :alt="item.alt"
          :src="`/media/svg/card-logos/${item.slug}.svg`"
          class="footer__payment-methods me-2"
          height="30"
          width="auto"
        />
      </div>
    </SfFooterColumn>
    <SfFooterColumn :title="$t('footer.social')">
      <div class="footer__socials">
        <a
          v-for="item in socialMedia"
          :key="item.name"
          :href="item.url"
          target="_blank"
        >
          <SfImage
            :alt="item.name"
            :src="'/icons/' + item.name + '.svg'"
            class="footer__social-image me-2"
            height="32"
            width="32"
          />
        </a>
      </div>
    </SfFooterColumn>
    <SfFooterColumn
      v-if="renderLocaleSelector"
      :title="$t('general.language')"
      class="footer__locale-select"
    >
      <div>
        <LocaleSelector />
      </div>
    </SfFooterColumn>
  </SfFooter>
</template>

<script>
import {
  SfFooter,
  SfImage,
  SfList,
  SfMenuItem,
  LocaleSelector,
} from "@konfetti-ui/vue";

export default {
  components: {
    SfFooter,
    SfList,
    SfImage,
    SfMenuItem,
    LocaleSelector,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      aboutUs: [
        {
          name: this.$t("footer.events.one.title"),
          url: this.$t("footer.events.one.link"),
        },
        {
          name: this.$t("footer.events.two.title"),
          url: this.$t("footer.events.two.link"),
        },
        {
          name: this.$t("footer.events.three.title"),
          url: this.$t("footer.events.three.link"),
        },
        {
          name: this.$t("footer.events.four.title"),
          url: this.$t("footer.events.four.link"),
        },
        {
          name: this.$t("footer.events.five.title"),
          url: this.$t("footer.events.five.link"),
        },
        {
          name: this.$t("footer.events.six.title"),
          url: this.$t("footer.events.six.link"),
        },
        {
          name: this.$t("footer.events.seven.title"),
          url: this.$t("footer.events.seven.link"),
        },
        {
          name: this.$t("footer.events.eight.title"),
          url: this.$t("footer.events.eight.link"),
        },
        {
          name: this.$t("footer.events.nine.title"),
          url: this.$t("footer.events.nine.link"),
        },
        {
          name: this.$t("footer.events.ten.title"),
          url: this.$t("footer.events.ten.link"),
        },
      ],
      help: [
        {
          name: this.$t("footer.links.faq"),
          url: this.getUrlFromRouteName("faq"),
        },
        {
          name: this.$t("footer.links.termsAndConditions"),
          url: this.getUrlFromRouteName("terms-and-conditions"),
        },
        {
          name: this.$t("footer.links.dataProtection"),
          url: this.$t("footer.links.dataProtectionLink"),
        },
        {
          name: this.$t("footer.links.cookie"),
          url: this.$t("footer.links.cookieLink"),
        },
        {
          name: this.$t("footer.links.impressum"),
          url: this.getUrlFromRouteName("impressum"),
        },
        {
          name: this.$t("footer.links.sitemap"),
          url: this.getUrlFromRouteName("sitemap"),
        },
        {
          name: this.$t("footer.links.magazine"),
          url: this.getUrlFromRouteName("magazine"),
        },
      ],
      workWithUs: [
        {
          name: this.$t("footer.links.backOfficeName"),
          url: this.$t("footer.links.backOfficeLink"),
        },
        {
          name: this.$t("footer.links.termsSupplier"),
          url: this.$t("footer.links.termsSupplierLink"),
        },
        {
          name: this.$t("footer.links.partner"),
          url: this.getUrlFromRouteName("partner"),
        },
        {
          name: this.$t("footer.links.pressName"),
          url: this.$t("footer.links.pressLink"),
        },
        {
          name: this.$t("footer.links.teamevents"),
          url: this.getUrlFromRouteName("teamevent"),
        },
        {
          name: this.$t("footer.links.carrers"),
          url: "https://join.com/companies/gokonfetti",
        },
      ],
      socialMedia: [
        {
          name: "facebook",
          url: this.$t("footer.socialMedia.links.facebook"),
        },
        {
          name: "linkedin",
          url: this.$t("footer.socialMedia.links.linkedin"),
        },
        {
          name: "instagram",
          url: this.$t("footer.socialMedia.links.instagram"),
        },
      ],
      paymentMethods: [
        {
          alt: "Mastercard",
          slug: "mastercard",
        },
        {
          alt: "Visa",
          slug: "visa",
        },
        {
          alt: "Sofort",
          slug: "sofort",
        },
        {
          alt: "Paypal",
          slug: "paypal",
        },
      ],
      desktopMin: 1024,
    };
  },
  computed: {
    renderLocaleSelector() {
      return !this.$route?.name?.includes("magazine-pages");
    },
  },

  methods: {
    getUrlFromRouteName(route) {
      const url = this.$router
        .getRoutes()
        .find(({ name }) => name === `${route}___${this.$i18n.locale}`);
      if (url === undefined) {
        return "#";
      }

      return url.path;
    },
  },
};
</script>

<style lang="scss">
.footer {
  margin-bottom: 3.75rem;
  background: $footer-background-color !important;
  @include for-desktop {
    margin-bottom: 0;
  }

  &__payment-methods {
    justify-content: flex-start;
    display: flex;
  }

  &__socials {
    display: flex;
    justify-content: flex-start;
    margin: 0 auto var(--spacer-8);
    @include for-desktop {
      margin: 0 auto;
    }
  }

  &__social-image {
    margin: 0 var(--spacer-1) 0 0;
  }

  &__locale-select {
    display: none;
    @include for-desktop {
      display: block;
    }
  }
}

.sf-footer {
  @include for-desktop {
    border-top: none;
    margin-top: var(--spacer-20);
  }

  &__container {
    margin: var(--spacer-base);
    @include for-desktop {
      margin: 0 auto;
    }
  }
}
</style>
