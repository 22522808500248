<template>
  <div class="kft-order-by-container">
    <KftButton
      class="kft-button--pure kft-order-by-button d-flex py-1 position-relative"
      @click="toggleOrderByDropdown"
    >
      <i class="bi bi-sort-alpha-down" />
      <span class="px-2">
        {{
          orderBy && orderBy.label
            ? orderBy.label
            : $t("general.pagination.orderBy")
        }}
      </span>
      <i class="bi bi-chevron-down"></i>
    </KftButton>
    <div
      class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 py-2 w-auto w-215px"
      :class="showDropDown ? 'show' : ''"
    >
      <div
        v-for="option in orderByOptions"
        :key="`order-by-option-${option.label}`"
        class="menu-item px-5"
        @click="
          toggleOrderByDropdown();
          $emit('selectOrderBy', option);
        "
      >
        <span class="menu-title position-relative">
          {{ option.label }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "@vue/composition-api";
import { KftButton } from "@konfetti-ui/vue";

export default {
  name: "SearchResultsSortOrderMenu",
  components: {
    KftButton,
  },
  props: {
    orderByOptions: {
      type: Array,
      required: true,
    },
    orderBy: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const showDropDown = ref(false);
    const toggleOrderByDropdown = () => {
      showDropDown.value = !showDropDown.value;
    };

    return {
      toggleOrderByDropdown,
      showDropDown,
    };
  },
};
</script>
<style scoped>
.kft-order-by-container {
  margin-left: auto;
  position: relative;
}
.menu-sub-dropdown.show {
  z-index: 105;
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  top: 2rem;
  left: -4rem;
  min-width: 200px;
}
.menu-item {
  cursor: pointer;
  padding: 0.5rem 1.25rem;
  border-bottom: 1px solid #e0e0e0;
}
.menu-item:last-child {
  border-bottom: none;
}
</style>
