<template>
  <SfFooter :column="4" class="footer" multiple>
    <SfFooterColumn :title="$t('footer.poweredBy')">
      <!-- Konfetti Logo -->
      <a
        :href="
          supplier
            ? $fullLocalizedUrl(`/p/${supplier.data.permalink}`)
            : $fullLocalizedUrl('/')
        "
        class="kft-header__logo"
        data-cy="app-header-url_logo"
      >
        <img
          :alt="$t('general.titles.home') + $t('general.titles.base')"
          height="22.75"
          src="/logos/full-logo-color-black.svg"
          width="135"
        />
      </a>
      <!-- Konfetti Logo -->
    </SfFooterColumn>
    <SfFooterColumn :title="$t('footer.contact.title')">
      <div class="footer__payment-methods">
        <SfList style="--list-margin: 0; padding: 0">
          <SfListItem
            v-for="(item, i) in supplierContactInfo"
            :key="i"
            class="m-2"
            style="--list-item-margin: 0; padding: 0"
          >
            {{ item }}
          </SfListItem>
        </SfList>
      </div>
    </SfFooterColumn>
    <SfFooterColumn :title="$t('footer.help')">
      <SfList>
        <SfListItem v-for="(item, i) in help" :key="i" class="p-0 m-2">
          <SfMenuItem :label="item.name" :link="item.url" class="footer-item" />
        </SfListItem>
      </SfList>
    </SfFooterColumn>
    <SfFooterColumn :title="$t('footer.paymentMethods')">
      <div class="footer__payment-methods">
        <SfImage
          v-for="item in paymentMethods"
          :key="item.slug"
          :alt="item.alt"
          :src="`/media/svg/card-logos/${item.slug}.svg`"
          class="footer__payment-methods pe-1"
          height="30"
          width="auto"
        />
      </div>
    </SfFooterColumn>
  </SfFooter>
</template>

<script>
import { SfFooter, SfImage, SfList, SfMenuItem } from "@konfetti-ui/vue";
import { computed } from "@vue/composition-api";

export default {
  name: "SupplierFooter",
  components: {
    SfFooter,
    SfList,
    SfImage,
    SfMenuItem,
  },
  props: {
    supplier: {
      type: Object,
      default: () => ({
        data: {
          phone: "",
          address: "",
          email: "",
          permalink: "",
        },
      }),
    },
  },

  setup(props) {
    const supplierContactInfo = computed(() => [
      props.supplier?.data?.phone || "",
      props.supplier?.data?.email || "",
      props.supplier?.data?.address || "",
    ]);

    return {
      supplierContactInfo,
    };
  },

  data() {
    return {
      help: [
        {
          name: this.$t("footer.links.termsAndConditions"),
          url: this.getUrlFromRouteName("terms-and-conditions"),
        },
        {
          name: this.$t("footer.links.dataProtection"),
          url: this.$t("footer.links.dataProtectionLink"),
        },
      ],
      paymentMethods: [
        {
          alt: "Mastercard",
          slug: "mastercard",
        },
        {
          alt: "Visa",
          slug: "visa",
        },
        {
          alt: "Sofort",
          slug: "sofort",
        },
        {
          alt: "Paypal",
          slug: "paypal",
        },
      ],
      isMobile: false,
      desktopMin: 1024,
    };
  },

  methods: {
    getUrlFromRouteName(route) {
      const url = this.$router
        .getRoutes()
        .find(({ name }) => name === `${route}___${this.$i18n.locale}`);
      if (url === undefined) {
        return "#";
      }

      return url.path;
    },
  },
};
</script>

<style lang="scss">
.footer {
  margin-bottom: 3.75rem;
  background: $footer-background-color !important;
  @include for-desktop {
    margin-bottom: 0;
  }

  &__payment-methods {
    justify-content: flex-start;
    display: flex;
  }

  &__socials {
    display: flex;
    justify-content: flex-start;
    margin: 0 auto var(--spacer-8);
    @include for-desktop {
      margin: 0 auto;
    }
  }

  &__social-image {
    margin: 0 var(--spacer-1) 0 0;
  }
}

.sf-footer {
  @include for-desktop {
    border-top: none;
    margin-top: var(--spacer-20);
  }

  &__container {
    margin: var(--spacer-base);
    @include for-desktop {
      margin: 0 auto;
    }
  }
}
</style>
