






import { onMounted, ref } from "@vue/composition-api";
import noUiSlider from "nouislider";
import wNumb from "~/helpers/wNumb.js";

export default {
  name: "KftRangeSlider",

  props: {
    options: {
      type: Object,
      default: () => ({
        start: [0, 1200],
        connect: true,
        tooltips: [
          /* @todo merge & make it standard with currencyHelpers in the future */
          wNumb({
            decimals: 0,
            suffix: " €",
          }),
          wNumb({
            decimals: 0,
            suffix: " €",
          }),
        ],
        // step: 25,
        range: {
          min: [0],
          "40%": [100],
          "60%": [250],
          "80%": [600],
          "90%": [800],
          max: [1200],
        },
      }),
    },
  },

  emits: {
    change: null,
  },

  setup: (props, { emit }) => {
    const slider = ref(null);
    const kftRangeSliderRef = ref(null);
    const sliderValue = ref(props.options.start);

    const fnInitSlider = () => {
      slider.value = noUiSlider.create(kftRangeSliderRef.value, props.options);

      slider.value.on("update", (values, handle) => {
        sliderValue.value = values.map((value) => parseInt(value));

        emit("input", sliderValue.value);
      });
    };

    const fnSetValues = (values) => {
      slider.value.set(values);
    };
    const resetValues = (values) => {
      slider.value.reset();
    };

    onMounted(() => {
      fnInitSlider();
    });

    return {
      kftRangeSliderRef,
      sliderValue,

      fnSetValues,
      resetValues,
    };
  },
};
