import type { Ref } from "@vue/composition-api";

const sameValueAs = (
  form: Ref<any>,
  key: string,
  confirmKey: string,
): boolean => {
  return form.value[key] === form.value[confirmKey];
};

export default sameValueAs;
