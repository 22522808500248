// @todo add to env
// @todo use the correct formats for old browsers in getThumbnail
const THUMBNAIL_WIDTH = 200;
const MEDIA_BASE_URL = "https://media.gokonfetti.com";

const getId = (category): string => {
  return category?.id || null;
};

const getName = (category): string => {
  return category?.name || "";
};

const getDescription = (category): string => {
  return category?.description || "";
};

const getSlug = (category): string => {
  return category?.slug || null;
};

const getTitle = (category): string => {
  return category?.title || null;
};

const getSeoTitle = (category): string => {
  return category?.seo_title || null;
};

const getSeoMetaDescription = (category): string => {
  return category?.seo_meta_description || null;
};

const getSubtitle = (category): string => {
  return category?.subtitle || null;
};

const getGiftBlockTitle = (category): string => {
  return category?.title_gift_block || null;
};

const getGiftBlockSubtitle = (category): string => {
  return category?.subtitle_gift_block || null;
};

const getImage = (category): string => {
  // which size should it be
  // if (category?.image?.data?.id) {
  //   return `${MEDIA_BASE_URL}/${category?.image?.data?.id}/${THUMBNAIL_WIDTH}x0/${category?.image?.data?.filename}.webp`;
  // }
  return category?.image?.data?.url || null;
};

const getThumbnail = (category): string => {
  if (category?.thumbnail?.data?.id) {
    return `${MEDIA_BASE_URL}/${category?.thumbnail?.data?.id}/${THUMBNAIL_WIDTH}x0/${category?.thumbnail?.data?.filename}.webp`;
  }

  return category?.thumbnail?.data?.url || getImage(category) || null;
};

const getImageDesktop = (category): string => {
  return category?.image?.data?.desktop?.url || getImage(category);
};

const getImageMobile = (category): string => {
  return category?.image?.data?.mobile?.url || getImageDesktop(category);
};

const getShowcaseTitle = (category): string => {
  return category?.showcase_title || "";
};

const getShowcase = (category): string => {
  return category?.showcase || null;
};

export const categoryGetters = {
  getId,
  getName,
  getDescription,
  getThumbnail,
  getSlug,
  getTitle,
  getSeoMetaDescription,
  getSeoTitle,
  getSubtitle,
  getGiftBlockTitle,
  getGiftBlockSubtitle,
  getImage,
  getImageDesktop,
  getImageMobile,
  getShowcaseTitle,
  getShowcase,
};
