import { Logger, sharedRef } from "@konfetti-core/core";
import { computed } from "@vue/composition-api";
import { paginationGetters } from "../getters";
import { useApiHandler } from "../useApiHandler";

export const useLocalityCategory = (id: string): any => {
  const localityCategories = sharedRef(
    null,
    `useLocalityCategory-localityCategories-${id}`,
  );
  const loading = sharedRef(false, `useLocalityCategory-loading-${id}`);
  const error = sharedRef(null, `useCustom-error-${id}`);

  /** Wrapper for API requests */
  const { makeRequest } = useApiHandler();

  const clearLocalityCategories = () => {
    localityCategories.value = false;
  };

  // Method to call an API endpoint and update `localityCategories`, `loading` and `error` properties
  const loadLocalityCategories = async (params: string) => {
    Logger.debug(`useLocalityCategory/${id}/loadLocalityCategories`, params);

    try {
      loading.value = true;
      localityCategories.value = await makeRequest(
        "getLocalityCategory",
        params,
      );

      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useLocalityCategory/${id}/loadLocalityCategories`, err);
    } finally {
      loading.value = false;
    }
  };

  return {
    // Variables
    localityCategories: computed(() =>
      paginationGetters.validatePagination(localityCategories.value),
    ),
    loading: computed(() => loading.value),
    error: computed(() => error.value),

    // Methods
    loadLocalityCategories,
    clearLocalityCategories,
  };
};
