<!-- This is a copy of Nuxt/Image package -->
<!-- Please check their Docs for props usage -->
<!-- Some modifiers are not implemented -->
<template>
  <picture class="kft-picture" :key="nSources[0].src">
    <source
      v-if="nSources[1] && nSources[1].srcset"
      :type="nSources[1].type"
      :srcset="shouldRenderSrcs ? nSources[1].srcset : null"
      :sizes="nSources[1].sizes"
    />
    <img
      v-bind="{ ...nImgAttrs, ...imgAttrs }"
      :src="shouldRenderSrcs ? nSources[0].src : null"
      :srcset="shouldRenderSrcs ? nSources[0].srcset : null"
      :sizes="nSources[0].sizes"
      v-on="$listeners"
    />
  </picture>
</template>

<script>
import { getFileExtension } from "../../../utilities/helpers";
import { imageMixin, screens } from "../../../mixins/image";

export default {
  name: "KftPicture",

  mixins: [imageMixin],

  props: {
    legacyFormat: {
      type: String,
      default: null,
    },
    imgAttrs: {
      type: Object,
      default: null,
    },
    shouldLoadOnSsr: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    shouldRenderSrcs() {
      return process.client || this.shouldLoadOnSsr;
    },
    isTransparent() {
      return ["png", "webp", "gif"].includes(this.originalFormat);
    },
    originalFormat() {
      return getFileExtension(this.src);
    },
    nFormat() {
      if (this.format) {
        return this.format;
      }
      if (this.originalFormat === "svg") {
        return "svg";
      }
      return "webp";
    },
    nLegacyFormat() {
      if (this.legacyFormat) {
        return this.legacyFormat;
      }
      const formats = {
        webp: this.isTransparent ? "png" : "jpeg",
        svg: "png",
      };
      return formats[this.nFormat] || this.originalFormat;
    },
    nSources() {
      if (this.nFormat === "svg") {
        return [
          {
            srcset: this.src,
          },
        ];
      }
      const formats =
        this.nLegacyFormat !== this.nFormat
          ? [this.nLegacyFormat, this.nFormat]
          : [this.nFormat];
      return formats.map((format) => {
        const { srcset, sizes, src } = this.getSizes(this.src, {
          ...this.nOptions,
          sizes: this.sizes || screens,
          modifiers: {
            ...this.nModifiers,
            format,
          },
        });
        return {
          src,
          type: `image/${format}`,
          sizes,
          srcset,
        };
      });
    },
    created() {
      if (process.server && process.static) {
        // Force compute sources into ssrContext
        // eslint-disable-next-line no-unused-expressions
        this.nSources;
      }
    },
  },
};
</script>
