<template functional>
  <component
    :is="props.link ? injections.components.KftLink : 'button'"
    :openNewTab="props.openNewTab"
    v-bind="data.attrs"
    v-on="listeners"
    :class="[
      data.class,
      data.staticClass,
      'kft-button',
      {
        'is-disabled--button': $options.buttonActive(
          props.link,
          props.disabled,
        ),
        'is-disabled--link': $options.linkActive(props.link, props.disabled),
      },
    ]"
    :disabled="props.disabled"
    :link="props.link"
    :style="[data.style, data.staticStyle]"
  >
    <span class="loader" v-if="showSpinnerLeft"></span>
    <!--@slot Use this slot to place content inside the button.-->
    <slot />
  </component>
</template>
<script>
import { focus } from "../../../utilities/directives";
import KftLink from "../KftLink/KftLink.vue";

export default {
  name: "KftButton",
  inject: {
    components: {
      default: { KftLink },
    },
  },
  directives: {
    focus,
  },
  props: {
    /**
     * Native button disabled attribute
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Link for "a" tag, when empty it is button.
     */
    link: {
      type: [String, Object],
      default: "",
    },

    /**
     * Target Link for "a" tag, when empty it is _self.
     */
    openNewTab: {
      type: Boolean,
      default: false,
    },

    showSpinnerLeft: {
      type: Boolean,
      default: true,
    },
  },
  linkActive(link, disabled) {
    return link && disabled;
  },
  buttonActive(link, disabled) {
    return !link && disabled;
  },
};
</script>
<style lang="scss" scoped>
.kft-button {
  background: transparent;
  border: none;
  color: black;
}

.loader {
  width: 24px;
  height: 24px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
