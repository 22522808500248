import { nextTick } from "@nuxtjs/composition-api";
import { sharedRef, UseKftContext } from "@konfetti-core/core";

export interface KftNotification {
  title?: string;
  message: string;
  type: string;
  htmlContent?: string;
}

export enum KftNotificationType {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

export const useNotifications = () => {
  const { $scrollToTop } = UseKftContext();
  const notifications = sharedRef<KftNotification[]>(
    [],
    "useNotifications-notifications",
  );

  const removeNotification = (id) => {
    if (id > -1) {
      notifications.value.splice(id, 1);
    }
  };

  const addNotification = async (
    notification: KftNotification,
    { shouldScrollToTop }: { shouldScrollToTop?: boolean } = {
      shouldScrollToTop: true,
    },
  ) => {
    const newIdx = notifications.value.length;
    notifications.value.push(notification);
    if (shouldScrollToTop) {
      await $scrollToTop();
    }

    await nextTick();

    return () => removeNotification(newIdx);
  };

  const handleCloseNotification = () => {
    // should remove the first item of the array
    notifications.value.shift();
  };

  return {
    notifications,
    addNotification,
    handleCloseNotification,
  };
};
