


























































import { ref } from "@vue/composition-api";

export default {
  name: "KftHorizontalScrollWrapper",
  props: {
    noMargin: {
      type: Boolean,
      default: false,
    },
    itemsLength: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    /* This is so we can track scrolling  */
    const scrollableContainerRef = ref(null);
    const shadowContainerScrollLeft = ref(0);
    const dragged = ref(false);
    const dragTimeout = ref(null);
    /**
     ** @name fnHandleShadowScroll
     ** @desc The function handles scrolling on a shadowed container
     ** @param e Scroll event
     **/
    const fnHandleShadowScroll = (e) => {
      shadowContainerScrollLeft.value = e.target.scrollLeft;
    };

    const onDragStart = () => {
      clearTimeout(dragTimeout.value);

      dragged.value = false;
      dragTimeout.value = setTimeout(() => {
        dragged.value = true;
      }, 100);
    };

    const onDragClick = (e) => {
      if (dragged.value) {
        e.preventDefault();
      }

      dragged.value = false;
    };

    return {
      scrollableContainerRef,
      shadowContainerScrollLeft,
      fnHandleShadowScroll,
      onDragClick,
      onDragStart,
    };
  },
};
