import { computed, ref, Ref } from "@vue/composition-api";
import { Logger, sharedRef } from "@konfetti-core/core";
import { useApiHandler } from "../useApiHandler";

const PERSONAL_DETAILS_EMPTY = {
  firstName: "",
  lastName: "",
  companyName: "",
  email: "",
  password: "",
};

const SHIPPING_EMPTY = {
  first_name: "",
  // eslint-disable-next-line camelcase
  last_name: "",
  // eslint-disable-next-line camelcase
  street: "",
  // eslint-disable-next-line camelcase
  street_number: "",
  // eslint-disable-next-line camelcase
  additional_information: "",
  zipcode: "",
  locality: "",
  country: "DE",
};

const INVOICE_EMPTY = {
  first_name: "",
  // eslint-disable-next-line camelcase
  last_name: "",
  // eslint-disable-next-line camelcase
  company_name: "",
  street: "",
  // eslint-disable-next-line camelcase
  street_number: "",
  zipcode: "",
  locality: "",
  country: "DE",
  vat: "",
};

// @todo what's the necessity of this composable? how can it be improved?
export const useCheckout = (): any => {
  const loading = ref(false);
  const error = ref(null);
  const promoCode: Ref<string> = ref("");
  const personalDetails = ref(PERSONAL_DETAILS_EMPTY);
  const shippingDetails = ref(SHIPPING_EMPTY);
  const invoiceDetails = ref(INVOICE_EMPTY);

  /** Shipping Costs */
  const shippingCosts = sharedRef(null, "useCheckout-shipping-costs");
  const loadingShippingCosts = sharedRef(
    false,
    "useCheckout-loading-shipping-costs",
  );

  /** Wrapper for API requests */
  const { makeRequest } = useApiHandler();

  const fnLoadShippingCosts = async (params) => {
    Logger.debug("useCheckout/shippingCosts", params);
    try {
      loadingShippingCosts.value = true;
      shippingCosts.value = await makeRequest("getShippingCosts", params);
      error.value = null;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error("useCheckout/shippingCosts", err);
    } finally {
      loadingShippingCosts.value = false;
    }
  };

  return {
    /* Variables */
    personalDetails,
    shippingDetails,
    invoiceDetails,
    promoCode,
    shippingCosts,

    /* Control Variables */

    /* Methods */
    loading: computed(() => loading.value),
    loadingShippingCosts: computed(() => loadingShippingCosts.value),
    error: computed(() => error.value),
    fnLoadShippingCosts,
  };
};
