import { Logger } from "@konfetti-core/core";
import { ref, computed } from "@vue/composition-api";
import { useApiHandler } from "../useApiHandler";

export const useCache = (id: string): any => {
  const loading = ref(false);
  const error = ref(null);
  const { cacheSetKey, cacheGetKey } = useApiHandler();

  const getKey = async (params: string) => {
    Logger.debug(`useCache/${id}/getKey`, params);

    try {
      loading.value = true;
      const results = await cacheGetKey(params);
      error.value = null;
      return results;
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useCache/${id}/getKey`, err);
    } finally {
      loading.value = false;
    }
  };

  const setKey = async (key: string, value: object) => {
    Logger.debug(`useCache/${id}/setKey`, key);
    try {
      loading.value = true;
      await cacheSetKey(key, JSON.stringify(value));
    } catch (err) {
      error.value = err?.response?.data || err;
      Logger.error(`useCache/${id}/setKey`, err);
    } finally {
      loading.value = false;
    }
  };

  return {
    // Variables
    loading: computed(() => loading.value),
    error: computed(() => error.value),

    // Methods
    getKey,
    setKey,
  };
};
