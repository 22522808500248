import algoliasearch from "algoliasearch/lite";
import { sharedRef, UseKftContext } from "@konfetti-core/core";
import { computed, watchEffect } from "@nuxtjs/composition-api";
import type { SearchResponse } from "@algolia/client-search";
import { useCache } from "@konfetti/composables";
import { hashString, uuid4 } from "~/helpers/cryptoHelpers";

export const ALGOLIA_USER_TOKEN_KEY = "konfetti-algolia-tracking-user-token";
export const HITS_PER_SHOWCASE = 10;
export const HITS_PER_PAGINATION = 50;

export const useAlgoliaCore = (id: string) => {
  const { getKey, setKey } = useCache();
  const context = UseKftContext();
  const indexName = sharedRef(null, `useAlgoliaClient/indexName-${id}`);
  const client = sharedRef(null, `useAlgoliaClient/client-${id}`);
  const token = sharedRef(null, `useAlgoliaClient/token-${id}`);
  const searchIndex = sharedRef(null, `useAlgoliaClient/searchIndex-${id}`);
  const isLoaded = computed(() => client.value !== null);
  const areTokensLoaded = computed(() => token.value !== null);

  const defaultSearchParams = computed(() => ({
    userToken: token.value,
    hitsPerPage: HITS_PER_SHOWCASE,
    aroundLatLngViaIP: process.client,
    aroundRadius: "all",
  }));

  /**
   * @name fnSetIndex
   * @desc Change searchIndex name
   *
   * @param name The name of the searchIndex to be pointed to
   *  */
  const fnSetIndex = (name) => {
    indexName.value = name;
    searchIndex.value = client.value.initIndex(name);
  };

  /**
   * @name fnLoadUserTokens
   * @desc Gets the user token from the LocalStorage, if not available, creates one
   * */
  const fnLoadUserTokens = () => {
    if (areTokensLoaded.value || !window?.localStorage) {
      return;
    }

    token.value = window?.localStorage?.getItem(ALGOLIA_USER_TOKEN_KEY);
    if (!token.value || token.value === "undefined") {
      token.value = uuid4();
    }

    const storageUser = window?.localStorage?.getItem("user");
    if (storageUser) {
      const user = JSON.parse(storageUser);
      token.value = user?.data?.email
        ? hashString(user.data.email)
        : token.value;
    }

    window?.localStorage?.setItem(ALGOLIA_USER_TOKEN_KEY, token.value);
  };

  /**
   * @name fnInit
   * @desc Initializes an algolia search client
   *
   * @param name the index that searchIndex will be initiated
   * */
  const fnInit = (name: string = null) => {
    /*
     * We want to load userTokens whenever in client side
     * We might have initiated the algolia client before but since it
     * is ssr, we don't get/set algolia tokens with localStorage
     */
    if (process.client && !areTokensLoaded.value) {
      fnLoadUserTokens();
    }

    /* If it was already loaded the client and with the same index set,
     * we avoid initializing again.
     *
     * We want to load it again if it's client-side and the index is different,
     * and if initIndex is not set anymore (in some cases it is not available,
     * not sure about the reason)
     */
    if (isLoaded.value && indexName.value === name && client.value.initIndex) {
      return;
    }

    client.value = algoliasearch(
      context.$config.algolia.appId,
      context.$config.algolia.apiClientKey,
    );

    if (!name || name === "") {
      return;
    }

    fnSetIndex(name);
  };

  const fnCopyParamsForCache = (params) => {
    const copy = { ...params };
    if (!params.aroundLatLngViaIP && !params.enablePeronalization) {
      copy.userToken = null;
    }

    return copy;
  };

  const tryCache = async (cacheKey: string) => {
    const cacheResults = await getKey(cacheKey);

    if (cacheResults !== null && cacheResults !== "") {
      return cacheResults;
    }
  };

  /**
   * @name fnPerformSearch
   * @desc Perform a general algolia search based on the given params
   * */
  const fnPerformSearch = async (options: {
    customParams?: any;
    query?: "";
    shouldCacheResult?: false;
  }): Promise<SearchResponse> => {
    const defaultOptions = {
      customParams: undefined,
      query: "",
      shouldCacheResult: false,
    };

    const { customParams, query, shouldCacheResult } = {
      ...defaultOptions,
      ...options,
    };

    let results = null;

    if (!searchIndex.value?.search) {
      await fnInit(indexName.value);
    }

    const params = customParams || defaultSearchParams.value;

    let cachedResult = null;
    let cacheKey = null;

    if (shouldCacheResult) {
      cacheKey = hashString(JSON.stringify(fnCopyParamsForCache(params)));
      cachedResult = await tryCache(cacheKey);

      if (cachedResult) {
        return cachedResult;
      }
    }
    await searchIndex.value
      .search(query, {
        headers: {
          "X-Algolia-UserToken": token.value,
        },
        clickAnalytics: true,
        ...params,
      })
      .then((res: SearchResponse) => {
        results = res;
        if (cacheKey) {
          setKey(cacheKey, results);
        }
      });

    return results;
  };

  /**
   * @name fnPerformMultipleQueriesSearch
   * @desc Performs the queries inputted and runs the callback function
   *
   * @param queries The queries to be executed
   * @param callback The function to be executed
   * @param options
   *        none: each query is important and gets result to reach it's hitsPerPage
   *        stopIfEnoughMatches: stops when total hits hitsPerPage. Which means queries
   *            the beginning gets higher priority
   * */
  const fnPerformMultipleQueriesSearch = async (
    queries,
    callback,
    options = { strategy: "none", shouldCacheResult: false },
  ) => {
    const defaultOptions = { strategy: "none", shouldCacheResult: false };
    const { strategy, shouldCacheResult } = { ...defaultOptions, ...options };

    let cachedResult = null;
    let cacheKey = null;

    if (shouldCacheResult) {
      cacheKey = hashString(
        `${JSON.stringify(options)}.${JSON.stringify(
          fnCopyParamsForCache(queries),
        )}`,
      );

      cachedResult = await tryCache(cacheKey);

      if (cachedResult) {
        await callback(cachedResult);
        return;
      }
    }

    await client.value
      .multipleQueries(queries, { strategy })
      /* { results } is an array in the same order as the queries we sent */
      .then(async ({ results }) => {
        await callback(results);

        if (cacheKey) {
          setKey(cacheKey, results);
        }
      });
  };

  return {
    defaultSearchParams,

    client,
    searchIndex,
    token,
    indexName,

    isLoaded,

    fnInit,
    fnSetIndex,
    fnLoadUserTokens,
    fnPerformSearch,
    fnPerformMultipleQueriesSearch,
  };
};
