import { configureSSR } from "@konfetti-core/core";
import {
  onBeforeMount,
  onServerPrefetch,
  ssrRef,
} from "@nuxtjs/composition-api";

const hasRouteChanged = (ctx) => {
  const { from } = ctx.$router.app.context;
  const { current } = ctx.$router.history;

  if (!from) {
    return false;
  }

  return from.fullPath !== current.fullPath;
};

const ssrPlugin = () => {
  configureSSR({
    kftRef: ssrRef,
    onSSR: (fn) => {
      if (typeof window !== "undefined") {
        return onBeforeMount(fn);
      }

      onServerPrefetch(fn);

      // if (typeof window !== "undefined") {
      //   const vm = getCurrentInstance();
      //   if (hasRouteChanged(vm)) {
      //     return fn();
      //   }
      // }
    },
  });
};

export default ssrPlugin;
