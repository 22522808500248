var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === _vm.EventDateType.PUBLIC || _vm.hasAvailableDates),expression:"type === EventDateType.PUBLIC || hasAvailableDates"}],staticClass:"card kft-buy-box__checkout-container__card p-4 mb-8"},[_c('div',{staticClass:"card-body"},[(_vm.price)?_c('div',{staticClass:"d-flex gap-2 align-items-end"},[_c('div',{staticClass:"price-per-person"},[_vm._v("\n        "+_vm._s((_vm.price + " " + (_vm.type === _vm.EventDateType.PUBLIC ? "" : ("/ " + (_vm.$t("general.perGroup"))))))+"\n      ")]),_vm._v(" "),_c('KftTooltip',{key:_vm.tooltipText,attrs:{"styles":{ 'font-size': '16px' },"tooltip-text":_vm.tooltipText,"length":"large","parent":"<i class='bi bi-info-circle-fill'></i>","type":"span"}})],1):_vm._e(),_vm._v(" "),(_vm.enableGiftcard && _vm.hasAvailableDates)?_c('div',{staticClass:"separator my-3"}):_vm._e(),_vm._v(" "),(_vm.hasAvailableDates)?[_c('div',{ref:"datesMultiselectRef",staticClass:"event-date-picker__input mb-3"},[_c('label',{staticClass:"kft-buy-box__label overline"},[_vm._v(_vm._s(_vm.$t("product.show.checkout.availableTimes")))]),_vm._v(" "),_c('multiselect',{ref:"multiselectCmp",staticClass:"w-100",attrs:{"id":("" + _vm.sharedKey),"allow-empty":false,"options":_vm.datesWithOrder,"clear-on-select":false,"close-on-select":true,"loading":_vm.loadingMoreDates,"option-height":50,"placeholder":_vm.$t('product.show.checkout.availableTimes'),"searchable":false,"show-labels":false,"hide-selected":true},on:{"input":_vm.handleSelectTime,"open":_vm.handleOpenMultiselect,"end-of-scroll":function($event){return _vm.$emit('end-of-scroll')},"scroll":_vm.handleScrollDatesMultiselect},scopedSlots:_vm._u([{key:"caret",fn:function(ref){
          var toggle = ref.toggle;
return [_c('div',{staticClass:"kft-buy-box__checkout-container__multiselect-arrow",on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return toggle()}}},[_c('i',{staticClass:"bi bi-chevron-down"})])]}},{key:"singleLabel",fn:function(props){return [_c('KftBuyBoxDateOption',{attrs:{"options":_vm.datesWithOrder,"option-data":props.option,"show-collapse-button":false,"force-show-multidate":props.isOpen}})]}},{key:"option",fn:function(props){return [_c('KftBuyBoxDateOption',{attrs:{"options":_vm.datesWithOrder,"option-data":props.option},on:{"click":function($event){$event.stopPropagation();}}})]}},{key:"afterList",fn:function(){return [_c('div',{staticClass:"my-2 d-flex justify-content-center"},[_c('span',{staticClass:"overline"},[_vm._v(_vm._s(_vm.$t("product.show.checkout.noMoreDatesAvailable")))])]),_vm._v(" "),(_vm.loadingMoreDates)?_vm._l((3),function(i){return _c('li',{key:("multiselect__option-" + i),staticClass:"multiselect__option"},[_c('content-loader',{staticClass:"w-100",attrs:{"viewBox":"0 0 300 45","height":"45","speed":2,"preserveAspectRatio":"none","primary-color":"#F4F4F4","secondary-color":"rgba(229,229,229,0.8)"}},[_c('rect',{staticClass:"w-100",attrs:{"x":"0","y":"0","rx":"5","ry":"5","width":"300","height":"45"}})])],1)}):_vm._e(),_vm._v(" "),(!_vm.enableEmbedded)?_c('KftBuyBoxMultiselectAfterList',{attrs:{"enable-date-request":_vm.eventGetters.isDateRequestActiveBS(
                  _vm.kftEvent.data,
                  _vm.enableSubdomain
                ),"enable-waitlist":_vm.eventGetters.isWaitlistActive(_vm.kftEvent.data)}}):_vm._e()]},proxy:true}],null,false,880422178),model:{value:(_vm.selectedTime),callback:function ($$v) {_vm.selectedTime=$$v},expression:"selectedTime"}},[_vm._v(" "),_c('template',{slot:"noResult"},[_vm._v("\n            "+_vm._s(_vm.$t("general.select.noResultsFound"))+"\n          ")])],2),_vm._v(" "),(!_vm.enableEmbedded)?_c('KftSubscribeToEventUpdates',{ref:"subscribeToEventUpdatesRef",attrs:{"event-id":_vm.eventGetters.getId(_vm.kftEvent.data)}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"event-date-picker__input mb-3"},[_c('label',{staticClass:"kft-buy-box__label overline"},[_vm._v(_vm._s(_vm.type === _vm.EventDateType.PRIVATE
            ? _vm.$t("product.show.checkout.privateQuantity")
            : _vm.$t("product.show.checkout.quantity")))]),_vm._v(" "),_c('multiselect',{ref:"ticketsMultiselectRef",staticClass:"w-100",attrs:{"allow-empty":false,"options":_vm.availableTickets,"placeholder":_vm.type === _vm.EventDateType.PRIVATE
              ? _vm.$t('product.show.checkout.privateQuantityPlaceholder')
              : _vm.$t('product.show.checkout.quantity'),"searchable":false,"show-labels":false},scopedSlots:_vm._u([{key:"caret",fn:function(){return [_c('div',{staticClass:"kft-buy-box__checkout-container__multiselect-arrow"},[_c('i',{staticClass:"bi bi-chevron-down"})])]},proxy:true},{key:"noResult",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t("general.select.noResultsFound"))+"\n          ")]},proxy:true}],null,false,1539400307),model:{value:(_vm.selectedTicketQuantity),callback:function ($$v) {_vm.selectedTicketQuantity=$$v},expression:"selectedTicketQuantity"}}),_vm._v(" "),(_vm.selectedTicketQuantity === null && _vm.triedBooking)?_c('div',{staticClass:"overline fst-italic mt-1",staticStyle:{"color":"red"}},[_vm._v("\n          "+_vm._s(_vm.$t("product.show.checkout.privateQuantityNoTicketsSelected"))+"\n        ")]):_vm._e()],1),_vm._v(" "),_c('button',{ref:"bookButtonRef",class:[
          'btn btn-shadow w-100',
          'kft-buy-box__add-to-cart-button',
          {
            'mt-5': _vm.hasAvailableDates,
            'btn-primary': _vm.hasAvailableDates && !_vm.enableEmbedded,
            'embedded-btn': _vm.enableEmbedded,
          } ],style:(_vm.cssVars),attrs:{"data-test-id":("kft-buy-box__add-to-cart-button-" + _vm.sharedKey)},on:{"click":_vm.book}},[_vm._v("\n        "+_vm._s(_vm.$t("product.show.checkout.book"))+"\n      ")])]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"kft-buy-box-more-options"},[_vm._t("giftcard-buy-box",function(){return [(_vm.enableGiftcard)?_c('KftGiftcardBuyBox',{attrs:{"primary-button":!_vm.hasAvailableDates,"enable-embedded":_vm.enableEmbedded,"enable-subdomain":_vm.enableSubdomain,"shared-key":_vm.sharedKey,"kft-event-description-obj":_vm.kftEvent},on:{"gift":function($event){return _vm.$emit('gift', $event)}}}):_vm._e()]}),_vm._v(" "),(_vm.showBuyBoxNoDates)?_c('hr',{staticClass:"my-2"}):_vm._e(),_vm._v(" "),(_vm.showBuyBoxNoDates)?_c('KftBuyBoxNoDates',{attrs:{"waitlist-active":_vm.eventGetters.isWaitlistActive(_vm.computedEventData),"request-active":_vm.eventGetters.isDateRequestActiveBS(
            _vm.computedEventData,
            _vm.enableSubdomain
          ),"primary-button":!_vm.enableGiftcard},on:{"click:request-date":_vm.toggleDateRequestSidebar,"click:waitlist":_vm.toggleDateWaitlistSidebar}}):_vm._e()],2)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }