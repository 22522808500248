/* eslint-disable  */

// @francesca- this page is the dynamic /category-gutschein page: content based on category type

export default {
  giftcards: {
    giftAnEvent: 'Geschenkgutschein für Hobbyköche',
    mostGifted: 'Meist begabte Klassen',
    giftOptions: 'Tolle Geschenkideen für Kochliebhaber',
    courses: 'Welchen Kurs willst Du machen?',
    categories: 'Wähle aus unseren Lieblingskategorien',
    header: {
      title: 'Verschenke einen Kochkurs',
      description:
        'Du willst Deinen Liebsten eine Freude machen? Dann verschenke mit unserem Gutschein tolle Kochkurse.',
      button: 'GUTSCHEIN KAUFEN',
      image: '/giftcards/header.png',
      imageMobile: '/giftcards/header.png',
      titleTextColor: '#ffffff',
    },
    // @francesca can't find callToAction on page

    callToAction: {
      title: 'Book Events for Private Groups',
      subtitle:
        'Danish fontina feta cheese and wine. Jarlsberg emmental rubber cheese feta halloumi taleggio danish fontina taleggio. Pecorino stinking bishop taleggio cheddar goat jarlsberg dolcelatte the big cheese. Cut the cheese cream cheese ricotta.',
      buttonText: 'Join',
      inputPlaceholderText: 'hello@gokonfetti.com',
      image: '/homepage/CTA.webp',
      mobileImage: '/homepage/CTA.webp',
    },

    heroes: {
      first: {
        title: 'Freie Wahl beim Gutscheinwert',
        subtitle:
          'Von 15€ bis 200€ - wähle Deinen Gutscheinwert aus. Jederzeit einlösbar.',
        image: '/giftcards/subheader.png',
        mobileImage: '/giftcards/subheader.png',
        buttonText: 'GUTSCHEIN KAUFEN',
      },
      second: {
        title: 'Tolle Geschenkideen für Kochliebhaber',
        subtitle:
          'Mit unserem Geschenkgutschein kannst Du über 500 schöne Kochkurse und Kochevents verschenken. Somit bleibt Dein Geschenk in Erinnerung.',
        image: '/giftcards/cta.png',
        mobileImage: '/giftcards/cta.png',
        buttonText: 'MEHR ERFAHREN ',
      },
    },
  },
};
