import { eventGetters } from "@konfetti/composables";
import {
  AlgoliaEventItem,
  EventDescription,
} from "../../composables/src/types";

declare global {
  interface Window {
    __NUXT__: any;
  }
}

// @todo pass it to .ENV
const MEDIA_BASE_URL = "https://media.gokonfetti.com";
const THUMBNAIL_WIDTH = 200;

export const getThumbnailImage = (
  filename: string,
  id: string | number,
): string => {
  if (id === undefined) {
    return "";
  }

  return `${MEDIA_BASE_URL}/${id}/${THUMBNAIL_WIDTH}x0/${filename}.webp`;
};

export const getSupplierSubdomain = (nuxt, config): string | null => {
  if (typeof window !== "undefined" && window?.__NUXT__?.subdomain) {
    return window.__NUXT__.subdomain;
  }

  return nuxt?.subdomain || config?.subdomain || null;
};

export const addSubdomain = (url, toSubdomain) => {
  if (toSubdomain === null) {
    return url;
  }

  const replace = "://" + toSubdomain + ".";

  // Prepend http://
  if (!/^\w*:\/\//.test(url)) {
    url = "http://" + url;
  }

  return url.replace(/:\/\/(\w*\.)/, `${replace}$1`);
};

export const parseEventDescriptionToAlgoliaEventItem = (
  item: EventDescription,
): Partial<AlgoliaEventItem> => {
  return {
    categories: eventGetters.getCategories(item),
    title: eventGetters.getTitle(item),
    default_duration: eventGetters.getEventMaximumDuration(item),
    default_price: eventGetters.getDefaultPriceObject(item),
    hashed_id: eventGetters.getId(item),
    images: eventGetters.getGallery(item),
    permalink: eventGetters.getPermalink(item),
    supplier: eventGetters.getSupplierName(item),
    next_event: eventGetters.getNextInstanceInTimestamp(item),
    rating: eventGetters.getReviewsRating(item),
  };
};
